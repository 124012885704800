import React from "react";
import { inimmedAxios } from "../../InimmedHelpers";
import PrimaryButton from "../Utility/PrimaryButton";
import TextInput from "../Utility/TextInput";
import AddButton from "../Utility/AddButton";
import { Branch, Client, FormComponentProps } from "../../Interfaces";
import Loading from "../Utility/Loading";
import ClientSelector from "../Utility/Selectors/ClientSelector";
import BranchSelector from "../Utility/Selectors/BranchSelector";

interface ContactFormProps extends FormComponentProps {
    savedInputs?: ContactFormState
}

interface ContactFormState {
    response_clients: Client[],
    client: Client | null,
    response_branches: Branch[],
    branch: Branch | null,
    name: string,
    first_last: string,
    second_last: string,
    email: string,
    phone: string,
    client_input_holder: string
}

class ContactForm extends React.Component<ContactFormProps, ContactFormState> {
    constructor(props: ContactFormProps) {
        super(props);
        this.state = {
            response_clients: [],
            client: null,
            response_branches: [],
            branch: null,
            name: '',
            first_last: '',
            second_last: '',
            email: '',
            phone: '',
            client_input_holder: ''
        }


        this.selectClient = this.selectClient.bind(this);
        this.selectBranch = this.selectBranch.bind(this);
        this.submitContact = this.submitContact.bind(this);
    }

    componentDidMount() {
        if (!this.props.savedInputs) {
            inimmedAxios.get('api/clients')
            .then(response => {
                this.setState({ response_clients: response.data });
            })
            .catch(reason => alert(reason));
        } else {
            this.setState(this.props.savedInputs);
            inimmedAxios.get('api/clients')
            .then(response => {
                this.setState({ response_clients: response.data });
            })
            .catch(reason => alert(reason));
        }
        
    }

    selectClient(client: Client) {
        this.setState({ client });
    }

    selectBranch(branch: Branch) {
        this.setState({ branch: branch });
    }

    submitContact() {
        if (!this.state.client) {
            alert('Por favor elija un cliente correspondiente al nuevo contacto.')
            return;
        }
        if (!this.state.name) {
            alert('Por favor ingrese un nombre para el contacto.');
            return;
        }
        inimmedAxios.post('api/save_contact', {
            client: this.state.client.id,
            branch: this.state.branch?.id,
            name: this.state.name,
            first_last: this.state.first_last,
            second_last: this.state.second_last,
            email: this.state.email,
            phone: this.state.phone
        })
        .then(response => {
            if (response.data.Result === 'Success') {
                if (window.confirm('Contacto guardado, ¿Salir?'))
                    this.props.backFunction();
            }
            else {
                alert(response.data.Result);
            }
        })
        .catch(reason => alert(reason));
    }

    render() {
        if (this.state.response_clients.length) {
            return (
                <div className='form-container'>
                    <h2>Cliente</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Cliente:</label>
                            <div className='form-input-plus'>
                                <ClientSelector selected_client={ this.state.client } clients={ this.state.response_clients } selected={ this.selectClient } />
                                <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'ClientForm', state: this.state})}} />
                            </div>
                        </div>
                        <div className='form-input'>
                            <label>Sucursal:</label>
                            <div className='form-input-plus'>
                                <BranchSelector selected_branch={ this.state.branch } branches={ this.state.response_branches } selected={ this.selectBranch } />
                                <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'BranchForm', state: this.state})}} />
                            </div>
                        </div>
                    </div>
                    <div className='top-separator' />
                    <h2>Detalles</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Nombre:</label>
                            <TextInput onChange={event => this.setState({name: event.target.value})}>{ this.state.name }</TextInput>
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Email:</label>
                            <TextInput onChange={event => this.setState({email: event.target.value})}>{ this.state.email }</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Número de Celular:</label>
                            <TextInput onChange={event => this.setState({phone: event.target.value})}>{ this.state.phone }</TextInput>
                        </div>
                    </div>
                    <div className='top-separator' />
                    <div className='button-strip'>
                        <PrimaryButton onClick={this.submitContact}>Registrar</PrimaryButton>
                    </div>
                </div>
            )
        } else {
            return <Loading color='primary' />;
        }
    }
}
export default ContactForm;