import React from "react";
import { inimmedAxios } from "../../InimmedHelpers";
import PrimaryButton from "../Utility/PrimaryButton";
import DangerButton from "../Utility/DangerButton";
import { toast } from "react-toastify";
import { DetailComponentProps, Employee, Policy } from "../../Interfaces";
import DevicesCatalog from "../Catalogs/DevicesCatalog";

interface PolicyDetailsProps extends DetailComponentProps {
    data: Policy,
    user: Employee
}

class PolicyDetails extends React.Component<PolicyDetailsProps, {}> {
    constructor(props: PolicyDetailsProps) {
        super(props);

        this.goToClient = this.goToClient.bind(this);
        this.goToDevice = this.goToDevice.bind(this);
        this.delete = this.delete.bind(this);
    }

    goToClient() {
        if (typeof this.props.data.client !== 'string') {
            this.props.getDetail('clients', this.props.data.client.id);
        }
    }

    goToDevice(catalog: string, deviceID: number | undefined) {
        if (this.props.data.devices?.length && deviceID) {
            this.props.getDetail(catalog, deviceID);
        }
    }

    delete() {
        if (window.confirm('¿Seguro que desea borrar la póliza "' + this.props.data.display_name + '"?')) {
            inimmedAxios.post('api/delete_policy/' + this.props.data.id)
                .then(response => {
                    if (response.data.Result === 'Success') {
                        toast.success('Póliza eliminada. 🗑️');
                        this.props.getCatalog('policies');
                        this.props.goBack();
                    }
                    else
                        alert(response.data.Result);
                })
                .catch(reason => {
                    alert(reason);
                });
        }
    }

    render() {
        const start_date = new Date(this.props.data.start_date);
        const end_date = new Date(this.props.data.end_date);
        const date_config = {
            weekday: 'long' as const,
            day: '2-digit' as const,
            month: 'long' as const,
            year: 'numeric' as const,
            hour: 'numeric' as const,
            minute: 'numeric' as const,
            hour12: true
        }
        if (typeof this.props.data.client !== 'string') {
            return (
                <div style={{ boxSizing: 'border-box', padding: '1em', fontSize: '1.25em' }} className='float-from-down'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1em', flexWrap: 'wrap' }}>
                        <h1>{this.props.data.display_name}</h1>
                        { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? <PrimaryButton onClick={() => this.props.editFunction(this.props.data.id)}>Editar</PrimaryButton> : null }
                    </div>
                    <p><strong>Fecha de inicio:</strong><br />{ start_date.toLocaleString('es', date_config) }</p>
                    <p><strong>Fecha de conclusión:</strong><br />{ end_date.toLocaleString('es', date_config) }</p>

                    <p><strong>Cliente: </strong><br /><span onClick={ this.goToClient } className='clickable-detail'>{ this.props.data.client.display_name }</span></p>

                    <DevicesCatalog changeSectionFunction={() => null} getDetail={ this.goToDevice } data={ this.props.data.devices ? this.props.data.devices : [] } user={this.props.user} />

                    {this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                        <div>
                            <DangerButton onClick={this.delete}>Eliminar</DangerButton>
                        </div>
                        :
                        null}
                </div>
            );
        }
    }
}

export default PolicyDetails;