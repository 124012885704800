import { currency_formatter } from "../../InimmedHelpers";
import { OwnBankAccount } from "../../Interfaces";

const CARD_WIDTH = 3.37;
const CARD_HEIGHT = 2.125;
const CARD_RADIUS = 0.125;
const CARD_FACTOR = 80;

interface OwnBankAccountProps {
    account: OwnBankAccount;
    onClick: (own_bank_account_id: number) => void;
}

export function SantanderBankAccount(props: OwnBankAccountProps) {
    let account_censors = ''
    for (let i = 0; i < props.account.account_number.length - 4; i++) {
        if (i % 4 === 0 && i !== 0) {
            account_censors += '* ';
        } else {
            account_censors += '*';
        }
    }
    return(
        <div
            className='own_bank_account_card'
            style={{
                minWidth: (CARD_WIDTH * CARD_FACTOR).toString() + 'px',
                minHeight: (CARD_HEIGHT * CARD_FACTOR).toString() + 'px',
                backgroundColor: '#e00000',
                color: '#fafafa',
                borderRadius:(CARD_RADIUS * CARD_FACTOR).toString() + 'px'
            }}
            onClick={() => props.onClick(props.account.id)}>
            <h2 style={{ margin: '0' }}>Santander</h2>
            <h3 style={{ margin: '0' }} >{ account_censors + props.account.account_number.slice(props.account.account_number.length - 4, props.account.account_number.length) }</h3>
            <p style={{ margin: '0' }}>{ currency_formatter.format(Number.parseFloat(props.account.balance)) }</p>
        </div>
    );
}

export function HSBCBankAccount(props: OwnBankAccountProps) {
    let account_censors = ''
    for (let i = 0; i < props.account.account_number.length - 4; i++) {
        if (i % 4 === 0 && i !== 0) {
            account_censors += '* ';
        } else {
            account_censors += '*';
        }
    }
    return(
        <div
            className='own_bank_account_card'
            style={{
                minWidth: (CARD_WIDTH * CARD_FACTOR) + 'px',
                minHeight: (CARD_HEIGHT * CARD_FACTOR).toString() + 'px',
                backgroundColor: 'white',
                color: '#e00000',
                borderRadius:(CARD_RADIUS * CARD_FACTOR).toString() + 'px'
            }}
            onClick={() => props.onClick(props.account.id)}>
            <h2 style={{ margin: '0' }}>HSBC</h2>
            <h3 style={{ margin: '0' }} >{ account_censors + props.account.account_number.slice(props.account.account_number.length - 4, props.account.account_number.length) }</h3>
            <p style={{ margin: '0' }}>{ currency_formatter.format(Number.parseFloat(props.account.balance)) }</p>
        </div>
    );
}

export function INBURSABankAccount(props: OwnBankAccountProps) {
    let account_censors = ''
    for (let i = 0; i < props.account.account_number.length - 4; i++) {
        if (i % 4 === 0 && i !== 0) {
            account_censors += '* ';
        } else {
            account_censors += '*';
        }
    }
    return(
        <div
            className='own_bank_account_card'
            style={{
                minWidth: (CARD_WIDTH * CARD_FACTOR) + 'px',
                minHeight: (CARD_HEIGHT * CARD_FACTOR).toString() + 'px',
                backgroundColor: '#011f44',
                color: 'white',
                borderRadius:(CARD_RADIUS * CARD_FACTOR).toString() + 'px'
            }}
            onClick={() => props.onClick(props.account.id)}>
            <h2 style={{ margin: '0' }}>INBURSA</h2>
            <h3 style={{ margin: '0' }} >{ account_censors + props.account.account_number.slice(props.account.account_number.length - 4, props.account.account_number.length) }</h3>
            <p style={{ margin: '0' }}>{ currency_formatter.format(Number.parseFloat(props.account.balance)) }</p>
        </div>
    );
}

export function EfectivoBankAccount(props: OwnBankAccountProps) {
    let account_censors = ''
    for (let i = 0; i < props.account.account_number.length - 4; i++) {
        if (i % 4 === 0 && i !== 0) {
            account_censors += '* ';
        } else {
            account_censors += '*';
        }
    }
    return(
        <div
            className='own_bank_account_card'
            style={{
                minWidth: (CARD_WIDTH * CARD_FACTOR) + 'px',
                minHeight: (CARD_HEIGHT * CARD_FACTOR).toString() + 'px',
                backgroundColor: 'white',
                color: 'green',
                borderRadius:(CARD_RADIUS * CARD_FACTOR).toString() + 'px'
            }}
            onClick={() => props.onClick(props.account.id)}
        >
            <h2 style={{ margin: '0' }}>EFECTIVO</h2>
            <h3 style={{ margin: '0' }} >{ account_censors + props.account.account_number.slice(props.account.account_number.length - 4, props.account.account_number.length) }</h3>
            <p style={{ margin: '0' }}>{ currency_formatter.format(Number.parseFloat(props.account.balance)) }</p>
        </div>
    );
}