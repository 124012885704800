import React from "react";
import Catalog from "./Catalog";
import { ServiceOrder } from "../../Interfaces";

interface ServiceOrdersCatalogProps {
    getDetail: (itemID: number) => void,
    data: ServiceOrder[]
}

class ServiceOrdersCatalog extends React.Component<ServiceOrdersCatalogProps, {}> {
    render() {
        return (
            <Catalog
                data={this.props.data}
                headersForTable={[{name: 'Folio', width: '20%', data: 'number'}, {name: 'Ingeniero', width: '60%', data: 'engineer'}, {name: 'Estatus', width: '20%', data: 'status'}]}
                dataHeaders={['number', 'engineer', 'status']}
                searchHeaders={['number', 'engineer', 'status']}
                objectType='Órdenes de Servicio'
                newButtonClick={() => null}
                newButtonPrompt=''
                getDetail={id => this.props.getDetail(id)}
                searchPlaceholder='Buscar órdenes de servicio'
                itemsPerPage={10} />
        )
    }
}
export default ServiceOrdersCatalog;