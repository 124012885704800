import { inimmedAxios } from "../../InimmedHelpers";
import React from "react";
import TextInput from "../Utility/TextInput";
import PrimaryButton from "../Utility/PrimaryButton";
import { Client, FormComponentProps, State, Zone } from "../../Interfaces";
import { RadioMenu } from "../Utility/RadioMenu";

interface ClientFormProps extends FormComponentProps {
    data?: Client
}

interface ClientFormState {
    email: string;
    RFC: string;
    name: string;
    first_last: string;
    second_last: string;
    commercial_name: string;
    alias: string;
    social_reason: string;
    fixed_phone: string;
    whatsapp: string;
    location_url: string;
    state: State | null;
    response_states: State[];
    county: string;
    district: string;
    street: string;
    number: string;
    postal_code: string;
    zone: number | null;
    person_type: string;
    main_contact_name: string;
}

class ClientForm extends React.Component<ClientFormProps, ClientFormState> {
    constructor(props: ClientFormProps) {
        super(props);
        this.state = {
            name: '',
            first_last: '',
            second_last: '',
            commercial_name: '',
            alias: '',
            RFC: '',
            social_reason: '',
            email: '',
            fixed_phone: '',
            whatsapp: '',
            location_url: '',
            state: null,
            response_states: [],
            county: '',
            district: '',
            street: '',
            number: '',
            postal_code: '',
            zone: 0,

            person_type: 'Persona Física',
            main_contact_name: '',
        }
        this.submitClient = this.submitClient.bind(this);
        this.selectState = this.selectState.bind(this);
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                name: this.props.data.name,
                first_last: this.props.data.first_last,
                second_last: this.props.data.second_last,
                commercial_name: this.props.data.commercial_name,
                alias: this.props.data.alias,
                RFC: this.props.data.RFC,
                social_reason: this.props.data.social_reason,
                email: this.props.data.email,
                fixed_phone: this.props.data.fixed_phone,
                whatsapp: this.props.data.fixed_phone,
                location_url: this.props.data.location_url,
                state: this.props.data.state,
                county: this.props.data.county,
                district: this.props.data.district,
                street: this.props.data.street,
                number: this.props.data.number,
                postal_code: this.props.data.postal_code,
                zone: typeof this.props.data.zone === 'string' ? null : this.props.data.zone
            });
        }
        inimmedAxios.get('api/get_states')
            .then(response => this.setState({ response_states: response.data }))
            .catch(reason => alert(reason));
    }

    handleStates() {
        let states_to_list: JSX.Element[] = [];
        states_to_list.push(<option key='default' value='0'>Elija un estado</option>);
        for (const state_to_list of this.state.response_states) {
            if (this.state.state?.id === state_to_list.id) {
                states_to_list.push(<option selected key={state_to_list.id} value={state_to_list.id}>{state_to_list.display_name}</option>);
                continue;
            }
            states_to_list.push(<option key={state_to_list.id} value={state_to_list.id}>{state_to_list.display_name}</option>);
        }
        return states_to_list;
    }

    selectState(event: React.ChangeEvent<HTMLSelectElement>) {
        if (event.target.value === '0') {
            this.setState({ state: null })
        }
        for (const state of this.state.response_states) {
            if (state.id === Number.parseInt(event.target.value)) {
                this.setState({ state: state });
            }
        }
    }

    submitClient() {

        if (this.state.person_type === 'Persona Moral') {
            if (!this.state.social_reason) {
                alert('Por favor ingrese o corrija la razón social del cliente.');
                return;
            }

            if (!this.state.RFC) {
                alert('Por favor ingrese o corrija el nombre del cliente.');
                return;
            }
        } else if (this.state.person_type === 'Persona Física') {
            if (!this.state.name) {
                alert('Por favor ingrese o corrija el nombre del cliente.');
                return;
            }
        }

        if (!this.state.email && !this.state.fixed_phone) {
            alert('Por favor ingrese el email o teléfono del contacto principal del cliente.');
            return;
        }

        const url = this.props.data ? 'api/save_client/' + this.props.data.id : 'api/save_client';
        inimmedAxios.post(url, {
            name: this.state.name,
            first_last: this.state.first_last,
            second_last: this.state.second_last,
            alias: this.state.alias,
            RFC: this.state.RFC,
            commercial_name: this.state.commercial_name,
            social_reason: this.state.social_reason,
            email: this.state.email,
            phone: this.state.fixed_phone,
            whatsapp: this.state.whatsapp,
            location_url: this.state.location_url,
            state: this.state.state?.id,
            county: this.state.county,
            district: this.state.district,
            street: this.state.street,
            number: this.state.number,
            postal_code: this.state.postal_code,
            main_contact_name: this.state.main_contact_name,
        })
            .then(response => {
                if (response.data.Result === 'Success') {
                    if (window.confirm('Cliente guardado, ¿Salir?')) {
                        if (this.props.data && this.props.getCatalog && this.props.getDetail) {
                            this.props.getCatalog('clients');
                            this.props.getDetail('clients', this.props.data.id, true);
                        }
                        this.props.backFunction();
                    }
                } else {
                    alert(response.data.Result);
                }
            })
            .catch(reason => {
                alert(reason);
            });
    }

    identitySection() {
        if (this.state.person_type === 'Persona Física') {
            return (
                <div className='form-input'>
                    <label>Nombre:</label>
                    <TextInput onChange={event => this.setState({ name: event.target.value })}>{this.state.name}</TextInput>
                </div>
            );
        } else {
            return (
                <div className='form-input'>
                    <label>Razón Social:</label>
                    <TextInput onChange={event => this.setState({ social_reason: event.target.value })}>{this.state.social_reason}</TextInput>
                </div>
            )
        }
    }

    render() {
        return (
            <>
                <RadioMenu setActive={option => this.setState({ person_type: option })} active={this.state.person_type} options={['Persona Física', 'Persona Moral']} ></RadioMenu>
                <div className='form-container'>
                    <h2>Identificación</h2>
                    <div className='form-input-div'>
                    { this.identitySection() }
                        <div className='form-input'>
                            <label>RFC:</label>
                            <TextInput onChange={event => this.setState({ RFC: event.target.value })}>{this.state.RFC}</TextInput>
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Nombre Comercial:</label>
                            <TextInput onChange={event => this.setState({ commercial_name: event.target.value })}>{this.state.commercial_name}</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Alias:</label>
                            <TextInput onChange={event => this.setState({ alias: event.target.value })}>{this.state.alias}</TextInput>
                        </div>
                    </div>
                    <div className='top-separator'></div>
                    <h2>Contacto Principal</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Nombre:</label>
                            <TextInput onChange={event => this.setState({ main_contact_name: event.target.value })}>{this.state.main_contact_name}</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Teléfono/Celular:</label>
                            <TextInput onChange={event => this.setState({ fixed_phone: event.target.value })}>{this.state.fixed_phone}</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Email:</label>
                            <TextInput onChange={event => this.setState({ email: event.target.value })}>{this.state.email}</TextInput>
                        </div>
                    </div>
                    <div className='top-separator'></div>
                    <h2>Domicilio</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Estado:</label>
                            <select onChange={this.selectState}>
                                {this.handleStates()}
                            </select>
                        </div>
                        <div className='form-input'>
                            <label>Ciudad/Municipio:</label>
                            <TextInput onChange={event => this.setState({ county: event.target.value })}>{this.state.county}</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Colonia:</label>
                            <TextInput onChange={event => this.setState({ district: event.target.value })}>{this.state.district}</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Vialidad:</label>
                            <TextInput onChange={event => this.setState({ street: event.target.value })}>{this.state.street}</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Número:</label>
                            <TextInput onChange={event => this.setState({ number: event.target.value })}>{this.state.number}</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>C.P.:</label>
                            <TextInput onChange={event => this.setState({ postal_code: event.target.value })}>{this.state.postal_code}</TextInput>
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Ubicación</label>
                            <TextInput onChange={event => this.setState({ location_url: event.target.value })}>{this.state.location_url}</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Zona:</label>
                            <select defaultValue={0} onChange={event => this.setState({ zone: Number.parseInt(event.target.value) })}>
                                <option value={0}>Elija una zona</option>
                                <option value={4}>Xalapa</option>
                                <option value={5}>Xalapa (Alrededores)</option>
                                <option value={3}>Cuernavaca</option>
                                <option value={2}>Córdoba</option>
                                <option value={1}>Sur</option>
                            </select>
                        </div>
                    </div>
                    <div className='top-separator'></div>
                    <div className='button-strip'>
                        <PrimaryButton onClick={this.submitClient}> {this.props.data ? 'Guardar' : 'Registrar'} </PrimaryButton>
                    </div>
                </div>
            </>
        )
    }
}
export default ClientForm;