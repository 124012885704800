import { inimmedAxios } from "./InimmedHelpers";

export const deleteMonthBudgetConcept = async (id: number) => {

    const response = await inimmedAxios.delete('api_banks/delete_month_budget_concept', {
        params: {
            month_budget_concept_id: id
        }
    });
    return response.data;
}