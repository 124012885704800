import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import Section from './Section';
import { inimmedAxios } from '../InimmedHelpers';
import { Employee, WorkOrder } from '../Interfaces';
import WorkOrdersCatalog from "./Catalogs/WorkOrdersCatalog";

interface DashboardSectionProps {
    changeSectionFunction: (section: number, IDToQueue: number | null, subsection: string | null) => void,
    goToFormFunction: (section: number, IDToQueue: number, subsection: string | null) => void,
    popQueuedSection: () => void,
    user: Employee,
}

interface DashboardSectionState {
    title: string,
    work_orders: WorkOrder[],

}

class DashboardSection extends React.Component<DashboardSectionProps, DashboardSectionState> {
    mobile: boolean;

    constructor(props: DashboardSectionProps) {
        super(props);
        this.state = {
            title: 'Inicio',
            work_orders: []
        }
        this.mobile = window.innerWidth > 768 ? false : true;
    
        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            Filler
        );
    }

    componentDidMount() {
        if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4') {
            inimmedAxios.get('api/work_orders_of/' + this.props.user.id)
                .then(response => {
                    this.setState({ work_orders: response.data })
                })
                .catch(reason => alert(reason));
        }
    }

    handleUserDashboard() {
        if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4') {
            let options1 = {
                responsive: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Servicios de Marzo'
                    }
                }
            };
            let labels1 = ['Israel', 'Saúl', 'José', 'Juan', 'Gustavo']
            let dataset1 = [
                {
                    backgroundColor: ['#ff844c', '#e7ff8c'],
                    borderColor: ['#b91400', '#7ecb20'],
                    data: [8, 10, 11, 5, 8]
                }];
            let options2 = {
                responsive: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Servicios del año'
                    }
                }
            };
            let labels2 = ['Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre', 'Enero', 'Febrero'];
            let dataset2 = [
                {
                    pointRadius: 3,
                    fill: true,
                    backgroundColor: '#e7ff8c55',
                    pointBackgroundColor: '#ff844c',
                    borderColor: '#ff844c',
                    borderWidth: 2,
                    data: [67, 53, 88, 67, 101, 92, 76, 88, 90, 92, 67, 80],
                    tension: 0.1
                }];
            return (
                <div>
                    <div style={{ margin: this.mobile ? '5px' : '2em' }}>
                        <h2 style={{ fontSize: '2em', margin: '0', fontFamily: 'Ubuntu' }} >Servicios</h2>
                        <WorkOrdersCatalog data={this.state.work_orders} user={this.props.user} getDetail={(itemID?: number) => {if (itemID) this.props.changeSectionFunction(4, itemID, null)}} changeSectionFunction={this.props.changeSectionFunction} />
                    </div>
                    <div style={{ margin: this.mobile ? '5px' : '2em' }}>
                        <h2 style={{ fontSize: '2em', margin: '0', fontFamily: 'Ubuntu' }}>Estadísticas de Servicios</h2>
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', gap: '4em', padding: '0.5em', boxSizing: 'border-box' }}>
                            <Bar options={options1} data={{ datasets: dataset1, labels: labels1 }} width={'300'} height={'300'} />
                            <Line options={options2} data={{ datasets: dataset2, labels: labels2 }} width={'300'} height={'300'} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    render(): React.ReactNode {
        return (
            <Section dont_reset_scroll={true} title={this.state.title}>
                {this.handleUserDashboard()}
            </Section>
        )
    }
}

export default DashboardSection;