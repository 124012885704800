import React from "react";

interface AddButtonProps {
    onClick: (event: any) => void;
    width?: string;
    not_for_form?: boolean;
}

class AddButton extends React.Component<AddButtonProps, {}> {
    render() {
        return (
            <button className='add-button' style={{ position: this.props.not_for_form ? 'static' : 'absolute' }} onClick={this.props.onClick} >
                <img alt='nuevo' src='icons/plus.svg' className='add-button-icon' style={{width: '1.5em'}} />
            </button>
        );
    }
}

export default AddButton;