import { Device } from "../../Interfaces"

interface DeviceCollectionProps {
    removeDevice: (device_id: number) => void,
    devices: Device[]
}

export function DeviceCollection(props: DeviceCollectionProps) {
    const devices_to_list: JSX.Element[] = [];
    for (const device of props.devices) {
        if (typeof device.model === 'string') {
            devices_to_list.push(
                <div key={ devices_to_list.length } style={{ outline: 'solid 1px rgba(0, 0, 0, 0.1)', borderRadius: '10px', backgroundColor: 'white', boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.1)' }}>
                    <div style={{ backgroundColor: 'var(--primary-color)', padding: '2px 0 2px 10px', display: 'flex' }}>
                        <p style={{ margin: '0', color: 'var(--on-primary-color)' }}>{ devices_to_list.length + 1 }</p>
                        <img alt='cerrar' onClick={() => props.removeDevice(device.id)} src='icons/close.svg' style={{ marginLeft: 'auto', width: '1em', marginRight: '10px', filter: 'invert(100%)', cursor: 'pointer' }} />
                    </div>
                    <p style={{ margin: '0', padding: '10px' }}>{ device.display_name }</p>
                </div>
            );
        }
    }

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', padding: '10px', userSelect: 'none', MozUserSelect: 'none', WebkitUserSelect: 'none' }}>
            { devices_to_list }
        </div>
    );
}