import React from "react";

interface BackButtonProps {
    onClick: () => void
}

class BackButton extends React.Component<BackButtonProps, {}> {
    mobile: boolean;
    
    constructor(props: BackButtonProps) {
        super(props);
        this.mobile = window.innerWidth > 768 ? false : true;
    }

    render() {
        return (
            <button className='back-button' onClick={this.props.onClick} style={{ fontSize: this.mobile ? '1em' : '1.5em' }}>{'<'}</button>
        );
    }
}

export default BackButton;