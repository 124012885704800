import { toast } from "react-toastify";
import { inimmedAxios } from "../../InimmedHelpers";
import { Contact } from "../../Interfaces"

interface ContactCardProps {
    contact: Contact;
    mobile: Boolean;
    main_contact_id?: number;
    getDetail: (catalog: string, itemID?: number, replace?: boolean) => void;
}

export function ContactCard(props: ContactCardProps) {
    const mobile = window.innerWidth > 768 ? false : true;

    const changeMainContact = () => {
        if (props.main_contact_id === props.contact.id) {
            return;
        }
        if (window.confirm(`¿Desea cambiar el contacto principal de este cliente a ${props.contact.display_name}?`)) {
            inimmedAxios.post('/api/change_client_main_contact', {
                new_contact_id: props.contact.id,
                client_id: props.contact.client_id
            })
                .then(resp => {
                    if (resp.data.Result === 'Success') {
                        toast('Contacto principal cambiado', {
                            icon: '✅'
                        });
                        props.getDetail('clients', props.contact.client_id, true);
                    } else {
                        throw new Error('Hubo un error al cambiar el contacto principal');
                    }
                })
                .catch(error => {
                    toast('Hubo un error al cambiar el contacto principal', {
                        icon: '❌'
                    });
                    console.error(error);
                });
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', outline: 'solid 1px rgba(0, 0, 0, 0.1)', borderRadius: '10px', fontSize: props.mobile ? '0.5em' : '', backgroundColor: 'var(--on-primary-color)', boxShadow: '-2px 2px 5px 0 rgba(0, 0, 0, 0.1)', minWidth: '25vw', }}>
            <p style={{ margin: '0', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', fontWeight: 'bold', padding: mobile ? '5px' : '10px', borderRadius: '10px 10px 0 0' }}>{props.contact.display_name}</p>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '1em 0.5em', gap: '1em' }}>
                <p style={{ margin: 0 }}><b>Email: </b>{props.contact.email}</p>
                <p style={{ margin: 0 }}><b>Teléfono: </b>{props.contact.phone}</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input onClick={() => changeMainContact()} type="checkbox" checked={props.main_contact_id === props.contact.id}></input>
                    <p style={{ margin: 0 }}>&nbsp;<b>Contacto Principal</b></p>
                </div>
            </div>
        </div>
    )
}

interface ContactStripProps {
    contacts: Contact[];
    main_contact_id?: number;
    getDetail: (catalog: string, itemID?: number, replace?: boolean) => void;
}

export function ContactStrip(props: ContactStripProps) {
    const mobile: boolean = window.innerWidth > 768 ? false : true;
    const contacts_to_list: JSX.Element[] = [];
    const mainContactIndex = props.contacts.findIndex(iContact => iContact.id === props.main_contact_id);
    if (mainContactIndex > -1) {
        const [mainContact] = props.contacts.splice(mainContactIndex, 1);
        props.contacts.unshift(mainContact);
    }
    for (const contact of props.contacts) {
        contacts_to_list.push(<ContactCard key={contacts_to_list.length} mobile={mobile} contact={contact} main_contact_id={props.main_contact_id} getDetail={props.getDetail} />);
    }
    return (
        <div className='float-from-down' style={{ display: 'flex', gap: '20px', padding: '20px 50px', justifyContent: 'center', position: 'relative', width: '100%', flexWrap: 'wrap' }}>
            {props.contacts.length ? contacts_to_list : <p style={{ color: 'rgba(0, 0, 0, 0.5)', margin: '0' }}>Sin contactos</p>}
        </div>
    );
}