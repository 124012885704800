import { OwnBankAccount } from "../../../Interfaces";
import ItemSelector from "./ItemSelector";

interface OwnBankAccountSelectorProps {
    selected: (own_bank_account: any) => void;
    own_bank_accounts: OwnBankAccount[];
    selected_own_bank_account: OwnBankAccount | null;
}

export default (props: OwnBankAccountSelectorProps) => {
    return (
        <ItemSelector selected_item={ props.selected_own_bank_account } items={ props.own_bank_accounts } selected={ props.selected } />
    );
}