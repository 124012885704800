import React from "react";
import { DeviceType } from "../../../Interfaces";
import ItemSelector from "./ItemSelector";

interface DeviceTypeSelectorProps {
    selected: (device_type: any) => void,
    device_types: DeviceType[],
    selected_device_type: DeviceType | null
}

class DeviceTypeSelector extends React.Component<DeviceTypeSelectorProps, {}> {
    render() {
        return (
            <ItemSelector selected_item={ this.props.selected_device_type } items={ this.props.device_types } selected={ this.props.selected } />
        )
    }
}

export default DeviceTypeSelector;