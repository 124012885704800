import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { inimmedAxios, currency_formatter } from "../../InimmedHelpers";
import { Account, SaleAccountStatus, Expense, Income, PaymentForm, PurchaseAccount, SaleAccount, PurchaseAccountStatus } from "../../Interfaces"
import ExpensesCatalog from "../Catalogs/ExpensesCatalog";
import IncomesCatalog from "../Catalogs/IncomesCatalog";
import DangerButton from "../Utility/DangerButton";
import SecondaryButton from "../Utility/SecondaryButton";

export interface AccountDetailsProps {
    goBack: () => void;
    editAccount: (account: Account, account_type: 0 | 1) => void;
    getIncomeDetail: (income_id: number) => void;
    getExpenseDetail: (expense_id: number) => void;
    newIncome?: (account?: SaleAccount) =>  void;
    newExpense?: (account: PurchaseAccount) => void;
    data: SaleAccount | PurchaseAccount;
}

export const AccountDetails = (props: AccountDetailsProps) => {
    const mobile = window.innerWidth > 768 ? false : true;
    const date_config: Intl.DateTimeFormatOptions = {
        day: '2-digit' as const,
        month: '2-digit' as const,
        year: 'numeric' as const
    }
    const status_style: React.CSSProperties = {
        margin: '0',
        backgroundColor: 'var(--on-primary-color)',
        color: 'var(--primary-color)',
        width: 'fit-content',
        padding: mobile ? '3px 6px' : '5px 15px',
        borderRadius: mobile ? '5px' : '10px',
        fontSize: mobile ? '0.75em' : ''
    }
    switch (props.data.status) {
        case '1':
            status_style.backgroundColor = 'red';
            status_style.color = 'var(--on-primary-color)';
            break;
        case '2':
            status_style.backgroundColor = 'yellow';
            status_style.color = 'black';
            break;
        case '4':
            status_style.backgroundColor = 'var(--secondary-dark-color)';
            status_style.color = 'var(--on-primary-color)';
    }

    const delete_account = () => {
        if ('client' in props.data) {
            if (window.confirm('¿Seguro/a que desea eliminar esta cuenta y todos sus registros de ingresos?')) {
                inimmedAxios.post('api_banks/delete_sale_account', {
                    account_id: props.data.id
                })
                .then(response => {
                    if (response.data.Result === 'Ok') {
                        toast.success('Cuenta eliminada. 🗑️');
                        props.goBack();
                    } else {
                        alert(response.data.Result);
                    }
                })
                .catch(reason => alert(reason));
            }
        } else if ('supplier' in props.data) {
            if (window.confirm('¿Seguro/a que desea eliminar esta cuenta y todos sus registros de ingresos?')) {
                inimmedAxios.post('api_banks/delete_purchase_account', {
                    account_id: props.data.id
                })
                .then(response => {
                    if (response.data.Result === 'Ok') {
                        toast.success('Cuenta eliminada. 🗑️');
                        props.goBack();
                    } else {
                        alert(response.data.Result);
                    }
                })
                .catch(reason => alert(reason));
            }
        }
    }

    const [response_incomes, setResponseIncomes] = useState([] as Income[]);
    const [response_expenses, setResponseExpenses] = useState([] as Expense[]);

    const use_effect_deps = [response_incomes.length, response_expenses.length];
    if ('client' in props.data) use_effect_deps.push(props.data.amount_collected)

    useEffect(() => {
        if ('client' in props.data) {
            inimmedAxios.get('api_banks/get_sale_account_incomes', {
                params: {
                    account_id: props.data.id
                }
            })
            .then(response => {
                setResponseIncomes(response.data);
            })
            .catch(reason => alert(reason));
        } else if ('supplier' in props.data) {
            inimmedAxios.get('api_banks/get_purchase_account_expenses', {
                params: {
                    account_id: props.data.id
                }
            })
            .then(response => {
                setResponseExpenses(response.data);
            })
            .catch(reason => alert(reason));
        }
    }, use_effect_deps);

    if ('client' in props.data && typeof props.data.client !== 'string') {
        return (
            <div style={{ padding: mobile ? '10px' : '20px' }}>
                <div style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '-5px 5px 5px 0 rgba(0, 0, 0, 0.1)' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', backgroundColor: 'var(--primary-color)', padding: '10px', borderRadius: '15px', flexDirection: mobile ? 'column' : 'row', alignItems: mobile ? '' : 'center', boxShadow: '-5px 5px 5px 0 rgba(0, 0, 0, 0.1)', userSelect: 'none' }}>
                        <h2 className='float-from-right' style={{ margin: '0', color: 'var(--on-primary-color)' }}>{ props.data.concept }</h2>
                        <h3 style={status_style}>{ SaleAccountStatus[Number.parseInt(props.data.status)].toUpperCase() }</h3>
                        <div className='top-separator' style={{ margin: '10px auto' }} />
                        <div className='float-from-right'>
                            <h1 style={{ margin: '0', fontSize: mobile ? '' : '3em', color: 'var(--on-primary-color)' }}>{ currency_formatter.format(Number.parseFloat(props.data.total_amount)) }</h1>
                            <p style={{ margin: '0', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-10px)' }}>Monto total</p>
                        </div>
                        <div className='float-from-right'>
                            <h1 style={{ margin: '0', fontSize: mobile ? '' : '3em', color: 'var(--on-primary-color)' }}>{ currency_formatter.format(props.data.amount_collected) }</h1>
                            <p style={{ margin: '0', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-10px)' }}>Monto cobrado</p>
                        </div>
                        <div className='float-from-right'>
                            <h1 style={{ margin: '0', fontSize: mobile ? '' : '3em', color: 'var(--on-primary-color)' }}>{ currency_formatter.format(props.data.amount_to_collect) }</h1>
                            <p style={{ margin: '0', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-10px)' }}>Monto por cobrar</p>
                        </div>
                        <div className='top-separator' style={{ margin: '10px auto' }} />
                        <div className='float-from-right' style={{ display: 'flex', width: '100%', columnGap: '20px', rowGap: '0', flexWrap: 'wrap', justifyContent: mobile ? '' : 'space-evenly' }}>
                            <div>
                                <p style={{ margin: '0', fontSize: mobile ? '0.75em' : '', color: 'var(--on-primary-color)', fontWeight: 'bold' }}>{ props.data.client.display_name }</p>
                                <p style={{ margin: '0', fontSize: mobile ? '0.7em' : '', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-4px)' }}>Cliente</p>
                            </div>
                            <div>
                                <p style={{ margin: '0', fontSize: mobile ? '0.75em' : '', color: 'var(--on-primary-color)', fontWeight: 'bold' }}>{ PaymentForm[Number.parseInt(props.data.payment_form)].toUpperCase() }</p>
                                <p style={{ margin: '0', fontSize: mobile ? '0.7em' : '', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-4px)' }}>Forma de pago</p>
                            </div>
                            <div>
                                <p style={{ margin: '0', fontSize: mobile ? '0.75em' : '', color: 'var(--on-primary-color)', fontWeight: 'bold' }}>{ props.data.invoice }</p>
                                <p style={{ margin: '0', fontSize: mobile ? '0.7em' : '', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-4px)' }}>Factura</p>
                            </div>
                            <div>
                                <p style={{ margin: '0', fontSize: mobile ? '0.75em' : '', color: 'var(--on-primary-color)', fontWeight: 'bold' }}>{ new Date(props.data.initial_pay_date).toLocaleString('es-MX', date_config) }</p>
                                <p style={{ margin: '0', fontSize: mobile ? '0.7em' : '', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-4px)' }}>Fecha de pago inicial</p>
                            </div>
                            <div>
                                <p style={{ margin: '0', fontSize: mobile ? '0.75em' : '', color: 'var(--on-primary-color)', fontWeight: 'bold' }}>{ new Date(props.data.due_date).toLocaleString('es-MX', date_config) }</p>
                                <p style={{ margin: '0', fontSize: mobile ? '0.7em' : '', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-4px)' }}>Fecha de vencimiento</p>
                            </div>
                            {
                                props.data.month_payday ?
                                    <div>
                                        <p style={{ margin: '0', fontSize: mobile ? '0.75em' : '', color: 'var(--on-primary-color)', fontWeight: 'bold' }}>{ new Date(props.data.month_payday).toLocaleString('es-MX', {day: '2-digit'}) }</p>
                                        <p style={{ margin: '0', fontSize: mobile ? '0.7em' : '', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-4px)' }}>Fecha de pago mensual</p>
                                    </div>
                                :
                                    null
                            }
                        </div>
                    </div>
                    <div className='button-strip float-from-right' style={{ padding: '10px'}}>
                        <SecondaryButton onClick={() => props.editAccount(props.data, 0) } >Editar</SecondaryButton>
                        <DangerButton onClick={ delete_account }>Eliminar</DangerButton>
                    </div>
                </div>
                <div className='top-separator' />
                <h2>Ingresos de esta Cuenta</h2>
                <IncomesCatalog
                getIncome={ props.getIncomeDetail }
                newIncome={ () => {
                    if (props.newIncome && 'client' in props.data) {
                        props.newIncome(props.data);
                    }
                }} data={ response_incomes }  />
            </div>
        );
    } else if ('supplier' in props.data && typeof props.data.supplier !== 'string') {
        return (
            <div style={{ padding: mobile ? '10px' : '20px' }}>
                <div style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '-5px 5px 5px 0 rgba(0, 0, 0, 0.1)' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', backgroundColor: 'var(--primary-color)', padding: '10px', borderRadius: '15px', flexDirection: mobile ? 'column' : 'row', alignItems: mobile ? '' : 'center', boxShadow: '-5px 5px 5px 0 rgba(0, 0, 0, 0.1)', userSelect: 'none' }}>
                        <h2 className='float-from-right' style={{ margin: '0', color: 'var(--on-primary-color)' }}>{ props.data.concept }</h2>
                        <h3 style={status_style}>{ PurchaseAccountStatus[Number.parseInt(props.data.status)].toUpperCase() }</h3>
                        <div className='top-separator' style={{ margin: '10px auto' }} />
                        <div className='float-from-right'>
                            <h1 style={{ margin: '0', fontSize: mobile ? '' : '3em', color: 'var(--on-primary-color)' }}>{ currency_formatter.format(Number.parseFloat(props.data.total_amount)) }</h1>
                            <p style={{ margin: '0', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-10px)' }}>Monto total</p>
                        </div>
                        <div className='float-from-right'>
                            <h1 style={{ margin: '0', fontSize: mobile ? '' : '3em', color: 'var(--on-primary-color)' }}>{ currency_formatter.format(props.data.amount_payed) }</h1>
                            <p style={{ margin: '0', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-10px)' }}>Monto pagado</p>
                        </div>
                        <div className='float-from-right'>
                            <h1 style={{ margin: '0', fontSize: mobile ? '' : '3em', color: 'var(--on-primary-color)' }}>{ currency_formatter.format(props.data.amount_to_pay) }</h1>
                            <p style={{ margin: '0', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-10px)' }}>Monto por pagar</p>
                        </div>
                        <div className='top-separator' style={{ margin: '10px auto' }} />
                        <div className='float-from-right' style={{ display: 'flex', width: '100%', columnGap: '20px', rowGap: '0', flexWrap: 'wrap', justifyContent: mobile ? '' : 'space-evenly' }}>
                            <div>
                                <p style={{ margin: '0', fontSize: mobile ? '0.75em' : '', color: 'var(--on-primary-color)', fontWeight: 'bold' }}>{ props.data.supplier.display_name }</p>
                                <p style={{ margin: '0', fontSize: mobile ? '0.7em' : '', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-4px)' }}>Proveedor</p>
                            </div>
                            <div>
                                <p style={{ margin: '0', fontSize: mobile ? '0.75em' : '', color: 'var(--on-primary-color)', fontWeight: 'bold' }}>{ PaymentForm[Number.parseInt(props.data.payment_form)].toUpperCase() }</p>
                                <p style={{ margin: '0', fontSize: mobile ? '0.7em' : '', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-4px)' }}>Forma de pago</p>
                            </div>
                            <div>
                                <p style={{ margin: '0', fontSize: mobile ? '0.75em' : '', color: 'var(--on-primary-color)', fontWeight: 'bold' }}>{ props.data.invoice }</p>
                                <p style={{ margin: '0', fontSize: mobile ? '0.7em' : '', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-4px)' }}>Factura</p>
                            </div>
                            <div>
                                <p style={{ margin: '0', fontSize: mobile ? '0.75em' : '', color: 'var(--on-primary-color)', fontWeight: 'bold' }}>{ new Date(props.data.initial_pay_date).toLocaleString('es-MX', date_config) }</p>
                                <p style={{ margin: '0', fontSize: mobile ? '0.7em' : '', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-4px)' }}>Fecha de pago inicial</p>
                            </div>
                            <div>
                                <p style={{ margin: '0', fontSize: mobile ? '0.75em' : '', color: 'var(--on-primary-color)', fontWeight: 'bold' }}>{ new Date(props.data.due_date).toLocaleString('es-MX', date_config) }</p>
                                <p style={{ margin: '0', fontSize: mobile ? '0.7em' : '', color: 'rgba(250, 250, 250, 0.8)', transform: 'translateY(-4px)' }}>Fecha de vencimiento</p>
                            </div>
                        </div>
                    </div>
                    <div className='button-strip float-from-right' style={{ padding: '10px'}}>
                        <SecondaryButton onClick={() => props.editAccount(props.data, 1)} >Editar</SecondaryButton>
                        <DangerButton onClick={ delete_account }>Eliminar</DangerButton>
                    </div>
                </div>
                <div className='top-separator' />
                <h2>Egresos de esta Cuenta</h2>
                <ExpensesCatalog 
                getExpense={ props.getExpenseDetail }
                newExpense={() => {
                    if (props.newExpense && 'supplier' in props.data) {
                        props.newExpense(props.data);
                    }
                }} data={response_expenses} />
            </div>);
    } else {
        return null;
    }
}