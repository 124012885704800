import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface SidenavButtonProps extends React.DOMAttributes<HTMLButtonElement> {
    active: boolean,
    icon: IconProp
}

class SidenavButton extends React.Component<SidenavButtonProps, {}> {
    render() {
        let filterString = 'brightness(0) saturate(100%)';
        let buttonStyle;
        let selectorHeight;
        if (this.props.active) {
            selectorHeight = '100%';
            buttonStyle = {
                color: 'var(--primary-color)',
                boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)'
            }
            filterString += ' invert(37%) sepia(86%) saturate(3181%) hue-rotate(352deg) brightness(103%) contrast(91%)';
        }
        else {
            selectorHeight = '0';
            filterString += ' invert(58%) sepia(9%) saturate(180%) hue-rotate(173deg) brightness(96%) contrast(86%)';
        }
        return (
            <button onClick={this.props.onClick} className='sidenav-button' style={buttonStyle}>
                <div style={{width: '6px', height: selectorHeight, backgroundColor: 'var(--primary-color)', marginRight: '0.25em', transition: '0.3s', transitionDelay: '0s'}}></div>
                {/* <img src={ this.props.icon } style={ {width: '1.25em', filter: filterString, marginRight: '0.75em'} } alt='' /> */}
                <FontAwesomeIcon icon={ this.props.icon } style={{ marginRight: '16px', width: '24px' }} />
                {this.props.children}
            </button>
        );
    }
}
export default SidenavButton;