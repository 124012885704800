import { useState } from "react";
import { inimmedAxios } from "../../InimmedHelpers";
import PrimaryButton from "../Utility/PrimaryButton";
import TextInput from "../Utility/TextInput";

interface SupplierFormProps {
    goBack: () => void;
}

const SupplierForm = (props: SupplierFormProps) => {

    const [name, setName] = useState<string>('');
    const [social_reason, setSocialReason] = useState<string>('');
    const [rfc, setRFC] = useState<string>('');
    const [supplier_type, setSupplierType] = useState<string>('1');
    // const [organization, setOrganization] = useState<string>('');

    const submit_supplier = () => {
        if (!social_reason) {
            alert('Por favor ingrese la razón social del proveedor.');
            return;
        }

        inimmedAxios.post('api_banks/save_supplier', {
            name: name ? name : null,
            social_reason: social_reason,
            rfc: rfc ? rfc : null,
            supplier_type: supplier_type
            // organization: organization ? organization : null
        })
        .then(response => {
            if (response.data.Result === 'Ok') {
                if (window.confirm('Proveedor guardado, ¿Salir?')) {
                    props.goBack();
                }
            }
            
        })
        .catch(reason => alert(reason));
    }

    return (
        <div className='form-container'>
            <h2>Detalles</h2>
            <div className='form-input-div'>
                <div className='form-input'>
                    <label>Nombre:</label>
                    <TextInput onChange={event => setName(event.target.value)} >{ name }</TextInput>
                </div>
            </div>
            <div className='form-input-div'>
                <div className='form-input'>
                    <label>Razón Social:</label>
                    <TextInput onChange={event => setSocialReason(event.target.value)} >{ social_reason }</TextInput>
                </div>
                <div className='form-input'>
                    <label>RFC:</label>
                    <TextInput onChange={event => setRFC(event.target.value)} >{ rfc }</TextInput>
                </div>
            </div>
            <div className='form-input-div'>
                <div className='form-input'>
                    <label>Tipo de Proveedor:</label>
                    <select defaultValue={supplier_type} onChange={event => setSupplierType(event.target.value)} >
                        <option value={1}>Proveedor de Equipo</option>
                        <option value={2}>Proveedor de Consumible</option>
                        <option value={3}>Proveedor de Refacciones</option>
                        <option value={4}>Proveedor de Servicios</option>
                    </select>
                </div>
                <div className='form-input'>
                    <label>Organización</label>
                    <select></select>
                </div>
            </div>
            <div className='button-strip'>
                <PrimaryButton onClick={ submit_supplier } >Registrar</PrimaryButton>
            </div>
        </div>
    );
}

export default SupplierForm;