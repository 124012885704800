import React from "react";
import { Device } from "../../../Interfaces";
import ItemSelector from "./ItemSelector";

interface DeviceSelectorProps {
    selected: (device: any) => void;
    devices: Device[];
    selected_device: Device | null;
}

class DeviceSelector extends React.Component<DeviceSelectorProps, {}> {
    render() {

        let cadenaEstadoEquipo = '';

        if (this.props.selected_device) {
            if (!this.props.selected_device.in_guarantee && !this.props.selected_device.in_policy) {
                cadenaEstadoEquipo = 'Sin garantía, sin póliza';
            } else {
                if (this.props.selected_device.in_guarantee) {
                    const fecha = new Date(this.props.selected_device.guarantee_end!);
                    cadenaEstadoEquipo = `En garantía hasta ${fecha.toLocaleDateString('es-MX', { dateStyle: 'short' })}`;
                }
                if (this.props.selected_device.in_policy) {
                    const fecha = new Date(this.props.selected_device.policy_end!);
                    cadenaEstadoEquipo = `En póliza hasta ${fecha.toLocaleDateString('es-MX', { dateStyle: 'short' })}`;
                }
            }
        }

        return (
            <>
                <ItemSelector selected_item={this.props.selected_device} items={this.props.devices} selected={this.props.selected} filter_properties={['serial']} />
                <span style={{ position: 'absolute', bottom: '-65%', left: '5px' }}>{cadenaEstadoEquipo}</span>
            </>
        );
    }
}

export default DeviceSelector;