import React from "react";
import Section from "./Section";
import Menu from "./Menu";
import DeviceForm from "./Forms/DeviceForm";
import ModelForm from "./Forms/ModelForm";
import DeviceTypeForm from "./Forms/DeviceTypeForm";
import BrandForm from "./Forms/BrandForm";
import ClientForm from "./Forms/ClientForm";
import BranchForm from "./Forms/BranchForm";
import EmployeeForm from "./Forms/EmployeeForm";
import PartForm from "./Forms/PartForm";
import { Employee, MenuOptions } from "../Interfaces";
import ContactForm from "./Forms/ContactForm";
import PolicyForm from "./Forms/PolicyForm";
import WorkOrderForm from "./Forms/WorkOrderForm";
import SupplierForm from "./Forms/SupplierForm";

interface FormsSectionProps {
    popQueuedSection: () => void,
    startForm: string,
    user: Employee
}

interface FormsSectionState {
    title: string,
    form_history: FormHistoryItem[],
}

interface FormHistoryItem {
    form: string,
    state: any
}

class FormsSection extends React.Component<FormsSectionProps, FormsSectionState> {
    loadedQueued: boolean;
    
    constructor(props: FormsSectionProps) {
        super(props);
        this.state = {
            title: 'Formularios',
            form_history: []
        }

        this.loadedQueued = false;

        document.title = 'Inimmed - Formularios';
        this.changeForm = this.changeForm.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        if (this.props.startForm) {
            this.props.popQueuedSection();
            this.openForm(this.props.startForm);
        }
    }

    translateFormNameToFormDisplayName(form_name: string) {
        switch (form_name) {
            case 'DeviceForm':
                return 'Nuevo Equipo';
            case 'ModelForm':
                return 'Nuevo Modelo';
            case 'DeviceTypeForm':
                return 'Nuevo Tipo de Equipo';
            case 'BrandForm':
                return 'Nueva Marca';
            case 'PolicyForm':
                return 'Nueva Póliza';
            case 'PartForm':
                return 'Nueva Refacción';
            case 'ClientForm':
                return 'Nuevo Cliente';
            case 'BranchForm':
                return 'Nueva Sucursal';
            case 'ContactForm':
                return 'Nuevo Contacto';
            case 'PersonnelForm':
                return 'Nuevo Empleado';
            case 'WorkOrderForm':
                return 'Nueva Orden de Trabajo';
            case 'FilmForm':
                return 'Nueva Película';
            case 'SupplierForm':
                return 'Nuevo Proveedor'
            default:
                return 'Nuevo Desconocido';
        }
    }

    openForm(form: string) {
        if (!this.loadedQueued) {
            this.loadedQueued = true;
            let currentFormHistory: FormHistoryItem[] = this.state.form_history;
            currentFormHistory.push({form: form, state: null});
            this.setState({form_history: currentFormHistory, title: 'Formularios / ' + this.translateFormNameToFormDisplayName(form) });   
        }
    }

    changeForm(formAndStateObject: FormHistoryItem) {
        let currentFormHistory = [...this.state.form_history];
        currentFormHistory[currentFormHistory.length - 1].state = formAndStateObject.state;
        currentFormHistory.push({form: formAndStateObject.form, state: null});
        this.setState({ form_history: currentFormHistory, title: 'Formularios / ' + this.translateFormNameToFormDisplayName(formAndStateObject.form) });
    }

    goBack() {
        if (this.state.form_history.length) {
            let currentFormHistory = this.state.form_history;
            currentFormHistory.pop();
            this.setState({ form_history: currentFormHistory}, () => {
                if (!this.state.form_history.length) {
                    this.loadedQueued = false;
                    this.setState({ title: 'Formularios' });
                } else {
                    this.setState({ title: 'Formularios / ' + this.translateFormNameToFormDisplayName(this.state.form_history[this.state.form_history.length - 1].form) })
                }
            })
        }
    }

    render() {
        let content;
        if (!this.state.form_history.length) {
            let options: MenuOptions[] = [];

            if (this.props.user.position === '1' || this.props.user.position === '2' || (this.props.user.position === '3' || this.props.user.display_name === 'Juan Pablo Lares Larragoita') || this.props.user.position === '4') {
                options.push(
                    {
                    subsection: 'Servicios',
                    cards: [
                        { title: 'Nueva Orden de Trabajo', icon: 'icons/services.svg', onClick:() => this.openForm('WorkOrderForm') }
                    ]
                });
            }

            if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4') {
                options.push(
                    {
                        subsection: 'Equipos',
                        cards: [
                            { title: 'Nuevo Equipo', icon: 'icons/devices.svg', onClick:() => this.openForm('DeviceForm') },
                            { title: 'Nuevo Modelo', icon: 'icons/devices.svg', onClick:() => this.openForm('ModelForm') },
                            { title: 'Nuevo Tipo de Equipo', icon: 'icons/types.svg', onClick:() => this.openForm('DeviceTypeForm') },
                            { title: 'Nueva Marca', icon: 'icons/brands.svg', onClick:() => this.openForm('BrandForm') },
                            { title: 'Nueva Póliza', icon: 'icons/policies.svg', onClick:() => this.openForm('PolicyForm') },
                            { title: 'Nueva Refacción', icon: 'icons/parts.svg', onClick:() => this.openForm('PartForm') }
                        ]
                    });
            }

            if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' || this.props.user.position === '6') {
                options.push({
                    subsection: 'Clientes',
                    cards: [
                        { title: 'Nuevo Cliente', icon: 'icons/clients.svg', onClick:() => this.openForm('ClientForm') },
                        { title: 'Nueva Sucursal', icon: 'icons/branches.svg', onClick:() => this.openForm('BranchForm') },
                        { title: 'Nuevo Contacto', icon: 'icons/contacts.svg', onClick:() => this.openForm('ContactForm') }
                    ]
                });
            }

            if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3') {
                options.push({
                    subsection: 'Proveedores',
                    cards: [
                        {title: 'Nuevo Proveedor', icon: 'icons/suppliers.svg', onClick: () => this.openForm('SupplierForm')}
                    ]
                })
            }

            if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3') {
                options.push(
                    {
                        subsection: 'Personal',
                        cards: [
                            { title: 'Nuevo Empleado', icon: 'icons/personnel.svg', onClick:() => this.openForm('PersonnelForm') }
                        ]
                    }
                );
            }

            // if ( this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4') {
            //     options.push(
            //         {
            //             subsection: 'Consumible',
            //             cards: [
            //                 { title: 'Nueva Película', icon: 'icons/xrayfilm.svg', onClick: () => this.openForm('FilmForm') },
            //                 { title: 'Nuevo Contraste', icon: 'icons/xraycontrast.svg', onClick: () => this.openForm('ContrastForm') },
            //                 { title: 'Nuevo Bario', icon: 'icons/xraycontrast.svg', onClick: () => this.openForm('BariumForm') },
            //                 { title: 'Nuevo Papel Térmico', icon: 'icons/thermalpaper.svg', onClick: () => this.openForm('ThermalPaperForm') },
            //                 { title: 'Nuevo Otro Consumible', icon: 'icons/othermaterial.svg', onClick: () => this.openForm('OtherMaterialForm') }
            //             ]
            //         }
            //     );
            // }
            content = <Menu options={ options } />;
        }
        else {
            switch(this.state.form_history[this.state.form_history.length - 1].form) {
                case 'WorkOrderForm':
                    content = <WorkOrderForm user={this.props.user} backFunction={this.goBack} changeForm={this.changeForm} savedInputs={this.state.form_history[this.state.form_history.length - 1].state ? this.state.form_history[this.state.form_history.length - 1].state : null } />;
                    break;
                case 'DeviceForm':
                    content = <DeviceForm backFunction={this.goBack} changeForm={this.changeForm} savedInputs={this.state.form_history[this.state.form_history.length - 1].state ? this.state.form_history[this.state.form_history.length - 1].state : null } />;
                    break;
                case 'ModelForm':
                    content = <ModelForm backFunction={this.goBack} changeForm={this.changeForm} savedInputs={this.state.form_history[this.state.form_history.length - 1].state ? this.state.form_history[this.state.form_history.length - 1].state : null } />;
                    break;
                case 'DeviceTypeForm':
                    content = <DeviceTypeForm backFunction={this.goBack} />;
                    break;
                case 'BrandForm':
                    content = <BrandForm backFunction={this.goBack}  />;
                    break;
                case 'PartForm':
                    content = <PartForm backFunction={this.goBack} changeForm={this.changeForm} savedInputs={this.state.form_history[this.state.form_history.length - 1].state ? this.state.form_history[this.state.form_history.length - 1].state : null } />;
                    break;
                case 'ClientForm':
                    content = <ClientForm backFunction={this.goBack} />;
                    break;
                case 'BranchForm':
                    content = <BranchForm backFunction={this.goBack} changeForm={this.changeForm} savedInputs={this.state.form_history[this.state.form_history.length - 1].state ? this.state.form_history[this.state.form_history.length - 1].state : null } />;
                    break;
                case 'PersonnelForm':
                    content = <EmployeeForm backFunction={this.goBack} changeForm={this.changeForm} savedInputs={this.state.form_history[this.state.form_history.length - 1].state ? this.state.form_history[this.state.form_history.length - 1].state : null } />;
                    break;
                case 'ContactForm':
                    content = <ContactForm backFunction={this.goBack} changeForm={this.changeForm} savedInputs={this.state.form_history[this.state.form_history.length - 1].state ? this.state.form_history[this.state.form_history.length - 1].state : null } />;
                    break;
                case 'PolicyForm':
                    content = <PolicyForm backFunction={this.goBack} changeForm={this.changeForm} savedInputs={this.state.form_history[this.state.form_history.length - 1].state ? this.state.form_history[this.state.form_history.length - 1].state : null } user={this.props.user} />;
                    break;
                case 'SupplierForm':
                    content = <SupplierForm goBack={this.goBack} />;
                    break;
                // case 'FilmForm':
                //     content = <FilmForm  backFunction={this.goBack} changeForm={this.changeForm} savedInputs={this.state.form_history[this.state.form_history.length - 1].state ? this.state.form_history[this.state.form_history.length - 1].state : null } />
                //     break;
                default:
                    content = <h1>Desconocido</h1>;
                    break;
            }
        }
        return (
            <Section backButton={this.state.form_history.length ? true : false} onClick={this.goBack} title={this.state.title} >
                {content}
            </Section>
        )
    }
}
export default FormsSection;