import React from "react";
import { Brand } from "../../../Interfaces";
import ItemSelector from "./ItemSelector";

interface BrandSelectorProps {
    selected: (client: any) => void,
    brands: Brand[],
    selected_brand: Brand | null
}

class BrandSelector extends React.Component<BrandSelectorProps, {}> {
    render() {
        return (
            <ItemSelector selected_item={ this.props.selected_brand } items={ this.props.brands } selected={ this.props.selected } />
        )
    }
}

export default BrandSelector;