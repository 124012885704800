import React, { CSSProperties } from "react";

interface SpinnerProps extends React.DOMAttributes<HTMLInputElement> {
    lowerValue: () => void,
    raiseValue: () => void,
    onChange: (event: any) => void,
    value: number,
    min: number,
    max: number
}

class Spinner extends React.Component<SpinnerProps, {}> {
    constructor(props: SpinnerProps) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        this.props.onChange(event.target.value);
    }

    render () {
        const buttonPreviousStyle: CSSProperties = {
            height: '100%',
            width: '1.5em',
            display: 'flex',
            backgroundColor: 'var(--primary-color)',
            color: 'var(--on-primary-color)',
            boxShadow: 'none',
            justifyContent: 'center',
            alignItems: 'center',
            userSelect: 'none',
            borderRadius: '10px 0 0 10px'
        }
        const buttonNextStyle: CSSProperties = {
            height: '100%',
            width: '1.5em',
            display: 'flex',
            backgroundColor: 'var(--primary-color)',
            color: 'var(--on-primary-color)',
            boxShadow: 'none',
            justifyContent: 'center',
            alignItems: 'center',
            userSelect: 'none',
            borderRadius: '0 10px 10px 0'
        }
        const inputStyle: CSSProperties = {
            width: '2.5em',
            fontSize: '1em',
            outline: 'none',
            textAlign: 'center'
        }
        return (
            <div style={{display: 'flex', height: '2em'}}>
                <div onClick={this.props.lowerValue} style={buttonPreviousStyle}>{'-'}</div>
                <input style={inputStyle} value={this.props.value} onChange={this.handleChange} />
                <div onClick={this.props.raiseValue} style={buttonNextStyle}>{'+'}</div>
            </div>
        )
    }
}

export default Spinner;