import React from "react";
import { inimmedAxios } from "../InimmedHelpers";
import { Account, BanksHistoryItem, Employee, Expense, Income, MonthBudgetConcept, OwnBankAccount, PurchaseAccount, SaleAccount } from "../Interfaces";
import ExpensesCatalog from "./Catalogs/ExpensesCatalog";
import IncomesCatalog from "./Catalogs/IncomesCatalog";
import PurchaseAccountsCatalog from "./Catalogs/PurchaseAccountsCatalog";
import SaleAccountsCatalog from "./Catalogs/SaleAccountsCatalog";
import { AccountDetails } from "./Details/AccountDetails";
import ExpenseDetail from "./Details/ExpenseDetail";
import IncomeDetail from "./Details/IncomeDetail";
import MonthBudgetConceptDetails from "./Details/MonthBudgetConceptDetails";
import OwnBankAccountDetails from "./Details/OwnBankAccountDetails";
import { SantanderBankAccount, HSBCBankAccount, INBURSABankAccount, EfectivoBankAccount } from "./Extras/BankAccountCard";
import AccountForm from "./Forms/AccountForm";
import ExpenseForm from "./Forms/ExpenseForm";
import IncomeForm from "./Forms/IncomeForm";
import MonthBudgetConceptForm from "./Forms/MonthBudgetConceptForm";
import OwnBankAccountForm from "./Forms/OwnBankAccountForm";
import MonthBudget from "./MonthBudget";
import Section from "./Section";
import Loading from "./Utility/Loading";
import PrimaryButton from "./Utility/PrimaryButton";
import SecondaryButton from "./Utility/SecondaryButton";
import { TabSelector } from "./Utility/TabSelector";

interface BanksSectionProps {
    user: Employee;
}

interface BanksSectionState {
    history: BanksHistoryItem[];
    current_account_tab: string;
    current_io_tab: string;
    own_bank_accounts: OwnBankAccount[];
    sale_accounts: SaleAccount[];
    purchase_accounts: PurchaseAccount[];
    conceptless_incomes: Income[];
    conceptless_expenses: Expense[];
    loading: boolean;
    accounts_collapsed: boolean;
    io_collapsed: boolean;
    budget_collapsed: boolean;
}

class BanksSection extends React.Component<BanksSectionProps, BanksSectionState> {
    mobile: boolean;
    account_tabs: string[];
    io_tabs: string[];

    constructor(props: BanksSectionProps) {
        super(props);

        this.mobile = window.innerWidth > 768 ? false : true;

        this.account_tabs = ['Por Cobrar', 'Por Pagar'];
        this.io_tabs = ['Ingresos', 'Egresos'];

        this.state = {
            history: [],
            current_account_tab: this.account_tabs[0],
            current_io_tab: this.io_tabs[0],
            own_bank_accounts: [],
            sale_accounts: [],
            purchase_accounts: [],
            conceptless_incomes: [],
            conceptless_expenses: [],
            loading: false,
            accounts_collapsed: true,
            io_collapsed: true,
            budget_collapsed: true
        }

        this.getAccountDetail = this.getAccountDetail.bind(this);
        this.goBackAndRefresh = this.goBackAndRefresh.bind(this);
        this.newAccount = this.newAccount.bind(this);
        this.editAccount = this.editAccount.bind(this);
        this.newIncome = this.newIncome.bind(this);
        this.newExpense = this.newExpense.bind(this);
        this.getIncomeDetail = this.getIncomeDetail.bind(this);
        this.getExpenseDetail = this.getExpenseDetail.bind(this);
        this.getOwnBankAccountDetail = this.getOwnBankAccountDetail.bind(this);
        this.editOwnBankAccount = this.editOwnBankAccount.bind(this);
        this.newOwnBankAccount = this.newOwnBankAccount.bind(this);

        this.newMonthBudgetConcept = this.newMonthBudgetConcept.bind(this);
        this.getMonthBudgetConceptDetail = this.getMonthBudgetConceptDetail.bind(this);
        this.editMonthBudgetConceptDetail = this.editMonthBudgetConceptDetail.bind(this);
    }

    componentDidMount() {

        document.querySelector('#current-section')?.scrollIntoView({ behavior: 'smooth' })

        const initial_state = this.state as BanksSectionState;
        const promises: Promise<void>[] = [];

        promises.push(
            inimmedAxios.get('api_banks/get_own_bank_accounts')
            .then(response => {
                initial_state.own_bank_accounts = response.data;
            })
        );

        promises.push(
            inimmedAxios.get('api_banks/get_sale_accounts')
            .then(response => {
                initial_state.sale_accounts = response.data;
            })
        );

        promises.push(
            inimmedAxios.get('api_banks/get_purchase_accounts')
            .then(response => {
                initial_state.purchase_accounts = response.data;
            })
        );

        promises.push(
            inimmedAxios.get('api_banks/get_conceptless_incomes')
            .then(response => initial_state.conceptless_incomes = response.data)
        );

        promises.push(
            inimmedAxios.get('api_banks/get_conceptless_expenses')
            .then (response => initial_state.conceptless_expenses = response.data)
        );

        Promise.all(promises)
        .then(() => this.setState(initial_state))
        .catch(reason => alert(reason));

    }

    newOwnBankAccount() {
        const current_history = this.state.history;
        current_history.push({type: 'own_bank_account_form', props: null});
        this.setState({ history: current_history });
    }

    getOwnBankAccountDetail(own_bank_account_id: number) {
        this.setState({ loading: true });
        inimmedAxios.get('api_banks/get_own_bank_account', {
            params: {
                own_bank_account_id: own_bank_account_id
            }
        })
        .then(response => {
            const current_history = this.state.history;
            current_history.push({props: response.data, type: 'own_bank_account_detail'});
            this.setState({ history: current_history, loading: false });
        })
        .catch(reason => alert(reason));
    }

    editOwnBankAccount(own_bank_account: OwnBankAccount) {
        const current_history = this.state.history;
        current_history.push({props: own_bank_account, type: 'own_bank_account_form'});
        this.setState({ history: current_history });
    }

    getAccountDetail(account_id: number, account_type: 0 | 1) {
        if (account_type === 0) {
            this.setState({ loading: true });
            inimmedAxios.get('api_banks/get_sale_account', {
                params: {
                    account_id: account_id
                }
            })
            .then(response => {
                const current_history = this.state.history;
                current_history.pop();
                current_history.pop();
                current_history.push({props: response.data, type: 'sale_account_detail'});
                this.setState({ history: current_history, loading: false });
            })
            .catch(reason => alert(reason));
        } else if (account_type === 1) {
            this.setState({ loading: true });
            inimmedAxios.get('api_banks/get_purchase_account', {
                params: {
                    account_id: account_id
                }
            })
            .then(response => {
                const current_history = this.state.history;
                current_history.pop();
                current_history.pop();
                current_history.push({ props: response.data, type: 'purchase_account_detail' });
                this.setState({ history: current_history }, () => this.setState({ loading: false }))
            })
            .catch(reason => alert(reason));
        }
    }

    getIncomeDetail(income_id: number) {
        this.setState({ loading: true });
        inimmedAxios.get('api_banks/get_income', {
            params: {
                income_id: income_id
            }
        })
        .then(response => {
            const current_history = this.state.history;
            current_history.push({props: response.data, type: 'income_detail'});
            this.setState({history: current_history, loading: false});
        })
        .catch(reason => alert(reason));
    }

    getExpenseDetail(expense_id: number) {
        this.setState({ loading: true });
        inimmedAxios.get('api_banks/get_expense', {
            params: {
                expense_id: expense_id
            }
        })
        .then(response => {
            const current_history = this.state.history;
            current_history.push({props: response.data, type: 'expense_detail'});
            this.setState({ history: current_history, loading: false });
        })
        .catch(reason => alert(reason));
    }

    newAccount() {
        const current_history = this.state.history;
        current_history.push({type: 'account_form', props: {} as Account});
        this.setState({ history: current_history });
    }

    editAccount(account: Account, account_type: 0 | 1) {
        const current_history = this.state.history;
        if (account_type === 0) {
            current_history.push({ type: 'sale_account_form', props: account });
        } else if (account_type === 1) {
            current_history.push({ type: 'purchase_account_form', props: account });
        }
        this.setState({ history: current_history });
    }

    newIncome(sale_account?: SaleAccount) {
        const current_history = this.state.history;
        if (!sale_account) {
            current_history.push({ type: 'income_form', props: {} as Income });
            this.setState({ history: current_history });
        } else {
            current_history.push({ type: 'income_form', props: { initial_account: sale_account } });
            this.setState({ history: current_history });
        }
    }

    newExpense(purchase_account?: PurchaseAccount) {
        const current_history = this.state.history;
        if (!purchase_account) {
            current_history.push({ type: 'expense_form', props: {} as Expense });
            this.setState({ history: current_history });
        } else {
            current_history.push({ type: 'expense_form', props: {initial_account: purchase_account} });
            this.setState({ history: current_history });
        }
    }

    newMonthBudgetConcept(month_budget_concept?: MonthBudgetConcept) {
        const current_history = this.state.history;
        if (!month_budget_concept) {
            current_history.push({ type: 'month_budget_concept_form', props: undefined });
            this.setState({ history: current_history });
        }
    }

    getMonthBudgetConceptDetail(concept: MonthBudgetConcept) {
        const current_history = this.state.history;
        current_history.push({props: concept, type: 'month_budget_concept_detail'});
        this.setState({ history: current_history });
    }

    editMonthBudgetConceptDetail(concept: MonthBudgetConcept) {
        const current_history = this.state.history;
        current_history.push({ type: 'month_budget_concept_form', props: concept });
        this.setState({ history: current_history });
    }

    generateBankAccounts() {
        const own_bank_accounts: JSX.Element[] = [];
        for (const own_bank_account of this.state.own_bank_accounts) {
            if (own_bank_account.bank === 'Santander') {
                own_bank_accounts.push(
                    <SantanderBankAccount onClick={ this.getOwnBankAccountDetail } key={own_bank_accounts.length} account={own_bank_account} />
                );
            }
            if (own_bank_account.bank === 'HSBC') {
                own_bank_accounts.push(
                    <HSBCBankAccount onClick={ this.getOwnBankAccountDetail } key={own_bank_accounts.length} account={own_bank_account} />
                );
            }
            if (own_bank_account.bank === 'Inbursa') {
                own_bank_accounts.push(
                    <INBURSABankAccount onClick={ this.getOwnBankAccountDetail } key={own_bank_accounts.length} account={own_bank_account} />
                );
            }
            if (own_bank_account.bank === 'Efectivo') {
                own_bank_accounts.push(
                    <EfectivoBankAccount onClick={ this.getOwnBankAccountDetail } key={own_bank_accounts.length} account={own_bank_account} />
                );
            }
        }
        return own_bank_accounts;
    }

    showCurrentAccountsTab() {
        switch (this.state.current_account_tab) {
            case 'Por Cobrar': {
                return <SaleAccountsCatalog data={ this.state.sale_accounts } user={ this.props.user } getAccountDetail={ this.getAccountDetail } />
            }
            case 'Por Pagar': {
                return <PurchaseAccountsCatalog data={ this.state.purchase_accounts } user={ this.props.user } getAccountDetail={ this.getAccountDetail } />
            }
        }
    }

    showCurrentIOsTab() {
        switch (this.state.current_io_tab) {
            case 'Ingresos': {
                return <IncomesCatalog data={ this.state.conceptless_incomes } getIncome={ this.getIncomeDetail } newIncome={ this.newIncome } />
            }
            case 'Egresos': {
                return <ExpensesCatalog data={ this.state.conceptless_expenses } getExpense={ this.getExpenseDetail } newExpense={ this.newExpense } />
            }
        }
    }

    goBackAndRefresh() {
        const current_history = this.state.history;
        const item = current_history.pop();
        const current_item = current_history.pop();
        if (current_item) {
            switch (current_item.type) {
                case 'sale_account_detail': {
                    inimmedAxios.get('api_banks/get_sale_account', {
                        params: {
                            account_id: current_item.props.id
                        }
                    })
                    .then(response => {
                        current_item.props = response.data
                        current_history.push(current_item);
                        this.setState({ history: current_history });
                    })
                    .catch(reason => alert(reason));
                    break;
                }
                case 'purchase_account_detail': {
                    inimmedAxios.get('api_banks/get_purchase_account', {
                        params: {
                            account_id: current_item.props.id
                        }
                    })
                    .then(response => {
                        current_item.props = response.data;
                        current_history.push(current_item);
                        this.setState({ history: current_history });
                    })
                    .catch(reason => alert(reason));
                    break;
                }
                case 'own_bank_account_detail': {
                    inimmedAxios.get('api_banks/get_own_bank_account', {
                        params: {
                            own_bank_account_id: current_item.props.id
                        }
                    })
                    .then(response => {
                        current_item.props = response.data;
                        current_history.push(current_item);
                        this.setState({ history: current_history });
                    })
                    .catch(reason => alert(reason));
                    break;
                }
                case 'month_budget_concept_detail': {
                    inimmedAxios.get('api_banks/get_month_budget_concept', {
                        params: {
                            month_budget_concept_id: current_item.props.id
                        }
                    })
                    .then(response => {
                        current_item.props = response.data.concept;
                        current_history.push(current_item);
                        this.setState({ history: current_history });
                    })
                }
            }
        } else {
            const new_state = this.state as BanksSectionState;
            const promises: Promise<void>[] = [];
    
            promises.push(
                inimmedAxios.get('api_banks/get_own_bank_accounts')
                .then(response => {
                    new_state.own_bank_accounts = response.data;
                })
            );
    
            promises.push(
                inimmedAxios.get('api_banks/get_sale_accounts')
                .then(response => {
                    new_state.sale_accounts = response.data;
                })
            );
    
            promises.push(
                inimmedAxios.get('api_banks/get_purchase_accounts')
                .then(response => {
                    new_state.purchase_accounts = response.data;
                })
            );

            promises.push(
                inimmedAxios.get('api_banks/get_conceptless_incomes')
                .then(response => new_state.conceptless_incomes = response.data)
            );
    
            promises.push(
                inimmedAxios.get('api_banks/get_conceptless_expenses')
                .then (response => new_state.conceptless_expenses = response.data)
            );
            
            new_state.history = current_history;
    
            Promise.all(promises)
            .then(() => this.setState(new_state))
            .catch(reason => alert(reason));
        }
    }

    render(): React.ReactNode {
        if (this.state.loading) {
            return (
                <Section title='Bancos'>
                    <Loading color='primary' />
                </Section>
            );
        } else {
            if (!this.state.history.length) {
                return (
                    <Section dont_reset_scroll={ true } title='Bancos'>
                        <div className='float-from-down' style={{ padding: this.mobile ? '10px' : '1em', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <h1 style={{ margin: '0' }}>Saldos</h1>
                            <div style={{ scrollbarWidth: 'none', display: 'flex', gap: '10px', width: '95%', margin: 'auto', overflowX: 'scroll', padding: '15px', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                                { this.generateBankAccounts() }
                            </div>
                            {
                                this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' ?
                                    <div className='button-strip'>
                                        <PrimaryButton onClick={ this.newOwnBankAccount } >Nueva Cuenta Bancaria Propia</PrimaryButton>
                                    </div>
                                :
                                    null
                            }
                            <div className='top-separator' />
                            <h1 className='collapsable-header' onClick={() => this.setState({ accounts_collapsed: !this.state.accounts_collapsed })}>Cuentas</h1>
                            <div style={{ display: this.state.accounts_collapsed ? 'none' : 'flex', overflow: 'visible', flexDirection: 'column', gap: '10px' }}>
                                <div className='button-strip'>
                                    <PrimaryButton onClick={ this.newAccount }>Nueva Cuenta</PrimaryButton>
                                    <SecondaryButton onClick={() => this.newIncome() } >Nuevo Ingreso</SecondaryButton>
                                    <SecondaryButton onClick={() => this.newExpense() } >Nuevo Egreso</SecondaryButton>
                                </div>
                                <TabSelector tab_names={ this.account_tabs } active_tab={ this.state.current_account_tab } setTab={tab => this.setState({ current_account_tab: tab })} />
                                { this.showCurrentAccountsTab() }
                            </div>
                            <div className='top-separator' />
                            <h1 className='collapsable-header' onClick={() => this.setState({ io_collapsed: !this.state.io_collapsed })}>Ingresos/Egresos únicos</h1>
                            <div style={{ display: this.state.io_collapsed ? 'none' : 'flex', overflow: 'visible', flexDirection: 'column', gap: '10px' }}>
                                <TabSelector tab_names={ this.io_tabs } active_tab={ this.state.current_io_tab } setTab={tab => this.setState({ current_io_tab: tab })} />
                                { this.showCurrentIOsTab() }
                            </div>
                            <div className='top-separator' />
                            <h1 className='collapsable-header' onClick={() => this.setState({ budget_collapsed: !this.state.budget_collapsed })}>Presupuesto Mensual</h1>
                            <MonthBudget newConcept={ this.newMonthBudgetConcept } collapsed={this.state.budget_collapsed} getConceptDetail={ this.getMonthBudgetConceptDetail } />
                        </div>
                    </Section>
                );
            } else {
                const current_item = this.state.history[this.state.history.length - 1];
                switch(current_item?.type) {
                    case 'sale_account_detail': {
                        const sale_account_props = current_item.props as SaleAccount;
                        return (
                            <Section title={'Bancos / Cuenta por Cobrar ' + sale_account_props.id} backButton onClick={ this.goBackAndRefresh }>
                                <AccountDetails data={ current_item.props } goBack={ this.goBackAndRefresh } editAccount={ this.editAccount } newIncome={ this.newIncome } getIncomeDetail={ this.getIncomeDetail } getExpenseDetail={ this.getExpenseDetail } />
                            </Section>
                        );
                    }
                    case 'purchase_account_detail': {
                        const purchase_account = current_item.props as PurchaseAccount;
                            return (
                                <Section title={'Bancos / Cuenta por Pagar ' + purchase_account.id} backButton onClick={ this.goBackAndRefresh }>
                                    <AccountDetails data={ current_item.props } goBack={ this.goBackAndRefresh } editAccount={ this.editAccount } newExpense={ this.newExpense } getIncomeDetail={ this.getIncomeDetail } getExpenseDetail={ this.getExpenseDetail } />
                                </Section>
                            );
                    }
                    case 'sale_account_form': {
                        const sale_account = current_item.props as SaleAccount;
                        return (
                            <Section title={'Bancos / Editando Cuenta por Cobrar con Factura ' + sale_account.invoice} backButton onClick={ this.goBackAndRefresh } >
                                <AccountForm  getAccountDetail={ this.getAccountDetail } goBack={ this.goBackAndRefresh } account_to_edit={ this.state.history[this.state.history.length - 1].props } />
                            </Section>
                        );
                    }
                    case 'purchase_account_form': {
                        const purchase_account = current_item.props as PurchaseAccount;
                        return (
                            <Section title={'Bancos / Editando Cuenta por Pagar con Factura ' + purchase_account.invoice} backButton onClick={ this.goBackAndRefresh } >
                                <AccountForm getAccountDetail={ this.getAccountDetail } goBack={ this.goBackAndRefresh } account_to_edit={ this.state.history[this.state.history.length - 1].props } />
                            </Section>
                        );
                    }
                    case 'account_form': {
                        return (
                            <Section title='Bancos / Nueva Cuenta' backButton onClick={ this.goBackAndRefresh } >
                                <AccountForm getAccountDetail={ this.getAccountDetail } goBack={ this.goBackAndRefresh } />
                            </Section>
                        );
                    }
                    case 'income_form': {
                        const initial_account = current_item.props.initial_account as SaleAccount;
                        return (
                            <Section title={ initial_account ? 'Bancos / Nuevo Ingreso para Cuenta con Factura ' + initial_account.invoice : 'Bancos / Nuevo ingreso'} backButton onClick={ this.goBackAndRefresh }>
                                <IncomeForm initial_account={ initial_account } goBackAndRefresh={ this.goBackAndRefresh } />
                            </Section>
                        );
                    }
                    case 'expense_form': {
                        const initial_account = current_item.props.initial_account as PurchaseAccount;
                        return (
                            <Section title={ initial_account ? 'Bancos / Nuevo Egreso para Cuenta con Factura ' + initial_account.invoice : 'Bancos / Nuevo egreso'} backButton onClick={ this.goBackAndRefresh }>
                                <ExpenseForm initial_account={ initial_account } goBackAndRefresh={ this.goBackAndRefresh } />
                            </Section>
                        );
                    }
                    case 'income_detail': {
                        const income = current_item.props as Income;
                        return (
                            <Section title={'Bancos / ' + income.display_name} backButton onClick={ this.goBackAndRefresh }>
                                <IncomeDetail data={ income } goBackAndRefresh={ this.goBackAndRefresh } />
                            </Section>
                        )
                    }
                    case 'expense_detail': {
                        const expense = current_item.props as Expense;
                        return (
                            <Section title={'Bancos / ' + expense.display_name} backButton onClick={ this.goBackAndRefresh }>
                                <ExpenseDetail data={ expense } goBackAndRefresh={ this.goBackAndRefresh } />
                            </Section>
                        )
                    }
                    case 'own_bank_account_detail': {
                        const own_bank_account = current_item.props as OwnBankAccount;
                        return (
                            <Section title={'Bancos / Cuenta Bancaria ' + own_bank_account.display_name} backButton onClick={ this.goBackAndRefresh }>
                                <OwnBankAccountDetails data={own_bank_account} getIncomeDetail={ this.getIncomeDetail } getExpenseDetail={ this.getExpenseDetail } editOwnBankAccount={ this.editOwnBankAccount } goBackAndRefresh={ this.goBackAndRefresh } user={this.props.user} />
                            </Section>
                        );
                    }
                    case 'own_bank_account_form': {
                        const own_bank_account = current_item.props ? current_item.props as OwnBankAccount : undefined;
                        if (own_bank_account) {
                            return (
                                <Section title={'Bancos / Editando Cuenta Bancaria ' + own_bank_account.display_name } backButton onClick={ this.goBackAndRefresh }>
                                    <OwnBankAccountForm own_bank_account_to_edit={own_bank_account} goBackAndRefresh={ this.goBackAndRefresh } />
                                </Section>
                            );
                        } else {
                            return (
                                <Section title='Bancos / Nueva Cuenta Bancaria ' backButton onClick={ this.goBackAndRefresh }>
                                    <OwnBankAccountForm own_bank_account_to_edit={own_bank_account} goBackAndRefresh={ this.goBackAndRefresh } />
                                </Section>
                            );
                        }
                    }
                    case 'month_budget_concept_form': {
                        const month_budget_concept = current_item.props ? current_item.props as MonthBudgetConcept : undefined;
                        if (month_budget_concept === undefined) {
                            return (
                                <Section title='Bancos / Nuevo Concepto de Presupuesto Mensual' backButton onClick={ this.goBackAndRefresh } >
                                    <MonthBudgetConceptForm goBackAndRefresh={ this.goBackAndRefresh } />
                                </Section>
                            );
                        } else {
                            return (
                                <Section title={'Bancos / Editando Concepto de Presupuesto Mensual' + month_budget_concept.display_name}  backButton onClick={ this.goBackAndRefresh } >
                                    <MonthBudgetConceptForm goBackAndRefresh={ this.goBackAndRefresh } initial_month_budget_concept={month_budget_concept} />
                                </Section>
                            );
                        }
                    }
                    case 'month_budget_concept_detail': {
                        const month_budget_concept = current_item.props as MonthBudgetConcept;
                        return (
                            <Section title={'Bancos / Concepto de Presupuesto ' + month_budget_concept.display_name} backButton onClick={ this.goBackAndRefresh } >
                                <MonthBudgetConceptDetails month_budget_concept={month_budget_concept} editMonthBudgetConceptDetails={month_budget_concept => this.editMonthBudgetConceptDetail(month_budget_concept) } />
                            </Section>
                        )
                    }
                }
            }
        }
    }
}

export default BanksSection;