import React from "react";
import Catalog from "./Catalog";
import { Client, CatalogProps } from "../../Interfaces";

interface ClientsCatalogProps extends CatalogProps {
    reload: (catalog: string) => void,
    data: Client[]
}

class ClientsCatalog extends React.Component<ClientsCatalogProps, {}> {
    render() {
        let newButtonPrompt: string = this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? 'Nuevo Cliente' : '';

        return (
            <Catalog
                data={this.props.data}
                headersForTable={[{name: 'Cliente', width: '50%', data: 'display_name'}, {name: 'RFC', width: '20%', data: 'RFC'}, {name: 'Email', width: '30%', data: 'email'}]}
                dataHeaders={['display_name', 'RFC', 'email']}
                searchHeaders={['display_name', 'RFC', 'email', 'alias', 'commercial_name']}
                objectType='Clientes'
                getDetail={id => this.props.getDetail('clients', id)}
                newButtonPrompt={newButtonPrompt}
                newButtonClick={() => this.props.changeSectionFunction(3, null, 'ClientForm')}
                searchPlaceholder='Buscar clientes...'
                itemsPerPage={10} />
        )
    }
}

export default ClientsCatalog;