import React from "react";
import SignatureCanvas from "react-signature-canvas";
import DangerButton from "./DangerButton";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

interface SignaturePadProps {
    saveSignature: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    toggleSignaturePad: () =>  void;
}

interface SignaturePadState {
    width: number;
    height: number;
}

class SignaturePad extends React.Component<SignaturePadProps, SignaturePadState> {
    container: React.RefObject<HTMLDivElement>;
    sig_canvas: SignatureCanvas | null;

    constructor(props: SignaturePadProps) {
        super(props);

        this.state = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        this.container = React.createRef();
        this.sig_canvas = null;
    }

    componentDidMount() {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
        window.addEventListener('resize', () => this.setState({ width: window.innerWidth, height: window.innerHeight }));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setState({ width: window.innerWidth, height: window.innerHeight }));
    }

    render() {
        return (
            <div ref={this.container} style={{position: 'absolute', backgroundColor: 'white', color: 'var(--on-primary-color)', width: '100vw', height: '100vh', top: '0', left: '0', zIndex: '10'}}>
                <div style={{ width: 'fit-content', height: 'fit-content' }}>
                    <SignatureCanvas ref={ref => this.sig_canvas = ref} minDistance={0.5} velocityFilterWeight={0.5} minWidth={0.5} maxWidth={2.0} canvasProps={{width: this.state.width, height: this.state.height}} />
                </div>
                <div style={{ display: 'flex', position: 'absolute', top: '0', left: '0', padding: '5px', overflow: 'visible', gap: '5px'}}>
                    <PrimaryButton onClick={ this.props.saveSignature }>Aceptar</PrimaryButton>
                    <SecondaryButton onClick={ this.props.toggleSignaturePad }>Cancelar</SecondaryButton>
                </div>
                <div style={{ position: 'absolute', top: '0px', right: '0', padding: '5px' }}>
                    <DangerButton onClick={() => this.sig_canvas?.clear() }>Limpiar</DangerButton>
                </div>
            </div>
        )
    }
}

export default SignaturePad;