import { inimmedAxios } from "../../InimmedHelpers";
import React from "react";
import TextInput from "../Utility/TextInput";
import PrimaryButton from "../Utility/PrimaryButton";
import { Brand, FormComponentProps } from "../../Interfaces";

interface BrandFormProps extends FormComponentProps {
    data?: Brand
}

interface BrandFormState {
    name: string
}

class BrandForm extends React.Component<BrandFormProps, BrandFormState> {
    constructor(props: BrandFormProps) {
        super(props);
        this.state = {
            name: ''
        }

        this.submitBrand = this.submitBrand.bind(this);
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState(this.props.data);
        }
    }

    submitBrand() {
        if (!this.state.name) {
            alert('Por favor escriba el nombre de la nueva marca.');
            return;
        }
        const url = this.props.data ? 'api/save_brand/' + this.props.data.id : 'api/save_brand';
        inimmedAxios.post(url, {
            name: this.state.name,
        })
        .then(response => {
            if (response.data.Result === 'Success') {
                if (window.confirm('Marca guardada, ¿Salir?')) {
                    if (this.props.data && this.props.getCatalog && this.props.getDetail) {
                        this.props.getCatalog('brands');
                        this.props.getDetail('brands', this.props.data.id, true);
                    }
                    this.props.backFunction();
                }
            } else {
                alert(response.data.Result);
            }
        })
        .catch(reason => {
            alert(reason);
        });
    }

    render() {
        return (
            <div className='form-container'>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Nombre de la Marca:</label>
                        <TextInput onChange={event => this.setState({name: event.target.value})}>{ this.state.name }</TextInput>
                    </div>
                </div>
                <div className='top-separator'></div>
                <div className='button-strip'>
                    <PrimaryButton onClick={this.submitBrand}>{ this.props.data ? 'Guardar' : 'Registrar'}</PrimaryButton>
                </div>
            </div>
        )
    }
}
export default BrandForm;