import React from "react";
import { inimmedAxios, currency_formatter, percent_formatter } from "../InimmedHelpers";
import { MonthBudgetConcept, MONTH_BUDGET_CONCEPT_TYPE } from "../Interfaces";
import PrimaryButton from "./Utility/PrimaryButton";
import { TabSelector } from "./Utility/TabSelector";

interface MonthBudgetProps {
    newConcept: (concept?: MonthBudgetConcept) => void;
    getConceptDetail: (concept: MonthBudgetConcept) => void;
    collapsed: boolean;
}

interface MonthBudgetState {
    current_tab: string;
    month_budget_concepts: MonthBudgetConcept[] | null;
    area_total: string;
}

export default class MonthBudget extends React.Component<MonthBudgetProps, MonthBudgetState> {
    tabs: string[];
    constructor(props: MonthBudgetProps) {
        super(props);

        this.tabs = ['Equipo', 'Servicio', 'Consumible', 'Refacciones']

        this.state = {
            current_tab: this.tabs[0],
            month_budget_concepts: null,
            area_total: '0.00'
        }

        this.switchTabs = this.switchTabs.bind(this);
    }

    componentDidMount() {
        inimmedAxios.get('api_banks/get_month_budget_concepts', {
            params: {
                area: 'Equipo'
            }
        })
        .then(response => {
            this.setState({ month_budget_concepts: response.data.concepts })
        })
        .catch(reason => alert(reason));
    }

    switchTabs(tab: string) {
        switch (tab) {
            case 'Equipo': {
                this.setState({ current_tab: tab });
                inimmedAxios.get('api_banks/get_month_budget_concepts', {
                    params: {
                        area: 'Equipo'
                    }
                })
                .then(response => {
                    this.setState({ month_budget_concepts: response.data.concepts, area_total: response.data.total });
                })
                .catch(reason => alert(reason));
                break;
            }
            case 'Servicio': {
                this.setState({ current_tab: tab });
                inimmedAxios.get('api_banks/get_month_budget_concepts', {
                    params: {
                        area: 'Servicio'
                    }
                })
                .then(response => {
                    this.setState({ month_budget_concepts: response.data.concepts, area_total: response.data.total });
                })
                .catch(reason => alert(reason));
                break;
            }
            case 'Refacciones': {
                this.setState({ current_tab: tab });
                inimmedAxios.get('api_banks/get_month_budget_concepts', {
                    params: {
                        area: 'Refacciones'
                    }
                })
                .then(response => {
                    this.setState({ month_budget_concepts: response.data.concepts, area_total: response.data.total });
                })
                .catch(reason => alert(reason));
                break;
            }
            case 'Consumible': {
                this.setState({ current_tab: tab });
                inimmedAxios.get('api_banks/get_month_budget_concepts', {
                    params: {
                        area: 'Consumible'
                    }
                })
                .then(response => {
                    this.setState({ month_budget_concepts: response.data.concepts, area_total: response.data.total });
                })
                .catch(reason => alert(reason));
                break;
            }
        }
    }

    generateBudgetConceptsTable() {
        const concepts: JSX.Element[] = [];
        if (this.state.month_budget_concepts && this.state.month_budget_concepts.length) {
            let last_concept_type;
            for (const concept of this.state.month_budget_concepts) {
                if (concept.type !== last_concept_type && last_concept_type) {
                    concepts.push(
                        <tr style={{ backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)' }}>
                            <td>Total { MONTH_BUDGET_CONCEPT_TYPE[Number.parseInt(last_concept_type)] }</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    );
                }
                concepts.push(
                    <tr onClick={() => this.props.getConceptDetail(concept)}>
                        <td>{ concept.concept }</td>
                        <td>{ currency_formatter.format(Number.parseFloat(concept.amount)) }</td>
                        <td>{ currency_formatter.format(Number.parseFloat(concept.paid_amount)) }</td>
                        <td>{ percent_formatter.format(Number.parseFloat(concept.percent_paid)) }</td>
                        <td>{ currency_formatter.format(Number.parseFloat(concept.amount_to_pay)) }</td>
                    </tr>
                );
                if (concept.id === this.state.month_budget_concepts[this.state.month_budget_concepts.length - 1].id && last_concept_type) {
                    concepts.push(
                        <tr style={{ backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)' }}>
                            <td>Total { MONTH_BUDGET_CONCEPT_TYPE[Number.parseInt(last_concept_type)] }</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    );
                }
                last_concept_type = concept.type;
            }
            concepts.push(
                <tr style={{ backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', borderTop: 'solid 2px var(--primary-light-color)' }}>
                    <td>Total del Área</td>
                    <td>{ this.state.area_total }</td>
                    <td>{ '$ 0.00' }</td>
                    <td>0%</td>
                    <td>{ this.state.area_total }</td>
                </tr>
            )
        }
        return (
            <table style={{ marginTop: '20px' }}>
                <thead>
                    <tr>
                        <th>Concepto</th>
                        <th>Importe Estimado</th>
                        <th>Importe Pagado</th>
                        <th>Porcentaje Pagado</th>
                        <th>Pendiente de Pago</th>
                    </tr>
                </thead>
                <tbody>
                    { concepts }
                </tbody>
            </table>
        );
    }

    render() {
        if (this.state.month_budget_concepts !== null) {
            return (
                <div style={{ display: this.props.collapsed ? 'none' : 'flex', overflow: 'visible', flexDirection: 'column', gap: '10px' }}>
                    <div className='button-strip'>
                        <PrimaryButton onClick={() => this.props.newConcept()} >Nuevo Concepto</PrimaryButton>
                    </div>
                    <TabSelector active_tab={this.state.current_tab} tab_names={this.tabs} setTab={ this.switchTabs } />
                    { this.generateBudgetConceptsTable() }
                </div>
            );
        } else {
            return null;
        }
    }
}