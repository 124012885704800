import { useState } from "react";
import { toast } from "react-toastify";
import { inimmedAxios } from "../../InimmedHelpers";
import { Bank, BANK_ACCOUNT_AREA, OwnBankAccount } from "../../Interfaces";
import { MoneyTextInput } from "../Utility/MoneyInput";
import PrimaryButton from "../Utility/PrimaryButton";
import SecondaryButton from "../Utility/SecondaryButton";
import TextInput from "../Utility/TextInput";

interface OwnBankAccountFormProps {
    goBackAndRefresh: () => void;
    own_bank_account_to_edit?: OwnBankAccount;
}

const OwnBankAccountForm = (props: OwnBankAccountFormProps) =>  {

    const [name, setName] = useState<string>(props.own_bank_account_to_edit ? props.own_bank_account_to_edit.name : '');
    const [account_number, setAccountNumber] = useState<string>(props.own_bank_account_to_edit ? props.own_bank_account_to_edit.account_number : '');
    const [bank, setBank] = useState<Bank | null>(props.own_bank_account_to_edit ? props.own_bank_account_to_edit.bank as Bank : 'Santander');
    const [balance, setBalance] = useState<string>(props.own_bank_account_to_edit ? props.own_bank_account_to_edit.balance : '0.00');
    const [area, setArea] = useState<string>(props.own_bank_account_to_edit ? props.own_bank_account_to_edit.area : '0');
    const [loading, setLoading] = useState<boolean>(false);

    const submitOwnBankAccount = () => {
        if (!name) {
            alert('Por favor ingrese un nombre para la cuenta.');
            return;
        }
        if (!bank) {
            alert('Por favor elija el banco correspondiente a la cuenta');
            return;
        }
        if (!account_number) {
            alert('Por favor ingrese el número de cuenta.');
            return;
        }
        setLoading(true);
        if (props.own_bank_account_to_edit) {
            inimmedAxios.post('api_banks/save_own_bank_account', {
                name: name,
                account_number: account_number ? account_number : null,
                bank: bank,
                balance: balance ? balance : '0.00',
                area: area,
                own_bank_account_id: props.own_bank_account_to_edit.id
            })
            .then(response => {
                setLoading(false);
                if (response.data.Result === 'Ok') {
                    toast.success('Cuenta editada con éxito.');
                    props.goBackAndRefresh();
                } else {
                    alert(response.data.Result);
                }
            })
            .catch(reason => alert(reason));
        } else {
            inimmedAxios.post('api_banks/save_own_bank_account', {
                name: name,
                account_number: account_number ? account_number : null,
                bank: bank,
                balance: balance ? balance : '0.00',
                area: area ? area : null
            })
            .then(response => {
                setLoading(false);
                if (response.data.Result === 'Ok') {
                    toast.success('Cuenta creada con éxito.');
                    props.goBackAndRefresh();
                } else {
                    alert(response.data.Result);
                }
            })
            .catch(reason => alert(reason));
        }
    }

    return (
        <div className='form-container'>
            <h2>Detalles</h2>
            <div className='form-input-div'>
                <div className='form-input'>
                    <label>Nombre de la Cuenta:</label>
                    <TextInput onChange={event => setName(event.target.value)} >{ name }</TextInput>
                </div>
                <div className='form-input'>
                    <label>Número de la Cuenta:</label>
                    <TextInput onChange={event => setAccountNumber(event.target.value)} >{ account_number }</TextInput>
                </div>
            </div>
            <div className='form-input-div'>
                <div className='form-input'>
                    <label>Banco:</label>
                    <select defaultValue={ bank as string } onChange={event => setBank(event.target.value as Bank)}>
                        <option value='Santander'>Santander</option>
                        <option value='HSBC'>HSBC</option>
                        <option value='Inbursa'>Inbursa</option>
                        <option value='Efectivo'>Efectivo</option>
                    </select>
                </div>
                <div className='form-input'>
                    <label>Saldo:</label>
                    {/* <TextInput onChange={event => setBalance(event.target.value)} >{ balance }</TextInput> */}
                    <MoneyTextInput current_money_value={ balance } setMoneyValue={value => setBalance(value)} />
                </div>
                <div className='form-input'>
                    <label>Área Correspondiente:</label>
                    <select defaultValue={ area } onChange={event => setArea(event.target.value)}>
                        <option value={BANK_ACCOUNT_AREA.Devices}>Equipos</option>
                        <option value={BANK_ACCOUNT_AREA.Services}>Servicios</option>
                        <option value={BANK_ACCOUNT_AREA.Consumable}>Consumible</option>
                        <option value={BANK_ACCOUNT_AREA.Parts}>Refacciones</option>
                    </select>
                </div>
            </div>
            <div className='top-separator' />
            <div className='button-strip'>
                <PrimaryButton onClick={ submitOwnBankAccount } >{ props.own_bank_account_to_edit ? 'Guardar' : 'Registrar'}</PrimaryButton>
                <SecondaryButton onClick={ props.goBackAndRefresh } >Cancelar</SecondaryButton>
            </div>
        </div>
    )
}
export default OwnBankAccountForm;