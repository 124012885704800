import { ServiceType } from "../../InimmedHelpers";
import { RadioMenu } from "./RadioMenu";

interface ServiceTypeRadioMenuProps {
    setActive: (service_type: ServiceType) => void,
    service_type_options: ServiceType[],
    active_service_type: '1' | '2' | '3' | '4' | '5'
}

export function ServiceTypeRadioMenu(props: ServiceTypeRadioMenuProps) {
    let active_service_string: string;
    switch (props.active_service_type) {
        case '1':
            active_service_string = 'Preventivo';
            break;
        case '2':
            active_service_string = 'Correctivo';
            break;
        case '3':
            active_service_string = 'Instalación';
            break;
        case '4':
            active_service_string = 'Garantía extraordinaria';
            break;
        case '5':
            active_service_string = 'Capacitación';
            break;
    }
    return (
        <RadioMenu setActive={option => props.setActive(option as ServiceType) } active={ active_service_string } options={ props.service_type_options } />
    )
}