import { toast } from "react-toastify";
import { inimmedAxios, currency_formatter } from "../../InimmedHelpers";
import { Income } from "../../Interfaces";
import DangerButton from "../Utility/DangerButton";

interface IncomeDetailsProps {
    goBackAndRefresh: () => void;
    data: Income;
}

function IncomeDetail(props: IncomeDetailsProps) {
    const mobile = window.innerWidth > 768 ? false : true;
    const date = new Date(props.data.date_made);
    const delete_income = function() {
        if (window.confirm('¿Desea eliminar este ingreso? Los cambios que haya causado a saldos serán desechados.')) {
            inimmedAxios.post('api_banks/delete_income', {
                income_id: props.data.id
            })
            .then(response => {
                if (response.data.Result === 'Ok') {
                    toast.success('Ingreso eliminado.');
                    props.goBackAndRefresh();
                } else {
                    alert(response.data.Result);
                }
            })
            .catch(reason => alert(reason));
        }
    }
    return (
        <div style={{ padding: mobile ? '10px' : '20px' }}>
            <p>Monto del Ingreso: { currency_formatter.format(props.data.amount) }</p>
            <p>{ typeof props.data.account_receiving === 'string' ? props.data.account_receiving : props.data.account_receiving.display_name }</p>
            <p>{ typeof props.data.concept === 'string' ? props.data.concept : props.data.concept.display_name }</p>
            <p>{ date.toLocaleString('es-MX', {dateStyle: 'long'}) }</p>
            <div className='button-strip'>
                <DangerButton onClick={ delete_income } >Eliminar</DangerButton>
            </div>
        </div>
    );
}
export default IncomeDetail;