import React from "react";
import { inimmedAxios } from "../../InimmedHelpers";
import PrimaryButton from "../Utility/PrimaryButton";
import DangerButton from "../Utility/DangerButton";
import { toast } from "react-toastify";
import { DetailComponentProps, Device,  } from "../../Interfaces";
import DataEntry from "../Utility/DataEntry";

interface DeviceDetailsProps extends DetailComponentProps {
    data: Device
}

class DeviceDetails extends React.Component<DeviceDetailsProps, {}> {
    constructor(props: DeviceDetailsProps) {
        super(props);

        this.delete = this.delete.bind(this);
    }

    delete() {
        if (window.confirm('¿Seguro que desea borrar el equipo "' + this.props.data.display_name + '"?')) {
            inimmedAxios.post('api/delete_device/' + this.props.data.id)
                .then(response => {
                    if (response.data.Result === 'Success') {
                        toast.success('Equipo eliminado.', {icon: '🗑️'});
                        this.props.getCatalog('devices');
                        this.props.goBack();
                    }
                    else
                        alert(response.data.Result);
                })
                .catch(reason => {
                    alert(reason);
                });
        }
    }

    render() {
        let guaranteeStart: Date;
        let guaranteeEnd: Date;
        let guaranteeString;
        let guaranteeInfo;
        if (this.props.data.guarantee) {
            guaranteeStart = new Date(this.props.data.guarantee.start_date);
            guaranteeEnd = new Date(this.props.data.guarantee.end_date);
            const timeDifference = guaranteeEnd.getTime() - guaranteeStart.getTime();
            const monthsDifference = Math.trunc(timeDifference / 2.628e9);
            const daysDifference = Math.floor(((timeDifference - (2.628e9 * monthsDifference)) / 8.64e7) - 1);
            const dateConfig = {
                weekday: 'long' as const,
                day: '2-digit' as const,
                month: 'long' as const,
                year: 'numeric' as const,
                hour: 'numeric' as const,
                minute: 'numeric' as const,
                hour12: true
            }
            if (this.props.data.in_guarantee) {
                guaranteeString = 'Sí';
                guaranteeInfo = 'Inició: ' + guaranteeStart.toLocaleString('es', dateConfig) + ' | Concluye: ' + guaranteeEnd.toLocaleString('es', dateConfig) + ' Restan ' + monthsDifference + ' meses, ' + daysDifference + ' días.';
            }
            else {
                guaranteeInfo = 'Inició ' + guaranteeStart.toLocaleString('es', dateConfig) + ' | Concluyó: ' + guaranteeEnd.toLocaleString('es', dateConfig);
                guaranteeString = 'No';
            }
        } else {
            guaranteeInfo = '';
            guaranteeString = 'No';
        }
        let fabricationDateConfig: any = {
            month: 'long',
            year: 'numeric',
            day: 'numeric'
        }
        let final_date = new Date();
        if (this.props.data.fabrication_date) {
            const fab_date: Date = new Date(this.props.data.fabrication_date);
            const offset_in_millis = fab_date.getTimezoneOffset() * 60_000;
            final_date = new Date(fab_date.getTime() + offset_in_millis);
        }
        if (typeof this.props.data.model !== 'string' && typeof this.props.data.client !== 'string' && typeof this.props.data.branch !== 'string') {
            return (
                <div className='float-from-down' style={{ boxSizing: 'border-box', padding: '1em', fontSize: '1.25em' }}>
                    <div style={{ display: 'flex', flexGrow: '1', justifyContent: 'space-between', flexWrap: 'wrap', gap: '1rem' }}>
                        <h1>{this.props.data.display_name}</h1>
                        <div className='detail-button-strip'>
                            { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? <PrimaryButton onClick={() => this.props.editFunction(this.props.data.id)}>Editar</PrimaryButton> : null }
                        </div>
                    </div>
                    <div className='top-separator' />
                    <div className='data-container'>
                        <DataEntry title='Serie' data={ this.props.data.serial } nullData='Sin registrar' copyable />
                        <DataEntry title='Notas' data={ this.props.data.notes } nullData='Sin notas' />
                        <DataEntry title='Modelo' data={ this.props.data.model?.display_name } nullData='Sin registrar' onClick={() =>  typeof this.props.data.model !== 'string' ? this.props.getDetail('device_models', this.props.data.model.id) : null} copyable />
                        <DataEntry title='Fecha de Fabricación' data={ this.props.data.fabrication_date ? final_date.toLocaleString('es', fabricationDateConfig) : undefined } nullData='Sin registrar' />
                        <DataEntry title='Cliente' data={ this.props.data.client ? this.props.data.client.display_name : undefined } nullData='Sin cliente' onClick={ () => typeof this.props.data.client !== 'string' ? this.props.getDetail('clients', this.props.data.client.id) : null } />
                        <DataEntry title='Sucursal' data={ this.props.data.branch ? this.props.data.branch.display_name : undefined } nullData='N/A' onClick={ () => typeof this.props.data.branch !== 'string' ? this.props.getDetail('branches', this.props.data.branch.id) : null } />
                        <DataEntry title='Garantía' data={ guaranteeString } nullData='' />
                        {/* <DataEntry title='Detalle Garantía' data={ guaranteeInfo } nullData='' /> */}
                        <DataEntry title='Póliza' data={ this.props.data.in_policy ? 'Sí' : 'No' } nullData='' />
                    </div>
                    <p><strong>Pólizas: </strong><br /></p>
                    {this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                        <div>
                            <DangerButton onClick={this.delete}>Eliminar</DangerButton>
                        </div>
                        :
                        null}
                </div>
            )
        }
    }
}
export default DeviceDetails;