import React from "react";
import { dateInDateRange, datesEqual, engineerColors, engineerNameInArray, inimmedAxios } from "../../InimmedHelpers";
import { Employee } from "../../Interfaces";
import AddButton from "../Utility/AddButton";
import { CalendarEvent, checkIfEngineerIsFiltered } from './Calendar';

interface MobileDayProps {
    changeSectionFunction: (section: number, IDToQueue?: number | null, subsection?: string | null) => void,
    createWorkOrderFunction: (date: Date) => void,
    date: Date,
    month_events: CalendarEvent[],
    filtering: string[],
    user: Employee
}

class MobileDay extends React.Component<MobileDayProps, {}> {

    generateDayEvents() {
        const events_to_generate: JSX.Element[] = [];
        for (const event of this.props.month_events) {
            const event_start: Date = new Date(event.event_start);
            const event_end: Date = new Date(event.event_end);
            if (dateInDateRange(this.props.date, event_start, event_end) && checkIfEngineerIsFiltered(event.engineer, this.props.filtering)) {
                let engineer_color: string = '';
                switch (event.engineer) {
                    case 'Israel':
                        engineer_color = engineerColors.Israel;
                        break;
                    case 'Gustavo':
                        engineer_color = engineerColors.Gustavo;
                        break;
                    case 'Cristian Josué':
                        engineer_color = engineerColors.JoseDaniel;
                        break;
                    case 'José':
                        engineer_color = engineerColors.Jose;
                        break;
                    case 'Juan Pablo':
                        engineer_color = engineerColors.JuanPablo;
                        break;
                }
                let event_color = '';
                switch (event.event_status) {
                    case 'finished':
                        event_color = 'var(--secondary-dark-color)';
                        break;
                    case 'pending':
                        event_color = 'rgb(255, 222, 74)';
                        break;
                    case 'urgent':
                        event_color = '#ee3636';
                        break;
                    case 'in progress':
                        event_color = 'var(--secondary-color)';
                        break;
                }
                events_to_generate.push(
                    <div onClick={() => this.props.changeSectionFunction(event.section_to_link, event.itemID)} className='float-from-down calendar-event-week-p' style={{ borderLeft: 'solid 10px ' + event_color, borderRight: 'solid 10px ' + engineer_color, padding: '5px', borderRadius: '10px', position: 'relative' }}>
                        <p style={{ margin: '0' }}>{ event.type + ' para ' + event.client }</p>
                        <p style={{ margin: '0', fontSize: '0.9em', color: 'rgba(0, 0, 0, 0.5)' }}>{ 'Ing. ' + event.engineer }</p>
                        <p style={{ margin: '0', fontSize: '0.8em', color: 'rgba(0, 0, 0, 0.3)', position: 'fixed', bottom: '2px', right: '2px' }}>{ (event_start.getDate() === this.props.date.getDate() ? event_start.toLocaleTimeString('es-MX', {hour: '2-digit', minute: '2-digit'}) : '9:00') + ' - ' + (event_end.getDate() === this.props.date.getDate() ? event_end.toLocaleTimeString('es-MX', {hour: '2-digit', minute: '2-digit'}) : '19:00') }</p>
                    </div>
                );
            }
        }
        return events_to_generate;
    }

    generateDayName() {
        return this.props.date.toLocaleString('es', {
            weekday: 'long' as const,
            day: '2-digit' as const,
            month: 'long' as const,
            year: 'numeric' as const
        });
    }

    render() {
        return (
            <div className='float-from-down'>
                <h4 style={{ textAlign: 'center' }}>{ this.generateDayName() }</h4>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', overflow: 'visible', marginBottom: '1em' }}>
                    { this.generateDayEvents() }
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                {
                    this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                    <AddButton not_for_form onClick={() => this.props.createWorkOrderFunction( this.props.date )} /> : null
                }
                </div>
            </div>
        )
    }
}

interface MobileMonthCalendarProps {
    changeSectionFunction: (section: number, IDToQueue?: number | null, subsection?: string | null) => void,
    createWorkOrderFunction: (date: Date) => void,
    setActiveDay: (date: Date | null) => void,
    activeDay: Date | null,
    user: Employee
}

interface MobileMonthCalendarState {
    today: Date,
    current_month: number,
    current_year: number,
    events_in_month: CalendarEvent[],
    filtering: string[]
}

export class MobileMonthCalendar extends React.Component<MobileMonthCalendarProps, MobileMonthCalendarState> {
    touchStartX: number;
    touchStartY: number;
    touchEndX: number;
    touchEndY: number;

    constructor(props: MobileMonthCalendarProps) {
        super(props);
        const today = new Date(Date.now());
        this.state = {
            today: today,
            current_month: today.getMonth(),
            current_year: today.getFullYear(),
            events_in_month: [],
            filtering: ['Israel', 'Juan Pablo', 'José', 'Gustavo', 'Cristian Josué']
        }

        this.touchStartX = 0;
        this.touchStartY = 0;
        this.touchEndX = 0;
        this.touchEndY = 0;

        this.startGesture = this.startGesture.bind(this);
        this.endGesture = this.endGesture.bind(this);

        this.nextMonth = this.nextMonth.bind(this);
        this.previousMonth = this.previousMonth.bind(this);
        this.addEngineerToFilter = this.addEngineerToFilter.bind(this);
    }

    componentDidMount() {
        inimmedAxios.get('api/get_month_events', {
            params: {
                month: this.state.current_month + 1,
                year: this.state.current_year,
                user: this.props.user.id,
                long_title: true
            }
        })
        .then(response => {
            this.setState({ events_in_month: response.data });
        })
        .catch(reason => alert(reason));
    }

    parseMonth(month_to_parse?: number) {
        if (month_to_parse) {
            switch (month_to_parse) {
                case 0:
                    return 'Enero';
                case 1:
                    return 'Febrero';
                case 2:
                    return 'Marzo';
                case 3:
                    return 'Abril';
                case 4:
                    return 'Mayo';
                case 5:
                    return 'Junio';
                case 6:
                    return 'Julio';
                case 7:
                    return 'Agosto';
                case 8:
                    return 'Septiembre';
                case 9:
                    return 'Octubre';
                case 10:
                    return 'Noviembre';
                case 11:
                    return 'Diciembre';
            }
        } else {
            switch (this.state.current_month) {
                case 0:
                    return 'Enero';
                case 1:
                    return 'Febrero';
                case 2:
                    return 'Marzo';
                case 3:
                    return 'Abril';
                case 4:
                    return 'Mayo';
                case 5:
                    return 'Junio';
                case 6:
                    return 'Julio';
                case 7:
                    return 'Agosto';
                case 8:
                    return 'Septiembre';
                case 9:
                    return 'Octubre';
                case 10:
                    return 'Noviembre';
                case 11:
                    return 'Diciembre';
            }
        }
    }

    getMonthDays() {
        const current_month_days = new Date(this.state.current_year, this.state.current_month + 1, 0).getDate()
        return current_month_days;
    }

    nextMonth() {
        this.props.setActiveDay(null);
        if (this.state.current_month === 11) {
            this.setState({ current_month: 0, current_year: this.state.current_year + 1 }, () => {
                inimmedAxios.get('api/get_month_events', {
                    params: {
                        month: this.state.current_month + 1,
                        year: this.state.current_year,
                        long_title: true,
                        user: this.props.user.id
                    }
                })
                .then(response => {
                    this.setState({ events_in_month: response.data });
                })
                .catch(reason => alert(reason));
            });
        } else {
            this.setState({ current_month: this.state.current_month + 1 }, () => {
                inimmedAxios.get('api/get_month_events', {
                    params: {
                        month: this.state.current_month + 1,
                        year: this.state.current_year,
                        long_title: true,
                        user: this.props.user.id
                    }
                })
                .then(response => {
                    this.setState({ events_in_month: response.data });
                })
                .catch(reason => alert(reason));
            });
        }
    }

    previousMonth() {
        this.props.setActiveDay(null);
        if (this.state.current_month === 0) {
            this.setState({ current_month: 11, current_year: this.state.current_year - 1 }, () => {
                inimmedAxios.get('api/get_month_events', {
                    params: {
                        month: this.state.current_month + 1,
                        year: this.state.current_year,
                        long_title: true,
                        user: this.props.user.id
                    }
                })
                .then(response => {
                    this.setState({ events_in_month: response.data });
                })
                .catch(reason => alert(reason));
            });
        } else {
            this.setState({ current_month: this.state.current_month - 1 }, () => {
                inimmedAxios.get('api/get_month_events', {
                    params: {
                        month: this.state.current_month + 1,
                        year: this.state.current_year,
                        long_title: true,
                        user: this.props.user.id
                    }
                })
                .then(response => {
                    this.setState({ events_in_month: response.data });
                })
                .catch(reason => alert(reason));
            });
        }
    }

    addEngineerToFilter(event: React.MouseEvent<HTMLParagraphElement>) {
        const engineerNameP = event.target as HTMLParagraphElement;
        for (let i = 0; i < this.state.filtering.length; i++) {
            if (this.state.filtering[i] === engineerNameP.innerHTML) {
                const newFiltering = this.state.filtering;
                newFiltering.splice(i, 1);
                this.setState({ filtering: newFiltering })
                return;
            }
        }
        this.setState({ filtering: [...this.state.filtering, engineerNameP.innerHTML] });
    }

    generateDays() {
        const days_to_generate: JSX.Element[] = [];
        const first_of_current_month = new Date(this.state.current_year, this.state.current_month, 1);
        let last_of_current_month: Date;
        if (this.state.current_month === 11) {
            last_of_current_month = new Date(this.state.current_year + 1, 0, 0);
        } else {
            last_of_current_month = new Date(this.state.current_year, this.state.current_month + 1, 0);
        }

        for (let i = 0; i < first_of_current_month.getDay(); i++) {
            days_to_generate.push(
                <div key={days_to_generate.length} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ margin: '0', color: 'transparent', width: 'fit-content' }}>{ i + 1 }</p>
                </div>
            );
        }

        // Iterate over the inactive days before the start of the current month.
        for (let i = 0; i < last_of_current_month.getDate(); i++) {
            const current_day_iterated = new Date(first_of_current_month.getFullYear(), first_of_current_month.getMonth(), i + 1);

            const paragraph_style: React.CSSProperties = {
                margin: '0',
                width: '1.5em',
                height: '1.5em',
                padding: '2px',
                userSelect: 'none',
                MozUserSelect: 'none',
                WebkitUserSelect: 'none',
                textAlign: 'center'
            }

            if (current_day_iterated.getDay() === 0 || current_day_iterated.getDay() === 6) {
                paragraph_style.color = 'var(--primary-dark-color)';
                if (datesEqual(current_day_iterated, this.state.today)) {
                    paragraph_style.backgroundColor = 'var(--on-primary-color)';
                    paragraph_style.borderRadius = '10px';
                }
            } else {
                if (datesEqual(current_day_iterated, this.state.today)) {
                    paragraph_style.backgroundColor = 'var(--primary-color)';
                    paragraph_style.color = 'var(--on-primary-color)';
                    paragraph_style.borderRadius = '30px';
                }
            }

            if (this.props.activeDay && datesEqual(current_day_iterated, this.props.activeDay)) {
                paragraph_style.backgroundColor = 'var(--primary-light-color)';
                paragraph_style.color = 'var(--on-primary-color)';
                paragraph_style.borderRadius = '50%';
            }

            const event_dots: JSX.Element[] = [];
            let israel_active = false;
            let juanpablo_active = false;
            let cristian_active = false;
            let gustavo_active = false;
            let jose_active = false;
            for (const event of this.state.events_in_month) {
                if (dateInDateRange(current_day_iterated, new Date(event.event_start), new Date(event.event_end)) && checkIfEngineerIsFiltered(event.engineer, this.state.filtering)) {
                    if ((engineerNameInArray('Israel', event.auxiliary_engineers) || event.engineer === 'Israel') && !israel_active) {
                        event_dots.push(<span key={event_dots.length} style={{ color: engineerColors.Israel }}>•</span>);
                        israel_active = true;
                    }
                    if ((engineerNameInArray('Juan Pablo', event.auxiliary_engineers) || event.engineer === 'Juan Pablo') && !juanpablo_active) {
                        event_dots.push(<span key={event_dots.length} style={{ color: engineerColors.JuanPablo }}>•</span>);
                        juanpablo_active = true;
                    }
                    if ((engineerNameInArray('Cristian Josué', event.auxiliary_engineers) || event.engineer === 'Cristian Josué') && !cristian_active) {
                        event_dots.push(<span key={event_dots.length} style={{ color: engineerColors.JoseDaniel }}>•</span>);
                        cristian_active = true;
                    }
                    if ((engineerNameInArray('Gustavo', event.auxiliary_engineers) || event.engineer === 'Gustavo') && !gustavo_active) {
                        event_dots.push(<span key={event_dots.length} style={{ color: engineerColors.Gustavo }}>•</span>);
                        gustavo_active = true;
                    }
                    if ((engineerNameInArray('José', event.auxiliary_engineers) || event.engineer === 'José') && !jose_active) {
                        event_dots.push(<span key={event_dots.length} style={{ color: engineerColors.Jose }}>•</span>);
                        jose_active = true;
                    }
                }
            }

            days_to_generate.push(
                <div onClick={() => this.props.setActiveDay(current_day_iterated)} key={days_to_generate.length} style={{ display: 'flex', cursor: 'pointer', flexDirection: 'column', alignItems: 'center', userSelect: 'none', MozUserSelect: 'none', WebkitUserSelect: 'none', borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)', paddingTop: '3px' }}>
                    <p style={ paragraph_style }>{ i + 1 }</p>
                    <div style={{ margin: '0' }}>
                        { event_dots }
                    </div>
                </div>
            );
        }
        return days_to_generate;
    }

    startGesture(event: React.TouchEvent<HTMLDivElement>) {
        this.touchStartX = event.touches[0].clientX;
        this.touchStartY = event.touches[0].clientY;
    }

    endGesture() {
        if (this.touchEndX !== 0 && this.touchEndY !== 0) {
            if (this.touchStartX - this.touchEndX > window.innerWidth/6) {
                this.nextMonth();
            } else if (this.touchStartX - this.touchEndX < -(window.innerWidth/6)) {
                this.previousMonth();
            }
        }
        this.touchStartX = 0;
        this.touchStartY = 0;
        this.touchEndX = 0;
        this.touchEndY = 0;
    }

    render() {
        const first_of_current_month = new Date(this.state.current_year, this.state.current_month, 1);
        let last_of_current_month: Date;
        if (this.state.current_month === 11) {
            last_of_current_month = new Date(this.state.current_year + 1, 0, 0);
        } else {
            last_of_current_month = new Date(this.state.current_year, this.state.current_month + 1, 0);
        }
        let rows = 5;
        if (first_of_current_month.getDay() + last_of_current_month.getDate() > 35) {
            rows = 6;
        }
        return (
            <div className='float-from-down' style={{ width: '90%', margin: '1em auto auto auto', userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none' }}>
                { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                <div style={{ display: 'grid', gap: '5px', fontSize: '0.7em', gridTemplateRows: '1fr 1fr', gridTemplateColumns: 'repeat(3, 1fr)', marginBottom: '1em' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: '10px', width: '10px', borderRadius: '5px', backgroundColor: checkIfEngineerIsFiltered('Israel', this.state.filtering) ? engineerColors.Israel : '', border: 'solid 2px ' + engineerColors.Israel, marginRight: '0.2em' }}></div>
                        <p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer', color: 'black' }}>Israel</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: '10px', width: '10px', borderRadius: '5px', backgroundColor: checkIfEngineerIsFiltered('Juan Pablo', this.state.filtering) ? engineerColors.JuanPablo : '', border: 'solid 2px ' + engineerColors.JuanPablo, marginRight: '0.2em' }}></div>
                        <p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer', color: 'black' }}>Juan Pablo</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: '10px', width: '10px', borderRadius: '5px', backgroundColor: checkIfEngineerIsFiltered('Cristian Josué', this.state.filtering) ? engineerColors.JoseDaniel : '', border: 'solid 2px ' + engineerColors.JoseDaniel, marginRight: '0.2em' }}></div>
                        <p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer', color: 'black' }}>Cristian Josué</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: '10px', width: '10px', borderRadius: '5px', backgroundColor: checkIfEngineerIsFiltered('Gustavo', this.state.filtering) ? engineerColors.Gustavo : '', border: 'solid 2px ' + engineerColors.Gustavo, marginRight: '0.2em' }}></div>
                        <p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer', color: 'black' }}>Gustavo</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: '10px', width: '10px', borderRadius: '5px', backgroundColor: checkIfEngineerIsFiltered('José', this.state.filtering) ? engineerColors.Jose : '', border: 'solid 2px ' + engineerColors.Jose, marginRight: '0.2em' }}></div>
                        <p onClick={ this.addEngineerToFilter } style={{ left: '0', margin: '0', cursor: 'pointer', color: 'black' }}>José</p>
                    </div>
                </div> : null }
                <div style={{ position: 'fixed', display: 'flex', justifyContent: 'center', width: '100%', height: '1.5em', background: 'linear-gradient(to right, rgba(250, 250, 250, 1), rgba(250, 250, 250, 0), rgba(250, 250, 250, 1)' }}>
                    <div onClick={this.previousMonth} style={{ flex: '1 1 0px' }}></div>
                    <div style={{ flex: '1 1 0px' }}></div>
                    <div onClick={this.nextMonth} style={{ flex: '1 1 0px' }}></div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '3em' }}>
                    <div style={{ display: 'flex', flex: '1' }}>
                        <h3 style={{ margin: '0 auto 5px 0', textAlign: 'center', overflow: 'visible', whiteSpace: 'nowrap' }}>{ this.parseMonth(this.state.current_month - 1) }</h3>
                    </div>
                    <div style={{ display: 'flex', flex: '1', overflow: 'visible' }}>
                        <h3 style={{ margin: '0 0 5px 0', textAlign: 'center', overflow: 'visible', whiteSpace: 'nowrap' }}>{ this.parseMonth() + ' ' + this.state.current_year }</h3>
                    </div>
                    <div style={{ display: 'flex', flex: '1' }}>
                        <h3 style={{ margin: '0 0 5px auto', textAlign: 'center', overflow: 'visible', whiteSpace: 'nowrap' }}>{ this.parseMonth(this.state.current_month + 1) }</h3>
                    </div>
                </div>
                <div style={{ display: 'grid', gridTemplateRows: '1fr', gridTemplateColumns: 'repeat(7, 1fr)', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', borderRadius: '10px 10px 0 0', fontSize: '0.8em', borderTop: 'solid 1px rgba(0, 0, 0, 0.1)', borderRight: 'solid 1px rgba(0, 0, 0, 0.1)', borderLeft: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                    <p style={{ margin: '0', textAlign: 'center', paddingTop: '2px', paddingBottom: '2px' }}>D</p>
                    <p style={{ margin: '0', textAlign: 'center', paddingTop: '2px', paddingBottom: '2px' }}>L</p>
                    <p style={{ margin: '0', textAlign: 'center', paddingTop: '2px', paddingBottom: '2px' }}>M</p>
                    <p style={{ margin: '0', textAlign: 'center', paddingTop: '2px', paddingBottom: '2px' }}>M</p>
                    <p style={{ margin: '0', textAlign: 'center', paddingTop: '2px', paddingBottom: '2px' }}>J</p>
                    <p style={{ margin: '0', textAlign: 'center', paddingTop: '2px', paddingBottom: '2px' }}>V</p>
                    <p style={{ margin: '0', textAlign: 'center', paddingTop: '2px', paddingBottom: '2px' }}>S</p>
                </div>
                <div onTouchStart={ this.startGesture } onTouchMove={event => {this.touchEndX = event.touches[0].clientX; this.touchEndY = event.touches[0].clientY;} } onTouchEnd={ this.endGesture } style={{ display: 'grid', gridTemplateRows: 'repeat(' + rows + ', 1fr)', gridTemplateColumns: 'repeat(7, 1fr)', backgroundColor: 'var(--on-primary-color)', borderRadius: '0 0 10px 10px', height: 'fit-content' }}>
                    { this.generateDays() }
                </div>
                { this.props.activeDay ? <MobileDay user={this.props.user} filtering={this.state.filtering} createWorkOrderFunction={ this.props.createWorkOrderFunction } changeSectionFunction={ this.props.changeSectionFunction } key={new Date().getTime()} month_events={this.state.events_in_month} date={this.props.activeDay} /> : null }
            </div>
        );
    }
}