import React from "react";
import Catalog from "./Catalog";
import { Model, CatalogProps } from "../../Interfaces";

interface ModelsCatalogProps extends CatalogProps {
    reload: (catalog: string) => void,
    data: Model[]
}

class ModelsCatalog extends React.Component<ModelsCatalogProps, {}> {

    render() {
        let newButtonPrompt: string = this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? 'Nuevo modelo' : '';
        return (
            <Catalog data={this.props.data}
            headersForTable={[{name: 'Nombre', width: '25%', data: 'name'}, {name: 'Número de Modelo', width: '25%', data: 'model_number'}, {name: 'Marca', width: '25%', data: 'brand'}, {name: 'Tipo de Equipo', width: '25%', data: 'device_type'}]}
            dataHeaders={['name', 'model_number', 'brand', 'device_type']}
            searchHeaders={['name', 'model_number', 'brand', 'device_type']}
            objectType='Modelos'
            newButtonPrompt={newButtonPrompt}
            newButtonClick={() => this.props.changeSectionFunction(3, null, 'ModelForm')}
            searchPlaceholder='Buscar modelos...'
            getDetail={id => this.props.getDetail('device_models', id)}
            itemsPerPage={10} />
        )
    }
}

export default ModelsCatalog;