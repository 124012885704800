import React from "react";
import SignaturePad from "../Utility/SignaturePad";
import { inimmedAxios } from "../../InimmedHelpers";
import PrimaryButton from "../Utility/PrimaryButton";
import DangerButton from "../Utility/DangerButton";
import { toast } from "react-toastify";
import { Client, Employee, ServiceOrder, WorkOrder } from "../../Interfaces";
import Loading from "../Utility/Loading";
import DataEntry from "../Utility/DataEntry";

interface ServiceOrderDetailsProps {
    getWorkOrders: () => void,
    getWorkOrder: (itemID: number) => void,
    startEditing: () => void,
    goBack: () => void,
    data: ServiceOrder,
    user: Employee
}

interface ServiceOrderDetailsState {
    signing: boolean;
    start_signature: Blob | null;
    end_signature: Blob | null;
    employee_signature: Blob | null;
    client: Client | null;
    loading: boolean;
}

class ServiceOrderDetails extends React.Component<ServiceOrderDetailsProps, ServiceOrderDetailsState> {
    mobile: boolean;
    company_logo: React.RefObject<HTMLImageElement>;
    signature_pad: React.RefObject<SignaturePad>;
    
    constructor(props: ServiceOrderDetailsProps) {
        super(props);
        this.state = {
            signing: false,
            start_signature: null,
            end_signature: null,
            employee_signature: null,
            loading: false,
            client: null
        }

        this.company_logo = React.createRef();

        this.mobile = window.innerWidth > 768 ? false : true;
        this.toggleSignaturePad = this.toggleSignaturePad.bind(this);
        this.signature_pad = React.createRef();
        this.generateServiceReport = this.generateServiceReport.bind(this);
        this.delete = this.delete.bind(this);
        this.sendReportToClient = this.sendReportToClient.bind(this);
    }

    componentDidMount() {
        Promise.all([
            inimmedAxios.get('api/get_signature', {
                params: {
                    service_order: this.props.data.id,
                    start_service_signature: true
                },
                responseType: 'blob'
            }),
            inimmedAxios.get('api/get_signature', {
                params: {
                    service_order: this.props.data.id,
                    start_service_signature: false
                },
                responseType: 'blob'
            }),
            inimmedAxios.get('api/clients/' + (this.props.data.work_order as WorkOrder).client_id)
        ])
            .then(responses => {
                this.setState({
                    start_signature: responses[0].data,
                    end_signature: responses[1].data,
                    client: responses[2].data
                });
            })
            .catch(reason => alert(reason));


        if (typeof this.props.data.engineer !== 'string') {
            inimmedAxios.get('api/get_employee_signature/' + this.props.data.engineer.id, {
                responseType: 'blob'
            })
            .then(response => {
                const responseFile = new Blob([response.data])
                if (responseFile.size === 19) {
                    this.setState({ employee_signature: new Blob([]) })
                } else {
                    this.setState({ employee_signature: responseFile })
                }
            })
            .catch(reason => alert(reason));
        }
    }

    handleStatus() {
        switch (this.props.data.status) {
            case '1':
                return 'En curso';
            case '2':
                return 'Concluido';
            default:
                return 'Desconocido';
        }
    }

    handleFailureType() {
        switch (this.props.data.failure_type) {
            case '1':
                return 'Falta de condiciones básicas.'
            case '2':
                return 'Diseño débil o inadecuado'
            case '3':
                return 'Falta de habilidad del ingeniero'
            case '4':
                return 'Mantenimiento insuficiente'
            case '5':
                return 'Falta de habilidad del técnico u operador'
            case '6':
                return 'Influencias o causas ajenas'
            case '7':
                return 'Tiempo de vida'
            case '8':
                return 'Preventivo'
            case '9':
                return 'Demos'
            case '10':
                return 'Instalación'
            default:
                return 'Sin registrar.'
        }
    }

    handleServiceType() {
        switch (this.props.data.service_type) {
            case '1':
                return 'Preventivo';
            case '2':
                return 'Correctivo';
            case '3':
                return 'Instalación';
            case '4':
                return 'Garantía';
            case '5':
                return 'Capacitación';
            default:
                return 'Desconocido';
        }
    }

    toggleSignaturePad() {
        this.setState({ signing: !this.state.signing });
    }

    handleEditButton() {
        if (typeof this.props.data.engineer !== 'string') {
            if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' || (this.props.user.position === '5' && this.props.user.display_name === this.props.data.engineer.display_name && this.props.data.status !== 'Concluido'))
            return <PrimaryButton onClick={this.props.startEditing}>Llenar Reporte</PrimaryButton>;
        }
        return;
    }

    generateServiceReport() {
        window.open('https://inimmed.com.mx:8000/api/download_service_report_pdf/' + this.props.data.id);
    }

    handlePartsUsed() {
        let parts_used = [];
        if (this.props.data.parts_used) {
            let counter = 0;
            for (const part_used of this.props.data.parts_used) {
                parts_used.push(<p key={counter} >{part_used.name}</p>);
                counter++;
            }
            return parts_used;
        }
        parts_used.push(<p key={0} >Sin refacciones</p>);
    }

    sendReportToClient() {
        inimmedAxios.post('api/send_client_service_report/' + (this.props.data.id))
        .then(response => {
            if (response.data.message === 'ok') {
                toast.success('Reporte enviado', { icon: '👍' });
            } else {
                toast.error('Error al enviar el reporte', { type: 'error', theme: 'colored', style: { color: 'white' } });
            }
        })
        .catch(error => {
            console.error(error);
            toast.error('Error al enviar el reporte', { type: 'error', theme: 'colored', style: { color: 'white' } });
        });
    }

    handleEmailButton() {
        if (this.state.client && this.props.data.status === 'Concluido') {
            if (this.state.client.email) {
                return <PrimaryButton onClick={ this.sendReportToClient } >Enviar reporte a cliente</PrimaryButton>;
            } else {
                return <PrimaryButton inactive >Sin correo para enviar reporte</PrimaryButton>;
            }
        }
        return null;
    }

    delete() {
        if (window.confirm('¿Seguro que desea borrar la orden de servicio "' + this.props.data.display_name + '"?')) {
            inimmedAxios.post('api/delete_service_order/' + this.props.data.id)
                .then(response => {
                    if (response.data.Result === 'Success' && typeof this.props.data.work_order !== 'string') {
                        toast.success('Orden de servicio eliminada.', { icon: '🗑️' });
                        this.props.getWorkOrders();
                        this.props.getWorkOrder(this.props.data.work_order.id);
                        this.props.goBack();
                    }
                    else
                        alert(response.data.Result);
                })
                .catch(reason => {
                    alert(reason);
                });
        }
    }

    render() {
        const serviceOrderStart = new Date(this.props.data.start_date);
        const serviceOrderEnd = this.props.data.end_date ? new Date(this.props.data.end_date) : null;
        const dateConfig = {
            weekday: 'long' as const,
            day: '2-digit' as const,
            month: 'long' as const,
            year: 'numeric' as const,
            hour: 'numeric' as const,
            minute: 'numeric' as const,
            hour12: true
        };
        const pesoMXLocale = Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
            currencyDisplay: 'narrowSymbol',
            useGrouping: true
        });
        const engineersList = this.props.data.auxiliary_engineers.map(engineer => engineer.display_name).join(', ');
        let whoSigned = this.props.data.name_who_accepts ? this.props.data.name_who_accepts : '';
        whoSigned += this.props.data.position_who_accepts ? ` | ${this.props.data.position_who_accepts}` : '';
        if (typeof this.props.data.work_order !== 'string' && typeof this.props.data.engineer !== 'string') {
            if (this.state.loading)
                return <Loading color='primary' />;
            else
                return (
                    <div style={{ boxSizing: 'border-box', fontSize: '1.25em' }} className='float-from-down'>
                        <div style={{ display: 'flex', flexGrow: '1', justifyContent: 'space-between', flexWrap: 'wrap', gap: '1rem', alignItems: 'center' }}>
                            <h1>{ this.props.data.display_name }</h1>
                            <div className='detail-button-strip'>
                                { this.handleEditButton() }
                                { this.props.data.status === 'Concluido' ? <PrimaryButton onClick={this.generateServiceReport}>Descargar PDF</PrimaryButton> : null }
                                { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                                    <DangerButton onClick={this.delete}>Eliminar</DangerButton> : null }
                            </div>
                        </div>
                        <div className='top-separator' />
                        {
                            this.handleEmailButton()
                        }
                        <div className='data-container' >
                            <DataEntry title='Orden de Trabajo' data={ this.props.data.work_order.display_name } nullData='' />
                            <DataEntry title='Firmó Inicio' data={ this.props.data.signature_name } nullData='' />
                            <DataEntry title='Estatus' data={ this.props.data.status } nullData='' />
                            <DataEntry title='Inició' data={ serviceOrderStart.toLocaleString('es', dateConfig) } nullData='' />
                            <DataEntry title='Finalizó' data={ this.props.data.end_date ? serviceOrderEnd?.toLocaleString('es', dateConfig) : undefined } nullData='N/A' />
                            <DataEntry title='Folio' data={ this.props.data.number } nullData='' />
                            <DataEntry title='Ingeniero' data={ this.props.data.engineer ? this.props.data.engineer.display_name : undefined } nullData='Sin asignar' />
                            <DataEntry title='Ingenieros Auxiliares' data={ engineersList } nullData='Sin asignar' />
                            <DataEntry title='Tipo de Falla' data={ this.handleFailureType() } nullData='' />
                            <DataEntry title='Reporte de Servicio' data={ this.props.data.service_report } nullData='Sin registrar' />
                            <DataEntry title='Refacciones' data={ this.props.data.parts_used_open } nullData='Sin refacciones' />
                            <DataEntry title='Transporte y Viáticos' data={ this.props.data.transport_expenses ? pesoMXLocale.format(this.props.data.transport_expenses) : undefined } nullData='Sin registrar' />
                            <DataEntry title='Mano de Obra' data={ this.props.data.labor ? pesoMXLocale.format(this.props.data.labor) : undefined } nullData='Sin registrar' />
                            <DataEntry title='Subtotal' data={ this.props.data.sub_total ? pesoMXLocale.format(this.props.data.sub_total) : undefined } nullData='Sin registrar' />
                            <DataEntry title='I.V.A.' data={ this.props.data.iva ? pesoMXLocale.format(this.props.data.iva) : undefined } nullData='Sin registrar' />
                            <DataEntry title='Total' data={ this.props.data.total ? pesoMXLocale.format(this.props.data.total) : undefined } nullData='Sin registrar' />
                            <DataEntry title='Aceptó Servicio' data={ whoSigned } nullData='Sin terminar' />
                        </div> 
                        <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                            <div>
                                <h3>Firma de Inicio</h3>
                                { this.state.start_signature ? <img alt='firma inicio' src={URL.createObjectURL(this.state.start_signature)} style={{ maxWidth: this.mobile ? '90%' : undefined }} /> : undefined }
                            </div>
                            <div>
                                <h3>Firma de Aceptado</h3>
                                { (this.props.data.status === 'Concluido' && this.state.end_signature) ? <img alt='firma final' src={URL.createObjectURL(this.state.end_signature)} style={{ maxWidth: this.mobile ? '90%' : undefined }} /> : 'Sin finalizar'}
                            </div>
                        </div>
                        {this.state.signing ? <SignaturePad ref={this.signature_pad} toggleSignaturePad={this.toggleSignaturePad} saveSignature={() => null} /> : null}
                    </div>
                );
        }
    }
}
export default ServiceOrderDetails;
