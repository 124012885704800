import React from "react";
import latinize from 'latinize';
import TextInput from "../Utility/TextInput";
import PrimaryButton from "../Utility/PrimaryButton";
import Spinner from "../Utility/Spinner";
import { CatalogTableHeader } from "../../Interfaces";
import { currency_formatter } from "../../InimmedHelpers";

interface CatalogBaseProps {
    getDetail: (itemID: number) => void,
    newButtonClick?: () => void,
    data: any[],
    itemsPerPage: number,
    searchHeaders: string[],
    headersForTable: CatalogTableHeader[],
    searchPlaceholder: string,
    newButtonPrompt: string,
    dataHeaders: string[],
    objectType: string,
    height?: string
}

interface CatalogState {
    currentPage: number,
    found: any[],
    ordering: string,
    currentSearchString: string,
    sortedData: []
}

class Catalog extends React.Component<CatalogBaseProps, CatalogState> {
    mobile: boolean;

    constructor(props: CatalogBaseProps) {
        super(props);
        this.state = {
            currentPage: 1,
            found: [],
            ordering: '',
            currentSearchString: '',
            sortedData: []
        }

        this.mobile = window.innerWidth > 768 ? false : true;

        this.keypressHandle = this.keypressHandle.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.searchFunction = this.searchFunction.bind(this);
        this.pageSpinnerChange = this.pageSpinnerChange.bind(this);
    }

    componentDidMount() {
        window.addEventListener("keydown", this.keypressHandle);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.keypressHandle);
    }

    keypressHandle(event: any) {
        if (event.key === 'ArrowRight')
            this.nextPage();
        else if (event.key === 'ArrowLeft')
            this.previousPage();
    }

    previousPage() {
        if (this.state.currentPage <= 1)
            return;
        this.setState({ currentPage: this.state.currentPage - 1 });
    }

    nextPage() {
        let totalItems;
        if (this.state.found.length > 0) {
            totalItems = this.state.found.length;
        } else {
            totalItems = this.props.data.length;
        }
        const pages = Math.ceil(totalItems / (this.props.itemsPerPage ? this.props.itemsPerPage : 10));
        if (this.state.currentPage + 1 > pages)
            return;
        this.setState({ currentPage: this.state.currentPage + 1 });
    }

    pageSpinnerChange(value: number) {
        let totalItems;
        if (this.state.found.length > 0) {
            totalItems = this.state.found.length;
        } else {
            totalItems = this.props.data.length;
        }
        const pages = Math.ceil(totalItems / (this.props.itemsPerPage ? this.props.itemsPerPage : 10));
        if (value > pages || value < 1) {
            return;
        }
        this.setState({ currentPage: value });
    }

    searchFunction(event: any) {
        if (event.target.value === '') {
            this.setState({ found: [], currentSearchString: '' });
            return;
        }
        const search_lowered = event.target.value.toLowerCase();
        this.pageSpinnerChange(1);
        let filteredRows: any[] = this.props.data.filter((element: any) => {
            let found = false;
            for (const header of this.props.searchHeaders) {
                if (element[header] && typeof element[header] === 'string') {
                    const value_lowered: string = latinize(element[header].toLowerCase());
                    if (value_lowered.includes(search_lowered)) {
                        found = true;
                    }
                }
            }
            return found;
        })
        this.setState({
            found: filteredRows, currentSearchString: event.target.value});
    }

    checkOrdering(headerString: string) {
        if (this.state.ordering) {
            if (this.state.ordering.slice(1) === headerString) {
                if (this.state.ordering.slice(0, 1) === '+')
                    return (<img alt='sorting down' src='icons/downw.svg' style={{width: '1em', rotate: '180deg'}} />);
                else if (this.state.ordering.slice(0, 1) === '-')
                    return (<img alt='sorting up' src='icons/downw.svg' style={{width: '1em'}} />);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    setOrdering(headerString: string) {
        if (this.state.ordering) {
            if (this.state.ordering.slice(1) === headerString) {
                if (this.state.ordering.slice(0, 1) === '+')
                    this.setState({ordering: '-' + headerString});
                if (this.state.ordering.slice(0, 1) === '-')
                    this.setState({ordering: '', sortedData: []});
            } else {
                this.setState({ordering: '+' + headerString});
            }
        } else {
            this.setState({ordering: '+' + headerString});
        }
    }

    generateHeaders() {
        let headers = [];
        for (let header of this.props.headersForTable) {
            headers.push(<th onClick={() => this.setOrdering(header.data)} key={header.name + '-header'} style={{ width: header.width }}>{header.name} { this.checkOrdering(header.data)}</th>);
        }
        return headers;
    }

    render() {
        const itemsPerPage = this.props.itemsPerPage ? this.props.itemsPerPage : 10;
        const pages = Math.ceil(this.props.data.length / itemsPerPage);
        let totalItems;
        if (this.state.found.length > 0) {
            totalItems = this.state.found.length;
        } else {
            totalItems = this.props.data.length;
        }
        let tableData = [];
        let rowCounter = 0;
        let firstItem;
        let lastItem;
        
        let dataForTable;

        if (this.state.currentPage === 1) {
            firstItem = 0;
            lastItem = Math.min(itemsPerPage, totalItems);
        }
        else {
            firstItem = (this.state.currentPage * itemsPerPage) - itemsPerPage;
            lastItem = (this.state.currentPage * itemsPerPage);
            if (lastItem > totalItems) {
                lastItem = totalItems;
            }
        }
        if (this.state.found.length === 0 && this.state.currentSearchString.length === 0) {
            dataForTable = [...this.props.data];
            if (this.state.ordering.length > 0) {
                const sortingHeader = this.state.ordering.slice(1);
                if (typeof dataForTable[0][sortingHeader] === 'number') {
                    if (this.state.ordering.slice(0, 1) === '+') {
                        dataForTable.sort((a, b) => a[sortingHeader] - b[sortingHeader]);
                    }
                    else {
                        dataForTable.sort((a, b) => b[sortingHeader] - a[sortingHeader]);
                    }
                } else {
                    if (this.state.ordering.slice(0, 1) === '+') {
                        dataForTable.sort((a, b) => {
                            if (!a[sortingHeader])
                                return 1;
                            if (!b[sortingHeader])
                                return -1;
                            if (a[sortingHeader] > b[sortingHeader])
                                return 1;
                            else if (a[sortingHeader] < b[sortingHeader])
                                return -1;
                            else return 0;
                        });
                    }
                    else if (this.state.ordering.slice(0, 1) === '-') {
                        dataForTable.sort((a, b) => {
                            if (!a[sortingHeader])
                                return -1;
                            if (!b[sortingHeader])
                                return 1;
                            if (a[sortingHeader] > b[sortingHeader]) {
                                return -1;
                            }
                            else if (a[sortingHeader] < b[sortingHeader]) {
                                return 1;
                            }
                            else {
                                return 0;
                            }
                        });
                    }
                }
            }
            for (const tableItem of dataForTable.slice(firstItem, lastItem)) {
                let columnCounter = 0;
                let tableCells = [];
                for (const header of this.props.dataHeaders) {
                    if (this.props.headersForTable[columnCounter].type) {
                        if (this.props.headersForTable[columnCounter].type === 'currency') {
                            tableCells.push(<td key={header + '-column' + columnCounter}>{ tableItem[header] ? currency_formatter.format(tableItem[header]) : 'Desconocido' }</td>);
                            columnCounter++;
                            continue;
                        }
                        if (this.props.headersForTable[columnCounter].type === 'long_date') {
                            const date_created = new Date(tableItem[header]);
                            tableCells.push(<td key={'date-column' + columnCounter}>{tableItem[header] ? date_created.toLocaleString('es-MX', { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) : 'Sin registrar'}</td>);
                            columnCounter++;
                            continue;
                        }
                        if (this.props.headersForTable[columnCounter].type === 'short_date') {
                            const date_created = new Date(tableItem[header]);
                            tableCells.push(<td key={'date-column' + columnCounter}>{tableItem[header] ? date_created.toLocaleString('es-MX', { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) : 'Sin registrar'}</td>);
                            columnCounter++;
                            continue;
                        }
                    }
                    if (tableItem[header] === 0) {
                        tableCells.push(<td key={header + '-column' + columnCounter}>{'0'}</td>);
                    } else {
                        tableCells.push(<td key={header + '-column' + columnCounter}>{tableItem[header] ? tableItem[header] : 'Sin registrar'}</td>);
                    }
                    columnCounter++;
                }
                tableData.push(
                    <tr onClick={() => this.props.getDetail(tableItem.id)} key={'row-' + rowCounter}>
                        { tableCells }
                    </tr>
                )
                rowCounter++;
            }

            let cellCounter = 100;
            while (tableData.length < this.props.itemsPerPage) {
                const filler_cells: JSX.Element[] = [];
                for (const header of this.props.dataHeaders) {
                    filler_cells.push(<td key={cellCounter++}>&nbsp;</td>);
                }
                tableData.push(
                    <tr key={'row-' + rowCounter} className='filler'>
                        { filler_cells }
                    </tr>
                );
                rowCounter++;
            }
        }
        else if (this.state.currentSearchString.length > 0) {
            dataForTable = [...this.state.found];
            if (this.state.ordering) {
                const sortingHeader = this.state.ordering.slice(1);
                if (typeof dataForTable[0][sortingHeader] === 'number') {
                    if (this.state.ordering.slice(0, 1) === '+') {
                        dataForTable.sort((a, b) => a[sortingHeader] - b[sortingHeader]);
                    }
                    else {
                        dataForTable.sort((a, b) => b[sortingHeader] - a[sortingHeader]);
                    }
                } else {
                    if (this.state.ordering.slice(0, 1) === '+') {
                        dataForTable.sort((a, b) => {
                            if (!a[sortingHeader])
                                return 1;
                            if (!b[sortingHeader])
                                return -1;
                            if (a[sortingHeader] > b[sortingHeader])
                                return 1;
                            else if (a[sortingHeader] < b[sortingHeader])
                                return -1;
                            else return 0;
                        });
                    }
                    else if (this.state.ordering.slice(0, 1) === '-') {
                        dataForTable.sort((a, b) => {
                            if (!a[sortingHeader])
                                return -1;
                            if (!b[sortingHeader])
                                return 1;
                            if (a[sortingHeader] > b[sortingHeader]) {
                                return -1;
                            }
                            else if (a[sortingHeader] < b[sortingHeader]) {
                                return 1;
                            }
                            else {
                                return 0;
                            }
                        });
                    }
                }
            }
            for (const tableItem of dataForTable.slice(firstItem, lastItem)) {
                let tableCells = [];
                let columnCounter = 0;
                for (const header of this.props.dataHeaders) {
                    if (header.includes('date')) {
                        const date_created = new Date(tableItem[header]);
                        tableCells.push(<td key={'date-column' + columnCounter}>{tableItem[header] ? date_created.toLocaleString('es', { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) : 'Sin registrar'}</td>)
                        columnCounter++;
                        continue;
                    }
                    if (this.props.headersForTable[columnCounter].type) {
                        if (this.props.headersForTable[columnCounter].type === 'currency') {
                            tableCells.push(<td key={header + '-column' + columnCounter}>{ tableItem[header] ? currency_formatter.format(tableItem[header]) : 'Desconocido' }</td>);
                            columnCounter++;
                            continue;
                        }
                    }
                    if (tableItem[header] === 0) {
                    tableCells.push(<td key={header + '-column' + columnCounter}>{'0'}</td>);
                    } else {
                        tableCells.push(<td key={header + '-column' + columnCounter}>{tableItem[header] ? tableItem[header] : 'Sin registrar'}</td>);
                    }
                    columnCounter++;
                }
                tableData.push(
                    <tr onClick={() => this.props.getDetail(tableItem.id)} key={'row-' + rowCounter}>
                        {tableCells}
                    </tr>
                )
                rowCounter++;
            }

            let cellCounter2 = 0;
            while (tableData.length < this.props.itemsPerPage) {
                const filler_cells: JSX.Element[] = [];
                for (const header of this.props.dataHeaders) {
                    filler_cells.push(<td key={cellCounter2++}>&nbsp;</td>);
                }
                tableData.push(
                    <tr key={'row-' + rowCounter} className='filler'>
                        { filler_cells }
                    </tr>
                );
                rowCounter++;
            }
        }
        let pageOptions = [];
        for (let i = 1; i < pages + 1; i++) {
            pageOptions.push(<option>{i}</option>);
        }
        return (
            <div className='float-from-down' style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', padding: this.mobile ? '10px' : '1em', gap: '20px' }}>
                <div style={{ display: 'flex', gap: '1em', boxSizing: 'content-box', padding: '10px', flexWrap: 'wrap' }}>
                    { this.props.newButtonPrompt ? <div className='button-strip'><PrimaryButton onClick={ this.props.newButtonClick ? this.props.newButtonClick : () => null }>{this.props.newButtonPrompt}</PrimaryButton></div> : null }
                    { this.props.searchPlaceholder ?  <TextInput onChange={this.searchFunction} placeholder={this.props.searchPlaceholder} mobile={this.mobile}>{ this.state.currentSearchString }</TextInput> : null }
                </div>
                <div style={{ width: '100%', overflowX: 'auto', borderRadius: '10px 10px 0 0' }}>
                    <table style={{ fontSize: this.mobile ? '10px' : 'initial' }}>
                        <thead>
                            <tr>
                                { this.generateHeaders() }
                            </tr>
                        </thead>
                        <tbody>
                            { tableData }
                        </tbody>
                    </table>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 'auto', fontSize: this.mobile ? '1em' : '', flexWrap: 'wrap' }}>
                    Página &nbsp; <Spinner value={this.state.currentPage} onChange={this.pageSpinnerChange} min={1} max={pages} lowerValue={this.previousPage} raiseValue={this.nextPage} /> &nbsp; de {pages} - Mostrando {this.props.objectType} {firstItem + 1} a {lastItem} de {totalItems} 
                </div>
            </div>
        )
    }
}

export default Catalog;