import React from "react";
import Catalog from "./Catalog";
import { Branch, CatalogProps } from "../../Interfaces";

interface BranchesCatalogProps extends CatalogProps {
    reload: (catalog: string) => void,
    data: Branch[],
}

class BranchesCatalog extends React.Component<BranchesCatalogProps, {}> {
    render() {
        let newButtonPrompt: string = this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? 'Nueva sucursal' : '';
        return (
            <Catalog
                data={ this.props.data }
                headersForTable={[{name: 'Sucursal', width: '30%', data: 'name'}, {name: 'Cliente', width: '50%', data: 'client'}, {name: 'Número de Equipos', width: '20%', data: 'num_devices'}]}
                dataHeaders={['name', 'client', 'num_devices']}
                searchHeaders={['display_name', 'client', 'name']}
                objectType='Sucursales'
                getDetail={id => this.props.getDetail('branches', id)}
                newButtonPrompt={ newButtonPrompt }
                newButtonClick={() => this.props.changeSectionFunction(3, null, 'BranchForm')}
                searchPlaceholder='Buscar sucursales...'
                itemsPerPage={10} />
        );
    }
}

export default BranchesCatalog;