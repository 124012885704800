import React from 'react';
import { Chart } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { inimmedAxios, serverIP } from '../../InimmedHelpers';
import { Client, Device, Employee, Model } from '../../Interfaces';
import PrimaryButton from '../Utility/PrimaryButton';
import ClientSelector from '../Utility/Selectors/ClientSelector';
import DeviceSelector from '../Utility/Selectors/DeviceSelector';
import EmployeeSelector from '../Utility/Selectors/EngineerSelector';
import ModelSelector from '../Utility/Selectors/ModelSelector';
import { AllReportContent } from './ReportContents';
import { ServiceFailureTypeGraph, ServiceTypeGraph } from './Graphs/ServiceReportGraphs';

Chart.register(ChartDataLabels);

const enum report_by_options {
    Cliente,
    Ingeniero,
    Zona,
    Modelo,
    Equipo,
    Todo
}

const enum date_to_report_options {
    MesActual,
    MesAnterior,
    Ultimos30Dias,
    AnoActual,
    AnoAnterior,
    Ultimos365Dias,
    FechasEspecificas,
    MesActualDos
}

interface ServiceReportsState {
    report_by: report_by_options;
    date_to_report: date_to_report_options;
    report_date_start: string | null;
    report_date_end: string | null;
    clients: Client[];
    selected_client: Client | null;
    engineers: Employee[];
    selected_engineer: Employee | null;
    selected_zone: number | null;
    models: Model[];
    selected_model: Model | null;
    devices: Device[];
    selected_device: Device | null;
    report_data: any[] | null;
    graphs: any[];
    title: string;
}

interface ServiceReportsProps {

}

export default class ServiceReports extends React.Component<ServiceReportsProps, ServiceReportsState> {
    constructor(props: ServiceReportsProps) {
        super(props);

        this.state = {
            report_by: report_by_options.Cliente,
            date_to_report: date_to_report_options.MesActual,
            report_date_start: null,
            report_date_end: null,
            clients: [],
            selected_client: null,
            engineers: [],
            selected_engineer: null,
            selected_zone: null,
            models: [],
            selected_model: null,
            devices: [],
            selected_device: null,
            report_data: null,
            graphs: [],
            title: ''
        }

        this.setZone = this.setZone.bind(this);
        this.setClient = this.setClient.bind(this);
        this.getReportData = this.getReportData.bind(this);
        this.downloadXLSX = this.downloadXLSX.bind(this);
    }

    componentDidMount() {
        const promises = [];
        const currentState = { ...this.state };

        promises.push(
            inimmedAxios.get('api/clients')
                .then(response => {
                    currentState.clients = response.data;
                })
        );
        promises.push(
            inimmedAxios.get('api/engineers')
                .then(response => {
                    currentState.engineers = response.data;
                })
        );
        promises.push(
            inimmedAxios.get('api/device_models')
                .then(response => {
                    currentState.models = response.data;
                })
        )
        promises.push(
            inimmedAxios.get('api/devices')
                .then(response => {
                    currentState.devices = response.data;
                })
        )
        Promise.all(promises)
            .then(value => {
                this.setState(currentState);
            })
            .catch(reason => alert(reason));
    }

    setZone(event: React.ChangeEvent<HTMLSelectElement>) {
        if (event.target.value === '0') {
            this.setState({ selected_zone: null });
        } else {
            this.setState({ selected_zone: Number.parseInt(event.target.value) });
        }
    }

    setClient(client: Client) {
        const promises = [];
        const newState = { ...this.state };

        newState.selected_client = client;

        if (client) {
            promises.push(inimmedAxios.get('api/get_devices/' + client.id)
                .then(response => {
                    newState.devices = response.data;
                }));

            promises.push(inimmedAxios.get('api/get_client_models', {
                params: {
                    client_id: client.id
                }
            })
                .then(response => {
                    newState.models = response.data;
                }));

            Promise.all(promises)
                .then(idk => this.setState(newState))
                .catch(reason => alert(reason));
        } else {
            promises.push(inimmedAxios.get('api/devices')
                .then(response => {
                    newState.devices = response.data;
                }));

            promises.push(inimmedAxios.get('api/device_models')
                .then(response => {
                    newState.models = response.data;
                }));
        }

        Promise.all(promises)
            .then(idk => {
                this.setState(newState);
            })
            .catch(reason => alert(reason));
    }

    generateGraphs() {
        const graphElements = [];
        if (this.state.graphs.length && this.state.report_data) {
            graphElements.push(<ServiceTypeGraph data={this.state.graphs[0]} totalServices={this.state.report_data.length} />);
            graphElements.push(<ServiceFailureTypeGraph data={this.state.graphs[1]} totalServices={this.state.report_data.length} />);
        }
        
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', gap: '20px' }}>
                {graphElements}
            </div>
        );
    }

    generateReportContent() {
        if (this.state.report_data === null) {
            return (
                <div style={{ height: '200px' }} />
            );
        } else {
            return (
                <AllReportContent data={this.state.report_data} title={ this.state.title } />
            );
        }
    }

    getReportData() {
        inimmedAxios.get('api/get_report_data', {
            params: {
                report_by: this.state.report_by,
                date_to_report: this.state.date_to_report,
                report_date_start: this.state.report_date_start,
                report_date_end: this.state.report_date_end,
                client: this.state.selected_client?.id,
                engineer: this.state.selected_engineer?.id,
                zone: this.state.selected_zone,
                model: this.state.selected_model?.id,
                device: this.state.selected_device?.id
            }
        })
            .then(response => {
                this.setState({ report_data: response.data.data, graphs: response.data.graphs, title: this.generateTitle(response.data.data.length) });
            })
            .catch(reason => alert(reason));
    }

    generateTitle(count: number) {
        let title = 'Servicios';
        let dateSettings: Intl.DateTimeFormatOptions;
        let today = new Date(Date.now());
        if (this.state.selected_client) title += ' de ' + this.state.selected_client.display_name;
        if (this.state.selected_engineer) title += ' realizados por ' + this.state.selected_engineer.display_name;
        if (this.state.selected_model) title += ' a equipos del modelo ' + this.state.selected_model.display_name;
        switch (this.state.date_to_report) {
            case date_to_report_options.MesActualDos: {
                dateSettings = {
                    year: 'numeric',
                    month: 'long'
                }
                title += ' en ' + today.toLocaleString('es-MX', dateSettings);
                break;
            }
            case date_to_report_options.MesAnterior: {
                today.setMonth(today.getMonth() - 1);
                dateSettings = {
                    year: 'numeric',
                    month: 'long'
                }
                title += ' en ' + today.toLocaleString('es-MX', dateSettings);
                break;
            }
            case date_to_report_options.Ultimos30Dias: {
                title += ' en los últimos 30 días';
                break;
            }
            case date_to_report_options.AnoActual: {
                dateSettings = {
                    year: 'numeric'
                }
                title += ' en ' + today.toLocaleString('es-MX', dateSettings);
                break;
            }
            case date_to_report_options.AnoAnterior: {
                today.setFullYear(today.getFullYear() - 1);
                dateSettings = {
                    year: 'numeric',
                }
                title += ' en ' + today.toLocaleString('es-MX', dateSettings);
                break;
            }
            case date_to_report_options.Ultimos365Dias: {
                title += ' en los últimos 365 días';
                break;
            }
        }
        return title;
    }

    downloadXLSX() {
        let xlsxUrl = serverIP + '/api/get_report_xlsx?';
        let params = [];

        if (this.state.report_by) {
            params.push(`report_by=${this.state.report_by}`);
        }

        if (this.state.date_to_report) {
            params.push(`date_to_report=${this.state.date_to_report}`);
        }

        if (this.state.report_date_start) {
            params.push(`report_date_start=${this.state.report_date_start}`);
        }

        if (this.state.report_date_end) {
            xlsxUrl += `report_date_end=${this.state.report_date_end}`
            params.push(`report_date_end=${this.state.report_date_end}`);
        }

        if (this.state.selected_client) {
            params.push(`client=${this.state.selected_client.id}`);
        }

        if (this.state.selected_engineer) {
            params.push(`engineer=${this.state.selected_engineer.id}`);
        }

        if (this.state.selected_zone) {  
            params.push(`zone=${this.state.selected_zone}`);
        }

        if (this.state.selected_model) {
            params.push(`model=${this.state.selected_model.id}`);
        }

        if (this.state.selected_device) {
            params.push(`device=${this.state.selected_device.id}`);
        }
        console.log(xlsxUrl + params.join("&"));
        window.open(xlsxUrl + params.join("&"), '_blank');

        // if (this.state.report_data) {
        //     const workbook = XLSX.utils.book_new();
        //     const worksheet = XLSX.utils.json_to_sheet(this.state.report_data);
        //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        //     XLSX.writeFileXLSX(workbook, this.state.title + '.xlsx');
        // }
    }

    render() {
        return (
            <>
                <h2>Reporte de Servicios</h2>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Cliente:</label>
                        <ClientSelector clients={this.state.clients} selected_client={this.state.selected_client} selected={this.setClient} />
                    </div>
                    <div className='form-input'>
                        <label>Ingeniero:</label>
                        <EmployeeSelector employees={this.state.engineers} selected_employee={this.state.selected_engineer} selected={(engineer) => this.setState({ selected_engineer: engineer })} />
                    </div>
                    <div className='form-input'>
                        <label>Zona:</label>
                        <select defaultValue={0} onChange={this.setZone}>
                            <option value={0}>Todas</option>
                            <option value={4}>Xalapa</option>
                            <option value={5}>Xalapa (Alrededores)</option>
                            <option value={3}>Cuernavaca</option>
                            <option value={2}>Córdoba</option>
                            <option value={1}>Sur</option>
                        </select>
                    </div>
                </div>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Modelo:</label>
                        <ModelSelector models={this.state.models} selected_model={this.state.selected_model} selected={model => this.setState({ selected_model: model })} />
                    </div>
                    <div className='form-input'>
                        <label>Equipo:</label>
                        <DeviceSelector devices={this.state.devices} selected_device={this.state.selected_device} selected={device => this.setState({ selected_device: device })} />
                    </div>
                    <div className='form-input'>
                        <label>Periodo:</label>
                        <select defaultValue={this.state.date_to_report} onChange={event => this.setState({ date_to_report: Number.parseInt(event.target.value) })}>
                            <option value={date_to_report_options.MesActualDos}>Mes Actual</option>
                            <option value={date_to_report_options.MesAnterior}>Mes Anterior</option>
                            <option value={date_to_report_options.Ultimos30Dias}>Últimos 30 Días</option>
                            <option value={date_to_report_options.AnoActual}>Año Actual</option>
                            <option value={date_to_report_options.AnoAnterior}>Año Anterior</option>
                            <option value={date_to_report_options.Ultimos365Dias}>Últimos 365 días</option>
                            <option value={date_to_report_options.FechasEspecificas}>Fechas Específicas</option>
                        </select>
                    </div>
                </div>
                {
                    this.state.date_to_report === date_to_report_options.FechasEspecificas ?
                        <div className='form-input-div'>
                            <div className='form-input'>
                                <label>De:</label>
                                <input className='form-text-input' type={'date'} onChange={event => this.setState({ report_date_start: event.target.value })} />
                            </div>
                            <div className='form-input'>
                                <label>A:</label>
                                <input className='form-text-input' type={'date'} onChange={event => this.setState({ report_date_end: event.target.value })} />
                            </div>
                        </div>
                        :
                        null
                }
                <div className='button-strip'>
                    <PrimaryButton onClick={this.getReportData} >Generar Reporte</PrimaryButton>
                    <PrimaryButton onClick={this.downloadXLSX} inactive={this.state.report_data !== null ? false : true} >Guardar Excel</PrimaryButton>
                </div>
                <div style={{ marginTop: '20px' }}>
                    {this.generateGraphs()}
                    {this.generateReportContent()}
                </div>
            </>
        )
    }
}