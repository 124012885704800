import React from 'react';

interface OptionButtonProps extends React.DOMAttributes<HTMLButtonElement> {
    onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void,
    source: string
    type?: 'normal' | 'dangerous' | 'alternate'
}

class OptionButton extends React.Component<OptionButtonProps, {}> {
    mobile: boolean;

    constructor (props: OptionButtonProps) {
        super(props);
        this.mobile = window.innerWidth > 768 ? false : true;
    }

    render() {
        let button_color;
        if (this.props.type) {
            switch (this.props.type) {
                case 'normal':
                    button_color = '';
                    break;
                case 'dangerous':
                    button_color = 'invert(19%) sepia(90%) saturate(6217%) hue-rotate(357deg) brightness(91%) contrast(122%)';
                    break;
                case 'alternate':
                    button_color = 'var(--secondary-dark-color)';
                    break;
            }
        } else {
            button_color = 'var(--primary-color)';
        }
        return (
            <button style={{ boxShadow: '0 5px 5px rgba(0, 0, 0, 0.1)', padding: '0', cursor: 'pointer', width: this.mobile ? '45px' : '60px', height: this.mobile ? '45px' : '60px', border: 'none', backgroundColor: 'var(--primary-color)', borderRadius: '60%', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'visible' }} onClick={this.props.onClick}><img style={{ width: '50%', filter: 'invert(100%) sepia(0%) saturate(1%) hue-rotate(140deg) brightness(104%) contrast(100%)' }} src={ this.props.source } /></button>
        );
    }
}
export default OptionButton;