import React from "react";
import PrimaryButton from "../Utility/PrimaryButton";
import DangerButton from "../Utility/DangerButton";
import { inimmedAxios } from "../../InimmedHelpers";
import { toast } from "react-toastify";
import { DetailComponentProps, DeviceType } from "../../Interfaces";

interface DeviceTypeDetailsProps extends DetailComponentProps {
    data: DeviceType,
}

class DeviceTypeDetails extends React.Component<DeviceTypeDetailsProps, {}> {
    mobile: boolean;
    
    constructor(props: DeviceTypeDetailsProps) {
        super(props);

        this.mobile = window.innerWidth > 768 ? false : true;

        this.delete = this.delete.bind(this);
    }

    delete() {
        if (window.confirm('¿Seguro que desea borrar el tipo de equipo "' + this.props.data.display_name + '"?')) {
            inimmedAxios.post('api/delete_device_type/' + this.props.data.id)
                .then(response => {
                    if (response.data.Result === 'Success') {
                        toast.success('Tipo de Equipo eliminado. 🗑️');
                        this.props.getCatalog('device_types');
                        this.props.goBack();
                    }
                    else
                        alert(response.data.Result);
                })
                .catch(reason => {
                    alert(reason);
                });
        }
    }

    render() {
        return (
            <div style={{boxSizing: 'border-box', padding: '1em', fontSize: '1.25em'}} className='float-from-down'>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1em', flexWrap: 'wrap'}}>
                    <h1 style={{fontSize: this.mobile ? '1em' : ''}}>{this.props.data.display_name}</h1>
                    { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? <PrimaryButton onClick={() => this.props.editFunction(this.props.data.id)}>Editar</PrimaryButton> : null }
                </div>
                <p><strong>Nombre del Tipo: </strong><br/> { this.props.data.name } </p>
                {this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                        <div>
                            <DangerButton onClick={this.delete}>Eliminar</DangerButton>
                        </div>
                        :
                        null}
            </div>
        )
    }
}
export default DeviceTypeDetails;