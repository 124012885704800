import React from "react";
import Catalog from "./Catalog";
import { DeviceType, CatalogProps } from "../../Interfaces";

interface DeviceTypesCatalogProps extends CatalogProps {
    reload: (catalog: string) => void,
    data: DeviceType[]
}

class DeviceTypesCatalog extends React.Component<DeviceTypesCatalogProps, {}> {
    render() {
        let newButtonPrompt: string = this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? 'Nuevo tipo de equipo' : '';
        return (
            <Catalog data={this.props.data}
            headersForTable={[{name: 'Tipo', width: '70%', data: 'display_name'}, {name: 'Equipos del Tipo', width: '30%', data: 'devices'}]}
            dataHeaders={['display_name', 'devices']}
            searchHeaders={['display_name']}
            objectType={'Tipos de Equipos'}
            searchPlaceholder='Buscar tipos de equipos...'
            newButtonPrompt={newButtonPrompt}
            newButtonClick={() => this.props.changeSectionFunction(3, null, 'DeviceTypeForm')}
            getDetail={id => this.props.getDetail('device_types', id)}
            itemsPerPage={10} />
        )
    }
}

export default DeviceTypesCatalog;