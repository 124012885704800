interface ReportServiceEntry {
    folio: string;
    work_order: string;
    date: string;
    client: string;
    device: string;
    service_type: string;
    reported_failure: string;
    failure_type: string;
    engineer: string;
    accepted_by: string;
}

interface ReportContentProps {
    data: ReportServiceEntry[];
    title: string;
}

export const AllReportContent = (props: ReportContentProps) => {
    const table_rows = [];
    for (const service of props.data) {
        table_rows.push(
            <tr key={table_rows.length}>
                <td>{ service.folio }</td>
                <td>{ service.work_order }</td>
                <td>{ service.date }</td>
                {/* <td>{ new Date(service.date).toLocaleDateString('es-MX', {day: '2-digit', month: '2-digit', year: '2-digit'}) }</td> */}
                <td>{ service.client }</td>
                <td>{ service.device }</td>
                <td>{ service.service_type }</td>
                <td>{ service.reported_failure }</td>
                <td>{ service.failure_type }</td>
                <td>{ service.engineer }</td>
                <td>{ service.accepted_by }</td>
            </tr>
        );
    }
    return (
        <>
        <h2>{ props.data.length + ' ' + props.title }</h2>
            <table>
                <thead>
                    <tr>
                        <th>Folio</th>
                        <th>Orden de Trabajo</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Equipo</th>
                        <th>Tipo de Servicio</th>
                        <th>Falla Reportada</th>
                        <th>Tipo de Falla</th>
                        <th>Ingeniero</th>
                        <th>Aceptado Por</th>
                    </tr>
                </thead>
                <tbody>
                    { table_rows }
                </tbody>
            </table>
        </>
    );
}