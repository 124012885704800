import './App.css';
import Login from './Components/Login';
import React from 'react';
import Inimmed from './Components/Inimmed';
import { inimmedAxios } from './InimmedHelpers';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { Employee } from './Interfaces';

interface AppState {
    user: Employee | null,
    loading: boolean
}

class App extends React.Component<{}, AppState> {

    constructor(props: any) {
        super(props);
        this.state = {
            user: null,
            loading: false
        }

        this.tryLogin = this.tryLogin.bind(this);
        this.logOut = this.logOut.bind(this);
    }

    componentDidMount() {
        this.tryLogin();
    }

    logOut(): void {
        inimmedAxios.post('api/log_out/', {}, {
            withCredentials: true
        })
        .then(response => {
            this.setState({ user: null });
        })
        .catch(reason => alert(reason));
    }

    tryLogin(username?: string | number, password?: string | number): void {
        let toastId: any;
        if (username && password) toastId = toast.loading('Iniciando sesión...');
        inimmedAxios.post('api/login/', {
            username: username ? username : null,
            password: password ? password : null
        }, {
            withCredentials: true
        }).then(response => {
            console.log(response);
            toast.dismiss(toastId);
            if (response.data.User) {
                toast.success(`Bienvenid@, ${response.data.User.display_name.split(' ')[0]}`, {
                    autoClose: 3000,
                    isLoading: false
                });
                this.setState({ user: response.data.User});
            } else if (response.data.Result) {
                if (response.data.Result === 1) {
                    toast.warn(`Credenciales incorrectas`, {
                        autoClose: 3000,
                        isLoading: false
                    });
                }
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    render() {
        let mobile = false;
        if (window.innerWidth < 768) {
            mobile = true;
        }
        return (
            <div className='body-bg'>
                {!this.state.user ?
                <Login tryLogin={this.tryLogin} />
                :
                <Inimmed user={this.state.user} logOut={this.logOut} />}
                <ToastContainer
                    style={{ zIndex: 1000, marginTop: mobile ? '15vh' : '' }}
                    limit={5}
                    autoClose={ 5000 }
                    closeOnClick
                    hideProgressBar={ true }
                    draggablePercent={ 30 }
                    transition={ Flip } 
                    theme='light' />
            </div>
        );
    }
}

export default App;
