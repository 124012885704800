import React, { CSSProperties } from 'react';
import { inimmedAxios } from '../InimmedHelpers';
import { toast } from 'react-toastify';
import { Employee, Notification } from '../Interfaces';
import DashboardSection from './DashboardSection';
import Loading from './Utility/Loading';
import Sidenav from './Sidenav/Sidenav'
import DropdownButton from './Utility/DropdownButton';
import CatalogsSection from './CatalogsSection';

import hamburgerIcon from "../Icons/hamburger.svg";
import FormsSection from './FormsSections';
import ServicesSection from './ServicesSection';
import SignaturePad from "./Utility/SignaturePad";
import AgendaSection from './AgendaSection';
import BanksSection from './BanksSection';

interface InimmedProps {
    user: Employee,
    logOut: () => void
}

interface InimmedState {
    sectionHistory: any[];
    sectionStateHistory: any[];
    loading: boolean;
    sidenavVisible: boolean;
    showProfileMenu: boolean;
    queuedDetailID: number | null;
    queued_subsection: string | null;
    sectionTitle: string;
    updating_signature: boolean;
    signature_upload_percent: number;
}

class Inimmed extends React.Component<InimmedProps, InimmedState> {
    mobile: boolean;
    notificationInterval: NodeJS.Timeout | null;
    signature_pad: React.RefObject<SignaturePad>;
    
    constructor(props: any) {
        super(props);
        this.state = {
            sectionHistory: [],
            sectionStateHistory: [],
            loading: false,
            sidenavVisible: false,
            showProfileMenu: false,
            queuedDetailID: null,
            queued_subsection: null,
            sectionTitle: '',
            updating_signature: false,
            signature_upload_percent: 0
        }
        if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' || this.props.user.position === '6') {
            this.state.sectionHistory.push(0);
        }
        else if (this.props.user.position === '5')
            this.state.sectionHistory.push(4)
        else {
            this.state.sectionHistory.push(1);
        }

        this.signature_pad = React.createRef();

        this.mobile = window.innerWidth > 768 ? false : true;
        
        this.popQueuedSection = this.popQueuedSection.bind(this);
        this.popQueuedItem = this.popQueuedItem.bind(this);
        this.changeSection = this.changeSection.bind(this);
        this.previousSection = this.previousSection.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
        this.changeLoading = this.changeLoading.bind(this);
        this.startSigning = this.startSigning.bind(this);
        this.updateSignature = this.updateSignature.bind(this);
        this.uploadProgressHandler = this.uploadProgressHandler.bind(this);

        this.notificationInterval = null;
        this.getNotifications = this.getNotifications.bind(this);
    }

    componentDidMount() {
        this.getNotifications(this.props.user.id);
        this.notificationInterval = setInterval(() => {
        this.getNotifications(this.props.user.id);
        }, 10000);
    }

    componentWillUnmount() {
        if (this.notificationInterval !== null)
            clearInterval(this.notificationInterval);
    }

    handleServiceNotificationClick(notification: Notification) {
        if (this.state.sectionHistory[this.state.sectionHistory.length - 1] !== 4) {
            this.changeSection(parseInt(notification.section), notification.id_in_section);
        } else {
            this.setState({queuedDetailID: notification.id_in_section});
        }
    }

    getNotifications(userID: number) {
        inimmedAxios.get('api/get_notifications/' + userID)
        .then(response => {
            if (response.data.length) {
                let seenNotifications = [];
                for (const notification of response.data) {
                    toast(notification.text, {
                        autoClose: 3000,
                        progress: 1,
                        icon: '🛠️',
                        position: 'top-left',
                        hideProgressBar: true,
                        onClick: () => this.handleServiceNotificationClick(notification)
                    });
                    seenNotifications.push(notification.id);
                }
                inimmedAxios.post('api/clear_notifications', {
                    seen_notifications: seenNotifications
                });
            }
        })
        .catch(reason => alert(reason));
    }

    previousSection() {
        let currentSectionHistory = this.state.sectionHistory;
        let currentSectionStateHistory = this.state.sectionStateHistory;
        currentSectionHistory.pop();
        currentSectionStateHistory.pop();
        this.setState({ sectionHistory: currentSectionHistory, sectionStateHistory: currentSectionStateHistory });
    }

    changeLoading(state: boolean) {
        this.setState({ loading: state });
    }

    updateTitle(titleToAppend: string) {
        this.setState({ sectionTitle: this.state.sectionTitle + titleToAppend });
    }

    changeSection(section: number, IDToQueue?: number | null, subsection: string | null = null) {
        if (!IDToQueue) {
            const newSectionHistory = [section];
            if (subsection)
                this.setState({ sectionHistory: newSectionHistory, sidenavVisible: false, queued_subsection: subsection });
            else
                this.setState({ sectionHistory: newSectionHistory, sidenavVisible: false });
            return;
        }
        let currentSectionHistory = this.state.sectionHistory;
        currentSectionHistory.push(section);
        this.setState({ sectionHistory: currentSectionHistory, queuedDetailID: IDToQueue ? IDToQueue : null, queued_subsection: subsection ? subsection : null, sidenavVisible: false });
    }

    popQueuedSection() {
        this.setState({queued_subsection: null});
    }

    popQueuedItem() {
        this.setState({ queuedDetailID: null });
    }

    currentSection() {
        switch (this.state.sectionHistory[this.state.sectionHistory.length - 1]) {
            case 0: // Dashboard
                return <DashboardSection popQueuedSection={this.popQueuedSection} user={this.props.user} changeSectionFunction={this.changeSection} goToFormFunction={this.changeSection} />
            case 1: // Catalogs
                return <CatalogsSection popQueuedSection={this.popQueuedSection} queuedCatalog={this.state.queued_subsection ? this.state.queued_subsection : ''} goToWorkOrder={this.changeSection} queuedDetailID={this.state.queuedDetailID ? this.state.queuedDetailID : null} changeLoading={this.changeLoading} backButton={this.state.sectionHistory.length > 1 ? true : false} goBack={this.previousSection} user={this.props.user} changeSectionFunction={this.changeSection} />;
            // case 2: // Inventory
            //     return <Section title={this.state.sectionTitle} />;
            case 3: // Forms
                return <FormsSection popQueuedSection={this.popQueuedSection} startForm={this.state.queued_subsection ? this.state.queued_subsection : ''} user={this.props.user} />;
            case 4: // Services
                return <ServicesSection popQueuedItem={this.popQueuedItem} popQueuedSection={this.popQueuedSection} changeSectionFunction={this.changeSection} user={this.props.user} on_queue={ this.state.queuedDetailID ? this.state.queuedDetailID : undefined} />
                // return <ServicesSection changeSectionFunction={this.changeSection} user={this.props.user} />
            case 5: // Banks
                return <BanksSection user={this.props.user} />
            case 6: // Agenda
                return <AgendaSection changeSectionFunction={this.changeSection} user={this.props.user} />;
            // case 6: // Troubleshooting
            //     return <Section title={this.state.sectionTitle} />;
            default:
                return null;
        }
    }

    startSigning() {
        this.setState({ updating_signature: !this.state.updating_signature });
    }

    updateSignature() {
        if (window.confirm('¿Desea guardar su firma?')) {
            if (this.signature_pad.current?.sig_canvas?.isEmpty()) {
                alert('No se firmó correctamente.');
                return;
            }
        }
        const dataToSend = new FormData();
        const imageToSend = this.signature_pad.current?.sig_canvas?.getTrimmedCanvas().toDataURL();
        if (imageToSend)
            dataToSend.append('image', imageToSend);
        this.setState({ loading: true });
        inimmedAxios.post('api/save_employee_signature/' + this.props.user.id, dataToSend, {
            headers: {'Content-Type': 'multipart/form-data'},
            onUploadProgress: this.uploadProgressHandler,
            timeout: 0
        })
        .then(response => {
            if (response.data.Result === 'Success') {
                alert('Firma actualizada con éxito.');
                this.setState({ updating_signature: false, loading: false, signature_upload_percent: 0 });
            }
        })
        .catch(reason => {
            this.setState({ loading: false, signature_upload_percent: 0 });
            alert(reason);
        });
    }

    uploadProgressHandler(progressEvent: ProgressEvent) {
        if (progressEvent.lengthComputable) {
            this.setState({ signature_upload_percent: progressEvent.loaded / progressEvent.total });
        }
    }

    render() {
        let headerStyle: CSSProperties;
        let hamburgerWidth;
        let titleGap;

        if (this.mobile) {
            headerStyle = {
                margin: '0',
                color: 'var(--on-primary-color)',
                userSelect: 'none',
                fontFamily: 'sans-serif',
                fontSize: '1em',
                cursor: 'pointer'
            }
            hamburgerWidth = '1em';
            titleGap = '0.25em';
        }
        else {
            headerStyle = {
                margin: '0',
                color: 'var(--on-primary-color)',
                userSelect: 'none',
                fontFamily: 'sans-serif',
                cursor: 'pointer',
            }
            hamburgerWidth = '1.75em';
            titleGap = '0.5em';
        }
        if (!this.state.updating_signature) {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflow: 'visible' }}>
                    <div style={{ backgroundColor: 'var(--primary-color)', paddingTop: '0.5em', paddingBottom: '0.5em', paddingLeft: '0.5em', boxShadow: '0 0.2rem 15px 5px rgba(0, 0, 0, 0.2)', display: 'flex', alignItems: 'center', gap: titleGap, zIndex: '6', height: '6%', overflow: 'visible' }}>
                        <img src={hamburgerIcon} style={{ width: hamburgerWidth, cursor: 'pointer' }} onClick={() => this.setState({ sidenavVisible: !this.state.sidenavVisible })} alt='' />
                        <h1 onClick={() => this.setState({ sidenavVisible: !this.state.sidenavVisible })} style={headerStyle}>INIMMED</h1>
                        {this.state.loading ? <Loading color='primary-light' /> : null}
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', overflow: 'visible' }}>
                            <img alt='menú sesión' src='icons/down.svg' style={{ width: this.mobile ? '1em' : '1.5em', filter: 'invert(93%) sepia(73%) saturate(160%) hue-rotate(170deg) brightness(113%) contrast(96%)', cursor: 'pointer', transition: '0.15s', transform: this.state.showProfileMenu ? 'rotate(180deg)' : 'rotate(0deg)' }} onClick={() => this.setState((state) => ({ showProfileMenu: !state.showProfileMenu }))} />
                            <div style={{ position: 'relative', backgroundColor: 'var(--on-primary-color)', display: 'inline-block', overflow: 'visible' }}>
                                <div style={{ display: this.state.showProfileMenu ? 'flex' : 'none', flexDirection: 'column', gap: '0.5em', position: 'absolute', zIndex: '2000', backgroundColor: 'var(--on-primary-color)', padding: '1em', boxShadow: '0 0 15px 2px rgba(0, 0, 0, 0.1)', right: this.mobile ? '100%' : '', borderRadius: '10px' }}>
                                    <DropdownButton onClick={ this.startSigning }>Actualizar Firma</DropdownButton>
                                    <DropdownButton onClick={ () => { toast.clearWaitingQueue();toast.dismiss(); } }>Limpiar Notif.</DropdownButton>
                                    <DropdownButton onClick={ this.props.logOut }>Cerrar sesión</DropdownButton>
                                </div>
                            </div>
                        </div>
                        <h2 style={{ ...headerStyle, color: 'var(--on-primary-color)', marginRight: '1em', cursor: 'auto' }}>{this.mobile ? this.props.user.name : this.props.user.display_name}</h2>
                    </div>
                    <div style={{ backgroundColor: 'var(--bg-color)', width: '100%', display: 'flex', flexGrow: '1', justifyContent: 'center', alignItems: 'center' }}>
                        <Sidenav user={this.props.user} currentSection={this.state.sectionHistory[this.state.sectionHistory.length - 1]} changeSection={this.changeSection} visible={this.state.sidenavVisible} />
                        { this.currentSection() }
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <SignaturePad ref={ this.signature_pad } toggleSignaturePad={this.startSigning} saveSignature={ this.updateSignature } />
                    {this.state.loading ? 
                    <div style={{ position: 'absolute', width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '11', top: '0', left: '0' }}>
                        <p style={{ position: 'absolute', top: '50%', left: '50%', textAlign: 'center', transform: 'translate(-50%, -50%)', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', padding: '8px 16px', borderRadius: '5px', boxShadow: '0 5px 5px rgba(0 , 0, 0, 0.1)', zIndex: '11' }}>Subiendo firma, espere...<br />{ (this.state.signature_upload_percent * 100).toFixed(0) }%</p>
                    </div> : null}
                </>
            );
        }
    }
}
export default Inimmed;