import React from "react";
import SignaturePad from "../Utility/SignaturePad";
import { inimmedAxios } from "../../InimmedHelpers";
import PrimaryButton from "../Utility/PrimaryButton";
import DangerButton from "../Utility/DangerButton";
import { toast } from "react-toastify";
import { Employee, WorkOrder } from "../../Interfaces";
import ServiceOrdersCatalog from "../Catalogs/ServiceOrdersCatalog";
import { getMIMEType } from "../Forms/WorkOrderForm";
import DataEntry from "../Utility/DataEntry";

interface WorkOrderDetailsProps {
    changeSectionFunction: (section: number, itemID?: number, subsection?: string) => void,
    getDetail: (itemID: number) => void,
    getWorkOrders: () => void,
    startEditing: () => void,
    goBack: () => void,
    data: WorkOrder
    user: Employee
}

interface WorkOrderDetailsState {
    signing: boolean;
    signature_name: string;
    loading: boolean;
    signature_upload_percent: number;
}

class WorkOrderDetails extends React.Component<WorkOrderDetailsProps, WorkOrderDetailsState> {
    signature_pad: React.RefObject<SignaturePad>;

    constructor(props: WorkOrderDetailsProps) {
        super(props);
        this.state = {
            signing: false,
            signature_name: '',
            loading: false,
            signature_upload_percent: 0
        }

        this.uploadProgressHandler = this.uploadProgressHandler.bind(this);
        this.goToClient = this.goToClient.bind(this);
        this.goToDevice = this.goToDevice.bind(this);
        this.goToBranch = this.goToBranch.bind(this);
        this.goToEngineer = this.goToEngineer.bind(this);
        this.readSignatureNameAndSign = this.readSignatureNameAndSign.bind(this);
        this.toggleSignaturePad = this.toggleSignaturePad.bind(this);
        this.startService = this.startService.bind(this);
        this.signature_pad = React.createRef();
        this.delete = this.delete.bind(this);
        this.openEvidenceFile = this.openEvidenceFile.bind(this);
        this.downloadWorkOrderPdf = this.downloadWorkOrderPdf.bind(this);
    }

    handleStatus() {
        switch (this.props.data.status) {
            case '1':
                return 'Pendiente';
            case '2':
                return 'Urgente';
            case '3':
                return 'En Proceso';
            case '4':
                return 'Firmada';
            case '5':
                return 'En espera de refacciones';
            case '6':
                return 'En espera de información';
            case '7':
                return 'En espera de autorización';
            case '8':
                return 'Concluido';
            case '9':
                return 'Retomado';
            default:
                return 'Desconocido';
        }
    }

    handleContactMethod() {
        switch (this.props.data.contact_method) {
            case '1':
                return 'Correo Electrónico';
            case '2':
                return 'Llamada Telefónica';
            case '3':
                return 'WhatsApp Oficina';
            case '4':
                return 'WhatsApp Personal';
            case '5':
                return 'Facebook';
            default:
                return 'Desconocido';
        }
    }

    handleServiceType() {
        switch (this.props.data.service_type) {
            case '1':
                return 'Preventivo';
            case '2':
                return 'Correctivo';
            case '3':
                return 'Instalación';
            case '4':
                return 'Garantía';
            case '5':
                return 'Capacitación';
            default:
                return 'Desconocido';
        }
    }

    openEvidenceFile(file_name: string) {
        inimmedAxios.get('api/get_evidence_file', {
            params: {
                work_order: this.props.data.number,
                file_name: file_name
            },
            responseType: 'blob'
        })
            .then(response => {
                let file = new File([response.data], file_name, {
                    type: getMIMEType(file_name)
                });
                window.open(URL.createObjectURL(file));
            })
            .catch(reason => alert(reason));
    }

    handleEvidenceFiles() {
        let evidenceFiles = [];
        let fileCounter = 1;
        for (const evidenceFile of this.props.data.evidence_files) {
            evidenceFiles.push(<p key={fileCounter} className={'clickable-detail'} onClick={() => this.openEvidenceFile(evidenceFile.name)}>{evidenceFile.name}</p>);
            fileCounter++;
        }
        return evidenceFiles;
    }

    goToClient() {
        if (typeof this.props.data.client !== 'string')
            this.props.changeSectionFunction(1, this.props.data.client.id, 'clients');
    }

    goToDevice() {
        if (typeof this.props.data.device !== 'string')
            this.props.changeSectionFunction(1, this.props.data.device.id, 'devices');
    }

    goToBranch() {
        this.props.changeSectionFunction(1, this.props.data.branch.id, 'branches');
    }

    goToEngineer() {
        if ((this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4') && typeof this.props.data.engineer !== 'string')
            this.props.changeSectionFunction(1, this.props.data.engineer.id, 'employees');
    }

    toggleSignaturePad() {
        this.setState({ signing: !this.state.signing });
    }

    readSignatureNameAndSign() {
        let signature_name = prompt('Por favor introduzca el nombre de quien firmará el inicio de servicio:');
        if (signature_name) {
            this.setState({ signature_name: signature_name, signing: true });
        }
    }

    handleStartServiceButton() {
        if ((this.props.data.status === '1' || this.props.data.status === '2' || this.props.data.status === '8') && typeof this.props.data.engineer !== 'string')
            if ((this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4') || (this.props.user.position === '5' && this.props.user.display_name === this.props.data.engineer.display_name))
                if (this.props.data.engineer && this.props.data.expected_start && this.props.data.expected_end)
                    return <PrimaryButton onClick={this.readSignatureNameAndSign}>Iniciar Servicio</PrimaryButton>;
        return null;
    }

    handleEditButton() {
        if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4')
            return <PrimaryButton onClick={this.props.startEditing}>Editar</PrimaryButton>;
        return;
    }

    startService(event: any) {
        event.preventDefault();
        if (window.confirm('¿Desea guardar la firma e iniciar el servicio?')) {
            if (this.signature_pad.current?.sig_canvas?.isEmpty()) {
                alert('No se firmó correctamente.');
                return;
            }
            const dataToSend = new FormData();
            const imageToSend = this.signature_pad.current?.sig_canvas?.getTrimmedCanvas().toDataURL();
            if (imageToSend)
                dataToSend.append('image', imageToSend);
            dataToSend.append('work_order', this.props.data.id.toString());
            dataToSend.append('signature_name', this.state.signature_name);

            this.setState({ loading: true });
            inimmedAxios.post('api/start_service', dataToSend, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: this.uploadProgressHandler,
                timeout: 0
            })
                .then(response => {
                    this.setState({ signing: false, loading: false, signature_upload_percent: 0 });
                    this.props.getDetail(response.data.id);
                })
                .catch(reason => {
                    this.setState({ loading: false, signature_upload_percent: 0 });
                    alert(reason);
                });
        }
    }

    uploadProgressHandler(progressEvent: ProgressEvent) {
        if (progressEvent.lengthComputable) {
            this.setState({ signature_upload_percent: progressEvent.loaded / progressEvent.total });
        }
    }

    delete() {
        if (window.confirm('¿Seguro que desea borrar la orden de trabajo "' + this.props.data.display_name + '"?')) {
            inimmedAxios.post('api/delete_work_order/' + this.props.data.id)
                .then(response => {
                    if (response.data.Result === 'Success') {
                        toast.success('Orden de trabajo eliminada.', { icon: '🗑️' });
                        this.props.getWorkOrders();
                        this.props.goBack();
                    }
                    else
                        alert(response.data.Result);
                })
                .catch(reason => {
                    alert(reason);
                });
        }
    }

    downloadWorkOrderPdf() {
        window.open(`${process.env.REACT_APP_DJANGO_HOST}/api/work_order_pdf?orders=${this.props.data.id}`, '_blank');
    }

    render() {
        const dateCreated = new Date(this.props.data.date_created);
        const dateConfig = {
            weekday: 'long' as const,
            day: '2-digit' as const,
            month: 'long' as const,
            year: 'numeric' as const,
            hour: 'numeric' as const,
            minute: 'numeric' as const,
            hour12: true
        }
        const engineersList = this.props.data.auxiliary_engineers.map(engineer => engineer.display_name).join(', ');
        if (typeof this.props.data.device !== 'string' && typeof this.props.data.client !== 'string' && typeof this.props.data.engineer !== 'string') {
            return (
                !this.state.signing ?
                    <div style={{ boxSizing: 'border-box', fontSize: '1.25em' }} className='float-from-down'>
                        <div style={{ display: 'flex', flexGrow: '1', justifyContent: 'space-between', flexWrap: 'wrap', gap: '1rem', alignItems: 'center' }}>
                            <h1>{this.props.data.display_name}</h1>
                            <div className='detail-button-strip'>
                                <PrimaryButton onClick={this.downloadWorkOrderPdf}>Descargar PDF</PrimaryButton>
                                { this.handleStartServiceButton() }
                                { this.handleEditButton() }
                            </div>
                        </div>
                        <div className="top-separator" />
                        <div className='data-container'>
                            <DataEntry title='Folio' data={ this.props.data.number } nullData={ 'N/A' } />
                            <DataEntry title='Estatus' data={ this.handleStatus() } nullData='' />
                            <DataEntry title='Cliente' data={ this.props.data.client.display_name } nullData='' onClick={ this.goToClient } />
                            <DataEntry title='Sucursal' data={ this.props.data.branch ? this.props.data.branch.display_name : undefined } nullData='N/A' onClick={ this.goToBranch } />
                            <DataEntry title='Equipo' data={ this.props.data.device.display_name } nullData='' onClick={ this.goToDevice } />
                            <DataEntry title='Creada' data={ dateCreated.toLocaleString('es', dateConfig) } nullData='' />
                            <DataEntry title='Creada Por' data={ this.props.data.who_created.display_name } nullData='' />
                            <DataEntry title='Tipo' data={ this.handleServiceType() } nullData='' />
                            <DataEntry title='Falla Reportada' data={ this.props.data.reported_failure } nullData='Sin registrar' />
                            <DataEntry title='Reportó' data={ this.props.data.who_reported ? this.props.data.who_reported : undefined } nullData='Sin registrar' />
                            <DataEntry title='Método de Contacto' data={ this.handleContactMethod() } nullData='' />
                            <DataEntry title='Ingeniero' data={ this.props.data.engineer ? this.props.data.engineer.display_name : undefined } nullData='Sin registrar' onClick={ this.goToEngineer } />
                            <DataEntry title='Ingenieros Auxiliares' data={ this.props.data.auxiliary_engineers.length ? engineersList : undefined } nullData='Sin asignar' />
                            <DataEntry title='Inicio Estimado' data={ this.props.data.expected_start ? new Date(this.props.data.expected_start).toLocaleString('es', dateConfig) : undefined } nullData='Sin asignar' />
                            <DataEntry title='Término Estimado' data={ this.props.data.expected_end ? new Date(this.props.data.expected_end).toLocaleString('es', dateConfig) : undefined } nullData='Sin asignar' />
                            {/*
                            <p><strong>Evidencias de falla:</strong></p> */}
                            {/* {this.props.data.evidence_files.length ?
                            this.handleEvidenceFiles()
                            : <div><p>Sin evidencias</p></div>}*/}
                        </div>
                        {this.props.data.service_orders.length ? <ServiceOrdersCatalog data={this.props.data.service_orders} getDetail={this.props.getDetail} /> : null}
                        {this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                            <div>
                                <DangerButton onClick={this.delete}>Eliminar</DangerButton>
                            </div>
                            :
                            null}
                    </div> :
                    <>
                        <SignaturePad ref={this.signature_pad} toggleSignaturePad={this.toggleSignaturePad} saveSignature={this.startService} />
                        {this.state.loading ?
                            <div style={{ position: 'absolute', width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '11', top: '0', left: '0' }}>
                                <p style={{ position: 'absolute', top: '50%', left: '50%', textAlign: 'center', transform: 'translate(-50%, -50%)', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', padding: '8px 16px', borderRadius: '5px', boxShadow: '0 5px 5px rgba(0 , 0, 0, 0.1)', zIndex: '11' }}>Subiendo firma, espere...<br />{(this.state.signature_upload_percent * 100).toFixed(0)}%</p>
                            </div> : null}
                    </>
            );
        }
    }
}
export default WorkOrderDetails;