import React from "react";
import { inimmedAxios } from "../../InimmedHelpers";
import PrimaryButton from "../Utility/PrimaryButton";
import DangerButton from "../Utility/DangerButton";
import { toast } from "react-toastify";
import { Branch, DetailComponentProps, Employee } from "../../Interfaces";
import DevicesCatalog from "../Catalogs/DevicesCatalog";
import DataEntry from "../Utility/DataEntry";
import BranchViewDevicesCatalog from "../Catalogs/BranchViewDevicesCatalog";

interface BranchDetailsProps extends DetailComponentProps {
    data: Branch,
    user: Employee
}

class BranchDetails extends React.Component<BranchDetailsProps, {}> {
    constructor(props: BranchDetailsProps) {
        super(props);

        this.delete = this.delete.bind(this);
    }

    delete() {
        if (window.confirm('¿Seguro que desea borrar la sucursal "' + this.props.data.display_name + '"?')) {
            inimmedAxios.post('api/delete_branch/' + this.props.data.id)
                .then(response => {
                    if (response.data.Result === 'Success') {
                        toast.success('Sucursal eliminada. 🗑️');
                        this.props.getCatalog('branches');
                        this.props.goBack();
                    }
                    else
                        alert(response.data.Result);
                })
                .catch(reason => {
                    alert(reason);
                });
        }
    }

    buildAddress() {
        let address_string: string = '';
        if (this.props.data.street)
            address_string += this.props.data.street;
        else
            address_string += 'Sin calle registrada';
        if (this.props.data.number)
            address_string += ' ' + this.props.data.number;
        else
            address_string += ' S/N';
        if (this.props.data.district)
            address_string += ', ' + this.props.data.district;
        if (this.props.data.county)
            address_string += ', ' + this.props.data.county;
        if (this.props.data.state)
            address_string += ', ' + this.props.data.state;
        return address_string;
    }

    render() {
        if (typeof this.props.data.client !== 'string') {
            return (
                <div style={{ boxSizing: 'border-box', padding: '1em', fontSize: '1.25em' }} className='float-from-down'>
                    <div style={{ display: 'flex', flexGrow: '1', justifyContent: 'space-between', flexWrap: 'wrap', gap: '1rem', alignItems: 'center' }}>
                        <h1>{ this.props.data.display_name }</h1>
                        <div className='detail-button-strip'>
                            {this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? <PrimaryButton onClick={() => this.props.editFunction(this.props.data.id)}>Editar</PrimaryButton> : null}    
                        </div>
                    </div>
                    <div className='top-separator' />
                    <div className='data-container'>
                        <DataEntry title='Cliente' data={ this.props.data.client ? this.props.data.client.display_name : undefined } nullData='' onClick={ () => typeof this.props.data.client !== 'string' ? this.props.getDetail('clients', this.props.data.client.id) : null } />
                        <DataEntry title='Domicilio' data={ this.buildAddress() } nullData='' externalLink={ this.props.data.location_url } />
                    </div>

                    <p><strong>Equipos: </strong></p>
                    {/* <DevicesCatalog getDetail={this.props.getDetail} data={this.props.data.devices} changeSectionFunction={() => null} user={this.props.user} /> */}
                    <BranchViewDevicesCatalog getDetail={this.props.getDetail} data={this.props.data.devices} changeSectionFunction={() => null} user={this.props.user} />
                    {this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                        <div>
                            <DangerButton onClick={this.delete}>Eliminar</DangerButton>
                        </div>
                        :
                        null}
                </div>
            );
        }
    }
}

export default BranchDetails;