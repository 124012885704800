import { Employee, Zone } from "../../../Interfaces";
import ItemSelector from "./ItemSelector";

interface ZoneSelectorProps {
    selected: (zone: any) => void;
    zones: SelectorZone[];
    selected_zone: SelectorZone | null;
}

export type SelectorZone = {
    id: number;
    display_name: string;
    engineers: Employee[] | null;
    type: string | null;
}

const ZoneSelector = (props: ZoneSelectorProps) => {
    return (
        <ItemSelector selected_item={ props.selected_zone } items={ props.zones } selected={ props.selected } filter_properties={['display_name']} />
    );
}

export default ZoneSelector;