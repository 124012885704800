import React from "react";
import { inimmedAxios } from "../../InimmedHelpers";
import { FormComponentProps, Part, PartType } from "../../Interfaces";
import PrimaryButton from "../Utility/PrimaryButton";
import TextInput from "../Utility/TextInput";
import { Brand, Model, DeviceType } from '../../Interfaces';
import Loading from "../Utility/Loading";
import AddButton from "../Utility/AddButton";
import BrandSelector from "../Utility/Selectors/BrandSelector";
import DeviceTypeSelector from "../Utility/Selectors/DeviceTypeSelector";
import ModelSelector from "../Utility/Selectors/ModelSelector";
import { TabSelector } from "../Utility/TabSelector";
import PartTypeSelector from "../Utility/Selectors/PartTypeSelector";

interface PartFormProps extends FormComponentProps {
    data?: Part
    savedInputs?: PartFormState
}

interface PartFormState {
    name: string;
    current_tab: string;
    part_number: string;
    notes: string;
    stock: number;
    selected_brand: Brand | null;
    response_brands: Brand[] | null;
    response_device_types: DeviceType[] | null;
    selected_device_type: DeviceType | null;
    model: Model | null;
    response_models: Model[] | null;
    selected_part_type: PartType | null;
    response_part_types: PartType[];
}

class PartForm extends React.Component<PartFormProps, PartFormState> {
    tabs: string[];

    constructor(props: PartFormProps) {
        super(props);

        this.tabs = ['Refacción de equipo', 'Refacción Genérica'];

        this.state = {
            name: '',
            part_number: '',
            notes: '',
            stock: 0,
            selected_brand: null,
            response_brands: null,
            selected_device_type: null,
            response_device_types: null,
            model: null,
            response_models: null,
            current_tab: this.tabs[0],
            response_part_types: [],
            selected_part_type: null
        }

        this.selectBrand = this.selectBrand.bind(this);
        this.selectDeviceType = this.selectDeviceType.bind(this);
        this.submitPart = this.submitPart.bind(this);

    }

    componentDidMount() {
        let initial_state: PartFormState = {} as PartFormState;
        const promises: Promise<void>[] = [];

        if (this.props.savedInputs) {
            initial_state = this.props.savedInputs;
        }

        promises.push(inimmedAxios.get('api/get_brands_and_types')
        .then(response => {
            if (this.props.data && typeof this.props.data.model !== 'string') {
                for (const brand of response.data.response_brands) {
                    if (brand.display_name === this.props.data.model?.brand)
                        initial_state.selected_brand = brand;
                }
                for (const device_type of response.data.response_device_types) {
                    if (device_type.display_name === this.props.data.model?.device_type)
                        initial_state.selected_device_type = device_type;
                }
            }
            initial_state.response_brands = response.data.response_brands;
            initial_state.response_device_types = response.data.response_device_types;
        }));

        promises.push(
            inimmedAxios.get('api/get_part_types')
            .then(response => {
                initial_state.response_part_types = response.data;
            })
        );

        if (this.props.data) {
            initial_state.name = this.props.data.name;
            initial_state.part_number = this.props.data.part_number;
            initial_state.notes = this.props.data.notes;
            initial_state.stock = this.props.data.stock;
            initial_state.model = this.props.data.model as Model;
        }


        Promise.all(promises)
        .then(() => this.setState(initial_state))
        .catch(reason => alert(reason));
    }

    selectBrand(brand: Brand) {
        if (!brand) {
            inimmedAxios.get('api/get_models', { 
                params: {
                    selected_device_type: this.state.selected_device_type ? this.state.selected_device_type.id : '0',
                    selected_brand: '0'
                }
            })
            .then(response => {
                this.setState({ response_models: response.data.response_models, response_brands: response.data.response_brands, response_device_types: response.data.response_device_types, model: null, selected_brand: null });
            })
            .catch(reason => alert(reason));
        } else {
            inimmedAxios.get('api/get_models', {
                params: {
                    selected_brand: brand.id,
                    selected_device_type: this.state.selected_device_type ? this.state.selected_device_type.id : '0'
                }
            })
            .then(response => {
                this.setState({ response_models: response.data.response_models, response_device_types: response.data.response_device_types, model: null, selected_brand: brand });
            })
            .catch(reason => {
                alert(reason);
            });
        }
    }

    selectDeviceType(device_type: DeviceType) {
        if (!device_type) {
            inimmedAxios.get('api/get_models', { 
                params: {
                    selected_brand: this.state.selected_brand ? this.state.selected_brand.id : '0',
                    selected_device_type: '0'
                }
            })
            .then(response => {
                this.setState({ response_models: response.data.response_models, response_brands: response.data.response_brands, response_device_types: response.data.response_device_types, model: null, selected_device_type: null });
            })
            .catch(reason => alert(reason));
        } else {
            inimmedAxios.get('api/get_models', {
                params: {
                    selected_brand: this.state.selected_brand ? this.state.selected_brand.id : '0',
                    selected_device_type: device_type.id
                }
            })
            .then(response => {
                this.setState({ response_models: response.data.response_models, response_brands: response.data.response_brands, model: null, selected_device_type: device_type });
            })
            .catch(reason => {
                alert(reason);
            });
        }
    }

    generateTabContent() {
        if (this.state.current_tab === this.tabs[0] && this.state.response_device_types && this.state.response_brands) {
            return (
                <>
                    <h2>Equipo</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Marca:</label>
                            <div className='form-input-plus'>
                                <BrandSelector selected_brand={ this.state.selected_brand } brands={ this.state.response_brands } selected={ this.selectBrand } />
                                { !this.props.data ? <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'BrandForm', state: this.state})}} /> : null }
                            </div>
                        </div>
                        <div className='form-input'>
                            <label>Tipo de Equipo:</label>
                            <div className='form-input-plus'>
                                <DeviceTypeSelector selected_device_type={ this.state.selected_device_type } device_types={ this.state.response_device_types } selected={ this.selectDeviceType } />
                                { !this.props.data ? <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'DeviceTypeForm', state: this.state})}} /> : null }
                            </div>
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Modelo:</label>
                            <div className='form-input-plus'>
                                <ModelSelector selected_model={ this.state.model } models={ this.state.response_models ? this.state.response_models : []  } selected={model => this.setState({ model: model })} />
                                { !this.props.data ? <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'ModelForm', state: this.state})}} /> : null }
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (this.state.current_tab === this.tabs[1] && this.state.response_brands) {
            return (
                <>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Marca:</label>
                            <BrandSelector brands={this.state.response_brands} selected_brand={ this.state.selected_brand } selected={ this.selectBrand } />
                        </div>
                        <div className='form-input'>
                            <label>Tipo de refacción</label>
                            <PartTypeSelector part_types={ this.state.response_part_types } selected_part_type={ this.state.selected_part_type } selected={part_type => this.setState({ selected_part_type: part_type })} />
                        </div>
                    </div>
                </>
            );
        }
    }

    submitPart() {
        if (!this.state.part_number) {
            alert('Por favor ingrese el número de parte de la refacción.');
            return;
        }
        if (!this.state.name) {
            alert('Por favor ingrese el nombre de la refacción.');
            return;
        }
        if (this.state.current_tab === this.tabs[0]) {
            const url = this.props.data ? 'api/save_part/' + this.props.data.id : 'api/save_part';
            inimmedAxios.post(url, {
                name: this.state.name,
                part_number: this.state.part_number,
                model: this.state.model?.id,
                notes: this.state.notes,
                stock: this.state.stock ? this.state.stock : 0
            })
            .then(response => {
                if (response.data.Result === 'Success') {
                    if (window.confirm('Refacción guardada. ¿Salir?')) {
                        if (this.props.data && this.props.getCatalog && this.props.getDetail) {
                            this.props.getCatalog('parts');
                            this.props.getDetail('parts', this.props.data.id, true);
                        }
                        this.props.backFunction();
                    }
                } else {
                    alert(response.data.Result);
                }
            })
            .catch(reason => alert(reason));
        } else {
            if (!this.state.selected_brand) {
                alert('Por favor elija la marca de la refacción.');
                return;
            }
            if (!this.state.selected_part_type) {
                alert('Por favor elija el tipo de refacción.');
                return;
            }
            const url = this.props.data ? 'api/save_part/' + this.props.data.id : 'api/save_part';
            inimmedAxios.post(url, {
                name: this.state.name,
                part_number: this.state.part_number,
                brand: this.state.selected_brand.id,
                part_type: this.state.selected_part_type.id,
                notes: this.state.notes,
                stock: this.state.stock
            })
            .then(response => {
                if (response.data.Result === 'Success') {
                    if (window.confirm('Refacción guardada. ¿Salir?')) {
                        if (this.props.data && this.props.getCatalog && this.props.getDetail) {
                            this.props.getCatalog('parts');
                            this.props.getDetail('parts', this.props.data.id, true);
                        }
                        this.props.backFunction();
                    }
                } else {
                    alert(response.data.Result);
                }
            })
            .catch(reason => alert(reason));
        }
    }

    render() {
        if (this.state.response_brands !== null && this.state.response_device_types !== null) {
            return (
                <div className='form-container'>
                    <h2>Detalles</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Nombre de la Refacción:</label>
                            <TextInput onChange={event => this.setState({name: event.target.value})}>{this.state.name}</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Número de parte:</label>
                            <TextInput onChange={event => this.setState({part_number: event.target.value})}>{ this.state.part_number }</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Existencia:</label>
                            <TextInput type='number' onChange={event => this.setState({ stock: event.target.value })}>{ this.state.stock ? this.state.stock : '0' }</TextInput>
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Notas:</label>
                            <textarea rows={4} onChange={event => this.setState({notes: event.target.value})} value={this.state.notes} />
                            </div>
                    </div>
                    <div className='top-separator'></div>
                    <TabSelector active_tab={this.state.current_tab} tab_names={this.tabs} setTab={tab => this.setState({ current_tab: tab })} />
                    { this.generateTabContent() }
                    <div className='top-separator'></div>
                    <div className='button-strip'>
                        <PrimaryButton onClick={this.submitPart}>{ this.props.data ? 'Guardar' : 'Registrar'}</PrimaryButton>
                    </div>
                </div>
            )
        } else {
            return <Loading color='primary' />;
        }
    }
}

export default PartForm;