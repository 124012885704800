import { inimmedAxios } from "../../InimmedHelpers";
import React from "react";
import TextInput from "../Utility/TextInput";
import PrimaryButton from "../Utility/PrimaryButton";
import AddButton from "../Utility/AddButton";
import { Brand, DeviceType, FormComponentProps, Model } from "../../Interfaces";
import Loading from "../Utility/Loading";
import BrandSelector from "../Utility/Selectors/BrandSelector";
import DeviceTypeSelector from "../Utility/Selectors/DeviceTypeSelector";

interface ModelFormProps extends FormComponentProps {
    data?: Model,
    savedInputs?: ModelFormState
}

interface ModelFormState {
    name: string,
    model_number: string,
    response_brands: Brand[],
    brand: Brand | null,
    response_device_types: DeviceType[],
    device_type: DeviceType | null
}

class ModelForm extends React.Component<ModelFormProps, ModelFormState> {
    constructor(props: ModelFormProps) {
        super(props);
        this.state = {
            name: '',
            model_number: '',
            response_brands: [],
            brand: null,
            response_device_types: [],
            device_type: null
        }

        this.submitModel = this.submitModel.bind(this);
    }

    componentDidMount() {
        let initial_state: ModelFormState = {} as ModelFormState;
        const promises: Promise<void>[] = [];

        if (this.props.savedInputs) {
            initial_state = this.props.savedInputs;
        }

        promises.push(inimmedAxios.get('api/get_brands_and_types')
        .then(response => {
            if (this.props.data) {
                if (typeof this.props.data.brand !== 'string') {
                    for (const brand of response.data.response_brands) {
                        if (brand.display_name === this.props.data.brand.display_name)
                            initial_state.brand = brand;
                    }
                }
                if (typeof this.props.data.device_type !== 'string') {
                    for (const device_type of response.data.response_device_types) {
                        if (device_type.display_name === this.props.data.device_type.display_name)
                            initial_state.device_type = device_type;
                    }
                }
                initial_state.name = this.props.data.name;
                initial_state.model_number = this.props.data.model_number;
            }
            initial_state.response_brands = response.data.response_brands;
            initial_state.response_device_types = response.data.response_device_types;
        }));

        Promise.all(promises)
        .then(() => {
            this.setState(initial_state);
        })
        .catch(reason => alert(reason));
    }

    submitModel() {
        if (!this.state.name) {
            alert('Por favor escriba el nombre del nuevo modelo.');
            return;
        }
        if (!this.state.brand) {
            alert('Por favor elija una marca del nuevo modelo.');
            return;
        }
        if (!this.state.device_type) {
            alert('Por favor elija el tipo de equipo del nuevo modelo.');
            return;
        }
        const url = this.props.data ? 'api/save_model/' + this.props.data.id : 'api/save_model';
        inimmedAxios.post(url, {
            name: this.state.name,
            model_number: this.state.model_number,
            brand: this.state.brand.id,
            device_type: this.state.device_type.id
        })
        .then(response => {
            if (response.data.Result === 'Success') {
                if (window.confirm('Modelo guardado, ¿Salir?')) {
                    if (this.props.data && this.props.getCatalog && this.props.getDetail) {
                        this.props.getCatalog('device_models');
                        this.props.getDetail('device_models', this.props.data.id, true);
                    }
                    this.props.backFunction();
                }
            } else {
                alert(response.data.Result);
            }
        })
        .catch(reason => {
            alert(reason);
        });
    }

    render() {
        if (this.state.response_brands.length && this.state.response_device_types.length) {
            return (
                <div className='form-container'>
                    <h2>Detalles</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Nombre del Modelo:</label>
                            <TextInput onChange={event => this.setState({name: event.target.value})}>{ this.state.name }</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Número del Modelo:</label>
                            <TextInput onChange={event => this.setState({model_number: event.target.value})}>{ this.state.model_number }</TextInput>
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Marca:</label>
                            <div className='form-input-plus'>
                                <BrandSelector selected_brand={ this.state.brand } brands={ this.state.response_brands } selected={brand => this.setState({ brand: brand })} />
                                { !this.props.data ? <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'BrandForm', state: this.state})}} /> : null }
                            </div>
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Tipo de Equipo:</label>
                            <div className='form-input-plus'>
                                <DeviceTypeSelector selected_device_type={ this.state.device_type } device_types={ this.state.response_device_types } selected={device_type => this.setState({ device_type: device_type })} />
                                { !this.props.data ? <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'DeviceTypeForm', state: this.state})}} /> : null }
                            </div>
                        </div>
                    </div>
                    <div className='top-separator'></div>
                    <div className='button-strip'>
                        <PrimaryButton onClick={this.submitModel}>{ this.props.data ? 'Guardar' : 'Registrar'}</PrimaryButton>
                    </div>
                </div>
            )
        } else {
            return <Loading color='primary' />;
        }
    }
}

export default ModelForm;