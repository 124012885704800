import React from "react";
import { inimmedAxios } from "../../InimmedHelpers";
import PrimaryButton from "../Utility/PrimaryButton";
import TextInput from "../Utility/TextInput";
import SignaturePad from "../Utility/SignaturePad";
import { Brand, DeviceType, Employee, Part, ServiceOrder, WorkOrder } from "../../Interfaces";
import { PartCollection } from "../Collections/PartCollection";

interface ServiceOrderFormProps {
    startEditing: () => void,
    goBack: () => void,
    data: ServiceOrder,
    user: Employee
}

interface ServiceOrderFormState {
    id: number | null;
    work_order: WorkOrder  | null;
    status: string;
    number: string;
    auxiliary_engineers: Employee[];
    failure_type: string;
    service_report: string;
    transport_expenses: number;
    labor: number;
    sub_total: number;
    iva: number;
    total: number;
    name_who_accepts: string;
    position_who_accepts: string;
    signing: boolean;
    device_guarantee_duration: number  | null;
    response_parts: Part[];
    parts_used: Part[];
    selected_part: Part | null;
    response_part_brands: Brand[];
    selected_part_brand: Brand | null;
    response_part_device_types: DeviceType[];
    selected_part_device_type: DeviceType | null;
    loading: boolean;
    signature_upload_percent: number;
    parts_used_open: string;
}

class ServiceOrderForm extends React.Component<ServiceOrderFormProps, ServiceOrderFormState> {
    part_input_container: React.RefObject<HTMLInputElement>;
    signature_pad: React.RefObject<SignaturePad>;

    constructor(props: ServiceOrderFormProps) {
        super(props);
        this.state = {
            id: null,
            work_order: null,
            status: '',
            number: '',
            auxiliary_engineers: [],
            failure_type: '',
            service_report: '',
            transport_expenses: 0.00,
            labor: 0.00,
            sub_total: 0.00,
            iva: 0.00,
            total: 0.00,
            name_who_accepts: '',
            position_who_accepts: '',
            signing: false,
            device_guarantee_duration: null,
            response_parts: [],
            parts_used: [],
            selected_part: null,
            response_part_brands: [],
            selected_part_brand: null,
            response_part_device_types: [],
            selected_part_device_type: null,
            loading: false,
            signature_upload_percent: 0,
            parts_used_open: ''
        }

        this.part_input_container = React.createRef();

        this.signature_pad = React.createRef();

        this.handleUploadProgress = this.handleUploadProgress.bind(this);

        this.saveServiceOrder = this.saveServiceOrder.bind(this);
        this.endService = this.endService.bind(this);
        this.changeTransportExpenses = this.changeTransportExpenses.bind(this);
        this.changeLabor = this.changeLabor.bind(this);
        this.toggleSignaturePad = this.toggleSignaturePad.bind(this);
        this.selectPartBrand = this.selectPartBrand.bind(this);
        this.selectPartDeviceType = this.selectPartDeviceType.bind(this);
        this.addPart = this.addPart.bind(this);
        this.removeUsedPart = this.removeUsedPart.bind(this);
    }

    componentDidMount() {
        this.setState({
            id: this.props.data.id,
            work_order: this.props.data.work_order as WorkOrder,
            status: this.props.data.status,
            number: this.props.data.number,
            auxiliary_engineers: this.props.data.auxiliary_engineers as Employee[],
            failure_type: this.props.data.failure_type,
            service_report: this.props.data.service_report,
            transport_expenses: this.props.data.transport_expenses,
            labor: this.props.data.labor,
            sub_total: this.props.data.sub_total,
            iva: this.props.data.iva,
            name_who_accepts: this.props.data.name_who_accepts,
            position_who_accepts: this.props.data.position_who_accepts,
            device_guarantee_duration: this.props.data.device_guarantee_duration,
            parts_used: this.props.data.parts_used,
            parts_used_open: this.props.data.parts_used_open
        });

        inimmedAxios.get('api/get_parts_brands_and_types', {
            params: {
                part_brand: this.state.selected_part_brand ? this.state.selected_part_brand.id : null,
                part_type: this.state.selected_part_device_type ? this.state.selected_part_device_type : null
            }
        })
        .then(response => {
            this.setState({ response_part_brands: response.data.brands, response_part_device_types: response.data.types, response_parts: response.data.parts });
        })
        .catch(reason => alert(reason));
        inimmedAxios.get('api/parts')
        .then(response => this.setState({response_parts: response.data}))
        .catch(reason => alert(reason));
    }

    updatePartStock() {
        let current_response_parts = [...this.state.response_parts];
        for (const responsePart of current_response_parts) {
            for (const partUsed of this.state.parts_used) {
                if (responsePart.id === partUsed.id) {
                    responsePart.stock--;
                    break;
                }
            }
        }
        this.setState({ response_parts: current_response_parts });
    }

    toggleSignaturePad() {
        this.setState((state, props) => ({signing: !state.signing}));
    }

    selectPartBrand(brand: Brand) {
        if (!brand) {
            inimmedAxios.get('api/get_parts_brands_and_types', {
                params: {
                    part_type: this.state.selected_part_device_type ? this.state.selected_part_device_type.id : null
                }
            })
            .then(response => {
                this.setState({selected_part_brand: null,  response_parts: response.data.parts, response_part_brands: response.data.brands, response_part_device_types: response.data.types }, this.updatePartStock);
            })
        } else {
            inimmedAxios.get('api/get_parts_brands_and_types', {
                params: {
                    part_brand: brand.id,
                    part_type: this.state.selected_part_device_type ? this.state.selected_part_device_type.id : null
                }
            })
            .then(response => {
                this.setState({selected_part_brand: brand, response_part_device_types: response.data.types, response_parts: response.data.parts}, this.updatePartStock);
            })
            .catch(reason => alert(reason));
        }
    }

    handlePartBrands() {
        let partBrands = [<option value={0} >Elija una marca de refacción</option>];
        for (const part_brand of this.state.response_part_brands) {
            partBrands.push(<option key={part_brand.id} value={part_brand.id}>{part_brand.name}</option>);
        }
        return partBrands;
    }

    selectPartDeviceType(device_type: DeviceType) {
        if (!device_type) {
            inimmedAxios.get('api/get_parts_brands_and_types', {
                params: {
                    part_brand: this.state.selected_part_brand ? this.state.selected_part_brand.id : null
                }
            })
            .then(response => {
                this.setState({selected_part_device_type: null, response_parts: response.data.parts, response_part_brands: response.data.brands, response_part_device_types: response.data.types }, this.updatePartStock);
            })
            .catch(reason => alert(reason));
        } else {
            inimmedAxios.get('api/get_parts_brands_and_types', {
                params: {
                    part_type: device_type.id,
                    part_brand: this.state.selected_part_brand ? this.state.selected_part_brand.id : null
                }
            })
            .then(response => {
                this.setState({selected_part_device_type: device_type, response_part_brands: response.data.brands, response_parts: response.data.parts}, this.updatePartStock);
            })
            .catch(reason => alert(reason));
        }
    }

    handlePartDeviceTypes() {
        let partDeviceTypes = [<option value={0}>Elija un tipo de equipo para la refacción</option>];
        for (const part_device_type of this.state.response_part_device_types) {
            partDeviceTypes.push(<option key={part_device_type.id} value={part_device_type.id}>{part_device_type.name}</option>)
        }
        return partDeviceTypes;
    }

    handleParts() {
        let parts = [];
        for (const part of this.state.response_parts) {
            parts.push(<option key={part.id}>{part.display_name}</option>);
        }
        return parts;
    }

    addPart(part: Part) {
        for (const part_iterated of this.state.response_parts) {
            if (part.id === part_iterated.id) {
                if (part.stock > 0) {
                    this.setState({ parts_used: [...this.state.parts_used, part] }, this.updatePartStock);
                    return;
                } else {
                    alert('No hay más en existencia de esta refacción.');
                    return;
                }
            }
        }
    }

    removeUsedPart(partUsedID: number) {
        let local_parts_used = this.state.parts_used;
        let localPartsLoaded = this.state.response_parts;
        for (let i = 0; i < this.state.parts_used.length; i++) {
            for (const part of localPartsLoaded) {
                if (local_parts_used[i].id === part.id) {
                    part.stock++;
                }
            }
            if (this.state.parts_used[i].id === partUsedID) {
                local_parts_used.splice(i, 1);
                this.setState({parts_used: local_parts_used, response_parts: localPartsLoaded});
                return;
            }
        }
    }

    handlePartsUsed() {
        let partsUsed = [];
        let partUsedCounter = 0;
        for (const part of this.state.parts_used) {
            partsUsed.push(<div style={{display: 'flex', gap: '1em'}}><p key={partUsedCounter++}>{ part.name }</p><img alt='borrar' onClick={() => this.removeUsedPart(part.id)} style={{width: '2em', cursor: 'pointer'}} src='icons/trash.svg' /></div>);
        }
        return partsUsed;
    }

    changeTransportExpenses(event: React.ChangeEvent<HTMLInputElement>) {
        const newExpenses = parseFloat(event.target.value);
        const newsub_total = newExpenses + this.state.labor;
        const newIVA = newsub_total * 0.16;
        const newTotal = newsub_total + newIVA;
        this.setState({transport_expenses: newExpenses, sub_total: newsub_total, iva: newIVA, total: newTotal});
    }

    changeLabor(event: React.ChangeEvent<HTMLInputElement>) {
        const newLabor = Number.parseFloat(event.target.value);
        const newsub_total = newLabor + this.state.transport_expenses;
        const newIVA = newsub_total * 0.16;
        const newTotal = newsub_total + newIVA;
        this.setState({labor: newLabor, sub_total: newsub_total, iva: newIVA, total: newTotal});
    }

    saveServiceOrder() {
        inimmedAxios.post('api/save_service_order/' + this.state.id, this.state)
        .then(response => {
            if (response.data.Result === 'Success') {
                alert('Los cambios fueron guardados.');
                this.props.goBack();
            }
            else
                alert(response.data.Result);
        })
        .catch(reason => alert(reason));
    }

    validateInputs() {
        if (typeof this.props.data.work_order !== 'string') {
            if (!this.state.failure_type && this.props.data.work_order.service_type !== '3') {
                alert('Por favor elija un tipo de falla.');
                return false;
            }
        }
        if (!this.state.service_report) {
            alert('Por favor escriba un reporte de servicio.');
            return false;
        }
        if (!this.state.name_who_accepts || !this.state.position_who_accepts) {
            alert('Por favor escriba los datos de la persona que acepta de finalizado el servicio.');
            return false;
        }
        return true;
    }

    endService() {
        if (window.confirm('¿Desea guardar la firma y finalizar el servicio?')) {
            if (this.signature_pad.current?.sig_canvas?.isEmpty()) {
                alert('No se firmó correctamente.');
                return;
            }
            const dataToSend = new FormData();
            const imageToSend = this.signature_pad.current?.sig_canvas?.getTrimmedCanvas().toDataURL();
            if (imageToSend)
                dataToSend.append('image', imageToSend);
            dataToSend.append('failure_type', this.state.failure_type);
            dataToSend.append('service_report', this.state.service_report);
            if (this.state.transport_expenses)
                dataToSend.append('transport_expenses', this.state.transport_expenses.toString());
            if (this.state.labor)
                dataToSend.append('labor', this.state.labor.toString());
            if (this.state.sub_total)
                dataToSend.append('sub_total', this.state.sub_total.toString());
            if (this.state.iva)
                dataToSend.append('iva', this.state.iva.toString());
            if (this.state.total)
                dataToSend.append('total', this.state.total.toString());
            dataToSend.append('name_who_accepts', this.state.name_who_accepts);
            dataToSend.append('position_who_accepts', this.state.position_who_accepts);
            if (this.state.device_guarantee_duration)
                dataToSend.append('device_guarantee_duration', this.state.device_guarantee_duration.toString());
            dataToSend.append('parts_used', JSON.stringify(this.state.parts_used));
            dataToSend.append('parts_used_open', this.state.parts_used_open)

            this.setState({ loading: true });
            inimmedAxios.post('api/end_service/' + this.state.id, dataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 0,
                onUploadProgress: this.handleUploadProgress
            })
            .then(response => {
                if (response.data.Result === 'Success') {
                    alert('Servicio finalizado con éxito.');
                    this.setState({ signing: false, loading: false, signature_upload_percent: 0 });
                    this.props.goBack();
                }
                else
                    alert(response.data.Result);
            })
            .catch(reason => {
                this.setState({ signing: false, loading: false, signature_upload_percent: 0 });
                alert(reason);
            });
        }
    }

    handleUploadProgress(progressEvent: ProgressEvent) {
        if (progressEvent.lengthComputable) {
            this.setState({ signature_upload_percent: progressEvent.loaded / progressEvent.total });
        }
    }

    render() {
        if (typeof this.props.data.work_order !== 'string') {
            return (
                !this.state.signing ? 
                <div className='form-container'>
                    <h2>Detalles</h2>
                    { this.props.data.work_order.service_type !== '3' ? <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Tipo de Falla:</label>
                            <select onChange={event => this.setState({failure_type: event.target.value})} value={this.state.failure_type}>
                                <option value='0'>Elija un tipo de falla.</option>
                                <option value='1'>Falta de condiciones básicas</option>
                                <option value='2'>Diseño débil o inadecuado</option>
                                <option value='3'>Falta de habilidad del ingeniero</option>
                                <option value='4'>Mantenimiento insuficiente</option>
                                <option value='5'>Falta de habilidad del técnico u operador</option>
                                <option value='6'>Influencias o causas ajenas</option>
                                <option value='7'>Tiempo de vida</option>
                                <option value='8'>Preventivo</option>
                                <option value='9'>Demos</option>
                                <option value='10'>Instalación</option>
                            </select>
                        </div>
                    </div> : null }
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Reporte de Servicio:</label>
                            <textarea rows={4} onChange={event => this.setState({service_report: event.target.value})} value={this.state.service_report} />
                        </div>
                    </div>
                    <div className='top-separator' />
                    <h2>Refacciones: </h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <textarea rows={4} onChange={event => this.setState({parts_used_open: event.target.value})} value={this.state.parts_used_open} />
                            {/* <label></label> */}
                            {/* <BrandSelector selected_brand={ this.state.selected_part_brand } brands={ this.state.response_part_brands } selected={ this.selectPartBrand } /> */}
                        </div>

                        {/* <div className='form-input'>
                            <label>Marca de la refacción:</label>
                            <BrandSelector selected_brand={ this.state.selected_part_brand } brands={ this.state.response_part_brands } selected={ this.selectPartBrand } />
                        </div>
                        <div className='form-input'>
                            <label>Tipo de equipo de la refacción: wasd</label>
                            <DeviceTypeSelector selected_device_type={ this.state.selected_part_device_type } device_types={ this.state.response_part_device_types } selected={ this.selectPartDeviceType } />
                        </div>
                        <div className='form-input'>
                            <label>Nombre de la refacción:</label>
                            <PartSelector selected_part={ this.state.selected_part } parts={ this.state.response_parts } selected={ this.addPart } />
                        </div> */}
                    </div>
                    <PartCollection parts={ this.state.parts_used } removePart={ this.removeUsedPart } />
                    <div className='top-separator' />
                    { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? 
                    <>
                    <h2>Gastos</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Transporte y Viáticos:</label>
                            <input className='form-text-input' type='number' min='1' step='any' onChange={this.changeTransportExpenses} value={this.state.transport_expenses} />
                        </div>
                        <div className='form-input'>
                            <label>Mano de Obra:</label>
                            <input className='form-text-input' type='number' min='1' step='any' onChange={this.changeLabor} value={this.state.labor} />
                        </div>
                        <div className='form-input'>
                            <label>Subtotal:</label>
                            <input className='form-text-input' type='number' min='1' step='any' disabled value={ this.state.sub_total } />
                        </div>
                        <div className='form-input'>
                            <label>I.V.A.:</label>
                            <input className='form-text-input' type='number' min='1' step='any' disabled value={this.state.iva} />
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Total:</label>
                            <input className='form-text-input' type='number' min='1' step='any' disabled value={this.state.total} />
                        </div>
                    </div>
                    <div className='top-separator' />
                    </> : null }
                    <h2>Finalización</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Aceptado por:</label>
                            <TextInput onChange={event => this.setState({name_who_accepts: event.target.value})}>{ this.state.name_who_accepts }</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Puesto de quien acepta:</label>
                            <TextInput onChange={event => this.setState({position_who_accepts: event.target.value})}>{ this.state.position_who_accepts }</TextInput>
                        </div>
                    </div>
                    <div className='top-separator' />
                    { this.props.data.work_order.service_type === '3' ? 
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Garantía:</label>
                            <select onChange={event => this.setState({device_guarantee_duration: Number.parseFloat(event.target.value)})} value={this.state.device_guarantee_duration?.toString()}>
                                <option value='0'>Elija una duración para la garantía del equipo.</option>
                                <option value='3'>3 meses</option>
                                <option value='6'>6 meses</option>
                                <option value='12'>12 meses</option>
                                <option value='24'>24 meses</option>
                                <option value='36'>36 meses</option>
                            </select>
                        </div>
                    </div>
                    : null}
                    <div className='button-strip'>
                        <PrimaryButton onClick={this.saveServiceOrder}>Guardar Cambios</PrimaryButton>
                        { this.props.data.status !== 'Concluido' ? <PrimaryButton onClick={() => {
                            if (this.validateInputs()) {
                                alert('Por favor introduzca la firma del cliente.');
                                this.toggleSignaturePad();
                            }
                            }}>Finalizar Servicio</PrimaryButton> : null }
                    </div>
                </div>
                :
                <>
                    <SignaturePad ref={this.signature_pad} toggleSignaturePad={this.toggleSignaturePad} saveSignature={this.endService} />{this.state.loading ? 
                    <div style={{ position: 'absolute', width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '11', top: '0', left: '0' }}>
                        <p style={{ position: 'absolute', top: '50%', left: '50%', textAlign: 'center', transform: 'translate(-50%, -50%)', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', padding: '8px 16px', borderRadius: '5px', boxShadow: '0 5px 5px rgba(0 , 0, 0, 0.1)', zIndex: '11' }}>Subiendo firma, espere...<br />{ (this.state.signature_upload_percent * 100).toFixed(0) }%</p>
                    </div> : null}
                </>
            )
        }
    }

}

export default ServiceOrderForm;