import React from "react";
import { Client } from "../../../Interfaces";
import ItemSelector from "./ItemSelector";

interface ClientSelectorProps {
    selected: (client: any) => void;
    clients: SelectorClient[];
    selected_client: SelectorClient | null;
}

export type SelectorClient = {
    id: number;
    display_name: string;
    email: string;
    fixed_phone: string;
    RFC: string;
    alias: string | null;
    commercial_name: string | null;
    zone: string | number;
}

class ClientSelector extends React.Component<ClientSelectorProps, {}> {
    render() {
        return (
            <ItemSelector selected_item={ this.props.selected_client } items={ this.props.clients } selected={ this.props.selected } filter_properties={['RFC', 'alias', 'commercial_name', 'email']} />
        );
    }
}

export default ClientSelector;