import React from "react";
import Section from "./Section";
import Menu from "./Menu";
import { inimmedAxios } from '../InimmedHelpers';
import { Branch, Brand, Client, Device, DeviceType, Employee, GenericItem, MenuOptions, Model, Part, Policy } from "../Interfaces";
import ClientsCatalog from "./Catalogs/ClientsCatalog";
import DevicesCatalog from "./Catalogs/DevicesCatalog";
import BrandsCatalog from "./Catalogs/BrandsCatalog";
import ModelsCatalog from "./Catalogs/ModelsCatalog";
import DeviceTypesCatalog from "./Catalogs/DeviceTypesCatalog";
import PartsCatalog from "./Catalogs/PartsCatalog";
import BranchesCatalog from "./Catalogs/BranchesCatalog";
import EmployeesCatalog from "./Catalogs/EmployeesCatalog";
import ClientDetails from "./Details/ClientDetails";
import DeviceDetails from "./Details/DeviceDetails";
import BranchDetails from "./Details/BranchDetails";
import ModelDetails from "./Details/ModelDetails";
import BrandDetails from "./Details/BrandDetails";
import DeviceTypeDetails from "./Details/DeviceTypeDetails";
import EmployeeDetails from "./Details/EmployeeDetails";
import PartDetails from "./Details/PartDetails";
import ClientForm from "./Forms/ClientForm";
import DeviceForm from "./Forms/DeviceForm";
import BranchForm from "./Forms/BranchForm";
import ModelForm from "./Forms/ModelForm";
import BrandForm from "./Forms/BrandForm";
import DeviceTypeForm from "./Forms/DeviceTypeForm";
import EmployeeForm from "./Forms/EmployeeForm";
import PartForm from "./Forms/PartForm";
import PoliciesCatalog from "./Catalogs/PoliciesCatalog";
import PolicyDetails from "./Details/PolicyDetails";
import { MobileClientsCatalog } from "./Catalogs/MobileClientsCatalog";

interface CatalogsSectionProps {
    goToWorkOrder: (section: number, IDToQueue?: number, subsection?: string | null) => void,
    changeSectionFunction: (section: number, IDToQueue?: number | null, subsection?: string | null) => void,
    changeLoading: (state: boolean) => void,
    goBack: () => void,
    popQueuedSection: () => void,
    queuedCatalog: string,
    queuedDetailID: number | null,
    user: Employee,
    backButton: boolean
}

interface CatalogsSectionState {
    title: string,
    data: GenericItem[],
    itemDetailHistory: DetailHistory[],
    currentTable: string
}

interface DetailHistory {
    data: GenericItem,
    toEdit: boolean
}

class CatalogsSection extends React.Component<CatalogsSectionProps, CatalogsSectionState> {
    mobile: boolean;
    constructor(props: CatalogsSectionProps) {
        super(props);

        this.state = {
            title: 'Catálogos',
            data: [],
            itemDetailHistory: [],
            currentTable: ''
        }
        
        document.title = 'Inimmed - Catálogos';

        this.mobile = window.innerWidth > 768 ? false : true;

        this.getCatalog = this.getCatalog.bind(this);
        this.getDetail = this.getDetail.bind(this);
        this.editItem = this.editItem.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        if (this.props.queuedCatalog) {
            this.getCatalog(this.props.queuedCatalog);
            if (this.props.queuedDetailID) {
                this.getDetail(this.props.queuedCatalog, this.props.queuedDetailID);
            }
        }
    }

    getCatalog(catalog: string) {
        let newTitle: string;
        let newTable: string;
        switch (catalog) {
            case 'brands':
                newTitle = 'Catálogos / Marcas';
                newTable = 'brands';
                break;
            case 'device_models':
                newTitle = 'Catálogos / Modelos';
                newTable = 'models';
                break;
            case 'device_types':
                newTitle = 'Catálogos / Tipos';
                newTable = 'device_types';
                break;
            case 'employees':
                newTitle = 'Catálogos / Empleados';
                newTable = 'employees';
                break;
            case 'zones':
                newTitle = 'Catálogos / Zonas';
                newTable = 'zones';
                break;
            case 'positions':
                newTitle = 'Catálogos / Puestos';
                newTable = 'positions';
                break;
            case 'clients':
                newTitle = 'Catálogos / Clientes';
                newTable = 'clients';
                break;
            case 'devices':
                newTitle = 'Catálogos / Equipos';
                newTable = 'devices';
                break;
            case 'branches':
                newTitle = 'Catálogos / Sucursales';
                newTable = 'branches';
                break;
            case 'parts':
                newTitle = 'Catálogos / Refacciones';
                newTable = 'parts';
                break;
            case 'policies':
                newTitle = 'Catálogos / Pólizas';
                newTable = 'policies';
                break;
        }
        this.props.changeLoading(true);
        inimmedAxios.get('api/' + catalog)
        .then(response => {
                this.setState({ data: response.data, title: newTitle, currentTable: newTable });
                this.props.changeLoading(false);
        })
        .catch(reason => {
                this.props.changeLoading(false);
                alert(reason);
        });
    }

    getDetail(item: string, itemID?: number, replace?: boolean) {
        inimmedAxios.get('api/' + item + '/' + itemID)
        .then(response => {
            let currentHistory = this.state.itemDetailHistory;
            if (replace)
                currentHistory.pop();
            currentHistory.push({data: response.data, toEdit: false});
            this.setState({itemDetailHistory: currentHistory});
        })
        .catch(reason => {
            alert(reason);
        });
    }

    editItem(itemID: number) {
        let currentHistory = this.state.itemDetailHistory;
        const toEdit = {data: {...currentHistory[currentHistory.length - 1].data, id: itemID}, toEdit: true}
        currentHistory.push(toEdit);
        this.setState({ itemDetailHistory: currentHistory });
    }

    goBack() {
        if (this.state.itemDetailHistory.length > 0) {
            let currentHistory = this.state.itemDetailHistory;
            currentHistory.pop();
            this.setState({itemDetailHistory: currentHistory});
            return;
        }
        this.setState({
            title: 'Catálogos',
            data: []
        });
    }
    
    render() {
        let content;
        if (this.state.data.length === 0) {
            
            const menu_options: MenuOptions[] = [];
            menu_options.push(
                {
                    subsection: 'Clientes',
                    cards: [
                        { title: 'Clientes', icon: 'icons/clients.svg', onClick: () => this.getCatalog('clients') },
                        { title: 'Equipos', icon: 'icons/devices.svg', onClick: () => this.getCatalog('devices') },
                        { title: 'Sucursales', icon: 'icons/branches.svg', onClick: () => this.getCatalog('branches') },
                    ]
                }
            );
            menu_options.push(
                {
                    subsection: 'Equipos',
                    cards: [
                        { title: 'Marcas', icon: 'icons/brands.svg', onClick: () => this.getCatalog('brands') },
                        { title: 'Modelos', icon: 'icons/devices.svg', onClick: () => this.getCatalog('device_models') },
                        { title: 'Tipos', icon: 'icons/types.svg', onClick: () => this.getCatalog('device_types') },
                        { title: 'Refacciones', icon: 'icons/parts.svg', onClick: () => this.getCatalog('parts') },
                        { title: 'Pólizas', icon: 'icons/policies.svg', onClick: () => this.getCatalog('policies') }
                    ]
                }
            );
            if ( this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3') {
                menu_options.push(
                    {
                        subsection: 'Personal',
                        cards: [
                            { title: 'Empleados', icon: 'icons/personnel.svg', onClick: () => this.getCatalog('employees') }
                        ]
                    }
                )
            }
            content = <Menu options={menu_options} />;
        }
        if (this.state.data.length !== 0 && this.state.itemDetailHistory.length === 0) {
            switch (this.state.currentTable) {
                case 'clients':
                    content = this.mobile ? <MobileClientsCatalog reload={ this.getCatalog } getDetail={this.getDetail} data={this.state.data as Client[]} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user}  /> : <ClientsCatalog reload={this.getCatalog} getDetail={this.getDetail} data={this.state.data as Client[]} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} />;
                    break;
                case 'devices':
                    content = <DevicesCatalog reload={this.getCatalog} getDetail={this.getDetail} data={this.state.data as Device[]} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} />;
                    break;
                case 'brands':
                    content = <BrandsCatalog reload={this.getCatalog} getDetail={this.getDetail} data={this.state.data as Brand[]} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} />;
                    break;
                case 'models':
                    content = <ModelsCatalog reload={this.getCatalog} getDetail={this.getDetail} data={this.state.data as Model[]} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} />;
                    break;
                case 'device_types':
                    content = <DeviceTypesCatalog reload={this.getCatalog} getDetail={this.getDetail} data={this.state.data as DeviceType[]} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} />;
                    break;
                case 'parts':
                    content = <PartsCatalog reload={this.getCatalog} getDetail={this.getDetail} data={this.state.data as Part[]} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} />;
                    break;
                case 'branches':
                    content = <BranchesCatalog reload={this.getCatalog} getDetail={this.getDetail} data={this.state.data as Branch[]} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} />;
                    break;
                case 'employees':
                    content = <EmployeesCatalog reload={this.getCatalog} getDetail={this.getDetail} data={this.state.data as Employee[]} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} />;
                    break;
                case 'policies':
                    content = <PoliciesCatalog reload={this.getCatalog} getDetail={this.getDetail} data={this.state.data as Policy[]} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} />;
                    break;
                default:
                    content = null;
                    break;
            }
        }
        if (this.state.itemDetailHistory.length > 0) {
            if (!this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].toEdit) {
                switch (this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data.type) {
                    case 'Client':
                        content = <ClientDetails getCatalog={this.getCatalog} goToWorkOrder={(id) => this.props.goToWorkOrder(4, id)} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Client} goBack={this.goBack} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} editFunction={this.editItem} />
                        break;
                    case 'Device':
                        content = <DeviceDetails getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Device} goBack={this.goBack} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} editFunction={this.editItem} />
                        break;
                    case 'Branch':
                        content = <BranchDetails getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Branch} goBack={this.goBack} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} editFunction={this.editItem} />
                        break;
                    case 'Model':
                        content = <ModelDetails getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Model} goBack={this.goBack} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} editFunction={this.editItem} />
                        break;
                    case 'Brand':
                        content = <BrandDetails getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Brand} goBack={this.goBack} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} editFunction={this.editItem} />
                        break;
                    case 'DeviceType':
                        content = <DeviceTypeDetails getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as DeviceType} goBack={this.goBack} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} editFunction={this.editItem} />
                        break;
                    case 'Employee':
                        content = <EmployeeDetails getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Employee} goBack={this.goBack} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} editFunction={this.editItem} />
                        break;
                    case 'Part':
                        content = <PartDetails getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Part} goBack={this.goBack} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} editFunction={this.editItem} />
                        break;
                    case 'Policy':
                        content = <PolicyDetails getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Policy} goBack={this.goBack} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} editFunction={this.editItem} />
                        break;
                    default:
                        content = <h1>Desconocido</h1>
                        break;
                }
            } else {
                switch (this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data.type) {
                    case 'Client':
                        content = <ClientForm backFunction={this.goBack} getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Client} />
                        break;
                    case 'Device':
                        content = <DeviceForm backFunction={this.goBack} getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Device} />
                        break;
                    case 'Branch':
                        content = <BranchForm backFunction={this.goBack} getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Branch} />
                        break;
                    case 'Model':
                        content = <ModelForm backFunction={this.goBack} getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Model} />
                        break;
                    case 'Brand':
                        content = <BrandForm backFunction={this.goBack} getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Brand} />
                        break;
                    case 'DeviceType':
                        content = <DeviceTypeForm backFunction={this.goBack} getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as DeviceType} />
                        break;
                    case 'Employee':
                        content = <EmployeeForm backFunction={this.goBack} getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Employee} />
                        break;
                    case 'Part':
                        content = <PartForm backFunction={this.goBack} getCatalog={this.getCatalog} getDetail={this.getDetail} data={this.state.itemDetailHistory[this.state.itemDetailHistory.length - 1].data as Part} />
                        break;
                    default:
                        content = <h1>Desconocido</h1>
                        break;
                }
            }
        }
        return (
            <Section backButton={this.state.data.length > 0 || this.props.backButton ? true : false} onClick={this.props.backButton ? this.props.goBack : this.goBack} title={this.state.title} >
                { content }
            </Section>
        );
    }
}

export default CatalogsSection;