import { inimmedAxios } from "../../InimmedHelpers";
import React from "react";
import TextInput from "../Utility/TextInput";
import PrimaryButton from "../Utility/PrimaryButton";
import { DeviceType, FormComponentProps } from "../../Interfaces";

interface DeviceTypeFormProps extends FormComponentProps {
    data?: DeviceType,
}

interface DeviceTypeFormState {
    name: string
}

class DeviceTypeForm extends React.Component<DeviceTypeFormProps, DeviceTypeFormState> {
    constructor(props: DeviceTypeFormProps) {
        super(props);
        this.state = {
            name: ''
        }

        this.submitType = this.submitType.bind(this);
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState(this.props.data);
        }
    }

    submitType() {
        if (!this.state.name) {
            alert('Por favor escriba el nombre del nuevo tipo de equipo.');
            return;
        }
        const url = this.props.data ? 'api/save_device_type/' + this.props.data.id : 'api/save_device_type';
        inimmedAxios.post(url, {
            name: this.state.name,
        })
        .then(response => {
            if (response.data.Result === 'Success') {
                if (window.confirm('Tipo de Equipo guardado, ¿Salir?')) {
                    if (this.props.data && this.props.getCatalog && this.props.getDetail) {
                        this.props.getCatalog('device_types');
                        this.props.getDetail('device_types', this.props.data.id, true)
                    }
                    this.props.backFunction();
                }
            } else {
                alert(response.data.Result);
            }
        })
        .catch(reason => {
            alert(reason);
        });
    }

    render() {
        return (
            <div className='form-container'>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Tipo de Equipo:</label>
                        <TextInput onChange={event => this.setState({name: event.target.value})}>{ this.state.name }</TextInput>
                    </div>
                </div>
                <div className='top-separator'></div>
                <div className='button-strip'>
                    <PrimaryButton onClick={this.submitType}>{ this.props.data ? 'Guardar' : 'Registrar'}</PrimaryButton>
                </div>
            </div>
        )
    }
}
export default DeviceTypeForm;