import React from "react";
import { inimmedAxios, itemInArray } from "../../InimmedHelpers";
import AddButton from "../Utility/AddButton";
import PrimaryButton from "../Utility/PrimaryButton";
import { toast } from "react-toastify";
import { Branch, Client, Device, Employee, FormComponentProps } from "../../Interfaces";
import ClientSelector from "../Utility/Selectors/ClientSelector";
import BranchSelector from "../Utility/Selectors/BranchSelector";
import DeviceSelector from "../Utility/Selectors/DeviceSelector";
import { DeviceCollection } from "../Collections/DeviceCollection";

interface PolicyFormProps extends FormComponentProps {
    savedInputs?: PolicyFormState,
    user: Employee
}

interface PolicyFormState {
    response_clients: Client[],
    client: Client | null,
    response_branches: Branch[],
    branch: Branch | null,
    response_devices: Device[],
    devices_in_policy: Device[],
    selected_device: Device | null,
    start_date: Date | null | string,
    end_date: Date | null | string,
    client_input_holder: string
}

class PolicyForm extends React.Component<PolicyFormProps, PolicyFormState> {
    mobile: boolean;

    constructor(props: PolicyFormProps) {
        super (props);

        this.state = {
            response_clients: [],
            client: null,
            response_branches: [],
            branch: null,
            response_devices: [],
            devices_in_policy: [],
            selected_device: null,
            start_date: new Date(),
            end_date: new Date(),
            client_input_holder: ''
        }

        this.mobile = window.innerWidth > 768 ? false : true;

        this.addDeviceToPolicy = this.addDeviceToPolicy.bind(this);
        this.removeDeviceFromPolicy = this.removeDeviceFromPolicy.bind(this);
        this.selectClient = this.selectClient.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleDurationChange = this.handleDurationChange.bind(this);

        this.submitPolicy = this.submitPolicy.bind(this);
    }

    componentDidMount() {
        if (!this.props.savedInputs) {
            inimmedAxios.get('api/clients')
            .then(response => {
                this.setState({ response_clients: response.data });
            })
            .catch(reason => alert(reason));
        } else {
            this.setState(this.props.savedInputs);
            inimmedAxios.get('api/clients')
            .then(response => this.setState({ response_clients: response.data }))
            .catch(reason => alert(reason));
        }
    }

    selectClient(client: Client) {
        if (client) {
            inimmedAxios.get('api/get_branches_of/' + client.id)
            .then(response => {
                this.setState({ client: client, response_branches: response.data });
            })
            .catch(reason => alert(reason));

            inimmedAxios.get('api/get_devices/' + client.id)
            .then(response => {
                this.setState({ response_devices: response.data });
            })
            .catch(reason => alert(reason));
        } else {
            this.setState({ client: null, response_branches: [], branch: null });
        }
    }

    addDeviceToPolicy(device: Device) {
        const devices_in_policy: Device[] = this.state.devices_in_policy;
        if (device) {
            if (!itemInArray(device, devices_in_policy)) {
                devices_in_policy.push(device);
                this.setState({ devices_in_policy: devices_in_policy, selected_device: device });   
            }
        } else {
            this.setState({ selected_device: device });
        }
    }

    removeDeviceFromPolicy(device_id: number) {
        const current_devices_in_policy: Device[] = this.state.devices_in_policy;
        let index = 0;
        for (const device of current_devices_in_policy) {
            if (device.id === device_id) {
                current_devices_in_policy.splice(index, 1);
                this.setState({ devices_in_policy: current_devices_in_policy });
                return;
            }
            index++;
        }
    }

    handleStartDateChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.value) {
            this.setState({start_date: null});
        }
        this.setState({start_date: new Date(event.target.valueAsNumber)});
    }

    handleEndDateChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.value) {
            this.setState({end_date: null});
        }
        this.setState({end_date: new Date(event.target.valueAsNumber)});
    }

    handleDurationChange(event: React.ChangeEvent<HTMLInputElement>) {
        let newEndDate = new Date(this.state.start_date as Date);
        newEndDate.setMonth(newEndDate.getMonth() + (event.target.value ? parseInt(event.target.value) : 1));
        this.setState({end_date: newEndDate});
    }

    submitPolicy() {
        if (!this.state.devices_in_policy || !this.state.start_date || !this.state.end_date) {
            alert('Por favor complete los campos necesarios de fechas o duración y equipo.');
            return;
        }

        const device_ids_in_policy: number[] = [];
        for (const device of this.state.devices_in_policy) {
            device_ids_in_policy.push(device.id);
        }

        inimmedAxios.post('api/save_policy', {
            devices: device_ids_in_policy,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            client: this.state.client?.id,
            user: this.props.user.id
        })
        .then(response => {
            if (response.data.Result === 'Success') {
                toast.success('Póliza guardada con éxito.');
                this.props.backFunction();
            } else {
                alert(response.data.Result);
            }
        })
        .catch(reason => alert(reason));
    }

    render() {
        let start_date_value: string = '';
        if (typeof this.state.start_date !== 'string' && this.state.start_date) {
            start_date_value = this.state.start_date.toISOString().slice(0, 10);
        }
        let end_date_value: string = ''
        if (typeof this.state.end_date !== 'string' && this.state.end_date) {
            end_date_value = this.state.end_date.toISOString().slice(0, 10);
        }
        return (
            <div className='form-container'>
                <h2>Cliente</h2>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Cliente:</label>
                            <div className='form-input-plus'>
                                <ClientSelector selected_client={ this.state.client } clients={ this.state.response_clients } selected={ this.selectClient } />
                                <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'ClientForm', state: this.state})}} />
                            </div>
                    </div>
                    <div className='form-input'>
                        <label>Sucursal:</label>
                        <div className='form-input-plus'>
                            <BranchSelector selected_branch={ this.state.branch } branches={ this.state.response_branches } selected={branch => this.setState({ branch: branch })} />
                            <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'BranchForm', state: this.state})}} />
                        </div>
                    </div>
                </div>
                <div className='top-separator'></div>
                <h2>Equipos en Póliza</h2>
                <div style={{ width: '95%', overflow: 'visible' }}>
                    <DeviceCollection removeDevice={ this.removeDeviceFromPolicy } devices={ this.state.devices_in_policy } />
                </div>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Equipo:</label>
                        <DeviceSelector selected_device={ this.state.selected_device } devices={ this.state.response_devices } selected={ this.addDeviceToPolicy } />
                    </div>
                </div>
                <div className='top-separator'></div>
                <h2>Fechas</h2>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Fecha de inicio:</label>
                        <input id='start' type='date' className='date-time-input' onChange={this.handleStartDateChange} value={start_date_value} />
                    </div>
                    <div className='form-input'>
                        <label>Fecha de vencimiento:</label>
                        <input id='end' type='date' className='date-time-input' onChange={this.handleEndDateChange} value={end_date_value} />
                    </div>
                    <div className='form-input'>
                        <label>Duración:</label>
                        <input className='form-text-input' type='number' onChange={this.handleDurationChange} />
                    </div>
                </div>
                <div className='top-separator'></div>
                <div className='button-strip'>
                    <PrimaryButton onClick={() => this.submitPolicy()}>Registrar</PrimaryButton>
                </div>
            </div>
        )
    }
}

export default PolicyForm;