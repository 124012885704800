import { inimmedAxios } from "../../InimmedHelpers";
import React from "react";
import TextInput from "../Utility/TextInput";
import PrimaryButton from "../Utility/PrimaryButton";
import { Employee, FormComponentProps } from "../../Interfaces";

interface EmployeeFormProps extends FormComponentProps {
    data?: Employee
    savedInputs?: EmployeeFormState
}

interface EmployeeFormState {
    name: string,
    first_last: string,
    second_last: string,
    email: string,
    phone_number: string,
    position: string,
    number_letters: string
}

class EmployeeForm extends React.Component<EmployeeFormProps, EmployeeFormState> {
    constructor(props: EmployeeFormProps) {
        super(props);
        this.state = {
            name: '',
            first_last: '',
            second_last: '',
            email: '',
            phone_number: '',
            position: '',
            number_letters: ''
        }

        this.selectPosition = this.selectPosition.bind(this);
        this.submitEmployee = this.submitEmployee.bind(this);
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState(this.props.data);
        }
    }

    selectPosition(event: React.ChangeEvent<HTMLSelectElement>) {
        if (event.target.value === '0') {
            this.setState({position: event.target.value});
            return;
        }
        this.setState({position: event.target.value});
    }

    handlePositions() {
        let positions = [];
        if (this.props.data) {
            if (!this.props.data.position)
                positions.push(<option selected value='0'>Elige un puesto</option>);
            else
                positions.push(<option value='0'>Elige un puesto</option>);
            if (this.props.data.position === '1')
                positions.push(<option selected value='1'>Ingeniero</option>);
            else
                positions.push(<option value='1'>Ingeniero</option>);
            if (this.props.data.position === '2')
                positions.push(<option selected value='2'>Sistemas</option>);
            else
                positions.push(<option value='2'>Sistemas</option>);
            if (this.props.data.position === '4')
                positions.push(<option selected value='4'>Administrativo</option>);
            else
                positions.push(<option value='4'>Administrativo</option>);
            if (this.props.data.position === '3')
                positions.push(<option selected value='3'>Administrador</option>);
            else
                positions.push(<option value='3'>Administrador</option>);
        } else {
            positions.push(<option value='0'>Elige un puesto</option>);
            positions.push(<option value='1'>Ingeniero</option>);
            positions.push(<option value='2'>Sistemas</option>);
            positions.push(<option value='4'>Administrativo</option>);
            positions.push(<option value='3'>Administrador</option>);
        }
        return positions;
    }

    submitEmployee() {
        if (!this.state.name) {
            alert('Por favor escriba el nombre del nuevo empleado.');
            return;
        }
        if (!this.state.first_last) {
            alert('Por favor escriba el primer apellido del nuevo empleado.');
            return;
        }
        if (!this.state.second_last) {
            alert('Por favor escriba el segundo apellido del nuevo empleado.');
            return;
        }
        if (this.state.position !== '1' && !this.state.number_letters) {
            alert('Por favor escriba las letras para el foliado del ingeniero.');
            return;
        }
        const url = this.props.data ? 'api/save_employee/' + this.props.data.id : 'api/save_employee';
        inimmedAxios.post(url, {
            name: this.state.name,
            first_last: this.state.first_last,
            second_last: this.state.second_last,
            email: this.state.email,
            phone_number: this.state.phone_number,
            position: this.state.position,
            number_letters: this.state.number_letters
        })
        .then(response => {
            if (response.data.Result === 'Success') {
                if (window.confirm('Empleado guardado, ¿Salir?')) {
                    if (this.props.data && this.props.getCatalog && this.props.getDetail) {
                        this.props.getCatalog('employees');
                        this.props.getDetail('employees', this.props.data.id, true);
                    }
                    this.props.backFunction();
                }
            } else {
                alert(response.data.Result);
            }
        })
        .catch(reason => {
            alert(reason);
        });
    }

    render() {
        return (
            <div className='form-container'>
                <h2>Detalles</h2>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Nombre:</label>
                        <TextInput onChange={event => this.setState({name: event.target.value})}>{ this.state.name }</TextInput>
                    </div>
                </div>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Primer Apellido:</label>
                        <TextInput onChange={event => this.setState({first_last: event.target.value})}>{ this.state.first_last }</TextInput>
                    </div>
                    <div className='form-input'>
                        <label>Segundo Apellido:</label>
                        <TextInput onChange={event => this.setState({second_last: event.target.value})}>{ this.state.second_last }</TextInput>
                    </div>
                </div>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Email:</label>
                        <TextInput onChange={event => this.setState({email: event.target.value})}>{ this.state.email }</TextInput>
                    </div>
                    <div className='form-input'>
                        <label>Número Telefónico:</label>
                        <TextInput onChange={event => this.setState({phone_number: event.target.value})}>{ this.state.phone_number }</TextInput>
                    </div>
                    <div className='form-input'>
                        <label>Puesto:</label>
                        <select onChange={this.selectPosition}>
                            { this.handlePositions() }
                        </select>
                    </div>
                </div>
                <div className='form-input-div'>
                    { this.state.position === '1' || this.state.position === '3' ? <div className='form-input'>
                        <label>Iniciales para Foliado:</label>
                        <TextInput onChange={(event => this.setState({number_letters: event.target.value}))}>{ this.state.number_letters }</TextInput>
                    </div> : null }
                </div>
                <div className='top-separator' />
                <div className='button-strip'>
                    <PrimaryButton onClick={this.submitEmployee}>{ this.props.data ? 'Guardar' : 'Registrar'}</PrimaryButton>
                </div>
            </div>
        )
    }
}
export default EmployeeForm;