import React from "react";
import { toast } from "react-toastify";
import { inimmedAxios } from "../../InimmedHelpers";
import { DetailComponentProps, Employee } from "../../Interfaces";
import PrimaryButton from "../Utility/PrimaryButton";
import DangerButton from "../Utility/DangerButton";

interface EmployeeDetailsProps extends DetailComponentProps {
    data: Employee
}

class EmployeeDetails extends React.Component<EmployeeDetailsProps, {}> {
    mobile: boolean;

    constructor(props: EmployeeDetailsProps) {
        super(props);
        this.mobile = window.innerWidth > 768 ? false : true;
        this.delete = this.delete.bind(this);
    }

    delete() {
        if (window.confirm('¿Seguro que desea borrar el empleado "' + this.props.data.display_name + '"?')) {
            inimmedAxios.post('api/delete_employee/' + this.props.data.id)
            .then(response => {
                if (response.data.Result === 'Success') {
                    toast.success('Empleado eliminado.', {icon: '🗑️'});
                    this.props.getCatalog('employees');
                    this.props.goBack();
                }
                else
                    alert(response.data.Result);
            })
            .catch(reason => {
                alert(reason);
            });
        }
    }

    render() {
        let employeePosition: string;
        switch (this.props.data.position) {
            case '1':
                employeePosition = 'Ingeniero';
                break;
            case '2':
                employeePosition = 'Sistemas';
                break;
            case '3':
                employeePosition = 'Administrador';
                break;
            case '4':
                employeePosition = 'Administrativo';
                break;
            default:
                employeePosition = 'Sin registrar';
                break;
        }
        return (
            <div style={{boxSizing: 'border-box', padding: '1em', fontSize: '1.25em'}} className='float-from-down'>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1em', flexWrap: 'wrap'}}>
                    <h1 style={{fontSize: this.mobile ? '1em' : ''}}>{this.props.data.display_name}</h1>
                    { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? <PrimaryButton onClick={() => this.props.editFunction(this.props.data.id)}>Editar</PrimaryButton> : null }
                </div>
                <p><strong>Nombre: </strong><br /> {this.props.data.name ? this.props.data.name : 'Sin registrar'} </p>
                <p><strong>Primer Apellido: </strong><br /> {this.props.data.first_last ? this.props.data.first_last : 'Sin registrar'} </p>
                <p><strong>Segundo Apellido: </strong><br /> {this.props.data.second_last ? this.props.data.second_last : 'Sin registrar'} </p>
                <p><strong>Email: </strong><br /> {this.props.data.email ? this.props.data.email : 'Sin registrar'}</p>
                <p><strong>Número Telefónico: </strong><br /> {this.props.data.phone_number ? this.props.data.phone_number : 'Sin registrar'}</p>
                <p><strong>Puesto: </strong><br /> {this.props.data.position ? employeePosition : 'Sin registrar'} </p>
                <p><strong>Nombre de Usuario: </strong><br />{this.props.data.user ? this.props.data.user.username : 'Sin usuario'}</p>
                {this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                        <div>
                            <DangerButton onClick={this.delete}>Eliminar</DangerButton>
                        </div>
                        :
                        null}
            </div>
        )
    }
}

export default EmployeeDetails;