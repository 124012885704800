import React, { CSSProperties } from 'react';

interface TextInputProps extends React.DOMAttributes<HTMLInputElement> {
    height?: string | number | undefined,
    width?: string | number | undefined,
    onChange: (idk: any) => void,
    onKeyPress?: (idk: any) => void,
    placeholder?: string,
    children: string | number,
    type?: string,
    mobile?: boolean,
    list?: string,
    autocomplete?: string,
    style?: CSSProperties
}

interface TextInputState {
    focused: boolean
}

class TextInput extends React.Component<TextInputProps, TextInputState> {
    mobile: boolean;

    constructor(props: TextInputProps) {
        super(props);
        this.state = {
            focused: false
        }

        this.mobile = window.innerWidth > 768 ? false : true;
    }

    render() {
        return(
            <input className='form-text-input' autoComplete={this.props.autocomplete} list={this.props.list} onChange={this.props.onChange} type={this.props.type} placeholder={this.props.placeholder} value={this.props.children ? this.props.children : ''} />
            // </div>
        )
    }
}

export default TextInput;