import React from "react";
import Catalog from "./Catalog";
import { Part, CatalogProps } from "../../Interfaces";

interface PartsCatalogProps extends CatalogProps {
    reload: (catalog: string) => void,
    data: Part[]
}

class PartsCatalog extends React.Component<PartsCatalogProps, {}> {

    render() {
        let newButtonPrompt: string = this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? 'Nueva refacción' : '';
        return (
            <Catalog
            data={this.props.data}
            headersForTable={[{name: 'Nombre', width: '25%', data: 'name'}, {name: 'Número de Parte', width: '25%', data: 'part_number'}, {name: 'Modelo', width: '20%', data: 'model'}, {name: 'Marca', width: '20%', data: 'brand'}, {name: 'Existencia', width: '10%', data: 'stock'}]}
            dataHeaders={['name', 'part_number', 'model', 'brand', 'stock']}
            searchHeaders={['name', 'part_number', 'model', 'brand']}
            objectType='Refacciones'
            newButtonPrompt={newButtonPrompt}
            newButtonClick={() => this.props.changeSectionFunction(3, null, 'PartForm')}
            searchPlaceholder='Buscar refacciones...'
            getDetail={id => this.props.getDetail('parts', id)}
            itemsPerPage={10} />
        )
    }
}

export default PartsCatalog;