import React from "react";
import { Employee } from "../../../Interfaces";
import ItemSelector from "./ItemSelector";

interface EmployeeSelectorProps {
    selected: (employee: any) => void,
    employees: SelectorEmployee[],
    selected_employee: SelectorEmployee | null
}

export type SelectorEmployee = {
    id: number;
    name: string;
    display_name: string;
    phone_number: string | null;
    position: string;
    type: string | null;
}

class EmployeeSelector extends React.Component<EmployeeSelectorProps, {}> {
    render() {
        return (
            <ItemSelector selected_item={ this.props.selected_employee } items={ this.props.employees } selected={ this.props.selected } filter_properties={['name']} />
        )
    }
}

export default EmployeeSelector;