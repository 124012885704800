import { toast } from "react-toastify";
import { inimmedAxios } from "../../InimmedHelpers";
import { Expense } from "../../Interfaces";
import DangerButton from "../Utility/DangerButton";

interface ExpenseDetailsProps {
    goBackAndRefresh: () => void;
    data: Expense;
}

export default (props: ExpenseDetailsProps) => {
    const mobile = window.innerWidth > 768 ? false : true;
    const date = new Date(props.data.date_made);
    const delete_expense = () => {
        if (window.confirm('¿Desea eliminar este egreso? Los cambios que haya causado a saldos serán desechados.')) {
            inimmedAxios.post('api_banks/delete_expense', {
                expense_id: props.data.id
            })
            .then(response => {
                if (response.data.Result === 'Ok') {
                    toast.success('Egreso eliminado.');
                    props.goBackAndRefresh();
                } else {
                    alert(response.data.Result);
                }
            })
            .catch(reason => alert(reason));
        }
    }

    return (
        <div style={{ padding: mobile ? '10px' : '20px' }}>
        <p>{ props.data.amount }</p>
            <p>{ typeof props.data.account_giving === 'string' ? props.data.account_giving : props.data.account_giving.display_name }</p>
            <p>{ typeof props.data.concept === 'string' ? props.data.concept : null }</p>
            <p>{ date.toLocaleString('es-MX', {dateStyle: 'long'}) }</p>
            <div className='button-strip'>
                <DangerButton onClick={ delete_expense } >Eliminar</DangerButton>
            </div>
        </div>
    );
}