import { CatalogProps, CatalogTableHeader, Income, SaleAccount } from "../../Interfaces"
import Catalog from "./Catalog"

interface IncomesCatalogProps {
    getIncome: (income_id: number) => void;
    newIncome: (account?: SaleAccount) => void;
    data: Income[];
    client?: boolean;
}

export default function(props: IncomesCatalogProps) {

    const mobile = window.innerWidth > 768 ? false : true;

    const date_type = mobile ? 'short_date' : 'long_date';

    let headers_for_table: CatalogTableHeader[];
    let data_headers: string[];
    let search_headers: string[];
    if (!props.client) {
        headers_for_table = [{name: 'Fecha efectuado', data: 'date_made', width: '25%', type: date_type}, {name: 'Monto', data: 'amount', type: 'currency', width: '25%'}, {name: 'Concepto', data: 'concept', width: '30%'}, {name: 'Cuenta', data: 'account_receiving', width: '20%'}];
        data_headers = ['date_made', 'amount', 'concept', 'account_receiving'];
        search_headers = ['date_made', 'amount', 'concept', 'account_receiving'];
    } else {
        headers_for_table = [{name: 'Fecha efectuado', data: 'date_made', width: '33%', type: date_type}, {name: 'Monto', data: 'amount', type: 'currency', width: '33%'}, {name: 'Cuenta', data: 'account_receiving', width: '33%'}];
        data_headers = ['date_made', 'amount', 'account_receiving'];
        search_headers = ['date_made', 'amount', 'account_receiving'];
    }

    return (
        <Catalog
        data={ props.data }
        headersForTable={ headers_for_table }
        dataHeaders={ data_headers }
        searchHeaders={ search_headers }
        objectType='Ingresos'
        getDetail={income_id => props.getIncome(income_id)}
        itemsPerPage={10}
        newButtonClick={() => props.newIncome() }
        newButtonPrompt='Nuevo Ingreso'
        searchPlaceholder='Buscar ingresos...'
        />
    );
}