import React, { useEffect, useState } from "react"
import ClientSelector, { SelectorClient } from "../Utility/Selectors/ClientSelector";
import EmployeeSelector, { SelectorEmployee } from "../Utility/Selectors/EngineerSelector";
import { Client, Employee, WorkOrder, Zone } from "../../Interfaces";
import { inimmedAxios } from "../../InimmedHelpers";
import { toast } from "react-toastify";
import ZoneSelector, { SelectorZone } from "../Utility/Selectors/ZoneSelector";
import PrimaryButton from "../Utility/PrimaryButton";
import WorkOrdersCatalog from "../Catalogs/WorkOrdersCatalog";

const enum date_to_report_options {
    MesActual,
    MesAnterior,
    Ultimos30Dias,
    AnoActual,
    AnoAnterior,
    Ultimos365Dias,
    FechasEspecificas
}

type ServiceHistoryProps = {
    getDetail: (itemID: number) => void;
    changeSectionFunction: (section: number, IDToQueue?: number | null, subsection?: string | null) => void;
    user: Employee;
}

const ServiceHistory = (props: ServiceHistoryProps) => {

    const [clients, setClients] = useState<Client[]>([]);
    const [selectedClient, setSelectedClient] = useState<SelectorClient | null>(null);

    const [engineers, setEngineers] = useState<Employee[]>([]);
    const [selectedEngineer, setSelectedEngineer] = useState<SelectorEmployee | null>(null);

    const [selectedZone, setSelectedZone] = useState(null);
    const [zones, setZones] = useState<SelectorZone[]>([]);

    const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);

    const dateSelectorRef = React.createRef<HTMLSelectElement>();

    useEffect(() => {
        Promise.all([
            inimmedAxios.get('api/clients')
                .then(resp => resp.data)
                .catch(error => {
                    console.error('There was an error fetching clients', error);
                    toast.error('Hubo un error al obtener los clientes, comuníquese con soporte.');
                    return [];
                }),
            inimmedAxios.get('api/engineers')
                .then(resp => resp.data)
                .catch(error => {
                    console.error('There was an error fetching engineers', error);
                    toast.error('Hubo un error al obtener los ingenieros, comuníquese con soporte.');
                    return [];
                }),
            inimmedAxios.get('api/zones')
                .then(resp => resp.data)
                .catch(error => {
                    console.error('There was an error fetching service zones', error);
                    toast.error('Hubo un error al obtener las zonas de servicio, comuníquese con soporte.');
                    return [];
                }),
        ])
            .then((responses: [Client[], Employee[], Zone[]]) => {
                setClients(responses[0]);
                setEngineers(responses[1]);
                const zoneOptions = responses[2];
                zoneOptions.unshift({
                    display_name: 'Todas',
                    engineers: [],
                    id: 0,
                    type: ''
                } as Zone);
                setZones(zoneOptions);
            })
            .catch(() => console.error('Error in component useEffect'));
    }, []);


    const getHistoryData = () => {
        inimmedAxios.get('api/work_orders_history', {
            params: {
                date_to_report: dateSelectorRef.current?.value,
                client: selectedClient?.id,
                engineer: selectedEngineer?.id,
                zone: selectedZone
            }
        })
            .then(resp => {
                console.log(resp.data);
                setWorkOrders(resp.data);
            })
            .catch(error => {
                console.error(error);
                setWorkOrders([]);
                toast.error('Hubo un error al leer los registros de historia, comuníquese con soporte.');
            });
    }

    return (
        <>
            <h2>Historia Órdenes de Trabajo</h2>
            <div className="form-input-div">
                <div className='form-input'>
                    <label>Cliente:</label>
                    <ClientSelector clients={clients} selected_client={selectedClient} selected={client => setSelectedClient(client)} />
                </div>
                <div className='form-input'>
                    <label>Ingeniero:</label>
                    <EmployeeSelector employees={engineers} selected_employee={selectedEngineer} selected={engineer => setSelectedEngineer(engineer)} />
                </div>
            </div>
            <div className="form-input-div">
                <div className='form-input'>
                    <label>Zona:</label>
                    <ZoneSelector zones={zones} selected_zone={selectedZone} selected={zone => setSelectedZone(zone)} />
                </div>
                <div className='form-input'>
                    <label>Periodo:</label>
                    <select ref={dateSelectorRef}>
                        <option value={date_to_report_options.MesActual}>Mes Actual</option>
                        <option value={date_to_report_options.MesAnterior}>Mes Anterior</option>
                        <option value={date_to_report_options.Ultimos30Dias}>Últimos 30 Días</option>
                        <option value={date_to_report_options.AnoActual}>Año Actual</option>
                        <option value={date_to_report_options.AnoAnterior}>Año Anterior</option>
                        <option value={date_to_report_options.Ultimos365Dias}>Últimos 365 días</option>
                        <option value={date_to_report_options.FechasEspecificas}>Fechas Específicas</option>
                    </select>
                </div>
            </div>
            <div className="button-strip">
                <PrimaryButton onClick={getHistoryData} >Obtener Registros</PrimaryButton>
            </div>
            <WorkOrdersCatalog getDetail={props.getDetail} data={workOrders} changeSectionFunction={props.changeSectionFunction} user={props.user} hideNewOrderButton={true} />
        </>
    )
}

export default ServiceHistory;