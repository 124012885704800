import React from "react";

interface LoadingProps extends React.DOMAttributes<HTMLImageElement> {
    color: string
}

class Loading extends React.Component<LoadingProps, {}> {
    render() {
        let filterString = '';
        if (this.props.color === 'primary')
            filterString = 'brightness(0) saturate(100%) invert(33%) sepia(97%) saturate(2245%) hue-rotate(352deg) brightness(104%) contrast(91%)';
        else if (this.props.color === 'secondary')
            filterString = 'brightness(0) saturate(100%) invert(83%) sepia(52%) saturate(502%) hue-rotate(33deg) brightness(103%) contrast(101%)';
        else if (this.props.color === 'primary-light')
            filterString = 'brightness(0) saturate(100%) invert(49%) sepia(94%) saturate(353%) hue-rotate(332deg) brightness(103%) contrast(102%)';
        else if (this.props.color === 'secondary-light')
            filterString = 'brightness(0) saturate(100%) invert(95%) sepia(8%) saturate(2052%) hue-rotate(19deg) brightness(102%) contrast(104%)';
        
        return (
            <div style={{animation: '3s ease-in-out infinite spin', width: '5em', height: '5em', position: 'absolute', top: 'calc(50% - 2.5em)', right: 'calc(50% - 2.5em)'}}>
                <img alt='cargando...' src={'loader.svg'} style={{width: '5em', height: '5em', filter: filterString, animation: '1s ease-in-out infinite spin'}} />
            </div>
        )
    }
}
export default Loading;