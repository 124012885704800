import React from 'react';

interface MenuCardProps {
    onClick: () => void,
    icon: string,
    title: string
}

class MenuCard extends React.Component<MenuCardProps, {}> {
    titleHeader: React.RefObject<HTMLDivElement>;

    constructor(props: MenuCardProps) {
        super(props);

        this.titleHeader = React.createRef();
    }

    render() {
        let cardMinWidth;
        if (window.innerWidth > 768)
            cardMinWidth = '25%';
        else
            cardMinWidth = '90%';
        return (
            <div style={{
                minWidth: cardMinWidth
            }} onMouseEnter={() => {
                if (this.titleHeader.current) this.titleHeader.current.style.backgroundColor = 'var(--secondary-dark-color)';
            }} onMouseLeave={() => {
                if (this.titleHeader.current) this.titleHeader.current.style.backgroundColor = 'var(--primary-color)';
            }} onClick={ this.props.onClick } className='menu-card'>
                <img src={ this.props.icon } style={{width: '125px', boxSizing: 'border-box', padding: '1em 0 1em 0'}} alt={ this.props.title } />
                <h1 ref={this.titleHeader} style={{backgroundColor: 'var(--primary-color)', width: '100%', textAlign: 'center', margin: '0', padding: window.innerWidth > 768 ? '0.5em' : '1em 0.5em 1em 0.5em', color: 'var(--on-primary-color)', fontFamily: 'Ubuntu', transition: '0.3s', fontSize: window.innerWidth > 768 ? '' : '1em'}}>{ this.props.title }</h1>
            </div>
        );
    }
}

export default MenuCard;