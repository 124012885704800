import React from 'react';

interface DropdownButtonProps extends React.DOMAttributes<HTMLButtonElement> {
    
}

class DropdownButton extends React.Component<DropdownButtonProps, {}> {
    render() {
        return (
            <button className='dropdown-button' onClick={this.props.onClick} >{this.props.children}</button>
        )
    }
}

export default DropdownButton;