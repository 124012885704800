import { PartType } from "../../../Interfaces";
import ItemSelector from "./ItemSelector";

interface PartTypeSelectorProps {
    selected: (part_type: any) => void;
    part_types: PartType[];
    selected_part_type: PartType | null;
}

const PartTypeSelector = (props: PartTypeSelectorProps) => {
    return (
        <ItemSelector selected_item={ props.selected_part_type } items={ props.part_types } selected={ props.selected } />
    );
}
export default PartTypeSelector;