import React from 'react';
import { Employee, SaleAccount } from '../../Interfaces';
import Catalog from './Catalog';

interface SaleAccountsCatalogProps {
    getAccountDetail: (account_id: number, account_type: 0 | 1) => void;
    data: SaleAccount[];
    user: Employee;
}

class SaleAccountsCatalog extends React.Component<SaleAccountsCatalogProps, {}> {
    render() {
        return (
            <Catalog
            data={ this.props.data }
            headersForTable={[{name: 'Folio', width: '10%', data: 'id', type: 'id'}, {name: 'Cliente', width: '30%', data: 'client'}, {name: 'Concepto', width: '40%', data: 'concept'}, {name: 'Estatus', width: '20%', data: 'status'}]}
            dataHeaders={['id', 'client', 'concept', 'status']}
            searchHeaders={['id', 'client', 'concept', 'status']}
            objectType={'Cuentas por cobrar'}
            getDetail={account_id => this.props.getAccountDetail(account_id, 0)}
            itemsPerPage={10}
            newButtonClick={() => null}
            newButtonPrompt=''
            searchPlaceholder='Buscar cuentas por cobrar...' />
        );
    }
}

export default SaleAccountsCatalog;