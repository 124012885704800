import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard as farClipboard } from "@fortawesome/free-regular-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

interface DataEntryProps {
    title: string;
    data: string | undefined;
    nullData: string;
    onClick?: React.MouseEventHandler<HTMLParagraphElement>;
    copyable?: boolean;
    externalLink?: string;
}

export default class DataEntry extends React.Component<DataEntryProps, {}> {
    render(): React.ReactNode {
        const dataJSX = this.props.data ? this.props.data : this.props.nullData;
        return (
            <div className='dataEntry'>
                <div className='dataEntryTitleContainer' style={{ cursor: this.props.copyable ? 'pointer' : '' }} onClick={() => navigator.clipboard.writeText(this.props.data ? this.props.data : '')}>
                    <p className="dataEntryTitle"><strong>{ this.props.title }</strong></p>
                    { (this.props.data && this.props.copyable) ? <span>&nbsp;&nbsp;<FontAwesomeIcon icon={ farClipboard } color='var(--on-primary-color)' /></span> : null }
                </div>
                <p onClick={ (this.props.onClick && this.props.data) ? this.props.onClick : () => null } className={ 'dataEntryData' + ((this.props.onClick && this.props.data) ? ' clickable-detail' : '') }>
                    { this.props.externalLink ? <a href={ this.props.externalLink }>{ dataJSX } <FontAwesomeIcon icon={ faArrowUpRightFromSquare } /> </a> : dataJSX }
                </p>
            </div>
        )
    }
}