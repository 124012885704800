import { faCalendar, faFileLines } from "@fortawesome/free-regular-svg-icons";
import { faHome, faMoneyBillTransfer, faScrewdriverWrench, faTableList } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Employee } from "../../Interfaces";
import SidenavButton from './SidenavButton'

interface SidenavProps {
    changeSection: (section: number, IDToQueue?: number, subsection?: string | null) => void,
    visible: boolean,
    user: Employee,
    currentSection: number
}

class Sidenav extends React.Component<SidenavProps, {}> {
    render() {
        let translation;
        if (this.props.visible) {
            translation = 'translate(0)';
        }
        else {
            translation = 'translate(-100%)';
        }

        const sidenav_entries: JSX.Element[] = [];
        if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' || this.props.user.position === '6') {
            sidenav_entries.push(<SidenavButton key={ sidenav_entries.length } onClick={() => this.props.changeSection(0)} active={this.props.currentSection === 0 ? true : false} icon={ faHome } >Inicio</SidenavButton>);
        }
        sidenav_entries.push(<SidenavButton key={ sidenav_entries.length } onClick={() => this.props.changeSection(1)} active={this.props.currentSection === 1 ? true : false} icon={ faTableList }>Catálogos</SidenavButton>);
        
        if (this.props.user.position === '1' || this.props.user.position === '2' || (this.props.user.position === '3' || this.props.user.display_name === 'Juan Pablo Lares Larragoita') || this.props.user.position === '4' || this.props.user.position === '6') {
            sidenav_entries.push(<SidenavButton key={ sidenav_entries.length } onClick={() => this.props.changeSection(3)} active={this.props.currentSection === 3 ? true : false} icon={ faFileLines } >Formularios</SidenavButton>);
        }
        if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' || this.props.user.position === '5') {
            sidenav_entries.push(<SidenavButton key={ sidenav_entries.length } onClick={() => this.props.changeSection(4)} active={this.props.currentSection === 4 ? true : false} icon={ faScrewdriverWrench } >Servicios</SidenavButton>);
        }
        if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3') {
            sidenav_entries.push(<SidenavButton key={ sidenav_entries.length } onClick={() => this.props.changeSection(5)} active={this.props.currentSection === 5 ? true : false} icon={ faMoneyBillTransfer } >Bancos</SidenavButton>);
        }
        sidenav_entries.push(<SidenavButton key={ sidenav_entries.length } onClick={() => this.props.changeSection(6)} active={this.props.currentSection === 6 ? true : false} icon={ faCalendar }>Agenda</SidenavButton>);

        return(
            <nav style={{
                width: 'fit-content',
                height: '95%',
                boxShadow: '5px 0 2em 0 rgba(0, 0, 0, 0.2)',
                left: '0',
                position: 'fixed',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.75em',
                padding: '1em',
                transform: translation,
                transition: '0.3s',
                zIndex: '5',
                backgroundColor: 'var(--on-primary-color)'
            }}>{ sidenav_entries }</nav>
        )
    }
}
export default Sidenav;