import React from "react";
import Catalog from "./Catalog";
import { Device, CatalogProps } from "../../Interfaces";

interface ClientlessDevicesCatalogProps extends CatalogProps {
    reload?: (catalog: string) => void,
    data: Device[]
}

class ClientlessDevicesCatalog extends React.Component<ClientlessDevicesCatalogProps, {}> {
    render() {
        let newButtonPrompt: string = this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? 'Nuevo Equipo' : '';
        return (
            <Catalog
                data={this.props.data}
                headersForTable={[
                    { name: 'Número de Serie', width: '25%', data: 'serial' },
                    { name:'Modelo', width: '25%', data: 'model' },
                    { name: 'Sucursal', width: '25%', data: 'branch' },
                    { name: 'Contrato', width: '25%', data: 'contract_string' }
                ]}
                dataHeaders={['serial', 'model', 'branch', 'contract_string']}
                searchHeaders={['serial', 'model', 'branch', 'contract_string']}
                objectType={'Equipos'}
                getDetail={id => this.props.getDetail('devices', id)}
                newButtonPrompt={ newButtonPrompt }
                newButtonClick={() => this.props.changeSectionFunction(3, null, 'DeviceForm')}
                searchPlaceholder='Buscar equipos...'
                itemsPerPage={10} />
        );
    }
}

export default ClientlessDevicesCatalog;