import React from "react";
import { Model } from "../../../Interfaces";
import ItemSelector from "./ItemSelector";

interface ModelSelectorProps {
    selected: (model: any) => void,
    models: Model[],
    selected_model: Model | null
}

class ModelSelector extends React.Component<ModelSelectorProps, {}> {
    render() {
        return (
            <ItemSelector selected_item={ this.props.selected_model } items={ this.props.models } selected={ this.props.selected } />
        )
    }
}

export default ModelSelector;