import React from "react";
import Catalog from "./Catalog";
import { Brand, CatalogProps } from "../../Interfaces";

interface BrandsCatalogProps extends CatalogProps {
    reload: (catalog: string) => void,
    data: Brand[],
}

class BrandsCatalog extends React.Component<BrandsCatalogProps, {}> {

    render() {
        let newButtonPrompt: string = this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? 'Nueva marca' : '';
        return (
            <Catalog data={this.props.data}
            headersForTable={[{name: 'Nombre', width: '80%', data: 'display_name'}, {name: 'Número de Equipos', width: '20%', data: 'num_devices'}]}
            dataHeaders={['display_name', 'num_devices']}
            searchHeaders={['name', 'display_name']}
            objectType='Marcas'
            newButtonPrompt={newButtonPrompt}
            newButtonClick={() => this.props.changeSectionFunction(3, null, 'BrandForm')}
            searchPlaceholder='Buscar marcas...'
            getDetail={id => this.props.getDetail('brands', id)}
            itemsPerPage={10} />
        )
    }
}

export default BrandsCatalog;