import React from "react";
import Catalog from "./Catalog";
import { CatalogProps, Policy } from "../../Interfaces";

interface PoliciesCatalogProps extends CatalogProps {
    reload: (catalog: string) => void,
    data: Policy[]
}

class PoliciesCatalog extends React.Component<PoliciesCatalogProps, {}> {
    render() {
        let newButtonPrompt: string = this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? 'Nueva póliza' : '';
        return (
            <Catalog
            data={ this.props.data }
            headersForTable={[{name: 'Cliente', width: '50%', data: 'client'}, {name: 'Equipos', width: '50%', data: 'num_devices'}]}
            dataHeaders={['client', 'num_devices']}
            searchHeaders={['display_name', 'client', 'num_devices']}
            objectType='Pólizas'
            getDetail={id => this.props.getDetail('policies', id)}
            newButtonPrompt={ newButtonPrompt }
            newButtonClick={() => this.props.changeSectionFunction(3, null, 'PolicyForm')}
            searchPlaceholder='Buscar pólizas...'
            itemsPerPage={ 10 } />
        );
    }
}

export default PoliciesCatalog;