import React from "react";
import { Supplier } from "../../../Interfaces";
import ItemSelector from "./ItemSelector";

interface SupplierSelectorProps {
    selected: (supplier: any) => void;
    suppliers: Supplier[];
    selected_supplier: Supplier | null;
}

export default function SupplierSelector(props: SupplierSelectorProps) {
    return (
        <ItemSelector selected_item={ props.selected_supplier } items={  props.suppliers } selected={ props.selected } />
    );
}