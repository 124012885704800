import React from "react";
import Catalog from "./Catalog";
import { Employee, CatalogProps } from "../../Interfaces";

interface EmployeesCatalogProps extends CatalogProps {
    reload: (catalog: string) => void,
    data: Employee[]
}

class EmployeesCatalog extends React.Component<EmployeesCatalogProps, {}> {
    render() {
        let newButtonPrompt: string = this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? 'Nuevo empleado' : '';
        return (
            <Catalog data={this.props.data}
            headersForTable={[{name: 'Nombre', width: '50%', data: 'display_name'}, {name: 'Número telefónico', width: '25%', data: 'phone_number'}, {name: 'Puesto', width: '25%', data: 'position'}]}
            dataHeaders={['display_name', 'phone_number', 'position']}
            searchHeaders={['display_name', 'phone_number', 'position']}
            objectType={'Empleados'}
            newButtonPrompt={newButtonPrompt}
            newButtonClick={() => this.props.changeSectionFunction(3, null, 'PersonnelForm')}
            searchPlaceholder='Buscar empleados...'
            getDetail={id => this.props.getDetail('employees', id)}
            itemsPerPage={10} />
        )
    }
}

export default EmployeesCatalog;