import React from "react";
import { MenuOptions } from "../Interfaces";
import MenuCard from './MenuCard';

interface MenuProps {
    options: MenuOptions[]
}

class Menu extends React.Component<MenuProps, {}> {
    renderCards() {
        let subsections = [];
        for (let subsection of this.props.options) {
            let cards = [];
            for (let card of subsection.cards) {
                cards.push(<MenuCard key={card.title.toLowerCase() + '-card'} title={card.title} icon={card.icon} onClick={ card.onClick } />);
            }
            subsections.push(
                <div key={subsection.subsection + '-subsection'}>
                    <h2 style={{color: 'var(--primary-color)', margin: '0', fontFamily: 'Ubuntu'}}>{ subsection.subsection }</h2>
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', gap: '4em', padding: '0.5em', boxSizing: 'border-box'}}>
                        { cards }
                    </div>
                </div>
            );
        }
        return subsections;
    }

    render() {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '2em',
                padding: '1em'
            }} className='float-from-down'>
                { this.renderCards() }
            </div>
        )
    }
}

export default Menu;