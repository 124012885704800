import React from "react";
import Catalog from "./Catalog";
import { CatalogTableHeader, Employee, WorkOrder } from "../../Interfaces";

interface WorkOrdersCatalogProps {
    changeSectionFunction: (section: number, itemID: number | null, subsection: string) => void,
    getDetail: (itemID: number) => void,
    data: WorkOrder[],
    user: Employee
    hideNewOrderButton?: boolean;
}

class WorkOrdersCatalog extends React.Component<WorkOrdersCatalogProps, {}> {

    render() {
        const tableHeaders: CatalogTableHeader[] = [];
        tableHeaders.push(
            { name: 'Folio', width: '5%', data: 'number' },
            { name: 'Estatus', width: '10%', data: 'status' },
            { name: 'Cliente', width: '25%', data: 'client' },
            { name: 'Equipo', width: '20%', data: 'device' },
            { name: 'Fecha Creada', width: '20%', data: 'date_created', type: window.innerWidth > 768 ? 'long_date' : 'short_date' }
        )
        if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' || this.props.user.display_name === 'Juan Pablo Lares Larragoita')
            tableHeaders.push({ name: 'Ingeniero', width: '20%', data: 'engineer' });
        // tableHeaders.push({name: 'Estatus', width: '10%', data: 'status'});
        const dataHeaders = [];
        dataHeaders.push('number');
        dataHeaders.push('status');
        dataHeaders.push('client');
        dataHeaders.push('device');
        dataHeaders.push('date_created');
        if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' || this.props.user.display_name === 'Juan Pablo Lares Larragoita')
            dataHeaders.push('engineer');
        const searchHeaders: string[] = [];
        searchHeaders.push('number');
        searchHeaders.push('status');
        searchHeaders.push('client');
        searchHeaders.push('device');
        if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' || this.props.user.display_name === 'Juan Pablo Lares Larragoita')
            searchHeaders.push('engineer');
        if (this.props.getDetail) {
            return (
                <Catalog data={this.props.data}
                    headersForTable={tableHeaders}
                    dataHeaders={dataHeaders}
                    searchHeaders={searchHeaders}
                    height='38em'
                    objectType={'Órdenes de Trabajo'}
                    getDetail={id => this.props.getDetail(id)}
                    newButtonPrompt={(this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' || this.props.user.display_name === 'Juan Pablo Lares Larragoita') && this.props.hideNewOrderButton !== true ? 'Nueva Orden' : ''}
                    newButtonClick={() => this.props.changeSectionFunction(3, null, 'WorkOrderForm')}
                    searchPlaceholder='Buscar Órdenes de Trabajo'
                    itemsPerPage={10} />
            )
        }
    }
}

export default WorkOrdersCatalog;