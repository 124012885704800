import React, { ReactNode } from 'react';
import TextInput from './Utility/TextInput';
import PrimaryButton from './Utility/PrimaryButton';

interface LoginProps {
    tryLogin: (username: string | number, password: string | number) => void
}

interface LoginState {
    username: string | number,
    password: string | number
}

class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: any) {
        super(props);
        this.state = {
            username: '',
            password: ''
        };
    }

    render(): ReactNode {
        return (
            <form className='login-container float-from-down' onSubmit={ event => {
                event.preventDefault();
                this.props.tryLogin(this.state.username, this.state.password)
            } }>
                <img src='logo.png' style={{ width: '100%', padding: '20px 20px 0 20px' }} alt='Logo Inimmed' />
                <div className='form-container' style={{ paddingTop: '0' }}>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Usuario</label>
                            <TextInput onChange={event => this.setState({username: event.target.value})}>{ this.state.username }</TextInput>
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Contraseña</label>
                            <TextInput type='password' onChange={event => this.setState({password: event.target.value})}>{ this.state.password }</TextInput>
                        </div>
                    </div>
                    <div className='top-separator' />
                    <PrimaryButton>INICIAR</PrimaryButton>
                </div>
            </form>
        );
    }
}

export default Login;