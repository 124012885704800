import React, { ReactNode } from 'react';

interface PrimaryButtonProps extends React.DOMAttributes<HTMLButtonElement> {
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    inactive?: boolean;
    className?: string;
    children: ReactNode;
    floatFromUp?: boolean;
}

class PrimaryButton extends React.Component<PrimaryButtonProps, {}> {
    mobile: boolean;

    constructor (props: PrimaryButtonProps) {
        super(props);
        this.mobile = window.innerWidth > 768 ? false : true;
    }

    render() {
        return (
            <button style={{ fontSize: this.mobile ? '0.75em' : '', userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none' }} className={ this.props.inactive ? 'button-primary-inactive' : 'button-primary'} onClick={this.props.onClick}>{this.props.children}</button>
        )
    }
}
export default PrimaryButton;