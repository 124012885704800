import React from "react";
import { Branch } from '../../../Interfaces'
import ItemSelector from '../Selectors/ItemSelector';

interface BranchSelectorProps {
    selected: (branch: any) => void,
    branches: Branch[],
    selected_branch: Branch | null
}

class BranchSelector extends React.Component<BranchSelectorProps, {}> {
    render() {
        return (
            <ItemSelector selected_item={ this.props.selected_branch } items={ this.props.branches } selected={ this.props.selected } />
        )
    }
}

export default BranchSelector;