import React from "react";
import { ReactNode } from "react";
import { toast } from "react-toastify";
import { inimmedAxios } from "../../InimmedHelpers";
import { MonthBudgetConcept, OwnBankAccount, PurchaseAccount } from "../../Interfaces";
import Loading from "../Utility/Loading";
import MoneyInput from "../Utility/MoneyInput";
import PrimaryButton from "../Utility/PrimaryButton";
import AccountSelector from "../Utility/Selectors/AccountSelector";
import MonthBudgetConceptSelector from "../Utility/Selectors/MonthBudgetConceptSelector";
import OwnBankAccountSelector from "../Utility/Selectors/OwnBankAccountSelector";
import { TabSelector } from "../Utility/TabSelector";

interface ExpenseFormProps {
    goBackAndRefresh: () => void;
    initial_account?: PurchaseAccount;
}

interface ExpenseFormState {
    amount: string;
    currency: number;
    response_bank_accounts: OwnBankAccount[] | null;
    bank_account: OwnBankAccount | null;
    date_received: string;
    response_accounts: PurchaseAccount[] | null;
    concept: PurchaseAccount | null;
    concept_detail: string;
    uploading: boolean;
    current_tab: string;
    response_month_budget_concepts: MonthBudgetConcept[] | null;
    month_budget_concept: MonthBudgetConcept | null;
    month_budget_concept_area: '0' | '1' | '2' | '3';
    month_budget_concept_type: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
}

export default class ExpenseForm extends React.Component<ExpenseFormProps, ExpenseFormState> {
    tabs: string[];

    constructor(props: ExpenseFormProps) {
        super(props);
        
        const today = new Date();

        this.tabs = ['A Cuenta por Pagar', 'Egreso Único', 'A Presupuesto Mensual']

        this.state = {
            amount: '0.00',
            currency: 0,
            response_bank_accounts: null,
            bank_account: null,
            date_received: new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().substring(0, 19),
            response_accounts: null,
            concept: null,
            concept_detail: '',
            uploading: false,
            current_tab: this.tabs[0],
            response_month_budget_concepts: null,
            month_budget_concept: null,
            month_budget_concept_area: '0',
            month_budget_concept_type: '0'
        }

        this.submitExpense = this.submitExpense.bind(this);
    }

    componentDidMount() {
        const initial_state = this.state as ExpenseFormState;
        const promises: Promise<void>[] = [];

        promises.push(
            inimmedAxios.get('api_banks/get_purchase_accounts')
            .then(response => {
                initial_state.response_accounts = response.data;
            })
        );

        promises.push(
            inimmedAxios.get('api_banks/get_own_bank_accounts')
            .then(response => {
                initial_state.response_bank_accounts = response.data;
            })
        )

        promises.push(
            inimmedAxios.get('api_banks/get_month_budget_concepts')
            .then(response => {
                initial_state.response_month_budget_concepts = response.data;
            })
        )

        if (this.props.initial_account) {
            initial_state.concept = this.props.initial_account;
        }

        Promise.all(promises)
        .then(() => {
            this.setState(initial_state);
        })
        .catch(reason => alert(reason));
    }

    generateConceptInput() {
        switch (this.state.current_tab) {
            case this.tabs[0]:
                return (
                    <div className='form-input'>
                        <label>Cuenta por Pagar:</label>
                        <AccountSelector accounts={ this.state.response_accounts ? this.state.response_accounts : [] } selected_account={ this.state.concept } selected={account => this.setState({ concept: account })} />
                    </div>
                );
            case this.tabs[1]:
                return (
                    <div className='form-input'>
                        <label>Concepto:</label>
                        <textarea onChange={event => this.setState({ concept_detail: event.target.value }) } value={ this.state.concept_detail } rows={5} />
                    </div>
                );
            case this.tabs[2]:
                let month_budget_concepts: MonthBudgetConcept[] = this.state.response_month_budget_concepts as MonthBudgetConcept[];
                month_budget_concepts = month_budget_concepts.filter((concept) => {
                    if (this.state.month_budget_concept_area) {
                        if (concept.area === this.state.month_budget_concept_area) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                });
                month_budget_concepts = month_budget_concepts.filter(concept => {
                    if (this.state.month_budget_concept_type) {
                        if (concept.type === this.state.month_budget_concept_type) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                });
                
                return (
                    <>
                        <div className='form-input'>
                            <label>Área:</label>
                            <select onChange={event => this.setState({ month_budget_concept_area: event.target.value as '0' | '1' | '2' | '3' })}>
                                <option value='0'>Equipo</option>
                                <option value='1'>Servicio</option>
                                <option value='2'>Consumible</option>
                                <option value='3'>Refacciones</option>
                            </select>
                        </div>
                        <div className='form-input'>
                            <label>Tipo de Concepto:</label>
                            <select onChange={event => this.setState({ month_budget_concept_type: event.target.value as '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' })}>
                                <option value='0'>Nómina</option>
                                <option value='1'>Servicio</option>
                                <option value='2'>Crédito Automovilístico</option>
                                <option value='3'>Crédito Bancario</option>
                                <option value='4'>Tarjeta de Crédito</option>
                                <option value='5'>Cuota Bancaria</option>
                                <option value='6'>Impuesto</option>
                                <option value='7'>Mensajerías</option>
                                <option value='8'>Transporte</option>
                                <option value='9'>Otros Gastos</option>
                            </select>
                        </div>
                        <div className='form-input'>
                            <label>Concepto:</label>
                            <MonthBudgetConceptSelector month_budget_concepts={this.state.response_month_budget_concepts ? month_budget_concepts : []} selected_month_budget_concept={ this.state.month_budget_concept } selected={month_budget_concept => this.setState({ month_budget_concept: month_budget_concept })} />
                        </div>
                    </>
                );
        }
    }

    submitExpense() {
        let total_amount;
        if (!this.state.amount.replaceAll(',', '').match(/^\d+.\d*$/)) {
            alert('Por favor ingrese un valor válido para el monto del egreso.');
            return;
        } else {
            total_amount = Number.parseFloat(this.state.amount);
            if (!total_amount) {
                alert('Por favor ingrese un valor para el monto del ingreso.');
                return;
            }
        }
        if (!this.state.bank_account) {
            alert('Por favor elija la cuenta bancaria de donde proviene el egreso.');
            return;
        }
        if (!this.state.date_received) {
            alert('Por favor ingrese la fecha en que se efectuó el egreso.');
            return;
        }
        switch (this.state.current_tab) {
            case this.tabs[0]:
                if (!this.state.concept) {
                    alert('Por favor elija la cuenta correspondiente al egreso.');
                    return;
                }
                break;
            case this.tabs[1]:
                if (!this.state.concept_detail) {
                    alert('Por favor ingrese el concepto para el egreso.');
                    return;
                }
                break;
            case this.tabs[2]:
                if (!this.state.month_budget_concept) {
                    alert('Por favor elija el concepto del presupuesto mensual correspondiente al egreso.');
                }
        }

        this.setState({ uploading: true });
        inimmedAxios.post('api_banks/save_expense', {
            amount: this.state.amount.replaceAll(',', ''),
            currency: this.state.currency,
            bank_account: this.state.bank_account.id,
            date_received: this.state.date_received,
            concept: this.state.concept ? this.state.concept.id : null,
            concept_detail: this.state.concept_detail ? this.state.concept_detail : null,
            month_budget_concept: this.state.month_budget_concept ? this.state.month_budget_concept.id : null
        })
        .then(response => {
            if (response.data.Result !== 'Ok') {
                this.setState({ uploading: false });
                alert(response.data.Result);
            } else {
                this.setState({ uploading: false });
                toast.success('Egreso registrado con éxito.');
                this.props.goBackAndRefresh();
            }
        })
        .catch(reason => alert(reason));
    }

    render(): ReactNode {
        if (!this.state.uploading) {
            if (this.state.response_accounts !== null && this.state.response_bank_accounts !== null) {
                return (
                    <div className='form-container'>
                        <h2>Detalles</h2>
                        <div className='form-input-div'>
                            <div className='form-input'>
                                <label>Monto:</label>
                                <MoneyInput currency={ this.state.currency } setCurrency={event => this.setState({ currency: Number.parseInt(event.target.value) })} setValue={value => this.setState({ amount: value })}>{ this.state.amount }</MoneyInput>
                            </div>
                            <div className='form-input'>
                                <label>Cuenta Bancaria:</label>
                                <OwnBankAccountSelector own_bank_accounts={ this.state.response_bank_accounts !== null ? this.state.response_bank_accounts : [] } selected_own_bank_account={ this.state.bank_account } selected={own_bank_account => this.setState({ bank_account: own_bank_account })} />
                            </div>
                            <div className='form-input'>
                                <label>Fecha y hora efectuado:</label>
                                <input defaultValue={ this.state.date_received } onChange={event => this.setState({ date_received: event.target.value })} className='form-text-input' type={'datetime-local'} />
                            </div>
                        </div>
                        <TabSelector active_tab={this.state.current_tab} setTab={tab => this.setState({ current_tab: tab, concept: null, concept_detail: '', month_budget_concept: null })} tab_names={this.tabs} />
                        <div className='form-input-div'>
                            { this.generateConceptInput() }
                        </div>
                        <div className='button-strip'>
                            <PrimaryButton onClick={ this.submitExpense }>Registrar</PrimaryButton>
                        </div>
                    </div>
                );
            } else {
                return (
                    <Loading color='primary' />
                );
            }
        } else {
            return (
                <Loading color='primary' />
            );
        }
    }
}