import React from "react";
import { toast } from "react-toastify";
import { inimmedAxios } from "../../InimmedHelpers";
import { DetailComponentProps, Part } from "../../Interfaces.jsx";
import PrimaryButton from "../Utility/PrimaryButton";
import DangerButton from "../Utility/DangerButton";

interface PartDetailsProps extends DetailComponentProps {
    data: Part
}

class PartDetails extends React.Component<PartDetailsProps, {}> {
    constructor(props: PartDetailsProps) {
        super(props);

        this.delete = this.delete.bind(this);
    }

    delete() {
        if (window.confirm('¿Seguro/a que desea borrar la refacción ' + this.props.data.display_name + '?')) {
            inimmedAxios.post('api/delete_part/' + this.props.data.id)
            .then(response => {
                if (response.data.Result === 'Success') {
                    toast.success('Refacción eliminada. 🗑️');
                    this.props.getCatalog('parts');
                    this.props.goBack();
                } else {
                    alert(response.data.Result);
                }
            })
        }
    }

    render() {
        if (typeof this.props.data.model !== 'string' && typeof this.props.data.brand !== 'string' && typeof this.props.data.part_type !== 'string') {
            return (
                <div className='float-from-down' style={{ boxSizing: 'border-box', padding: '1em', fontSize: '1.25em'}}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1em', flexWrap: 'wrap'}}>
                        <h1>{ this.props.data.display_name }</h1>
                        { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? <PrimaryButton onClick={() => this.props.editFunction(this.props.data.id)}>Editar</PrimaryButton> : null }
                    </div>
                    <p><strong>Nombre:</strong><br />{ this.props.data.name }</p>
                    <p><strong>Número de Pieza:</strong><br />{ this.props.data.part_number }</p>
                    <p><strong>Marca:</strong><br />{ this.props.data.brand?.display_name }</p>
                    <p><strong>Tipo de Refacción:</strong><br />{ this.props.data.part_type.display_name }</p>
                    <p><strong>Para el modelo:</strong><br />{ this.props.data.model ? this.props.data.model.display_name : 'N/A' }</p>
                    <p><strong>En existencia:</strong><br />{ this.props.data.stock }</p>
                    <p><strong>Notas:</strong><br />{ this.props.data.notes }</p>
                    {this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                        <div>
                            <DangerButton onClick={this.delete}>Eliminar</DangerButton>
                        </div>
                        :
                        null}
                </div>
            )
        }
    }
}
export default PartDetails;