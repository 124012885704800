import React from "react";
import { Employee, PurchaseAccount } from "../../Interfaces";
import Catalog from "./Catalog";

interface PurchaseAccountsCatalogProps {
    getAccountDetail: (account_id: number, account_type: 1 | 0) => void;
    data: PurchaseAccount[];
    user: Employee;
}

class PurchaseAccountsCatalog extends React.Component<PurchaseAccountsCatalogProps, {}> {
    render() {
        return(
            <Catalog
            data={ this.props.data }
            headersForTable={[{name: 'Folio', width: '10%', data: 'id'}, {name: 'Proveedor', width: '30%', data: 'supplier'}, {name: 'Concepto', width: '40%', data: 'concept'}, {name: 'Estatus', width: '20%', data: 'status'}]}
            dataHeaders={['id', 'supplier', 'concept', 'status']}
            searchHeaders={['id', 'supplier', 'concept', 'status']}
            objectType={'Cuentas por pagar'}
            getDetail={account_id => this.props.getAccountDetail(account_id, 1)}
            itemsPerPage={10}
            newButtonClick={() => null}
            newButtonPrompt=''
            searchPlaceholder='Buscar cuentas por pagar...'
            />
        );
    }
}

export default PurchaseAccountsCatalog;