import { inimmedAxios, ServiceType } from "../../InimmedHelpers";
import React from "react";
import TextInput from "../Utility/TextInput";
import PrimaryButton from "../Utility/PrimaryButton";
import AddButton from "../Utility/AddButton";
import { toast } from "react-toastify";
import { Branch, Client, Device, Employee, FormComponentProps, InimmedFile, WorkOrder } from "../../Interfaces";
import ClientSelector from "../Utility/Selectors/ClientSelector";
import BranchSelector from "../Utility/Selectors/BranchSelector";
import DeviceSelector from "../Utility/Selectors/DeviceSelector";
import { ServiceTypeRadioMenu } from "../Utility/ServiceTypeRadioMenu";
import EmployeeSelector from "../Utility/Selectors/EngineerSelector";
import Loading from "../Utility/Loading";

export const getMIMEType = (filename: string) => {
    const filename_to_check = filename.slice(filename.lastIndexOf('.') + 1)
    switch (filename_to_check) {
        case 'jpeg':
            return 'image/jpeg';
        case 'jpg':
            return 'image/jpeg';
        case 'bmp':
            return 'image/bmp';
        case 'png':
            return 'image/png';
        case 'avi':
            return 'video/x-msvideo';
        case 'gif':
            return 'image/gif';
        case 'mpeg':
            return 'video/mpeg';
        case '3gp':
            return 'video/3gpp';
        case 'mp4':
            return 'video/mp4';
    }
}

interface WorkOrderFormProps extends FormComponentProps {
    getWorkOrders?: () => void,
    getWorkOrder?: (itemID: number) => void,
    data?: WorkOrder,
    savedInputs?: WorkOrderFormState,
    startDate?: Date | null,
    user: Employee
}

interface WorkOrderFormState {
    response_clients: Client[];
    client: Client | null;
    status: string;
    response_branches: Branch[];
    branch: Branch | null;
    response_devices: Device[];
    devices: Device[];
    device: Device | null;
    service_type: '1' | '2' | '3' | '4' | '5';
    response_engineers: Employee[];
    engineer: Employee | null;
    auxiliary_engineers: Employee[];
    expected_start: string;
    expected_end: string;
    reported_failure: string;
    who_reported: string;
    contact_method: string;
    evidence_files: InimmedFile[];
    evidence_files_to_delete: InimmedFile[];
    evidence_files_to_add: InimmedFile[];
    loaded_files: number;
    loading: boolean;
    upload_percent: number;
}

class WorkOrderForm extends React.Component<WorkOrderFormProps, WorkOrderFormState> {
    fileContainer: React.RefObject<HTMLInputElement>;
    mobile: boolean;

    constructor(props: WorkOrderFormProps) {
        super(props);
        this.state = {
            response_clients: [],
            client: null,
            status: '',
            response_branches: [],
            branch: null,
            response_devices: [],
            devices: [],
            device: null,
            service_type: '1',
            response_engineers: [],
            engineer: null,
            auxiliary_engineers: [],
            expected_start: '',
            expected_end: '',
            reported_failure: '',
            who_reported: '',
            contact_method: '',
            evidence_files: [],
            evidence_files_to_delete: [],
            evidence_files_to_add: [],
            loaded_files: 0,
            loading: false,
            upload_percent: 0
        }

        this.selectClient = this.selectClient.bind(this);
        this.selectBranch = this.selectBranch.bind(this);
        this.selectDevice = this.selectDevice.bind(this);
        this.selectServiceType = this.selectServiceType.bind(this);
        this.selectContactMethod = this.selectContactMethod.bind(this);
        this.submitWorkOrder = this.submitWorkOrder.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.selectEngineer = this.selectEngineer.bind(this);
        this.selectHeadEngineer = this.selectHeadEngineer.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.uploadProgressHandler = this.uploadProgressHandler.bind(this);

        this.fileContainer = React.createRef();
        this.mobile = window.innerWidth > 768 ? false : true;
    }

    componentDidMount() {
        let initial_state: WorkOrderFormState = {} as WorkOrderFormState;
        let promises: Promise<void>[] = [];

        if (this.props.savedInputs) {
            initial_state = this.props.savedInputs;
        }

        if (this.props.startDate) {
            initial_state.expected_start = this.props.startDate.toISOString().substring(0, 16);
        }

        promises.push(inimmedAxios.get('api/clients')
            .then(response => {
                if (this.props.data && typeof this.props.data.client !== 'string') {
                    for (const client of response.data) {
                        if (client.display_name === this.props.data.client.display_name) {
                            initial_state.client = client;
                        }
                    }
                }
                if (this.props.user.display_name === 'Juan Pablo Lares Larragoita') {
                    const response_clients: Client[] = response.data;
                    const zone_filtered = response_clients.filter(client => {
                        if (client.zone === 'Zona Cuernavaca') return true;
                        else return false;
                    })
                    initial_state.response_clients = zone_filtered;
                } else {
                    initial_state.response_clients = response.data;
                }
            }));

        if (this.props.data) {
            if (typeof this.props.data.client !== 'string') {
                promises.push(inimmedAxios.get('api/get_branches_of/' + this.props.data.client.id)
                    .then(response => {
                        initial_state.response_branches = response.data;
                    }));

                promises.push(inimmedAxios.get('api/get_devices/' + this.props.data.client.id)
                    .then(response => {
                        initial_state.response_devices = response.data;
                        initial_state.devices = response.data;
                    }));
            }
            this.handleDateChange();
            initial_state.status = this.props.data.status;
            initial_state.branch = this.props.data.branch;
            initial_state.device = this.props.data.device as Device;
            initial_state.service_type = this.props.data.service_type;
            initial_state.engineer = this.props.data.engineer as Employee;
            initial_state.expected_start = this.props.data.expected_start?.toString();
            initial_state.expected_end = this.props.data.expected_end?.toString();
            initial_state.reported_failure = this.props.data.reported_failure;
            initial_state.who_reported = this.props.data.who_reported;
            initial_state.contact_method = this.props.data.contact_method;
            initial_state.evidence_files = this.props.data.evidence_files;
            initial_state.auxiliary_engineers = this.props.data.auxiliary_engineers;

            for (let i = 0; i < this.props.data.evidence_files.length; i++) {
                promises.push(inimmedAxios.get('api/get_evidence_file', {
                    params: {
                        work_order: this.props.data.number,
                        file_name: this.props.data.evidence_files[i].name
                    },
                    responseType: 'blob'
                })
                    .then(response => {
                        if (this.props.data) {
                            let newFile: InimmedFile = new File([response.data], this.props.data.evidence_files[i].name, {
                                type: getMIMEType(this.props.data.evidence_files[i].name)
                            });
                            newFile.id = this.props.data.evidence_files[i].id;
                            newFile.path = this.props.data.evidence_files[i].path;
                            newFile.url = URL.createObjectURL(newFile);
                            const evidence_files = this.props.data.evidence_files;
                            evidence_files[i] = newFile;
                            initial_state.evidence_files = evidence_files;
                            initial_state.loaded_files = this.state.loaded_files + 1;
                        }
                    }));
            }
        }

        Promise.all(promises)
            .then(() => {
                this.setState(initial_state);
            })
            .catch(reason => alert(reason));
    }

    selectClient(client: Client) {
        if (client) {
            let response_branches: Branch[] = [];
            let response_devices: Device[] = [];

            const promises: Promise<void>[] = [];

            promises.push(inimmedAxios.get('api/get_branches_of/' + client.id)
                .then(response => {
                    response_branches = response.data;
                }));
            promises.push(inimmedAxios.get('api/get_devices/' + client.id)
                .then(response => {
                    response_devices = response.data;
                }));
            Promise.all(promises)
                .then(value => this.setState({ response_branches: response_branches, response_devices: response_devices, client: client, devices: response_devices }))
                .catch(reason => alert(reason));
        } else {
            this.setState({ client: null, response_branches: [], branch: null });
        }
    }

    selectBranch(branch: Branch) {
        const devices = [];
        for (const device of this.state.response_devices) {
            if (device.branch_id === branch.id) {
                devices.push(device);
            }
        }
        this.setState({ branch: branch, device: null, devices: devices });
    }

    selectDevice(device: Device) {
        this.setState({ device: device });
        if (device.in_guarantee) {
            toast.info('El equipo seleccionado está en garantía.', {
                theme: 'colored',
                style: { fontFamily: 'Ubuntu' },
                autoClose: false
            });
        }
        if (device.in_policy) {
            toast.info('El equipo seleccionado está en póliza.', {
                theme: 'colored',
                style: { fontFamily: 'Ubuntu' },
                autoClose: false
            })
        }
        this.setState({ device: device });
        return;
    }

    selectContactMethod(event: React.ChangeEvent<HTMLSelectElement>) {
        if (event.target.value === '0') {
            this.setState({ contact_method: '' });
            return;
        }
        this.setState({ contact_method: event.target.value });
    }

    selectServiceType(service_type_string: ServiceType) {
        if (service_type_string === 'Preventivo') {
            this.setState({ service_type: '1' });
            return;
        }
        if (service_type_string === 'Correctivo') {
            this.setState({ service_type: '2' });
            return;
        }
        if (service_type_string === 'Instalación') {
            this.setState({ service_type: '3' });
            return;
        }
        if (service_type_string === 'Garantía extraordinaria') {
            this.setState({ service_type: '4' });
            return;
        }
        if (service_type_string === 'Capacitación') {
            this.setState({ service_type: '5' });
            return;
        }
    }

    selectEngineer(event: any) {
        let currentSelectedEngineers = [];
        for (const option of event.target.selectedOptions) {
            for (const engineer of this.state.response_engineers) {
                if (engineer.id === Number.parseInt(option.value)) {
                    currentSelectedEngineers.push(engineer);
                }
            }
        }
        this.setState({ auxiliary_engineers: currentSelectedEngineers });
    }

    selectHeadEngineer(engineer: Employee) {
        if (!engineer) {
            this.setState({ engineer: null });
            return;
        }
        this.setState({ engineer: engineer });
    }

    handleContactMethod() {
        let contactMethods = [];
        contactMethods.push(<option key='default-contact-method' value='0'>Elija un método de contacto</option>);
        if (this.props.data) {
            if (this.props.data.contact_method === '1')
                contactMethods.push(<option selected key='1' value='1'>Correo Electrónico</option>);
            else
                contactMethods.push(<option key='1' value='1'>Correo Electrónico</option>);
            if (this.props.data.contact_method === '2')
                contactMethods.push(<option selected key='2' value='2'>Llamada Telefónica</option>);
            else
                contactMethods.push(<option key='2' value='2'>Llamada Telefónica</option>);
            if (this.props.data.contact_method === '3')
                contactMethods.push(<option selected key='3' value='3'>WhatsApp</option>);
            else
                contactMethods.push(<option key='3' value='3'>WhatsApp</option>);
            if (this.props.data.contact_method === '4')
                contactMethods.push(<option selected key='4' value='4'>Facebook</option>);
            else
                contactMethods.push(<option key='4' value='4'>Facebook</option>);
        } else {
            contactMethods.push(<option key='1' value='1'>Correo Electrónico</option>);
            contactMethods.push(<option key='2' value='2'>Llamada Telefónica</option>);
            contactMethods.push(<option key='3' value='3'>WhatsApp Oficina</option>);
            contactMethods.push(<option key='4' value='4'>WhatsApp Personal</option>);
            contactMethods.push(<option key='5' value='5'>Facebook</option>);
        }
        return contactMethods;
    }

    handleStatus() {
        let statuses = [];
        if (this.props.data) {
            if (this.props.data.status === 'Pendiente')
                statuses.push(<option selected key='1' value='1'>Pendiente</option>);
            else
                statuses.push(<option key='1' value='1'>Pendiente</option>);
            if (this.props.data.status === 'Urgente')
                statuses.push(<option selected key='2' value='2'>Urgente</option>);
            else
                statuses.push(<option key='2' value='2'>Urgente</option>);
            if (this.props.data.status === 'En Proceso')
                statuses.push(<option selected key='3' value='3'>En Proceso</option>);
            else
                statuses.push(<option key='3' value='3'>En Proceso</option>);
            if (this.props.data.status === 'En espera de refacciones')
                statuses.push(<option selected key='5' value='5'>En espera de refacciones</option>);
            else
                statuses.push(<option key='5' value='5'>En espera de refacciones</option>);
            if (this.props.data.status === 'En espera de información')
                statuses.push(<option selected key='6' value='6'>En espera de información</option>);
            else
                statuses.push(<option key='6' value='6'>En espera de información</option>);
            if (this.props.data.status === 'En espera de autorización')
                statuses.push(<option selected key='7' value='7'>En espera de autorización</option>);
            else
                statuses.push(<option key='7' value='7'>En espera de autorización</option>);
            if (this.props.data.status === 'Concluido')
                statuses.push(<option selected key='8' value='8'>Concluido</option>);
            else
                statuses.push(<option key='8' value='8'>Concluido</option>);
            if (this.props.data.status === 'Retomado')
                statuses.push(<option selected key='9' value='9'>Retomado</option>);
            else
                statuses.push(<option key='9' value='9'>Retomado</option>);
        }
        return statuses;
    }

    checkIfEngineerInList(engineer: Employee, list: Employee[]) {
        for (const engineer_of_list of list) {
            if (engineer.id === engineer_of_list.id)
                return true;
        }
        return false;
    }

    handleAuxiliaryEngineers() {
        let auxiliary_engineers_to_list: JSX.Element[] = [];
        for (const engineer of this.state.response_engineers) {
            if (this.state.engineer) {
                if (this.state.engineer.id !== engineer.id) {
                    if (this.checkIfEngineerInList(engineer, this.state.auxiliary_engineers)) {
                        auxiliary_engineers_to_list.push(<option selected key={engineer.id} value={engineer.id}>{engineer.display_name}</option>)
                    } else {
                        auxiliary_engineers_to_list.push(<option key={engineer.id} value={engineer.id}>{engineer.display_name}</option>)
                    }
                }
            } else {
                if (this.checkIfEngineerInList(engineer, this.state.auxiliary_engineers)) {
                    auxiliary_engineers_to_list.push(<option selected key={engineer.id} value={engineer.id}>{engineer.display_name}</option>)
                } else {
                    auxiliary_engineers_to_list.push(<option key={engineer.id} value={engineer.id}>{engineer.display_name}</option>)
                }
            }
        }
        return auxiliary_engineers_to_list;
    }

    handleDateChange(event?: React.ChangeEvent<HTMLInputElement>) {
        if (!event && this.props.data) {
            if (this.props.data.expected_start && this.props.data.expected_end) {
                inimmedAxios.get('api/get_available_engineers', {
                    params: {
                        start_date: this.props.data.expected_start,
                        end_date: this.props.data.expected_end
                    }
                })
                    .then(response => {
                        let engineers: Employee[] = response.data;
                        if (this.props.data && typeof this.props.data.engineer !== 'string' && this.props.data.engineer) {
                            engineers.push(this.props.data.engineer);
                        }
                        if (this.props.user.display_name === 'Juan Pablo Lares Larragoita') {
                            engineers = engineers.filter(engineer => {
                                if (engineer.display_name === 'Juan Pablo Lares Larragoita' || engineer.display_name === 'Cristian Josué Viveros Aguilar') return true;
                                else return false;
                            });
                            this.setState({ response_engineers: engineers });
                        } else {
                            this.setState({ response_engineers: engineers });
                        }
                    })
                    .catch(reason => alert(reason));
                return;
            }
        }

        if (!event)
            return;

        if (event.target.id === 'start') {
            this.setState({ expected_start: event.target.value ? event.target.value : '' });
            if (this.state.expected_end) {
                inimmedAxios.get('api/get_available_engineers', {
                    params: {
                        start_date: event.target.value,
                        end_date: this.state.expected_end
                    }
                })
                    .then(response => {
                        if (this.props.data) {
                            let engineers: Employee[] = response.data;
                            if (typeof this.props.data.engineer !== 'string' && this.props.data.engineer && this.props.data.engineer) {
                                engineers.push(this.props.data.engineer);
                            }
                            if (this.props.user.display_name === 'Juan Pablo Lares Larragoita') {
                                console.log('ENTERED HERE 2.');
                                engineers = engineers.filter(engineer => {
                                    if (engineer.display_name === 'Juan Pablo Lares Larragoita' || engineer.display_name === 'Cristian Josué Viveros Aguilar') return true;
                                    else return false;
                                });
                                this.setState({ response_engineers: engineers, engineer: null, auxiliary_engineers: [] });
                            } else {
                                this.setState({ response_engineers: engineers, engineer: null, auxiliary_engineers: [] });
                            }
                        } else {
                            let engineers: Employee[] = response.data;
                            if (this.props.user.display_name === 'Juan Pablo Lares Larragoita') {
                                engineers = engineers.filter(engineer => {
                                    if (engineer.display_name === 'Juan Pablo Lares Larragoita' || engineer.display_name === 'Cristian Josué Viveros Aguilar') return true;
                                    else return false;
                                });
                                this.setState({ response_engineers: engineers, engineer: null, auxiliary_engineers: [] });
                            } else {
                                this.setState({ response_engineers: engineers, engineer: null, auxiliary_engineers: [] });
                            }
                        }
                    })
                    .catch(reason => {
                        alert(reason);
                    });
            }
        }

        if (event.target.id === 'end') {
            this.setState({ expected_end: event.target.value ? event.target.value : '' });
            if (this.state.expected_start) {
                inimmedAxios.get('api/get_available_engineers', {
                    params: {
                        start_date: this.state.expected_start,
                        end_date: event.target.value
                    }
                })
                    .then(response => {
                        if (this.props.data) {
                            let engineers: Employee[] = response.data;
                            if (typeof this.props.data.engineer !== 'string' && this.props.data.engineer && this.props.data.engineer) {
                                engineers.push(this.props.data.engineer);
                            }
                            if (this.props.user.display_name === 'Juan Pablo Lares Larragoita') {
                                engineers = engineers.filter(engineer => {
                                    if (engineer.display_name === 'Juan Pablo Lares Larragoita' || engineer.display_name === 'Cristian Josué Viveros Aguilar') return true;
                                    else return false;
                                });
                                this.setState({ response_engineers: engineers, engineer: null, auxiliary_engineers: [] });
                            } else {
                                this.setState({ response_engineers: engineers, engineer: null, auxiliary_engineers: [] });
                            }
                        } else {
                            let engineers: Employee[] = response.data;
                            if (this.props.user.display_name === 'Juan Pablo Lares Larragoita') {
                                engineers = engineers.filter(engineer => {
                                    if (engineer.display_name === 'Juan Pablo Lares Larragoita' || engineer.display_name === 'Cristian Josué Viveros Aguilar') return true;
                                    else return false;
                                });
                                this.setState({ response_engineers: engineers, engineer: null, auxiliary_engineers: [] });
                            } else {
                                this.setState({ response_engineers: engineers, engineer: null, auxiliary_engineers: [] });
                            }
                        }
                    })
                    .catch(reason => {
                        alert(reason);
                    });
            }
        }
    }

    handleEvidenceFiles() {
        if (this.props.data) {
            if (this.state.loaded_files >= this.state.evidence_files.length) {
                let fileDescriptions = [];
                let fileCounter = 0;
                for (const file of this.state.evidence_files) {
                    let fileType = '';
                    if (file.type && file.size) {
                        if (file.type.includes('image')) {
                            fileType = 'Imágen';
                            const imagePreview = <img alt={file.name} src={file.url} style={{ width: '40%' }} />;
                            fileDescriptions.push(<div><div style={{ display: 'flex', gap: '1em' }}><p key={fileCounter}>{fileType}: {file.name}</p><img onClick={event => {
                                let newEvidenceFiles = [...this.state.evidence_files];
                                let index = 0;
                                for (let i = 0; i < newEvidenceFiles.length; i++) {
                                    if (newEvidenceFiles[i].name === file.name) {
                                        index = i;
                                        break;
                                    }
                                }
                                if (this.props.data) {
                                    const evidence_file_to_delete = newEvidenceFiles.splice(index, 1);
                                    let current_files_to_delete = this.state.evidence_files_to_delete;
                                    current_files_to_delete.push(evidence_file_to_delete[0]);
                                    this.setState({ evidence_files: newEvidenceFiles, evidence_files_to_delete: current_files_to_delete, loaded_files: this.state.loaded_files - 1 });
                                } else {
                                    newEvidenceFiles.splice(index, 1);
                                    this.setState({ evidence_files: newEvidenceFiles });
                                }
                            }} src='icons/trash.svg' alt='delete' style={{ width: '2em' }} /></div> {imagePreview} </div>);
                        }

                        if (file.type.includes('video')) {
                            fileType = 'Vídeo';
                            const videoPreview = <video autoPlay={false} controls style={{ width: '41%' }}><source src={file.url} type={file.type} /></video>;
                            fileDescriptions.push(<div><div style={{ display: 'flex', gap: '1em' }}><p key={fileCounter}>{fileType}: {file.name}</p><img onClick={event => {
                                let newEvidenceFiles = [...this.state.evidence_files];
                                let index = 0;
                                for (let i = 0; i < newEvidenceFiles.length; i++) {
                                    if (newEvidenceFiles[i].name === file.name) {
                                        index = i;
                                        break;
                                    }
                                }
                                if (this.props.data) {
                                    const evidence_file_to_delete = newEvidenceFiles.splice(index, 1);
                                    let current_files_to_delete = this.state.evidence_files_to_delete;
                                    current_files_to_delete.push(evidence_file_to_delete[0]);
                                    this.setState({ evidence_files: newEvidenceFiles, evidence_files_to_delete: current_files_to_delete, loaded_files: this.state.loaded_files - 1 });
                                } else {
                                    newEvidenceFiles.splice(index, 1);
                                    this.setState({ evidence_files: newEvidenceFiles });
                                }
                            }} src='icons/trash.svg' alt='delete' style={{ width: '2em' }} /></div> {videoPreview} </div>);
                        }
                    }
                    fileCounter++;
                }
                return fileDescriptions;
            }
        } else {
            let fileDescriptions = [];
            let fileCounter = 0;
            for (const file of this.state.evidence_files) {
                let fileType = '';
                if (file.type && file.size) {
                    if (file.type.includes('image')) {
                        fileType = 'Imágen';
                        const imagePreview = <img alt={file.name} src={file.url} style={{ width: '40%' }} />;
                        fileDescriptions.push(<div><div style={{ display: 'flex', gap: '1em' }}><p key={fileCounter}>{fileType}: {file.name}</p><img onClick={event => {
                            let newEvidenceFiles = [...this.state.evidence_files];
                            let index = 0;
                            for (let i = 0; i < newEvidenceFiles.length; i++) {
                                if (newEvidenceFiles[i].name === file.name) {
                                    index = i;
                                    break;
                                }
                            }
                            if (this.props.data) {
                                const evidence_file_to_delete = newEvidenceFiles.splice(index, 1);
                                let current_files_to_delete = this.state.evidence_files_to_delete;
                                current_files_to_delete.push(evidence_file_to_delete[0]);
                                this.setState({ evidence_files: newEvidenceFiles, evidence_files_to_delete: current_files_to_delete });
                            } else {
                                newEvidenceFiles.splice(index, 1);
                                this.setState({ evidence_files: newEvidenceFiles });
                            }
                        }} src='icons/trash.svg' alt='delete' style={{ width: '2em' }} /></div> {imagePreview} </div>);
                    }

                    if (file.type.includes('video')) {
                        fileType = 'Vídeo';
                        // const blob = new Blob([file], { type: file.type });
                        // const video_url = URL.createObjectURL(blob);
                        const videoPreview = <video autoPlay loop controls style={{ width: '41%' }}><source src={file.url} type={file.type} /></video>;
                        fileDescriptions.push(<div><div style={{ display: 'flex', gap: '1em' }}><p key={fileCounter}>{fileType}: {file.name}</p><img onClick={event => {
                            let newEvidenceFiles = [...this.state.evidence_files];
                            let index = 0;
                            for (let i = 0; i < newEvidenceFiles.length; i++) {
                                if (newEvidenceFiles[i].name === file.name) {
                                    index = i;
                                    break;
                                }
                            }
                            if (this.props.data) {
                                const evidence_file_to_delete = newEvidenceFiles.splice(index, 1);
                                let current_files_to_delete = this.state.evidence_files_to_delete;
                                current_files_to_delete.push(evidence_file_to_delete[0]);
                                this.setState({ evidence_files: newEvidenceFiles, evidence_files_to_delete: current_files_to_delete });
                            } else {
                                newEvidenceFiles.splice(index, 1);
                                this.setState({ evidence_files: newEvidenceFiles });
                            }
                        }} src='icons/trash.svg' alt='delete' style={{ width: '2em' }} /></div> {videoPreview} </div>);
                    }
                }
                fileCounter++;
            }
            return fileDescriptions;
        }
    }

    checkFileAlreadyAdded(file: File): boolean {
        for (let i = 0; i < this.state.evidence_files.length; i++) {
            if (this.state.evidence_files[i].name === file.name && this.state.evidence_files[i].size === file.size)
                return true;
            else
                return false;
        }
        return false;
    }

    uploadFile(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files?.length) {
            if (this.props.data) {
                let evidence_files: InimmedFile[] = this.state.evidence_files;
                let evidence_files_to_add: InimmedFile[] = [];
                for (let i = 0; i < event.target.files.length; i++) {
                    if (!this.checkFileAlreadyAdded(event.target.files[i])) {
                        let new_evidence_file: InimmedFile = event.target.files[i];
                        new_evidence_file.url = URL.createObjectURL(new_evidence_file);
                        evidence_files_to_add.push(new_evidence_file);
                        evidence_files.push(new_evidence_file);
                    } else {
                        continue;
                    }
                }
                this.setState({ evidence_files_to_add: evidence_files_to_add, evidence_files: evidence_files, loaded_files: this.state.loaded_files + 1 });
            } else {
                let newEvidenceFiles = [...this.state.evidence_files];
                for (let i = 0; i < event.target.files.length; i++) {
                    if (!this.checkFileAlreadyAdded(event.target.files[i])) {
                        let new_evidence_file: InimmedFile = event.target.files[i];
                        new_evidence_file.url = URL.createObjectURL(new_evidence_file);
                        newEvidenceFiles.push(new_evidence_file);
                    } else {
                        continue;
                    }
                }
                this.setState({ evidence_files: newEvidenceFiles, loaded_files: this.state.loaded_files + 1 });
            }
        }
    }

    submitWorkOrder(event: any) {
        event.preventDefault();
        if (!this.state.client) {
            alert('Por favor elija un cliente.');
            return;
        }
        if (!this.state.device) {
            alert('Por favor elija un equipo.');
            return;
        }
        if (!this.state.service_type) {
            alert('Por favor elija un tipo de servicio.');
            return;
        }
        if (this.state.expected_start && this.state.expected_end) {
            if (this.state.expected_start >= this.state.expected_end) {
                alert('Por favor corrija los campos de fechas.');
                return;
            }
        }
        const url = this.props.data ? 'api/save_work_order/' + this.props.data.id : 'api/save_work_order';
        let auxiliary_engineers_serialized = [];
        for (const auxiliary_engineer of this.state.auxiliary_engineers) {
            auxiliary_engineers_serialized.push(auxiliary_engineer.id);
        }
        let branch: number | null = null;
        if (this.state.service_type === '3' && this.state.branch)
            branch = this.state.branch.id;
        const dataToSend = new FormData();
        dataToSend.append('client', this.state.client.id.toString());
        dataToSend.append('device', this.state.device.id.toString());
        if (this.state.status)
            dataToSend.append('status', this.state.status);
        dataToSend.append('reported_failure', this.state.reported_failure);
        dataToSend.append('who_reported', this.state.who_reported);
        dataToSend.append('contact_method', this.state.contact_method);
        if (this.state.engineer)
            dataToSend.append('head_engineer', this.state.engineer.id.toString());
        if (auxiliary_engineers_serialized.length)
            dataToSend.append('engineers', JSON.stringify(auxiliary_engineers_serialized));
        dataToSend.append('expected_start', this.state.expected_start);
        dataToSend.append('expected_end', this.state.expected_end);
        dataToSend.append('service_type', this.state.service_type);
        if (branch)
            dataToSend.append('branch_for_installation', branch.toString());
        let fileCounter = 0;
        if (this.props.data) {
            if (this.state.evidence_files_to_add) {
                for (const file of this.state.evidence_files_to_add) {
                    dataToSend.append('file' + fileCounter, file);
                    fileCounter++;
                }
            }
            if (this.state.evidence_files_to_delete) {
                let fileIDs_to_delete: number[] = [];
                for (const file_to_delete of this.state.evidence_files_to_delete) {
                    if (file_to_delete.id)
                        fileIDs_to_delete.push(file_to_delete.id);
                }
                dataToSend.append('files_to_delete', JSON.stringify(fileIDs_to_delete));
            }
        } else {
            for (const fileToSend of this.state.evidence_files) {
                dataToSend.append('file' + fileCounter, fileToSend);
                fileCounter++;
            }
        }
        dataToSend.append('created_by', this.props.user.id.toString());

        this.setState({ loading: true });
        inimmedAxios.post(url, dataToSend, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: this.uploadProgressHandler
        })
            .then(response => {
                this.setState({ loading: false });
                if (response.data.Result === 'Success') {
                    if (window.confirm('Orden de trabajo guardada. ¿Salir?')) {
                        if (this.props.data && this.props.getWorkOrders && this.props.getWorkOrder) {
                            this.props.getWorkOrders();
                            this.props.getWorkOrder(this.props.data.id);
                        }
                        this.props.backFunction();
                    }
                } else {
                    if (window.confirm(response.data.Result)) {
                    } else {
                        alert('No se guardó la orden de trabajo.');
                    }
                }
            })
            .catch(reason => {
                this.setState({ loading: false });
                alert(reason);
            });
    }

    uploadProgressHandler(progressEvent: ProgressEvent) {
        if (progressEvent.lengthComputable) {
            this.setState({ upload_percent: progressEvent.loaded / progressEvent.total });
        }
    }

    generateServiceTypeDetails() {
        if (this.state.service_type === '2') {
            return (
                <div className='form-input'>
                    <label>Falla reportada:</label>
                    <TextInput onChange={event => this.setState({ reported_failure: event.target.value })}>{this.state.reported_failure}</TextInput>
                </div>
            );
        }
        if (this.state.service_type === '1') {
            return (
                <div className='form-input'>
                    <label>Detalle de Preventivo:</label>
                    <TextInput onChange={event => this.setState({ reported_failure: event.target.value })}>{this.state.reported_failure}</TextInput>
                </div>
            );
        }
        return null;
    }

    render() {
        let content = (
            <div className='form-container'>
                <h2>Cliente</h2>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Cliente:</label>
                        <div className='form-input-plus'>
                            <ClientSelector selected_client={this.state.client} clients={this.state.response_clients} selected={this.selectClient} />
                            {!this.props.data ? <AddButton onClick={() => { if (this.props.changeForm) this.props.changeForm({ form: 'ClientForm', state: this.state }) }} /> : null}
                        </div>
                    </div>
                </div>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Sucursal:</label>
                        <div className='form-input-plus'>
                            <BranchSelector selected_branch={this.state.branch} branches={this.state.response_branches} selected={this.selectBranch} />
                            {!this.props.data ? <AddButton onClick={() => { if (this.props.changeForm) this.props.changeForm({ form: 'BranchForm', state: this.state }) }} /> : null}
                        </div>
                    </div>
                    <div className='form-input'>
                        <label>Equipo:</label>
                        <div className='form-input-plus'>
                            <DeviceSelector selected_device={this.state.device} devices={this.state.devices} selected={this.selectDevice} />
                            {!this.props.data ? <AddButton onClick={() => { if (this.props.changeForm) this.props.changeForm({ form: 'DeviceForm', state: this.state }) }} /> : null}
                        </div>
                    </div>
                    {this.props.data ?
                        <div className='form-input-div'>
                            <div className='form-input'>
                                <label>Estatus:</label>
                                <select onChange={event => this.setState({ status: event.target.value })}>
                                    {this.handleStatus()}
                                </select>
                            </div>
                        </div> : null}
                </div>
                <div className='top-separator' />
                <h2>Detalles</h2>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Tipo de Servicio:</label>
                        <ServiceTypeRadioMenu active_service_type={this.state.service_type} setActive={this.selectServiceType} service_type_options={['Preventivo', 'Correctivo', 'Instalación', 'Garantía extraordinaria', 'Capacitación']} />
                    </div>
                </div>
                <div className='form-input-div'>
                    {this.generateServiceTypeDetails()}
                    <div className='form-input'>
                        <label>Persona que reportó:</label>
                        <TextInput onChange={event => this.setState({ who_reported: event.target.value })}>{this.state.who_reported}</TextInput>
                    </div>
                    <div className='form-input'>
                        <label>Método de contacto:</label>
                        <select onChange={this.selectContactMethod}>
                            {this.handleContactMethod()}
                        </select>
                    </div>
                </div>
                <div className='top-separator' />
                <h2>Fechas</h2>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Fecha de inicio:</label>
                        <input id='start' type='datetime-local' className='date-time-input' onChange={this.handleDateChange} value={this.state.expected_start} />
                    </div>
                    <div className='form-input'>
                        <label>Fecha de fin:</label>
                        <input id='end' type='datetime-local' className='date-time-input' onChange={this.handleDateChange} value={this.state.expected_end} />
                    </div>
                </div>
                <div className='top-separator' />
                <h2>Ingenieros</h2>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Ingeniero:</label>
                        <EmployeeSelector selected_employee={this.state.engineer} employees={this.state.response_engineers} selected={this.selectHeadEngineer} />
                    </div>
                    <div className='form-input'>
                        <label>Ingenieros Auxiliares:</label>
                        <select multiple onChange={this.selectEngineer} style={{ height: this.mobile ? '' : '6em' }}>
                            {this.handleAuxiliaryEngineers()}
                        </select>
                    </div>
                </div>
                {this.state.evidence_files.length ?
                    <div>
                        <h2>Archivos subidos:</h2>
                        {this.handleEvidenceFiles()}
                    </div>
                    : null
                }
                <div className='top-separator' />
                <div className='button-strip'>
                    <PrimaryButton onClick={event => { if (event) this.submitWorkOrder(event) }}>{this.props.data ? 'Guardar' : 'Registrar'}</PrimaryButton>
                    <input ref={this.fileContainer} type='file' multiple onChange={this.uploadFile} style={{ display: 'none' }} />
                    <PrimaryButton onClick={() => this.fileContainer.current?.click()}>Subir Foto/Vídeo</PrimaryButton>
                </div>
            </div>
        );
        if (!this.state.response_clients.length) {
            content = (<Loading color='primary' />);
        }
        if (this.props.data) {
            if (this.props.data.expected_start && this.props.data.expected_end) {
                if (!this.state.response_engineers.length || !this.state.response_devices.length) {
                    content = (<Loading color='primary' />);
                }
            } else {
                if (!this.state.response_clients.length || !this.state.response_devices.length) {
                    content = (<Loading color='primary' />);
                }
            }
        }
        if (this.state.loading) {
            content = (
                <div style={{ position: 'absolute', width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '11', top: '0', left: '0' }}>
                    <p style={{ position: 'absolute', top: '50%', left: '50%', textAlign: 'center', transform: 'translate(-50%, -50%)', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', padding: '8px 16px', borderRadius: '5px', boxShadow: '0 5px 5px rgba(0 , 0, 0, 0.1)', zIndex: '11' }}>Subiendo orden de trabajo, espere...<br />{(this.state.upload_percent * 100).toFixed(0)}%</p>
                </div>
            )
        }
        return content;
    }
}

export default WorkOrderForm;