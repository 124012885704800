import React from "react";
import { inimmedAxios } from "../../InimmedHelpers";
import PrimaryButton from "../Utility/PrimaryButton";
import DangerButton from "../Utility/DangerButton";
import { toast } from "react-toastify";
import { Brand, DetailComponentProps } from "../../Interfaces";

interface BrandDetailsProps extends DetailComponentProps {
    data: Brand,
}

class BrandDetails extends React.Component<BrandDetailsProps, {}> {
    constructor(props: BrandDetailsProps) {
        super(props);

        this.delete = this.delete.bind(this);
    }

    handleEditButton() {
        if (this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4')
            return <PrimaryButton onClick={() => this.props.editFunction(this.props.data.id)}>Editar</PrimaryButton>;
        return;
    }

    delete() {
        if (window.confirm('¿Seguro que desea borrar la Marca "' + this.props.data.display_name + '"?')) {
            inimmedAxios.post('api/delete_brand/' + this.props.data.id)
            .then(response => {
                if (response.data.Result === 'Success') {
                    toast.success('Marca eliminada. 🗑️');
                    this.props.getCatalog('brands');
                    this.props.goBack();
                } else {
                    alert(response.data.Result);
                }
            })
            .catch(reason => alert(reason));
        }
    }

    render() {
        return (
            <div style={{ boxSizing: 'border-box', padding: '1em', fontSize: '1.25em' }} className='float-from-down'>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1em', flexWrap: 'wrap' }}>
                    <h1> { this.props.data.display_name } </h1>
                    { this.handleEditButton() }
                </div>
                <p><strong>Nombre: </strong><br /> {this.props.data.name}</p>
                {this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                        <div>
                            <DangerButton onClick={this.delete}>Eliminar</DangerButton>
                        </div>
                        :
                        null}

            </div>
        )
    }
}
export default BrandDetails;