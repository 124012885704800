import { AccountFormProps, AccountFormState } from "./Components/Forms/AccountForm"

// CATALOG 
export interface CatalogTableHeader {
    name: string,
    width: string,
    data: string,
    type?: string
}

// FORM PROPS
export interface FormComponentProps {
    getCatalog?: (catalog: string) => void,
    getDetail?: (catalog: string, itemID?: number, replace?: boolean) => void,
    backFunction: () => void,
    changeForm?: (idk: any) => void
}

// DETAIL PROPS
export interface DetailComponentProps {
    getCatalog: (catalog: string) => void,
    goBack: () => void,
    getDetail: (catalog: string, itemID?: number, replace?: boolean) => void,
    changeSectionFunction: (section: number, IDToQueue: number | null, subsection: string | null) => void,
    editFunction: (itemID: number) => void,
    user: Employee
}

// CATALOG PROPS
export interface CatalogProps {
    getDetail: (catalog: string, itemID?: number, replace?: boolean) => void,
    changeSectionFunction: (section: number, IDToQueue: number | null, subsection: string | null) => void,
    user: Employee
}

// MENU OPTIONS

export interface CardProps {
    title: string,
    icon: string,
    onClick: () => void
}

export interface MenuOptions {
    subsection: string,
    cards: CardProps[]
}

// SERIALIZED MODEL INTERFACES

export interface GenericItem {
    display_name: string,
    type: string,
    id: number
}

export interface Zone extends GenericItem {
    engineers: Employee[]
}

export interface State extends GenericItem {
    name: string,
    short_name: string
}

export interface Device extends GenericItem {
    serial: string,
    model: Model | string,
    model_id?: string,
    client: Client | string,
    client_id?: string,
    branch: Branch | string,
    branch_id?: number,
    notes: string,
    policies: Policy[],
    fabrication_date: string,
    guarantee?: Guarantee,
    in_guarantee: boolean,
    guarantee_end?: string;
    in_policy: boolean
    policy_end?: string;
    contract_string: string;
}

export interface Model extends GenericItem {
    name: string,
    model_number: string,
    brand: Brand | string,
    brand_id?: string,
    device_type: DeviceType | string,
    device_type_id: string
}

export interface Brand extends GenericItem {
    name: string,
    num_devices: number,
}

export interface DeviceType extends GenericItem  {
    name: string,
    devices: number,
}

export interface Client extends GenericItem {
    email: string;
    RFC: string;
    name: string;
    first_last: string;
    second_last: string;
    commercial_name: string;
    alias: string;
    social_reason: string;
    fixed_phone: string;
    whatsapp: string;
    contacts?: Contact[];
    devices?: Device[];
    work_orders?: WorkOrder[];
    branches?: Branch[];
    location_url: string;
    state: State;
    county: string;
    district: string;
    street: string;
    number: string;
    postal_code: string;
    zone: number | string;
    in_guarantee: boolean;
    in_policy: boolean;
    policy_end_date: string | null;
    main_contact_id: number | null;
}

export interface Branch extends GenericItem {
    name: string,
    client: Client | string,
    client_id?: string,
    num_devices: number,
    devices: Device[],
    location_url: string,
    state?: string,
    county?: string,
    district?: string,
    street?: string,
    number?: string,
    postal_code?: string,
    zone?: string
}

export interface Guarantee {
    id: string,
    device: string,
    start_date: Date,
    end_date: Date
}

export interface Policy extends GenericItem {
    id: number,
    display_name: string,
    start_date: Date,
    end_date: Date,
    client: string | Client,
    num_devices?: string,
    devices?: Device[]
}

export interface WorkOrder {
    id: number,
    display_name: string,
    status: string,
    number: string,
    client: Client | string,
    client_id?: string,
    device: Device | string,
    device_id?: string,
    date_created: Date,
    who_created: Employee,
    engineer: Employee | string,
    reported_failure: string,
    who_reported: string,
    contact_method: string,
    auxiliary_engineers: Employee[],
    expected_start: Date,
    expected_end: Date,
    service_type: '1' | '2' | '3' | '4' | '5',
    service_orders: ServiceOrder[],
    evidence_files: InimmedFile[],
    branch: Branch
}

export interface ServiceOrder {
    id: number,
    display_name: string,
    client: Client,
    device: Device,
    service_type: string,
    work_order: WorkOrder | string,
    signature_name: string,
    service_start_signature: string,
    status: string,
    number: string,
    engineer: Employee | string,
    auxiliary_engineers: Employee[],
    failure_type: string,
    service_report: string,
    transport_expenses: number,
    labor: number,
    sub_total: number,
    iva: number,
    total: number,
    name_who_accepts: string,
    position_who_accepts: string,
    service_end_signature: string,
    start_date: Date,
    end_date: Date,
    device_guarantee_duration: number,
    parts_used: Part[],
    parts_used_open: string
}

export interface Part extends GenericItem {
    name: string;
    part_number: string;
    model?: Model | string;
    brand?: Brand | string;
    part_type: PartType | string;
    stock: number;
    notes: string;
}

export interface PartType extends GenericItem {
    name: string;
}

export interface Contact extends GenericItem {
    name: string;
    first_last: string;
    second_last: string;
    email: string;
    phone: string;
    position: string;
    client_id: number;
}

export interface Employee extends GenericItem {
    name: string,
    first_last: string,
    second_last: string,
    phone_number: string,
    email: string,
    position: string,
    number_letters: string,
    user: User
}

export interface Film extends GenericItem {
    
}

export interface Notification {
    id: number,
    text: string,
    section: string,
    id_in_section: number
}

export interface InimmedFile extends File {
    id?: number,
    name: string,
    path?: string,
    type: string,
    url?: string
}

export interface User {
    id: string,
    username: string
}

export interface Account extends GenericItem {
    total_amount: string;
    payment_form: string;
    initial_pay_date: string;
    issue_date: string;
    due_date: string;
    payment_months: number;
    month_payday: string;
    concept: string;
    invoice: string;
    status: string;
    currency: 1 | 0;
    currency_change: string | null;
}

export interface SaleAccount extends Account {
    client: Client | string;
    amount_to_collect: number;
    amount_collected: number;
    sale_type: string;
    remmision?: string;
}

export interface PurchaseAccount extends Account {
    supplier: Supplier | string;
    amount_to_pay: number;
    purchase_type: string;
    amount_payed: number;
}

export interface Income {
    id: number;
    display_name: string;
    date_made: Date;
    amount: number;
    account_receiving: OwnBankAccount | string;
    source: Client | string;
    concept: SaleAccount | string;
}

export interface Expense {
    id: number;
    display_name: string;
    date_made: Date;
    amount: number;
    account_giving: OwnBankAccount | string;
    destiny: Supplier | string;
    concept: PurchaseAccount | string;
}

export interface Supplier extends GenericItem {

}

export const enum Currency {
    MXN,
    USD
}

export const PaymentForm = [
    '_',
    'Anticipo y restante contra entrega',
    'Contado en una sola exhibición',
    'Anticipo y mensualidades',
    'Financiado 70% anticipo, 30% contra entrega',
    'Mensualidades',
    '15 días',
    '20 días'
]

export const SaleAccountStatus = [
    '_',
    'Vencida',
    'Próxima',
    'Por Cobrar',
    'Pagada',
]

export const PurchaseAccountStatus = [
    '',
    'Vencida',
    'Próxima',
    'Por pagar',
    'Pagada'
]

export const enum BANK_ACCOUNT_AREA {
    Devices,
    Services,
    Consumable,
    Parts
}

export const MONTH_BUDGET_CONCEPT_AREA = [
    'Equipo',
    'Servicio',
    'Consumible',
    'Refacciones'
]

export const MONTH_BUDGET_CONCEPT_TYPE = [
    'Nóminas',
    'Servicios',
    'Créditos Automovilísticos',
    'Créditos Bancarios',
    'Tarjetas de Crédito',
    'Cuotas Bancarias',
    'Impuestos',
    'Mensajerías',
    'Transporte',
    'Otros Gastos'
]

export type Bank = 'Santander' | 'HSBC' | 'Inbursa' | 'Efectivo';

export interface BankAccount extends GenericItem {
    account_number: string;
    bank: string;
    client: Bank;
}

export interface OwnBankAccount extends BankAccount {
    name: string;
    balance: string;
    area: '1' | '2' | '3' | '4';
}

export interface HistoryItem {
    type: string;
}

export interface BanksHistoryItem extends HistoryItem {
    state?: AccountFormState | AccountFormProps;
    props: any;
    extras?: any;
}

export interface MonthBudgetConcept {
    id: number;
    display_name: string;
    concept: string;
    amount: string;
    paid_amount: string;
    percent_paid: string;
    amount_to_pay: string;
    area: string;
    type: string;
}