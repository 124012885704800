import React from "react";
import { Employee } from "../Interfaces";
import { MonthCalendar, WeekCalendar } from "./Agenda/Calendar";
import { MobileMonthCalendar } from "./Agenda/MobileCalendar";
import WorkOrderForm from "./Forms/WorkOrderForm";
import Section from "./Section";

interface AgendaSectionProps {
    changeSectionFunction: (section: number, IDToQueue?: number | null, subsection?: string | null) => void,
    user: Employee
}

interface AgendaSectionState {
    creating_work_order: boolean,
    currently_selected_date: Date | null,
    week_view_active: boolean
}

class AgendaSection extends React.Component<AgendaSectionProps, AgendaSectionState> {
    mobile: boolean;

    constructor(props: AgendaSectionProps) {
        super(props);

        this.state = {
            creating_work_order: false,
            currently_selected_date: null,
            week_view_active: false
        }

        this.mobile = window.innerWidth > 768 ? false : true;

        this.switchView = this.switchView.bind(this);
    }

    startCreatingWorkOrder() {
        this.setState({ creating_work_order: true });
    }

    switchView() {
        this.setState({ week_view_active: !this.state.week_view_active });
    }

    render() {
        let content: JSX.Element;
        if (this.state.creating_work_order) {
            content = <WorkOrderForm user={ this.props.user } startDate={ this.state.currently_selected_date } backFunction={() => this.setState({ creating_work_order: false, currently_selected_date: null })} />;
        } else {
            if (this.state.week_view_active) {
                content = <WeekCalendar activeDay={ this.state.currently_selected_date } setActiveDay={active_day => this.setState({ currently_selected_date: active_day })} changeSectionFunction={this.props.changeSectionFunction} createWorkOrderFunction={() => this.startCreatingWorkOrder() } user={ this.props.user } />;
            } else {
                if (this.mobile) {
                    content = <MobileMonthCalendar createWorkOrderFunction={() => this.startCreatingWorkOrder()} changeSectionFunction={ this.props.changeSectionFunction } activeDay={ this.state.currently_selected_date } setActiveDay={active_day => this.setState({ currently_selected_date: active_day })} user={this.props.user} />
                } else {
                    content = <MonthCalendar activeDay={ this.state.currently_selected_date } setActiveDay={active_day => this.setState({ currently_selected_date: active_day })} changeSectionFunction={this.props.changeSectionFunction} createWorkOrderFunction={() => this.startCreatingWorkOrder() } user={ this.props.user } />;
                }
            }
        }
        const active_switch_style: React.CSSProperties = {
            margin: '0',
            padding: '0.5em',
            color: 'var(--on-primary-color)',
            cursor: 'pointer'
        }
        const inactive_switch_style: React.CSSProperties = {
            margin: '0',
            padding: '0.5em',
            backgroundColor: 'var(--on-primary-color)',
            cursor: 'pointer'
        }
        return (
            <Section onClick={() => this.setState({ creating_work_order: false, currently_selected_date: null })} title={ this.state.creating_work_order ? 'Agenda / Nueva Orden de Trabajo' : 'Agenda' } backButton={ this.state.creating_work_order ? true : false }>
                { !this.state.creating_work_order && !this.mobile ? <div onClick={ this.switchView } style={{ display: 'flex', backgroundColor: 'var(--primary-color)', marginTop: '1em', borderRadius: '10px', position: 'fixed', left: '86%', zIndex: '5', outline: 'solid 2px var(--primary-color)' }}>
                    <p style={ this.state.week_view_active ? inactive_switch_style : active_switch_style }>Mes</p>
                    <p style={ !this.state.week_view_active ? inactive_switch_style : active_switch_style }>Semana</p>
                </div> : null }
                { content }
            </Section>
        )
    }
}

export default AgendaSection;