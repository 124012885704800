import React from "react";
import { inimmedAxios } from "../../InimmedHelpers";
import WorkOrdersCatalog from "../Catalogs/WorkOrdersCatalog";
import { Client, DetailComponentProps } from "../../Interfaces";
import { ContactStrip } from "../Utility/Contacts";
import { ClientDetailCard } from "./DetailCards/ClientDetailCard";
import { TabSelector } from "../Utility/TabSelector";
import ClientlessDevicesCatalog from "../Catalogs/ClientlessDevicesCatalog";

interface ClientDetailsProps extends DetailComponentProps {
    goToWorkOrder: (id: number) => void,
    data: Client
}

interface ClientDetailsState {
    current_tab: string;
}

class ClientDetails extends React.Component<ClientDetailsProps, ClientDetailsState> {
    mobile: boolean;
    detail_tabs: string[];

    constructor(props: ClientDetailsProps) {
        super(props);

        this.mobile = window.innerWidth > 768 ? false : true;
        this.detail_tabs = ['Equipos', 'Contactos', 'Servicios', 'Extras'];
        this.state = {
            current_tab: this.detail_tabs[0]
        }

        this.deleteContact = this.deleteContact.bind(this);
    }

    deleteContact(event: any) {
        if (window.confirm('¿Seguro que desea eliminar el contacto ?')) {
            inimmedAxios.post('api/delete_contact/' + event.target.parentElement.id)
                .then(response => {
                    if (response.data.Result === 'Success') {
                        alert('El contacto fue eliminado correctamente.');
                        this.props.getDetail('clients', this.props.data.id, true);
                    } else {
                        alert(response.data.Result);
                    }
                })
                .catch(reason => alert(reason));
        }
    }

    getTabContent() {
        switch ( this.state.current_tab ) {
            case this.detail_tabs[0]:
                if (this.props.data.devices?.length) {
                    return <ClientlessDevicesCatalog changeSectionFunction={this.props.changeSectionFunction} data={ this.props.data.devices ? this.props.data.devices : [] } getDetail={this.props.getDetail} user={this.props.user} />;
                } else {
                    return <p className='float-from-down' style={{ color: 'rgba(0, 0, 0, 0.5)', margin: '0', padding: '20px', textAlign: 'center' }}>Sin Equipos</p>
                }
            case this.detail_tabs[1]:
                if (this.props.data.contacts?.length) {
                    return <ContactStrip contacts={ this.props.data.contacts ? this.props.data.contacts : [] } main_contact_id={this.props.data.main_contact_id || undefined} getDetail={this.props.getDetail} />;
                } else {
                    return <p className='float-from-down' style={{ color: 'rgba(0, 0, 0, 0.5)', margin: '0', padding: '20px', textAlign: 'center' }}>Sin Contactos</p>
                }
            case this.detail_tabs[2]:
                if (this.props.data.work_orders?.length) {
                    return <WorkOrdersCatalog changeSectionFunction={() => null} getDetail={(itemID: number) => {if (itemID) this.props.goToWorkOrder(itemID)}} user={this.props.user} data={ this.props.data.work_orders ? this.props.data.work_orders : [] } />;
                } else {
                    return <p className='float-from-down' style={{ color: 'rgba(0, 0, 0, 0.5)', margin: '0', padding: '20px', textAlign: 'center' }}>Sin Servicios</p>
                }
            default:
                return <ClientlessDevicesCatalog changeSectionFunction={this.props.changeSectionFunction} data={ this.props.data.devices ? this.props.data.devices : [] } getDetail={this.props.getDetail} user={this.props.user} />;
        }
    }

    render() {
        return (
            <div style={{ padding: this.mobile ? '10px' : '1em', fontSize: '1.25em', overflow: 'hidden' }} className='float-from-down'>
                <ClientDetailCard goToBranch={ this.props.getDetail } data={ this.props.data } goBack={ this.props.goBack } getCatalog={ this.props.getCatalog } editFunction={ this.props.editFunction } user={ this.props.user } />
                <div className='top-separator' />
                <TabSelector active_tab={ this.state.current_tab } tab_names={ this.detail_tabs } setTab={tab => this.setState({ current_tab: tab })} />
                { this.getTabContent() }
            </div>
        )
    }
}

export default ClientDetails;