import React from "react";
import { inimmedAxios } from "../InimmedHelpers";
import { Employee, ServiceOrder, WorkOrder } from "../Interfaces";
import WorkOrdersCatalog from "./Catalogs/WorkOrdersCatalog";
import ServiceOrderDetails from "./Details/ServiceOrderDetails";
import WorkOrderDetails from "./Details/WorkOrderDetails";
import ServiceOrderForm from "./Forms/ServiceOrderForm";
import WorkOrderForm from "./Forms/WorkOrderForm";
import ServiceReports from "./Reports/ServiceReports";
import Section from "./Section";
import ServiceHistory from "./Reports/ServiceHistory";

interface ServicesSectionProps {
    changeSectionFunction: (section: number, IDToQueue?: number | null, subsection?: string | null) => void,
    popQueuedSection: () => void,
    popQueuedItem: () => void,
    on_queue?: number,
    user: Employee
}

interface ServicesSectionState {
    response_work_orders: WorkOrder[],
    work_order_detail: WorkOrder | null,
    service_order: ServiceOrder | null,
    editing: boolean,
    on_queue: any | null
}

class ServicesSection extends React.Component<ServicesSectionProps, ServicesSectionState> {
    mobile: boolean;
    constructor(props: ServicesSectionProps) {
        super(props)
        this.state = {
            response_work_orders: [],
            work_order_detail: null,
            service_order: null,
            editing: false,
            on_queue: null,
        }

        document.title = 'Inimmed - Servicios';

        this.mobile = window.innerWidth > 768 ? false : true;

        this.getWorkOrder = this.getWorkOrder.bind(this);
        this.startEditing = this.startEditing.bind(this);
        this.getWorkOrders = this.getWorkOrders.bind(this);
        this.getServiceOrder = this.getServiceOrder.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        this.getWorkOrders();
        if (this.props.on_queue) {
            this.props.popQueuedSection();
            this.props.popQueuedItem();
            this.getWorkOrder(this.props.on_queue);
        }

    }

    componentDidUpdate() {
        if (this.props.on_queue && !this.state.on_queue) {
            this.getWorkOrders();
            this.getWorkOrder(this.props.on_queue);
            this.setState({on_queue: this.props.on_queue});
        }
    }

    startEditing() {
        this.setState({ editing: true });
    }

    getWorkOrders() {
        inimmedAxios.get('api/work_orders_of/' + this.props.user.id)
            .then(response => {
                let new_work_orders: WorkOrder[] = [];
                for (const workOrder of response.data)
                    new_work_orders.push(workOrder);
                this.setState(previousState => ({ response_work_orders: new_work_orders }));
            })
            .catch(reason => {
                alert(reason);
            });
    }

    getServiceOrder(itemID: number) {
        inimmedAxios.get('api/service_orders/' + itemID.toString())
            .then(response => {
                this.setState({ service_order: response.data })
            })
            .catch(reason => alert(reason));
    }

    goBack() {
        this.getWorkOrders();
        if (this.state.editing) {
            if (this.state.service_order)
                this.getServiceOrder(this.state.service_order.id);
            if (this.state.work_order_detail)
                this.getWorkOrder(this.state.work_order_detail.id);
            this.setState({ editing: false })
        } else if (this.state.service_order) {
            if (this.state.work_order_detail)
                this.getWorkOrder(this.state.work_order_detail?.id);
            this.setState({ service_order: null });
            return;
        } else if (this.state.work_order_detail) {
            this.setState({ work_order_detail: null });
        }
    }

    getWorkOrder(itemID: number) {
        inimmedAxios.get('api/work_orders/' + itemID.toString())
        .then(response => {
            this.setState({ work_order_detail: response.data });
        })
        .catch(reason => {
            alert(reason);
        });
    }

    render() {
        let content;
        if (this.state.service_order) {
            if (!this.state.editing) {
                content = <ServiceOrderDetails data={this.state.service_order} goBack={this.goBack} user={this.props.user} startEditing={this.startEditing} getWorkOrders={this.getWorkOrders} getWorkOrder={this.getWorkOrder} />
            } else {
                content = <ServiceOrderForm data={this.state.service_order} user={this.props.user} startEditing={this.startEditing} goBack={this.goBack} />;
            }
        }
        else if (this.state.work_order_detail) {
            if (!this.state.editing) {
                content = <WorkOrderDetails getWorkOrders={this.getWorkOrders} goBack={this.goBack} data={this.state.work_order_detail} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} getDetail={this.getServiceOrder} startEditing={this.startEditing} />;
            } else {
                content = <WorkOrderForm user={this.props.user} backFunction={this.goBack} data={this.state.work_order_detail} getWorkOrders={this.getWorkOrders} getWorkOrder={this.getWorkOrder} />;
            }
        }
        else if (!this.state.work_order_detail && !this.state.service_order) {
            content = (
                <>
                    <WorkOrdersCatalog getDetail={this.getWorkOrder} data={this.state.response_work_orders} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} />
                    <ServiceHistory getDetail={this.getWorkOrder} changeSectionFunction={this.props.changeSectionFunction} user={this.props.user} />
                    <ServiceReports />
                </>
            );
        }
        return (
            <Section backButton={this.state.work_order_detail ? true : false} title={'Servicios'} onClick={this.goBack}>
                <div style={{ padding: this.mobile ? '10px' : '1em' }}>
                    { content }
                </div>
            </Section>
        );
    }
}
export default ServicesSection;