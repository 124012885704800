import { MonthBudgetConcept } from "../../../Interfaces";
import ItemSelector from "./ItemSelector"

interface MonthBudgetConceptSelectorProps {
    selected: (month_budget_concept: any) => void;
    month_budget_concepts: MonthBudgetConcept[];
    selected_month_budget_concept: MonthBudgetConcept | null;
}

const MonthBudgetConceptSelector = (props: MonthBudgetConceptSelectorProps) => {
    return (
        <ItemSelector selected_item={props.selected_month_budget_concept} items={props.month_budget_concepts} selected={ props.selected } />
    )
}

export default MonthBudgetConceptSelector;