import { CatalogProps, Client } from "../../Interfaces"
import Catalog from "./Catalog"

interface MobileClientsCatalogProps extends CatalogProps {
    reload: (catalog: string) => void
    data: Client[]
}

export function MobileClientsCatalog(props: MobileClientsCatalogProps) {
    const newButtonPrompt: string = props.user.position === '1' || props.user.position === '2' || props.user.position === '3' || props.user.position === '4' ? 'Nuevo Cliente' : '';
    return (
        <Catalog
        data={ props.data }
        headersForTable={[{name: 'Cliente', width: '50%', data: 'display_name'}, {name: 'RFC', width: '50%', data: 'RFC'}]}
        dataHeaders={['display_name', 'RFC']}
        searchHeaders={['display_name', 'RFC', 'email', 'alias']}
        objectType='Clientes'
        getDetail={id => props.getDetail('clients', id)}
        newButtonPrompt={ newButtonPrompt }
        newButtonClick={() => props.changeSectionFunction(3, null, 'ClientForm')}
        searchPlaceholder='Buscar clientes...'
        itemsPerPage={10} />
    )
}