import React from "react";
import { toast } from "react-toastify";
import { inimmedAxios } from "../../InimmedHelpers";
import { OwnBankAccount, SaleAccount } from "../../Interfaces";
import Loading from "../Utility/Loading";
import MoneyInput from "../Utility/MoneyInput";
import PrimaryButton from "../Utility/PrimaryButton";
import AccountSelector from "../Utility/Selectors/AccountSelector";
import OwnBankAccountSelector from "../Utility/Selectors/OwnBankAccountSelector";
import { TabSelector } from "../Utility/TabSelector";

interface IncomeFormProps {
    goBackAndRefresh: () => void;
    initial_account?: SaleAccount;
}

interface IncomeFormState {
    amount: string;
    currency: number;
    response_bank_accounts: OwnBankAccount[] | null;
    bank_account: OwnBankAccount | null;
    date_received: string;
    response_accounts: SaleAccount[] | null;
    concept: SaleAccount | null;
    concept_detail: string;
    uploading: boolean;
    current_tab: string;
    area: number;
}

class IncomeForm extends React.Component<IncomeFormProps, IncomeFormState> {
    tabs: string[];

    constructor(props: IncomeFormProps) {
        super(props);

        const today = new Date();

        this.tabs = ['A Cuenta por Cobrar', 'Ingreso Único'];

        this.state = {
            amount: '0.00',
            currency: 0,
            response_bank_accounts: null,
            bank_account: null,
            date_received: new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().substring(0, 19),
            response_accounts: null,
            concept: null,
            concept_detail: '',
            uploading: false,
            area: 1,
            current_tab: this.tabs[0]
        }

        this.submitIncome = this.submitIncome.bind(this);
    }

    componentDidMount() {
        const initial_state = this.state as IncomeFormState;
        const promises: Promise<void>[] = [];

        promises.push(
            inimmedAxios.get('api_banks/get_sale_accounts')
            .then(response => {
                initial_state.response_accounts = response.data;
            })
        );

        promises.push(
            inimmedAxios.get('api_banks/get_own_bank_accounts')
            .then(response => {
                initial_state.response_bank_accounts = response.data;
            })
        )
        
        if (this.props.initial_account) {
            initial_state.concept = this.props.initial_account;
        }

        Promise.all(promises)
        .then(() => {
            this.setState(initial_state);
        })
        .catch(reason => alert(reason));
    }

    submitIncome() {
        let total_amount;
        if (!this.state.amount.replaceAll(',', '').match(/^\d+.\d*$/)) {
            alert('Por favor ingrese un valor válido para el monto del ingreso.');
            return;
        } else {
            total_amount = Number.parseFloat(this.state.amount);
            if (!total_amount) {
                alert('Por favor ingrese un valor para el monto del ingreso.');
                return;
            }
        }
        if (!this.state.bank_account) {
            alert('Por favor elija la cuenta bancaria a donde se recibió el ingreso.');
            return;
        }
        if (!this.state.date_received) {
            alert('Por favor ingrese la fecha en que se efectuó el ingreso.');
            return;
        }
        if (!this.state.concept && !this.state.concept_detail) {
            alert('Por favor ingrese al menos la cuenta correspondiente del ingreso o detalle el motivo del ingreso.');
            return;
        }

        this.setState({ uploading: true });

        let area = null;
        if (this.state.current_tab === 'Ingreso Único') {
            area = this.state.area;
        } else if (this.state.concept !== null) {
            area = Number.parseInt(this.state.concept.sale_type);
        }

        inimmedAxios.post('api_banks/save_income', {
            amount: this.state.amount.replaceAll(',', ''),
            currency: this.state.currency,
            bank_account: this.state.bank_account.id,
            date_received: this.state.date_received,
            concept: this.state.concept ? this.state.concept.id : null,
            concept_detail: this.state.concept_detail ? this.state.concept_detail : null,
            area: area
        })
        .then(response => {
            if (response.data.Result !== 'Ok') {
                this.setState({ uploading: false });
                alert(response.data.Result);
            } else {
                this.setState({ uploading: false });
                toast.success('Ingreso registrado con éxito.');
                this.props.goBackAndRefresh();
            }
        })
        .catch(reason => alert(reason));
    }

    render(): React.ReactNode {

        if (!this.state.uploading) {
            if (this.state.response_accounts !== null && this.state.response_bank_accounts !== null) {
                return(
                    <div className='form-container'>
                        <h2>Detalles</h2>
                        <div className='form-input-div'>
                            <div className='form-input'>
                                <label>Monto:</label>
                                <MoneyInput currency={this.state.currency} setCurrency={event => this.setState({ currency: Number.parseInt(event.target.value) })} setValue={value => this.setState({ amount: value })}>{ this.state.amount }</MoneyInput>
                            </div>
                            <div className='form-input'>
                                <label>Cuenta Bancaria:</label>
                                <OwnBankAccountSelector own_bank_accounts={ this.state.response_bank_accounts !== null ? this.state.response_bank_accounts : [] } selected_own_bank_account={ this.state.bank_account } selected={own_bank_account => this.setState({ bank_account: own_bank_account })} />
                            </div>
                            <div className='form-input'>
                                <label>Fecha y hora efectuado:</label>
                                <input defaultValue={this.state.date_received} onChange={event => this.setState({ date_received: event.target.value })} className='form-text-input' type={'datetime-local'} />
                            </div>
                        </div>
                        <TabSelector active_tab={this.state.current_tab} setTab={tab => this.setState({ current_tab: tab })} tab_names={this.tabs} />
                        {
                            this.state.current_tab === 'Ingreso Único' ?
                            <div className='form-input-div'>
                                <div className='form-input'>
                                    <label>Detalle de Concepto</label>
                                    <textarea onChange={event => this.setState({ concept_detail: event.target.value }) } value={ this.state.concept_detail } rows={5} />
                                </div>
                                <div className='form-input'>
                                    <label>Área:</label>
                                    <select defaultValue={this.state.area} onChange={event => this.setState({ area: Number.parseInt(event.target.value) })}>
                                        <option value={1}>Equipo</option>
                                        <option value={2}>Refacción</option>
                                        <option value={3}>Servicio</option>
                                        <option value={4}>Consumible</option>
                                    </select>
                                </div>
                            </div>
                            :
                            <div className='form-input-div'>
                                <div className='form-input'>
                                    <label>Concepto</label>
                                    <AccountSelector accounts={ this.state.response_accounts ? this.state.response_accounts : [] } selected_account={ this.state.concept } selected={account => this.setState({ concept: account })} />
                                </div>
                            </div>
                        }
                        <div className='top-separator' />
                        <div className='button-strip'>
                            <PrimaryButton onClick={ this.submitIncome }>Registrar</PrimaryButton>
                        </div>
                    </div>
                );
            } else {
                return (
                    <Loading color='primary' />
                );
            }
        } else {
            return (
                <Loading color='primary' />
            );
        }
    }
}

export default IncomeForm;