import { inimmedAxios } from "../../InimmedHelpers";
import React from "react";
import TextInput from "../Utility/TextInput";
import AddButton from "../Utility/AddButton";
import PrimaryButton from "../Utility/PrimaryButton";
import { Client, Model, Branch, Brand, DeviceType, Device, FormComponentProps } from "../../Interfaces";
import Loading from "../Utility/Loading";
import ClientSelector from "../Utility/Selectors/ClientSelector";
import BranchSelector from "../Utility/Selectors/BranchSelector";
import BrandSelector from "../Utility/Selectors/BrandSelector";
import DeviceTypeSelector from "../Utility/Selectors/DeviceTypeSelector";
import ModelSelector from "../Utility/Selectors/ModelSelector";

interface DeviceFormProps extends FormComponentProps {
    data?: Device,
    savedInputs?: DeviceFormState
}

interface DeviceFormState {
    // DEVICE PROPERTIES
    serial: string,
    model: Model | null,
    client: Client | null,
    branch: Branch | null,
    notes: string,
    fabrication_date: Date | null,

    // FORM OPTIONS
    response_clients: Client[],
    response_branches: Branch[],
    response_brands: Brand[],
    response_device_types: DeviceType[],
    response_models: Model[]

    // CURRENTLY SELECTED OPTIONS
    selected_branch: Branch | null,
    selected_brand: Brand | null,
    selected_device_type: DeviceType | null
}

class DeviceForm extends React.Component<DeviceFormProps, DeviceFormState> {
    constructor(props: DeviceFormProps) {
        super(props);
        this.state = {
            response_clients: [],
            client: null,
            branch: null,
            serial: '',
            model: null,
            fabrication_date: null,
            notes: '',
            response_branches: [],
            selected_branch: null,
            response_brands: [],
            selected_brand: null,
            response_device_types: [],
            selected_device_type: null,
            response_models: [],
        }

        this.handleFabricationDateChange = this.handleFabricationDateChange.bind(this);

        this.selectBrand = this.selectBrand.bind(this);
        this.selectDeviceType = this.selectDeviceType.bind(this);
        this.submitDevice = this.submitDevice.bind(this);
        this.selectClient = this.selectClient.bind(this);
    }

    componentDidMount() {
        let starting_state: DeviceFormState = {} as DeviceFormState;
        const promises: Promise<void>[] = [];

        if (this.props.savedInputs) {
            starting_state = this.props.savedInputs;
        }

        promises.push(inimmedAxios.get('api/get_brands_and_types')
        .then(response => {
            if (this.props.data && typeof this.props.data.model !== 'string') {
                for (const brand of response.data.response_brands) {
                    if (brand.display_name === this.props.data.model?.brand) {
                        starting_state.selected_brand = brand;
                        break;
                    }
                }
                for (const device_type of response.data.response_device_types) {
                    if (device_type.display_name === this.props.data.model?.device_type) {
                        starting_state.selected_device_type = device_type;
                        break;
                    }
                }
            }
            starting_state.response_brands = response.data.response_brands;
            starting_state.response_device_types = response.data.response_device_types;
        }));

        promises.push(inimmedAxios.get('api/clients')
        .then(response => {
            if (this.props.data && typeof this.props.data.client !== 'string' && this.props.data.client) {
                for (const client of response.data) {
                    if (client.display_name === this.props.data.client.display_name) {
                        starting_state.client = client;
                    }
                }
            }
            starting_state.response_clients = response.data;
        }));

        if (this.props.data) {
            if (typeof this.props.data.client !== 'string' && this.props.data.client) {
                promises.push(inimmedAxios.get('api/get_branches_of/' + this.props.data.client.id)
                .then(response => {
                    if (this.props.data && this.props.data.branch && typeof this.props.data.branch !== 'string') {
                        for (const branch of response.data) {
                            if (branch.display_name === this.props.data.branch.display_name) {
                                starting_state.selected_branch = branch;
                            }
                        }
                    }
                    starting_state.response_branches = response.data;
                }));
            }
            starting_state.branch = this.props.data.branch as Branch;
            starting_state.serial = this.props.data.serial;
            starting_state.fabrication_date = this.props.data.fabrication_date ? new Date(this.props.data.fabrication_date) : null;
            starting_state.notes = this.props.data.notes;
            starting_state.model = this.props.data.model as Model;
        }

        Promise.all(promises)
        .then(() => this.setState(starting_state))
        .catch(reason => alert(reason));
    }

    selectClient(client: Client) {
        if (client) {
            inimmedAxios.get('api/get_branches_of/' + client.id)
            .then(response => {
                this.setState({ client: client, response_branches: response.data });
            })
            .catch(reason => alert(reason));
        } else {
            this.setState({ client: null, response_branches: [], branch: null });
        }
    }

    selectBrand(brand: Brand) {
        if (!brand) {
            inimmedAxios.get('api/get_models', { 
                params: {
                    selected_device_type: this.state.selected_device_type ? this.state.selected_device_type.id : '0',
                    selected_brand: '0'
                }
            })
            .then(response => {
                this.setState({ response_models: response.data.response_models, response_brands: response.data.response_brands, response_device_types: response.data.response_device_types, model: null, selected_brand: null });
            })
            .catch(reason => alert(reason));
        } else {
            inimmedAxios.get('api/get_models', {
                params: {
                    selected_brand: brand.id,
                    selected_device_type: this.state.selected_device_type ? this.state.selected_device_type.id : '0'
                }
            })
            .then(response => {
                this.setState({ response_models: response.data.response_models, response_device_types: response.data.response_device_types, model: null, selected_brand: brand });
            })
            .catch(reason => {
                alert(reason);
            });
        }
    }

    selectDeviceType(device_type: DeviceType) {
        if (!device_type) {
            inimmedAxios.get('api/get_models', { 
                params: {
                    selected_brand: this.state.selected_brand ? this.state.selected_brand.id : '0',
                    selected_device_type: '0'
                }
            })
            .then(response => {
                this.setState({ response_models: response.data.response_models, response_brands: response.data.response_brands, response_device_types: response.data.response_device_types, model: null, selected_device_type: null });
            })
            .catch(reason => alert(reason));
        } else {
            inimmedAxios.get('api/get_models', {
                params: {
                    selected_brand: this.state.selected_brand ? this.state.selected_brand.id : '0',
                    selected_device_type: device_type.id
                }
            })
            .then(response => {
                this.setState({ response_models: response.data.response_models, response_brands: response.data.response_brands, model: null, selected_device_type: device_type });
            })
            .catch(reason => {
                alert(reason);
            });
        }
    }

    handleFabricationDateChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.value) {
            this.setState({fabrication_date: null});
        }
        this.setState({fabrication_date: new Date(event.target.valueAsNumber)});
    }

    submitDevice() {
        if (this.state.client) {
            if (this.state.response_branches.length && !this.state.branch) {
                alert('Si eligió un cliente que cuenta con sucursales, debe elegir una sucursal.');
                return;
            }
        }
        if (!this.state.serial) {
            alert('Por favor introduzca un número de serie para el nuevo equipo.');
            return;
        }
        if (!this.state.model) {
            alert('Por favor elija un modelo para el nuevo equipo.')
            return;
        }
        let clientToSend;
        let branchToSend;
        if (this.state.client)
            clientToSend = this.state.client.id;
        else
            clientToSend = null;
        if (this.state.branch)
            branchToSend = this.state.branch.id;
        else
            branchToSend = null;
        const url = this.props.data ? 'api/save_device/' + this.props.data.id : 'api/save_device';
        inimmedAxios.post(url, {
            client: clientToSend,
            branch: branchToSend,
            serial: this.state.serial,
            notes: this.state.notes,
            model: this.state.model.id,
            fabrication_date: this.state.fabrication_date ? this.state.fabrication_date : null
        })
        .then(response => {
            if (response.data.Result === 'Success') {
                if (window.confirm('Equipo guardado. ¿Salir?')) {
                    if (this.props.data && this.props.getCatalog && this.props.getDetail) {
                        this.props.getCatalog('devices');
                        this.props.getDetail('devices', this.props.data.id, true);
                    }
                    this.props.backFunction();
                }
            } else {
                alert(response.data.Result);
            }
        })
        .catch(reason => alert(reason));
    }

    render() {
        let fabrication_date_value: string = '';
        if (this.state.fabrication_date) {
            fabrication_date_value = this.state.fabrication_date ? this.state.fabrication_date.toISOString().slice(0, 10) : '';
        }
        if (this.state.response_brands.length && this.state.response_device_types.length && this.state.response_clients.length) {
            return (
                <div className='form-container'>
                    <h2>Cliente</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Cliente:</label>
                            <div className='form-input-plus'>
                                <ClientSelector selected_client={ this.state.client } clients={ this.state.response_clients } selected={ this.selectClient } />
                                { !this.props.data ? <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'ClientForm', state: this.state})}} /> : null }
                            </div>
                        </div>
                        <div className='form-input'>
                            <label>Sucursal:</label>
                            <div className='form-input-plus'>
                                <BranchSelector selected_branch={ this.state.branch } branches={ this.state.response_branches }  selected={(branch: Branch) => this.setState({ branch: branch })} />
                                { !this.props.data ? <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'BranchForm', state: this.state})}} /> : null }
                            </div>
                        </div>
                    </div>
                    <div className='top-separator'></div>
                    <h2>Detalles</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Número de Serie:</label>
                            <TextInput onChange={event => this.setState({ serial: event.target.value })}>{this.state.serial}</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Fecha de fabricación:</label>
                            <input type='date' className='date-time-input' onChange={this.handleFabricationDateChange} value={fabrication_date_value} />
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Notas:</label>
                            <textarea onChange={event => this.setState({ notes: event.target.value })} value={ this.state.notes }></textarea>
                        </div>
                    </div>
                    <div className='top-separator'></div>
                    <h2>Modelo</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Marca:</label>
                            <div className='form-input-plus'>
                                <BrandSelector selected_brand={ this.state.selected_brand } brands={ this.state.response_brands } selected={ this.selectBrand } />
                                { !this.props.data ? <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'BrandForm', state: this.state})}} /> : null }
                            </div>
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Tipo de Equipo:</label>
                            <div className='form-input-plus'>
                                <DeviceTypeSelector selected_device_type={ this.state.selected_device_type } device_types={ this.state.response_device_types } selected={ this.selectDeviceType } />
                                { !this.props.data ? <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'DeviceTypeForm', state: this.state})}} /> : null }
                            </div>
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Modelo:</label>
                            <div className='form-input-plus'>
                                <ModelSelector selected_model={ this.state.model } models={ this.state.response_models } selected={model => this.setState({ model: model })} />
                                { !this.props.data ? <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'ModelForm', state: this.state})}} /> : null }
                            </div>
                        </div>
                    </div>
                    <div className='top-separator'></div>
                    <div className='button-strip'>
                        <PrimaryButton onClick={this.submitDevice}>{ this.props.data ? 'Guardar' : 'Registrar'}</PrimaryButton>
                    </div>
                </div>
            )
        } else {
            return <Loading color='primary' />;
        }
    }
}
export default DeviceForm;