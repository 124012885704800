interface RadioMenuProps {
    setActive: (option: string) => void,
    options: string[],
    active: string
}

export function RadioMenu(props: RadioMenuProps) {
    const options_to_list: JSX.Element[] = [];
    for (const option of props.options) {
        if (option === props.active) {
            options_to_list.push(<p key={options_to_list.length} style={{ padding: '10px 20px 10px 20px', width: 'fit-content', borderRadius: '10px', outline: 'solid 1px rgba(0, 0, 0, 0.1)', color: 'var(--on-primary-color)', backgroundColor: 'var(--primary-color)', cursor: 'pointer', boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.1)' }}>{ option }</p>);
        } else {
            options_to_list.push(<p onClick={() => props.setActive(option) } key={options_to_list.length} style={{ padding: '10px 20px 10px 20px', width: 'fit-content', borderRadius: '10px', outline: 'solid 1px rgba(0, 0, 0, 0.1)', color: 'rgba(0, 0, 0, 0.5)', backgroundColor: 'white', cursor: 'pointer', boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.1)' }}>{ option }</p>);
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', userSelect: 'none' }}>
            { options_to_list }
        </div>
    );
}