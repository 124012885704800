import { useState } from "react";
import { toast } from "react-toastify";
import { inimmedAxios } from "../../../InimmedHelpers";
import { Client, Employee } from "../../../Interfaces";
import OptionButton from "../../Utility/OptionButton";

interface ClientDetailCardProps {
    goBack: () => void;
    getCatalog: (catalog: string) => void;
    editFunction: (itemID: number) => void;
    goToBranch: (catalog: string, itemID?: number, replace?: boolean) => void;
    data: Client;
    user: Employee;
}

export function ClientDetailCard(props: ClientDetailCardProps) {
    const mobile: Boolean = window.innerWidth > 768 ? false : true;

    const [expanded, expand] = useState(false);

    const buildContract = () => {

        let contract_string = '';
        if (props.data.policy_end_date) {
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            let policy_end_date_date = new Date(props.data.policy_end_date);
            if (policy_end_date_date.getTime() < today.getTime()) {
                contract_string += 'La última póliza venció en ' + props.data.policy_end_date;
            } 
            if (props.data.in_policy) {
                contract_string += 'En póliza hasta ' + props.data.policy_end_date;
            } 
        } else {
            contract_string += 'Sin pólizas'
        }

        contract_string += props.data.in_guarantee ? ', con equipos en garantía' : ', sin equipos en garantía'
        return contract_string;
        
    }

    const buildAddress = () => {
        let address_string: string = '';
        if (props.data.street)
            address_string += props.data.street;
        else
            address_string += 'Sin calle registrada';
        if (props.data.number)
            address_string += ' ' + props.data.number;
        else
            address_string += ' S/N';
        if (props.data.district)
            address_string += ', ' + props.data.district;
        if (props.data.county)
            address_string += ', ' + props.data.county;
        if (props.data.postal_code)
            address_string += ' ' + props.data.postal_code;
        if (props.data.state)
            address_string += ', ' + props.data.state.name;
        return address_string;
    }

    const delete_client = () => {
        if (window.confirm('¿Seguro que desea borrar el cliente "' + props.data.display_name + '"?')) {
            inimmedAxios.post('api/delete_client/' + props.data.id)
                .then(response => {
                    if (response.data.Result === 'Success') {
                        toast.success('Cliente eliminado. 🗑️');
                        props.getCatalog('clients');
                        props.goBack();
                    }
                    else
                        alert(response.data.Result);
                })
                .catch(reason => {
                    alert(reason);
                });
        }
    }

    const generate_branch_tabs = () => {
        const tabs: JSX.Element[] = [];
        if (props.data.branches) {
            for (const branch of props.data.branches) {
                tabs.push(
                    <p onClick={() => props.goToBranch('branches', branch.id)} style={{ width: 'fit-content', padding: '10px 5px', margin: '0', flex: '1 0 auto', textAlign: 'center', backgroundColor: 'var(--primary-light-color)', borderRadius: '10px', cursor: 'pointer' }}>{ branch.display_name }</p>
                );
            }
        }
        return tabs;
    }

    const openGoogleURL = () => {
        if (props.data.location_url) {
            window.open(props.data.location_url);
        } else {
            if (props.data.branches?.length) {
                alert('Este cliente tiene sucursales, navegue a la sucursal deseada en la parte superior de la pantalla e intente de nuevo.');
            } else {
                alert('No existe ubicación para este cliente, pero puede agregarla editandolo.');
            }
        }
    }

    const getMainContactInfo = () => {
        if (props.data.contacts) {
            const mainContact = props.data.contacts.find(iContact => iContact.id === props.data.main_contact_id);
            if (mainContact) {
                return [mainContact.display_name.trim(), mainContact.phone, mainContact.email].join(', ');
            }
        }
        return 'Sin registrar';
    }

    return (
        <div style={{ outline: 'solid 1px rgba(0, 0, 0, 0.1)', borderRadius: '10px', boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.1)', marginBottom: '20px', position: 'relative', paddingBottom: '20px' }}>
            <div style={{ padding: mobile ? '5px' : '10px', display: 'flex', backgroundColor: 'var(--primary-color)', color: 'var(--on-primary-color)', justifyContent: 'center', flexDirection: 'column' }}>
                <h2 style={{ textAlign: 'center', fontSize: mobile ? '0.7em' : '', margin: '0', padding: '0' }}>{ props.data.display_name }</h2>
                <div style={{ display: 'flex', fontSize: mobile ? '0.5em' : '', borderRadius: '5px', flexWrap: 'wrap', gap: '10px' }}>
                    { generate_branch_tabs() }
                </div>
            </div>
            <div style={{ userSelect: 'none', MozUserSelect: 'none', WebkitUserSelect: 'none', fontSize: mobile ? '0.5em' : '', padding: mobile ? '10px' : '1em', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '10px', backgroundColor: 'var(--on-primary-color)' }}>
                <p onClick={() => navigator.clipboard.writeText(props.data.RFC)} style={{ cursor: 'pointer', margin: '0', gridColumnStart: '1', gridColumnEnd: '3', gridRowStart: '1', gridRowEnd: '2', width: '100%' }}><strong>RFC:</strong><br />{props.data.RFC ? props.data.RFC : 'Sin registrar'}</p>
                {/* <p onClick={() => navigator.clipboard.writeText(props.data.email)} style={{ cursor: 'pointer', margin: '0', gridColumnStart: '1', gridColumnEnd: '3', gridRowStart: '2', gridRowEnd: '3', width: '100%' }}><strong>Email:</strong><br />{props.data.email ? props.data.email : 'Sin registrar'}</p> */}
                <p style={{ cursor: 'pointer', margin: '0', gridColumnStart: '3', gridColumnEnd: '5', gridRowStart: '1', gridRowEnd: '2', width: '100%' }}><strong>Contrato: </strong><br />{ buildContract() }</p>
                <p onDoubleClick={ openGoogleURL } onClick={() => navigator.clipboard.writeText(buildAddress())} style={{ cursor: 'pointer', margin: '0', gridColumnStart: '3', gridColumnEnd: '5', gridRowStart: '2', gridRowEnd: '3', width: '100%' }}><strong>Domicilio: </strong><br />{ buildAddress() }</p>
                <p onClick={() => navigator.clipboard.writeText(props.data.fixed_phone)} style={{ cursor: 'pointer', margin: '0', gridColumnStart: '1', gridColumnEnd: '3', gridRowStart: '2', gridRowEnd: '3', width: '100%' }}><strong>Contacto Principal:</strong><br /> {getMainContactInfo()}</p>
                { expanded ? <p className='float-from-down' style={{ margin: '0', gridColumnStart: '1', gridColumnEnd: '3', gridRowStart: '4', gridRowEnd: '5', width: '100%' }}><strong>Nombre Comercial:</strong><br />{ props.data.commercial_name ? props.data.commercial_name : 'Sin registrar'}</p> : null }
                { expanded ? <p className='float-from-down' style={{ margin: '0', gridColumnStart: '3', gridColumnEnd: '5', gridRowStart: '4', gridRowEnd: '5', width: '100%' }}><strong>Alias:</strong><br />{ props.data.alias ? props.data.alias : 'Sin registrar'}</p> : null }
                { expanded ? 
                <div className='float-from-down option-button-strip' style={{ margin: '0', gridColumnStart: '1', gridColumnEnd: '5', gridRowStart: '5', gridRowEnd: '6' }}>
                    { props.user.position === '1' || props.user.position === '2' || props.user.position === '3' || props.user.position === '4' ? <OptionButton onClick={() => props.editFunction(props.data.id)} source='icons/edit.svg' /> : null }
                    { props.user.position === '1' || props.user.position === '2' || props.user.position === '3' || props.user.position === '4' ? <OptionButton onClick={ delete_client } source='icons/trash.svg' type='dangerous' /> : null }
                </div> : null }
            </div>
            <img onClick={() => expand(!expanded) } src='icons/down.svg' style={{ position: 'absolute', width: '25px', bottom: '0', right: '50%', transform: expanded ? 'rotate(180deg)' : '' }} />
        </div>
    );
}