import React, { CSSProperties, ReactNode } from "react";

interface DangerButtonProps {
    onClick: (event: any) => void,
    children: ReactNode
}

class DangerButton extends React.Component<DangerButtonProps, {}> {
    mobile: boolean;

    constructor(props: DangerButtonProps) {
        super(props);
        this.mobile = window.innerWidth > 768 ? false : true;
    }
    render() {
        return (
            <button style={{ fontSize: this.mobile ? '0.75em' : '', userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none' }} className='button-danger' onClick={this.props.onClick}>{this.props.children}</button>
        )
    }
}
export default DangerButton;