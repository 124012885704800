import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { inimmedAxios, currency_formatter } from "../../InimmedHelpers";
import { Employee, Expense, Income, OwnBankAccount } from "../../Interfaces";
import ExpensesCatalog from "../Catalogs/ExpensesCatalog";
import IncomesCatalog from "../Catalogs/IncomesCatalog";
import DangerButton from "../Utility/DangerButton";
import PrimaryButton from "../Utility/PrimaryButton";

interface OwnBankAccountDetailsProps {
    goBackAndRefresh: () => void;
    editOwnBankAccount: (account: OwnBankAccount) => void;
    getIncomeDetail: (income_id: number) => void;
    getExpenseDetail: (expense_id: number) => void;
    data: OwnBankAccount;
    user: Employee;
}

const OwnBankAccountDetails = (props: OwnBankAccountDetailsProps) => {

    const [own_bank_account_incomes, setOwnBankAccountIncomes] = useState<Income[]>([]);
    const [own_bank_account_expenses, setOwnBankAccountExpenses] = useState<Expense[]>([]);

    const [incomes_collapsed, setIncomesCollapsed] = useState<boolean>(true);
    const [expenses_collapsed, setExpensesCollapsed] = useState<boolean>(true);

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        console.log('Reloaded');
        inimmedAxios.get('api_banks/get_own_bank_account_io', {
            params: {
                own_bank_account_id: props.data.id
            }
        })
        .then(response => {
            setOwnBankAccountIncomes(response.data.incomes);
            setOwnBankAccountExpenses(response.data.expenses);
        })
        .catch(reason => alert(reason));
    }, [own_bank_account_incomes.length, own_bank_account_expenses.length, props.data.id]);

    const delete_account = () => {
        if (window.confirm('¿Seguro que desea eliminar la cuenta bancaria propia ' + props.data.display_name + '?')) {
            setLoading(true);
            inimmedAxios.post('api_banks/delete_own_bank_account', {
                account_id: props.data.id
            })
            .then(response => {
                if (response.data.Result === 'Ok') {
                    toast.success('Cuenta bancaria propia eliminada con éxito. 🗑️');
                    props.goBackAndRefresh();
                } else {
                    alert(response.data.Result);
                }
            })
            .catch(reason => alert(reason));
        }
    }

    return (
        <div className='float-from-down' style={{ padding: '20px' }}>
            <p>{ 'Nombre: ' + props.data.name }</p>
            <p>{ 'Número de Cuenta: ' + (props.data.account_number ? props.data.account_number : 'Sin registrar') }</p>
            <p>{ 'Banco: ' + props.data.bank }</p>
            <p>{ 'Saldo: ' + currency_formatter.format(Number.parseFloat(props.data.balance)) }</p>
            <p>{ 'Área Correspondiente: ' + (props.data.area ? props.data.area : 'Sin registrar') }</p>
            { props.user.position === '1' || props.user.position === '2' || props.user.position === '3' ?
                <div className='button-strip'>
                    <PrimaryButton onClick={() => props.editOwnBankAccount(props.data) } >Editar</PrimaryButton>
                    <DangerButton onClick={ delete_account } >Eliminar</DangerButton>
                </div>
                :
                null
            }
            <div className='top-separator' />
            <h1 style={{ cursor: 'pointer', margin: '0' }} onClick={() => setIncomesCollapsed(!incomes_collapsed)} >{ 'Ingresos de ' + props.data.display_name }</h1>
            <div style={{ display: incomes_collapsed ? 'none' : 'flex', overflow: 'visible', flexDirection: 'column', gap: '10px' }}>
                <IncomesCatalog data={own_bank_account_incomes} getIncome={income_id => props.getIncomeDetail(income_id)} newIncome={() => null} client={true} />
            </div>
            <div className='top-separator' />
            <h1 style={{ cursor: 'pointer', margin: '0' }} onClick={() => setExpensesCollapsed(!expenses_collapsed)} >{ 'Egresos de ' + props.data.display_name }</h1>
            <div style={{ display: expenses_collapsed ? 'none' : 'flex', overflow: 'visible', flexDirection: 'column', gap: '10px' }}>
                <ExpensesCatalog data={own_bank_account_expenses} getExpense={expense_id => props.getExpenseDetail(expense_id)} newExpense={() => null} supplier={true} />
            </div>
            <div className='top-separator' />
        </div>
    );
}

export default OwnBankAccountDetails;