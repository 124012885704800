import { inimmedAxios } from "../../InimmedHelpers";
import React from "react";
import TextInput from "../Utility/TextInput";
import PrimaryButton from "../Utility/PrimaryButton";
import { Branch, Client, FormComponentProps } from "../../Interfaces";
import Loading from "../Utility/Loading";
import AddButton from "../Utility/AddButton";
import ClientSelector from "../Utility/Selectors/ClientSelector";

interface BranchFormProps extends FormComponentProps {
    data?: Branch,
    savedInputs?: BranchFormState
}

interface BranchFormState {
    response_clients: Client[],
    client: Client | null,
    name: string,
    client_input_holder: string
}

class BranchForm extends React.Component<BranchFormProps, BranchFormState> {
    constructor(props: BranchFormProps) {
        super(props);
        this.state = {
            response_clients: [],
            client: null,
            name: '',
            client_input_holder: ''
        }

        this.submitBranch = this.submitBranch.bind(this);
    }

    componentDidMount() {
        let initial_state: BranchFormState = {} as BranchFormState;
        const promises: Promise<void>[] = [];

        if (this.props.savedInputs) {
            initial_state = this.props.savedInputs;
        }

        promises.push(inimmedAxios.get('api/clients')
        .then(response => {
            if (this.props.data && typeof this.props.data.client !== 'string') {
                for (const client of response.data) {
                    if (client.display_name === this.props.data.client.display_name) {
                        initial_state.client = client;
                    }
                }
            }
            initial_state.response_clients = response.data;
        }));

        if (this.props.data) {
            initial_state.client = this.props.data.client as Client;
            initial_state.name = this.props.data.name;
        }

        Promise.all(promises)
        .then(() => this.setState(initial_state))
        .catch(reason => alert(reason));
    }

    submitBranch() {
        if (!this.state.client) {
            alert('Por favor elija un cliente al cual pertenece la sucursal actual.');
            return;
        }
        if (!this.state.name) {
            alert('Por favor escriba el nombre de la nueva sucursal.');
            return;
        }
        const url = this.props.data ? 'api/save_branch/' + this.props.data.id : 'api/save_branch';
        inimmedAxios.post(url, {
            name: this.state.name,
            client: this.state.client.id
        })
        .then(response => {
            if (response.data.Result === 'Success') {
                if (window.confirm('Sucursal guardada, ¿Salir?'))
                if (this.props.data && this.props.getCatalog && this.props.getDetail) {
                    this.props.getCatalog('branches');
                    this.props.getDetail('branches', this.props.data.id, true);
                }
                    this.props.backFunction();
            } else {
                alert(response.data.Result);
            }
        })
        .catch(reason => {
            alert(reason);
        });
    }

    render() {
        if (this.state.response_clients.length) {
            return (
                <div className='form-container'>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Cliente:</label>
                            <div className='form-input-plus'>
                                <ClientSelector selected_client={ this.state.client } clients={ this.state.response_clients } selected={client => this.setState({ client: client })} />
                                { !this.props.data ? <AddButton onClick={() => {if (this.props.changeForm) this.props.changeForm({form: 'ClientForm', state: this.state})}} /> : null }
                            </div>
                        </div>
                    </div>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Nombre de la Sucursal:</label>
                            <TextInput onChange={event => this.setState({name: event.target.value})}>{this.state.name}</TextInput>
                        </div>
                    </div>
                    <div className='top-separator' />
                    <div className='button-strip'>
                        <PrimaryButton onClick={this.submitBranch}>{ this.props.data ? 'Guardar' : 'Registrar'}</PrimaryButton>
                    </div>
                </div>
            )
        } else {
            return <Loading color='primary' />;
        }
    }
}
export default BranchForm;