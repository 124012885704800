import { ArcElement, Chart, ChartOptions } from "chart.js";
import { Bar, Doughnut, Pie } from "react-chartjs-2";

interface ServiceGraphProps {
    data: any;
    totalServices: number;
}
//                              1          2         3          4          5           6        7           8          9
const GRAPH_COLORS_ORANGE =  ['#FF6119', '#FF7D00', '#FF9733', '#FF844C', '#FFCF59'];
const GRAPH_COLORS_GREEN  =  ['#E7FF8C', '#DCFF59', '#BFF200', '#89FF59'];
const GRAPH_COLORS =      ['#FF844C', '#E7FF8C', '#CBF3D2', '#E87461', '#6C698D', '#40476D', '#EF6461', '#009FB7'];
// const GRAPH_COLORS = ['#FF6119', '#E7FF8C', '#FF7D00', '#DCFF59', '#FF9733', '#BFF200', '#FF844C', '#89FF59', '#FFCF59'];
const GRAPH_TEXT_COLORS = ['white',   'white',    'white',    'white',   'white',   'gray',   'gray'];

Chart.register(ArcElement);

export const ServiceTypeGraph = (props: ServiceGraphProps) => {
    const numericData = [];
    for (const key of Object.keys(props.data.categories)) {
        numericData.push(props.data.categories[key]);
    }
    const options: ChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: props.data.title,
                position: 'top',
                font: {
                    size: 20
                }
            }
        }
    }

    const graphColors = GRAPH_COLORS.slice(0, props.data.categories.length);

    const generateLegend = () => {
        const legendElements = [];
        let index = 0;
        for (const category of Object.keys(props.data.categories)) {
            const percent = (props.data.categories[category] / props.totalServices * 100);
            const percentString = percent >= 10 ? percent.toString().substring(0, 5) + '%' : percent.toString().substring(0, 4) + '%';
            legendElements.push(
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: '15px', height: '15px', backgroundColor: graphColors[index++], marginRight: '5px' }} />
                    <p style={{ margin: 0 }}>{ category + ': ' + props.data.categories[category] + '/' + percentString }</p>
                </div>
            );
        }
        return legendElements;
    }

    return (
        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', borderRadius: '10px', width: window.innerWidth > 768 ? '25vw' : '90vw' }}>
            <Doughnut
                options={options}
                data={{ datasets: [{
                            data: numericData,
                            datalabels: {
                                display: false
                            },
                            backgroundColor: graphColors,
                            borderColor: 'transparent',
                            borderWidth: 1 }],
                        labels: ['Preventivo', 'Correctivo', 'Instalación', 'Garantía Extraordinaria', 'Capacitación'] }} />
            <div style={{ padding: '15px' }}>
                { generateLegend() }
            </div>
        </div>
    )
}

export const ServiceFailureTypeGraph = (props: ServiceGraphProps) => {
    const numericData = [];
    for (const key of Object.keys(props.data.categories)) {
        numericData.push(props.data.categories[key]);
    }
    const options: ChartOptions = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: props.data.title,
                position: 'top',
                font: {
                    size: 20
                }
            },
            datalabels: {
                font: {
                    size: window.innerWidth > 768 ? 15 : 10
                },
                anchor: 'end',
                align: 'top',
                formatter: (value, context) => {
                    const percent = value/props.totalServices * 100;
                    const percentString = percent >= 10 ? percent.toString().slice(0, 4) + '%' : percent.toString().slice(0, 3) + '%';
                    return percentString;
                }
            },
            legend: {
                display: false
            }
        }
    }

    const generateLegend = () => {
        const legendElements = [];
        let index = 0;
        for (const category of Object.keys(props.data.categories)) {
            const percent = (props.data.categories[category] / props.totalServices * 100);
            const percentString = percent >= 10 ? percent.toString().substring(0, 5) + '%' : percent.toString().substring(0, 4) + '%';
            legendElements.push(
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: '15px', height: '15px', backgroundColor: graphColors[index++], marginRight: '5px' }} />
                    <p style={{ margin: 0, fontSize: '0.8em' }}>{ category + ': ' + props.data.categories[category] + '/' + percentString }</p>
                </div>
            );
        }
        return legendElements;
    }

    const graphColors = GRAPH_COLORS.slice(0, 5);

    return (
        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', padding: '15px 5px', borderRadius: '10px', width: window.innerWidth > 768 ? '25vw' : '90vw' }}>
            <Bar
                options={options}
                data={{ datasets: [{
                    data: numericData,
                    backgroundColor: graphColors,
                    borderColor: 'transparent',
                    borderWidth: 1 }],
                    labels: ['Falta de condiciones básicas', 'Diseño débil o inadecuado', 'Falta de habilidad del ingeniero', 'Mantenimiento insuficiente', 'Falta de habilidad del técnico u operador', 'Influencias o causas ajenas', 'Tiempo de vida', 'Preventivo', 'Demos', 'Instalación']
                }}
                height={ window.innerWidth > 768 ? '300px' : '450px'}
            />
        </div>
    );
}

export const ServiceEngineerGraph = (props: ServiceGraphProps) => {
    const numericData = [];
    for (const key of Object.keys(props.data.categories)) {
        numericData.push(props.data.categories[key]);
    }
    const options: ChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: props.data.title,
                position: 'top',
                font: {
                    size: 20
                }
            },
            datalabels: {
                formatter: (value, context) => {
                    return value === 0 ? '' : value
                },
                font: {
                    size: window.innerWidth > 768 ? 20 : 15
                }
            }
        }
    }

    const graphColors = GRAPH_COLORS.slice(0, props.data.categories.length);
    
    const generateLegend = () => {
        const legendElements = [];
        let index = 0;
        for (const category of Object.keys(props.data.categories)) {
            const percent = (props.data.categories[category] / props.totalServices * 100);
            const percentString = percent >= 10 ? percent.toString().substring(0, 5) + '%' : percent.toString().substring(0, 4) + '%';
            legendElements.push(
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: '15px', height: '15px', backgroundColor: graphColors[index++], marginRight: '5px' }} />
                    <p style={{ margin: 0, fontSize: '0.7em' }}>{ category + ': ' + percentString }</p>
                </div>
            );
        }
        return legendElements;
    }

    return (
        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', padding: '15px 5px', borderRadius: '10px', width: window.innerWidth > 768 ? '25vw' : '90vw' }}>
            <Doughnut
                options={options}
                data={{ datasets: [{
                    data: numericData,
                    backgroundColor: graphColors,
                    borderColor: '#A1A1A1',
                    borderWidth: 1 }],
                    labels: ['Falta de condiciones básicas', 'Diseño débil o inadecuado', 'Falta de habilidad del ingeniero', 'Mantenimiento insuficiente', 'Falta de habilidad del técnico u operador', 'Influencias o causas ajenas', 'Tiempo de vida', 'Preventivo', 'Demos', 'Instalación'] }}
                />
            <div style={{ padding: '15px', display: 'flex', gap: '5px', flexDirection: 'column' }}>
                { generateLegend() }
            </div>
        </div>
    );
}