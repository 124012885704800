import React, { ReactNode } from 'react';

interface SecondaryButtonProps extends React.DOMAttributes<HTMLButtonElement> {
    className?: string,
    onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void,
    children: ReactNode
}

class SecondaryButton extends React.Component<SecondaryButtonProps, {}> {
    mobile: boolean;

    constructor (props: SecondaryButtonProps) {
        super(props);
        this.mobile = window.innerWidth > 768 ? false : true;
    }

    render() {
        return (
            <button style={{ fontSize: this.mobile ? '1em' : '', userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none' }} className={ this.props.className ? this.props.className : 'button-secondary'} onClick={this.props.onClick}>{this.props.children}</button>
        )
    }
}
export default SecondaryButton;