interface TabSelectorProps {
    setTab: (tab: string) => void;
    active_tab: string;
    tab_names: string[];
    inactive?: boolean;
}


export function TabSelector(props: TabSelectorProps) {

    const mobile = window.innerWidth > 768 ? false : true;

    const tabs: JSX.Element[] = [];
    for (const tab_name of props.tab_names) {
        if (tab_name === props.active_tab) {
            tabs.push(<p key={ tabs.length } style={{ boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)', borderRadius: '10px', cursor: props.inactive ? '' : 'pointer', margin: '5px 3px', flex: '1', textAlign: 'center', whiteSpace: 'nowrap', backgroundColor: props.inactive ? '#CDCDCD' : 'var(--primary-color)', color: 'var(--on-primary-color)', padding: '10px 5px' }}>{ tab_name }</p>);
        } else {
            tabs.push(<p key={ tabs.length } onClick={() => props.inactive ? null : props.setTab(tab_name)} style={{ borderRadius: '10px', cursor: props.inactive ? '' : 'pointer', width: 'fit-content', margin: '5px 3px', flex: '1', textAlign: 'center', whiteSpace: 'nowrap', padding: '10px 5px', overflow: 'visible', color: 'rgba(0, 0, 0, 0.5)' }}>{ tab_name }</p>);
        }
    }

    return (
        <div style={{ display: 'flex', fontSize: mobile ? '0.6em' : '0.9em', width: '100%', justifyContent: 'center', borderRadius: '10px', outline: 'solid 1px rgba(0, 0, 0, 0.1)', userSelect: 'none' }}>
            { tabs }
        </div>
    );
}