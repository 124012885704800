import React, { CSSProperties } from "react";
import BackButton from "./Utility/BackButton";

interface SectionProps {
    onClick?: () => void;
    backButton?: boolean;
    children?: React.ReactNode;
    dont_reset_scroll?: boolean;
    title: string;
}

class Section extends React.Component<SectionProps, {}> {
    mobile: boolean;

    constructor(props: SectionProps) {
        super(props);
        this.mobile = window.innerWidth > 768 ? true : false;
    }

    componentDidUpdate() {
        if (!this.props.dont_reset_scroll) {
            document.querySelector('#current-section')?.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    render() {
        let headerStyle: CSSProperties;

        if (this.mobile) {
            headerStyle = {
                margin: '0',
                fontFamily: 'Ubuntu',
                userSelect: 'none',
            }
        }
        else {
            headerStyle = {
                margin: '0',
                fontSize: '1.25em',
                fontFamily: 'Ubuntu',
                userSelect: 'none',
            }
        }
        return (
            <div id='current-section' style={{
                backgroundColor: 'var(--on-primary-color)',
                boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
                width: !this.mobile ? '100%' : '95%',
                height: !this.mobile ? '100%' : '95%',
                borderRadius: this.mobile ? '10px' : '',
                boxSizing: 'border-box',
                overflowY: 'auto',
                scrollbarWidth: 'none'
            }}>
                <div style={{boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)', zIndex: '2', position: 'sticky', top: '0', backgroundColor: 'var(--on-primary-color)', padding: this.mobile ? '10px' : '0', display: 'flex', gap: '5px', alignItems: 'center', height: 'auto' }}>
                    {this.props.backButton ? <BackButton onClick={this.props.onClick ? this.props.onClick : () => null} /> : null}
                    <h1 style={headerStyle} className='float-from-right'>
                        {this.props.title}
                    </h1>
                </div>
                {this.props.children}
            </div>
        )
    }
}
export default Section;