import React from "react";
import { toast } from "react-toastify";
import { inimmedAxios } from "../../InimmedHelpers";
import { Employee, Model, DetailComponentProps } from "../../Interfaces";
import PrimaryButton from "../Utility/PrimaryButton";
import DangerButton from "../Utility/DangerButton";

interface ModelDetailsProps extends DetailComponentProps {
    data: Model,
    user: Employee
}

class ModelDetails extends React.Component<ModelDetailsProps, {}> {
    constructor(props: ModelDetailsProps) {
        super(props);
        this.delete = this.delete.bind(this);
    }

    delete() {
        if (window.confirm('¿Seguro/a que desea borrar el modelo "' + this.props.data.display_name + '"?')) {
            inimmedAxios.post('api/delete_device_model/' + this.props.data.id)
                .then(response => {
                    if (response.data.Result === 'Success') {
                        toast.success('Modelo eliminado. 🗑️');
                        this.props.getCatalog('device_models');
                        this.props.goBack();
                    }
                    else
                        alert(response.data.Result);
                })
                .catch(reason => {
                    alert(reason);
                });
        }
    }

    render() {
        if (typeof this.props.data.brand !== 'string' && typeof this.props.data.device_type !== 'string') {
            return (
                <div className='float-from-down' style={{ boxSizing: 'border-box', padding: '1em', fontSize: '1.25em' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1em', flexWrap: 'wrap' }}>
                        <h1>{this.props.data.display_name}</h1>
                        { this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ? <PrimaryButton onClick={() => this.props.editFunction(this.props.data.id)}>Editar</PrimaryButton> : null }
                    </div>
                    <p><strong>Nombre del Modelo: </strong><br /> {this.props.data.name}</p>
                    <p><strong>Número del Modelo: </strong><br /> {this.props.data.model_number ? this.props.data.model_number : 'Sin registrar'}</p>
                    <p><strong>Marca: </strong><br /> <span className='clickable-detail' onClick={() => typeof this.props.data.brand !== 'string' ? this.props.getDetail('brands', this.props.data.brand.id) : null}>{ this.props.data.brand.display_name }</span></p>
                    <p><strong>Tipo de Equipo: </strong><br /> <span className='clickable-detail' onClick={() => typeof this.props.data.device_type !== 'string' ? this.props.getDetail('device_types', this.props.data.device_type.id) : null}>{ this.props.data.device_type.display_name }</span></p>
                    {this.props.user.position === '1' || this.props.user.position === '2' || this.props.user.position === '3' || this.props.user.position === '4' ?
                        <div>
                            <DangerButton onClick={this.delete}>Eliminar</DangerButton>
                        </div>
                        :
                        null}
                </div>
            )
        }
    }
}
export default ModelDetails;