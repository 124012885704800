import { inimmedAxios } from "../../InimmedHelpers";
import React from "react";
import { TabSelector } from "../Utility/TabSelector";
import TextInput from "../Utility/TextInput";
import ClientSelector from "../Utility/Selectors/ClientSelector";
import { Client, PurchaseAccount, SaleAccount, Supplier } from "../../Interfaces";
import SupplierSelector from "../Utility/Selectors/SupplierSelector";
import PrimaryButton from "../Utility/PrimaryButton";
import MoneyInput, { MoneyTextInput } from "../Utility/MoneyInput";
import Loading from "../Utility/Loading";
import { toast } from "react-toastify";
import SecondaryButton from "../Utility/SecondaryButton";

export interface AccountFormProps {
    getAccountDetail: (account_id: number, account_type: 0 | 1) => void;
    goBack: () => void;
    account_to_edit?: SaleAccount | PurchaseAccount;
    saved_state?: AccountFormState;
}

export interface AccountFormState {
    [k: string]: string | number | Client[] | Client | Supplier[] | Supplier | null;
    current_tab: string;
    response_clients: Client[] | null;
    client: Client | null;
    response_suppliers: Supplier[] | null;
    supplier: Supplier | null;
    concept: string;
    payment_months: number;
    payment_form: number;
    total_amount: string;
    currency: number;
    currency_change: string;
    initial_pay_date: string;
    month_payday: string;
    invoice: string;
    issue_date: string;
    due_date: string;
    status: number | null;
    remmision: string;
    sale_type: string | null;
    purchase_type: string | null;
}

class AccountForm extends React.Component<AccountFormProps, AccountFormState> {
    tabs: string[];

    constructor(props: AccountFormProps) {
        super(props);

        this.tabs = ['Por Cobrar', 'Por Pagar'];

        const today = new Date();

        this.state = {
            current_tab: this.tabs[0],
            client: null,
            response_clients: null,
            supplier: null,
            response_suppliers: null,
            concept: '',
            payment_months: 12,
            payment_form: 1,
            total_amount: '0.00',
            currency: 0,
            currency_change: '0.00',
            initial_pay_date: new Date(today.getFullYear(), today.getMonth(), 25).toISOString().slice(0, 10),
            month_payday: '',
            issue_date: today.toISOString().slice(0, 10),
            due_date: today.toISOString().slice(0, 10),
            invoice: '',
            status: null,
            remmision: '',
            purchase_type: '1',
            sale_type: '1'
        }

        this.submitAccount = this.submitAccount.bind(this);
    }

    componentDidMount() {
        const initial_state = this.state as AccountFormState;

        if (this.props.saved_state) {
            Object.keys(this.props.saved_state).forEach(key => {
                if (this.props.saved_state) initial_state[key] = this.props.saved_state[key];
            });
        }
        
        if (this.props.account_to_edit) {
            initial_state.concept = this.props.account_to_edit.concept;
            initial_state.currency = this.props.account_to_edit.currency as number;
            initial_state.currency_change = this.props.account_to_edit.currency_change as string;
            initial_state.initial_pay_date = this.props.account_to_edit.initial_pay_date;
            initial_state.invoice = this.props.account_to_edit.invoice;
            initial_state.issue_date = this.props.account_to_edit.issue_date;
            initial_state.month_payday = this.props.account_to_edit.month_payday;
            initial_state.payment_form = Number.parseInt(this.props.account_to_edit.payment_form);
            initial_state.payment_months = this.props.account_to_edit.payment_months;
            initial_state.total_amount = this.props.account_to_edit.total_amount;

            if ('client' in this.props.account_to_edit) {
                initial_state.current_tab = this.tabs[0];
                initial_state.client = this.props.account_to_edit.client as Client;
                initial_state.remmision = this.props.account_to_edit.remmision as string;
                initial_state.sale_type = this.props.account_to_edit.sale_type;
            } else if ('supplier' in this.props.account_to_edit) {
                initial_state.current_tab = this.tabs[1];
                initial_state.supplier = this.props.account_to_edit.supplier as Supplier;
                initial_state.purchase_type = this.props.account_to_edit.purchase_type;
            }
        }

        const promises: Promise<void>[] = [];

        promises.push(
            inimmedAxios.get('api/clients')
            .then(response => {
                initial_state.response_clients = response.data;
            })
        );

        promises.push(
            inimmedAxios.get('api_banks/get_suppliers')
            .then(response => {
                initial_state.response_suppliers = response.data;
            })
        );

        Promise.all(promises)
        .then(() => this.setState(initial_state))
        .catch(reason => alert(reason));
    }

    submitAccount() {
        let total_amount;
        let currency_change;
        if (!this.state.concept) {
            alert('Por favor ingrese el concepto de la cuenta.');
            return;
        }
        if (!this.state.total_amount.match(/^\d+.\d*$/)) {
            alert('Por favor ingrese un valor válido sin comas para el monto de la cuenta.');
            return;
        } else {
            total_amount = Number.parseFloat(this.state.total_amount);
            if (!total_amount) {
                alert('Por favor ingrese un valor para el monto de la cuenta.');
                return;
            }
        }
        if (!this.state.initial_pay_date) {
            alert('Por favor ingrese una fecha de pago inicial.');
            return;
        }
        if (this.state.payment_form === 3 || this.state.payment_form === 5) {
            if (!this.state.payment_months) {
                alert('Por favor ingrese un valor para los meses a pagar la cuenta.');
                return;
            }
        }
        if (this.state.currency === 1) {
            if (!this.state.total_amount.match(/^\d+.\d*$/)) {
                alert('Por favor ingrese un valor válido para el tipo de cambio.');
                return;
            } else {
                currency_change = Number.parseFloat(this.state.currency_change);
                if (!currency_change) {
                    alert('Por favor ingrese un valor para el tipo de cambio.');
                    return;
                } else {
                    total_amount *= currency_change;
                }
            }
        }

        if (this.state.current_tab === 'Por Cobrar') {
            if (!this.state.client) {
                alert('Por favor elija un cliente correspondiente a la cuenta por cobrar.');
                return;
            }
            if (this.props.account_to_edit) {
                if (window.confirm('¿Desea guardar los cambios hechos a la cuenta?')) {
                    inimmedAxios.post('api_banks/save_sale_account', {
                        account_id: this.props.account_to_edit ? this.props.account_to_edit.id : null,
                        sale_type: this.state.sale_type,
                        subject: this.state.client.id,
                        concept: this.state.concept,
                        payment_months: this.state.payment_months,
                        payment_form: this.state.payment_form,
                        total_amount: this.state.total_amount.replaceAll(',', ''),
                        currency: this.state.currency,
                        currency_change: this.state.currency_change,
                        initial_pay_date: this.state.initial_pay_date,
                        month_payday: this.state.month_payday,
                        invoice: this.state.invoice,
                        issue_date: this.state.issue_date,
                        due_date: this.state.due_date,
                        remission: this.state.remmision
                    })
                    .then(response => {
                        toast.success('Cambios guardados');
                        this.props.getAccountDetail(response.data.id, 0);
                    })
                    .catch(reason => alert(reason));
                }
            } else {
                if (window.confirm('¿Desea registrar la nueva cuenta por cobrar?')) {
                    inimmedAxios.post('api_banks/save_sale_account', {
                        account_type: 'Por Cobrar',
                        sale_type: this.state.sale_type,
                        subject: this.state.client.id,
                        concept: this.state.concept,
                        payment_months: this.state.payment_months,
                        payment_form: this.state.payment_form,
                        total_amount: this.state.total_amount.replaceAll(',', ''),
                        currency: this.state.currency,
                        currency_change: this.state.currency_change,
                        initial_pay_date: this.state.initial_pay_date,
                        monthly_payday: this.state.monthly_payday,
                        invoice: this.state.invoice,
                        issue_date: this.state.issue_date,
                        due_date: this.state.due_date,
                        remission: this.state.remmision
                    })
                    .then(response => {
                        if (response.data.Result !== 'Ok') {
                            alert(response.data.Result);
                        } else {
                            toast.success('Cuenta creada');
                            this.props.getAccountDetail(response.data.id, 0);
                        }
                    })
                    .catch(reason => alert(reason));
                }
            }

        } else if (this.state.current_tab === 'Por Pagar') {
            if (!this.state.supplier) {
                alert('Por favor elija un proveedor correspondiente a la cuenta por pagar.');
                return;
            }
            if (this.props.account_to_edit) {
                if (window.confirm('¿Desea guardar los cambios hechos a la cuenta?')) {
                    inimmedAxios.post('api_banks/save_purchase_account', {
                        account_id: this.props.account_to_edit ? this.props.account_to_edit.id : null,
                        subject: this.state.supplier.id,
                        concept: this.state.concept,
                        payment_months: this.state.payment_months,
                        payment_form: this.state.payment_form,
                        total_amount: this.state.total_amount.replaceAll(',', ''),
                        currency: this.state.currency,
                        currency_change: this.state.currency_change,
                        initial_pay_date: this.state.initial_pay_date,
                        month_payday: this.state.month_payday,
                        invoice: this.state.invoice,
                        issue_date: this.state.issue_date,
                        due_date: this.state.due_date,
                        remission: this.state.remmision,
                        purchase_type: this.state.purchase_type
                    })
                    .then(response => {
                        toast.success('Cambios guardados');
                        this.props.getAccountDetail(response.data.id, 1);
                    })
                    .catch(reason => alert(reason));
                }
            } else {
                if (window.confirm('¿Desea registrar la nueva cuenta por pagar?')) {
                    inimmedAxios.post('api_banks/save_purchase_account', {
                        account_type: 'Por Pagar',
                        subject: this.state.supplier.id,
                        concept: this.state.concept,
                        payment_months: this.state.payment_months,
                        payment_form: this.state.payment_form,
                        total_amount: this.state.total_amount.replaceAll(',', ''),
                        currency: this.state.currency,
                        currency_change: this.state.currency_change,
                        initial_pay_date: this.state.initial_pay_date,
                        month_payday: this.state.month_payday,
                        invoice: this.state.invoice,
                        issue_date: this.state.issue_date,
                        due_date: this.state.due_date,
                        remission: this.state.remmision,
                        purchase_type: this.state.purchase_type
                    })
                    .then(response => {
                        toast.success('Cuenta creada');
                        this.props.getAccountDetail(response.data.id, 1);
                    })
                    .catch(reason => alert(reason));
                }
            }
        }
    }

    render() {
        if (this.state.response_clients && this.state.response_suppliers) {
            return (
                <div className='form-container'>
                    <h2 style={{ marginBottom: '10px' }}>Tipo de Cuenta</h2>
                    <TabSelector inactive={ this.props.account_to_edit ? true : false } active_tab={ this.state.current_tab } tab_names={this.tabs} setTab={tab => this.setState({ current_tab: tab })} />
                    <div className='top-separator' />
                    <h2>Detalles</h2>
                    { this.state.current_tab === 'Por Cobrar' ?
                        <div className='form-input-div'>
                            <div className='form-input'>
                                <label>Cliente:</label>
                                <ClientSelector clients={ this.state.response_clients ? this.state.response_clients : [] } selected_client={ this.state.client } selected={client => this.setState({ client: client })} />
                            </div>
                            <div className='form-input'>
                                <label>Concepto:</label>
                                <TextInput onChange={event => this.setState({ concept: event.target.value }) } >{ this.state.concept }</TextInput>
                            </div>
                            <div className='form-input'>
                                <label>Tipo de Venta:</label>
                                <select defaultValue={ this.state.sale_type ? this.state.sale_type : 0  } onChange={event => this.setState({ sale_type: event.target.value })}>
                                    <option value={1}>Venta de equipo</option>
                                    <option value={2}>Venta de refacción</option>
                                    <option value={3}>Venta de servicio</option>
                                    <option value={4}>Venta de consumible</option>
                                </select>
                            </div>
                        </div>
                    :
                        <div className='form-input-div'>
                            <div className='form-input'>
                                <label>Proveedor:</label>
                                <SupplierSelector suppliers={ this.state.response_suppliers ? this.state.response_suppliers : [] } selected_supplier={ this.state.supplier } selected={supplier => this.setState({ supplier: supplier })} />
                            </div>
                            <div className='form-input'>
                                <label>Concepto:</label>
                                <TextInput onChange={event => this.setState({ concept: event.target.value }) } >{ this.state.concept }</TextInput>
                            </div>
                            <div className='form-input'>
                                <label>Tipo de Compra:</label>
                                <select defaultValue={ this.state.purchase_type ? this.state.purchase_type : 0 } onChange={event => this.setState({ purchase_type: event.target.value })}>
                                    <option value={1}>Compra de equipo</option>
                                    <option value={2}>Compra de refacción</option>
                                    <option value={3}>Compra de servicio</option>
                                    <option value={4}>Compra de consumible</option>
                                </select>
                            </div>
                        </div>
                    }
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>{ this.state.current_tab === 'Por Cobrar' ? 'Monto total a cobrar:' : 'Monto total a pagar:' }</label>
                            <MoneyTextInput current_money_value={ this.state.total_amount } setMoneyValue={money_value => this.setState({ total_amount: money_value })} />
                            {/* <MoneyInput currency={ this.state.currency } setCurrency={event => this.setState({ currency: Number.parseInt(event.target.value) })} setValue={event => this.setState({ total_amount: event.target.value })} >{ this.state.total_amount }</MoneyInput> */}
                        </div>
                        {
                            this.state.currency === 1 ?
                            <div className='form-input'>
                                <label>Tipo de cambio:</label>
                                <TextInput onChange={event => this.setState({ currency_change: event.target.value })}>{ this.state.currency_change }</TextInput>
                            </div>
                            :
                            null
                        }
                        <div className='form-input'>
                            <label>Forma de pago:</label>
                            <select defaultValue={ this.state.payment_form } onChange={event => this.setState({ payment_form: Number.parseInt(event.target.value) })}>
                                <option value={1}>Anticipo y resto contra entrega</option>
                                <option value={2}>Contado en una sola exhibición</option>
                                {
                                    this.state.sale_type === '4' || this.state.purchase_type === '4' ?
                                    <>
                                        <option value={6}>15 días</option>
                                        <option value={7}>20 días</option>
                                    </>
                                    :
                                    <>
                                        <option value={3}>Anticipo y mensualidades.</option>
                                        <option value={4}>Financiado 70% anticipo, 30% contra entrega.</option>
                                        <option value={5}>Mensualidades</option>
                                    </>
                                }
                            </select>
                        </div>
                    </div>
                    <div className='top-separator' />
                    <h2>Facturación</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Factura:</label>
                            <TextInput onChange={event => this.setState({ invoice: event.target.value })}>{ this.state.invoice }</TextInput>
                        </div>
                        <div className='form-input'>
                            <label>Fecha de emisión:</label>
                            <input defaultValue={ this.state.issue_date } className='form-text-input' type={'date'} onChange={event => this.setState({ issue_date: event.target.value })} />
                        </div>
                        {
                            this.state.sale_type === '4' || this.state.purchase_type === '4' ?
                            <div className='form-input'>
                                <label>Remisión:</label>
                                <TextInput onChange={event => this.setState({ remmision: event.target.value })} >{ this.state.remmision }</TextInput>
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className='top-separator' />
                    <h2>Fechas</h2>
                    <div className='form-input-div'>
                        <div className='form-input'>
                            <label>Fecha de pago inicial/anticipo:</label>
                            <input defaultValue={ this.state.initial_pay_date } className='form-text-input' type={'date'} onChange={event => this.setState({ initial_pay_date: event.target.value })} />
                        </div>
                        {
                            this.state.payment_form === 3 || this.state.payment_form === 5 ?
                            <>
                                <div className='form-input'>
                                    <label>Meses a pagar:</label>
                                    <TextInput onChange={event => this.setState({ payment_months: Number.parseInt(event.target.value) })} >{ this.state.payment_months }</TextInput>
                                </div>
                                <div className='form-input'>
                                    <label>Fecha de pago mensual:</label>
                                    <input defaultValue={new Date().toDateString()} className='form-text-input' type={'date'} onChange={event => this.setState({ monthly_payday: event.target.value })} />
                                </div>
                            </>
                            :
                            null
                        }
                    </div>
                    <div className='top-separator' />
                    <div className='button-strip'>
                        <PrimaryButton onClick={ this.submitAccount } >{ this.props.account_to_edit ? 'Guardar' : 'Registrar'}</PrimaryButton>
                        <SecondaryButton onClick={ this.props.goBack }>Cancelar</SecondaryButton>
                    </div>
                </div>
            );
        } else {
            return (
                <Loading color='primary' />
            )
        }
    }
}

export default AccountForm;