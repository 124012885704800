import axios from "axios";
import { engineer_names } from "./Components/Agenda/Calendar";
import { GenericItem } from "./Interfaces";

// export const serverIP = 'http://localhost:8000';
export const serverIP = process.env.REACT_APP_DJANGO_HOST;

export const currency_formatter = Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN'});
export const percent_formatter = Intl.NumberFormat('es-MX', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2});

export const inimmedAxios = axios.create({
    baseURL: serverIP,
    withCredentials: true
});

export function datesEqual(date_1: Date, date_2: Date) {
    if (date_1.getFullYear() === date_2.getFullYear() && date_1.getMonth() === date_2.getMonth() && date_1.getDate() === date_2.getDate()) {
        return true;
    } else {
        return false;
    }
}

export function dateInDateRange(date_to_check: Date, date_range_start: Date, date_range_end: Date) {
    if (date_to_check.getFullYear() >= date_range_start.getFullYear() && date_to_check.getMonth() >= date_range_start.getMonth() && date_to_check.getDate() >= date_range_start.getDate()
    &&  date_to_check.getFullYear() <= date_range_end.getFullYear() && date_to_check.getMonth() <= date_range_end.getMonth() && date_to_check.getDate() <= date_range_end.getDate()) {
        return true;
    } else {
        return false;
    }
}

export function engineerNameInArray(engineer_name: engineer_names, array: engineer_names[]) {
    for (const engineer of array) {
        if (engineer === engineer_name)
            return true;
    }
    return false;
}

export function itemInArray<T extends GenericItem>(item: T, array: T[]) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].id === item.id) {
            return true;
        }
    }
    return false;
}

export const engineerColors = {
    Israel: 'lightgreen',
    JuanPablo: 'pink',
    JoseDaniel: 'purple',
    Gustavo: '#56A3A6',
    Jose: 'orange'
}

export type ServiceType = 'Preventivo' | 'Correctivo' | 'Instalación' | 'Garantía extraordinaria' | 'Capacitación';