import React from "react";
import { currency_formatter, inimmedAxios } from "../../InimmedHelpers";
import { Expense, MonthBudgetConcept, MONTH_BUDGET_CONCEPT_AREA, MONTH_BUDGET_CONCEPT_TYPE } from "../../Interfaces";
import ExpensesCatalog from "../Catalogs/ExpensesCatalog";
import DangerButton from "../Utility/DangerButton";
import PrimaryButton from "../Utility/PrimaryButton";

import * as APIFunctions from '../../API_Functions';
import { toast } from "react-toastify";

interface MonthBudgetConceptDetailsProps {
    editMonthBudgetConceptDetails: (month_budget_concept: MonthBudgetConcept) => void;
    month_budget_concept: MonthBudgetConcept;
}

interface MonthBudgetConceptDetailsState {
    response_expenses: Expense[] | null;
}

export default class MonthBudgetConceptDetails extends React.Component<MonthBudgetConceptDetailsProps, MonthBudgetConceptDetailsState> {
    constructor(props: MonthBudgetConceptDetailsProps) {
        super(props);

        this.state = {
            response_expenses: null
        }
        
        this.deleteMonthBudgetConcept = this.deleteMonthBudgetConcept.bind(this);
    }

    componentDidMount() {
        inimmedAxios.get('api_banks/get_month_budget_concept_expenses', {
            params: {
                month_budget_concept_id: this.props.month_budget_concept.id
            }
        })
        .then(response => {
            this.setState({ response_expenses: response.data });
        })
        .catch(reason => alert(reason));
    }

    async deleteMonthBudgetConcept() {
        if (await APIFunctions.deleteMonthBudgetConcept(this.props.month_budget_concept.id) === 'Ok') {
            toast.success('Concepto de Presupuesto Mensual eliminado con éxito.');
        }
    }

    render(): React.ReactNode {
        return (
            <div style={{ padding: '20px' }}>
                <p>{ this.props.month_budget_concept.concept }</p>
                <p>{ currency_formatter.format(Number.parseFloat(this.props.month_budget_concept.amount)) }</p>
                <p>{ MONTH_BUDGET_CONCEPT_AREA[Number.parseInt(this.props.month_budget_concept.area)] }</p>
                <p>{ MONTH_BUDGET_CONCEPT_TYPE[Number.parseInt(this.props.month_budget_concept.type)] }</p>
                <div className='button-strip'>
                    <PrimaryButton onClick={() => this.props.editMonthBudgetConceptDetails(this.props.month_budget_concept)}>Editar</PrimaryButton>
                    <DangerButton onClick={() => null}>Eliminar</DangerButton>
                </div>
                <div className='top-separator' />
                <ExpensesCatalog data={this.state.response_expenses ? this.state.response_expenses : [] } getExpense={() => null} newExpense={() => null} />
            </div>
        );
    }
}