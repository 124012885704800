import { CatalogTableHeader, Expense, PurchaseAccount } from "../../Interfaces";
import Catalog from "./Catalog";

interface ExpensesCatalogProps {
    getExpense: (expense_id: number) => void;
    newExpense: (account?: PurchaseAccount) => void;
    data: Expense[],
    supplier?: boolean;
}

export default (props: ExpensesCatalogProps) => {
    const mobile = window.innerWidth > 768 ? false : true;
    const date_type = mobile ? 'short_date' : 'long_date';
    let headers_for_table: CatalogTableHeader[];
    let data_headers: string[];
    let search_headers: string[];
    if (props.supplier) {
        headers_for_table = [{name: 'Fecha efectuado', data: 'date_made', width: '33%', type: date_type}, {name: 'Monto', data: 'amount', type: 'currency', width: '33%'}, {name: 'Proveedor', data: 'source', width: '33%'}];
        data_headers = ['date_made', 'amount', 'source'];
        search_headers = ['date_made', 'amount', 'source'];
    } else {
        headers_for_table = [{name: 'Fecha efectuado', data: 'date_made', width: '33%', type: date_type}, {name: 'Monto', data: 'amount', type: 'currency', width: '33%'}, {name: 'Cuenta', data: 'account_giving', width: '33%'}];
        data_headers = ['date_made', 'amount', 'account_giving'];
        search_headers = ['date_made', 'amount', 'account_giving'];
    }

    return (
        <Catalog
        data={ props.data }
        headersForTable={ headers_for_table }
        dataHeaders={ data_headers }
        searchHeaders={ search_headers }
        objectType='Egresos'
        getDetail={expense_id => props.getExpense(expense_id)}
        itemsPerPage={10}
        newButtonClick={() => props.newExpense() }
        newButtonPrompt='Nuevo Egreso'
        searchPlaceholder='Buscar egresos...'
        />
    )
}