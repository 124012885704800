import React from "react";
import { toast } from "react-toastify";
import { inimmedAxios } from "../../InimmedHelpers";
import { MonthBudgetConcept } from "../../Interfaces";
import { MoneyTextInput } from "../Utility/MoneyInput";
import PrimaryButton from "../Utility/PrimaryButton";
import SecondaryButton from "../Utility/SecondaryButton";
import TextInput from "../Utility/TextInput";

interface MonthBudgetConceptFormProps {
    goBackAndRefresh: () => void;
    initial_month_budget_concept?: MonthBudgetConcept;
}

interface MonthBudgetConceptFormState {
    concept_name: string;
    estimated_amount: string;
    concept_area: '0' | '1' | '2' | '3';
    concept_type: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
}

export default class MonthBudgetConceptForm extends React.Component<MonthBudgetConceptFormProps, MonthBudgetConceptFormState> {
    constructor(props: MonthBudgetConceptFormProps) {
        super(props);

        this.state = {
            concept_name: '',
            estimated_amount: '0.00',
            concept_area: '0',
            concept_type: '0'
        }

        this.submitMonthBudgetConcept = this.submitMonthBudgetConcept.bind(this);
    }
    
    componentDidMount() {
        if (this.props.initial_month_budget_concept !== undefined) {
            this.setState({
                concept_name: this.props.initial_month_budget_concept.concept,
                estimated_amount: this.props.initial_month_budget_concept.amount,
                concept_area: this.props.initial_month_budget_concept.area as '0' | '1' | '2' | '3',
                concept_type: this.props.initial_month_budget_concept.type as '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'
            });
        }
    }

    submitMonthBudgetConcept() {
        if (!this.state.concept_name) {
            alert('Por favor ingrese el nombre del concepto.');
            return;
        }
        if (!this.state.estimated_amount || this.state.estimated_amount === '0.00') {
            alert('Por favor ingrese el monto estimado del concepto.');
            return;
        }
        inimmedAxios.post('api_banks/save_month_budget_concept', {
            concept_name: this.state.concept_name,
            estimated_amount: this.state.estimated_amount,
            concept_area: this.state.concept_area,
            concept_type: this.state.concept_type,
            month_budget_concept_id: this.props.initial_month_budget_concept ? this.props.initial_month_budget_concept.id : null
        })
        .then(response => {
            if (response.data.Result === 'Ok') {
                if (this.props.initial_month_budget_concept) {
                    if (window.confirm('Concepto guardado, ¿Salir?')) {
                        toast.success('Concepto de Presupuesto guardado con éxito.');
                        this.props.goBackAndRefresh();
                    }
                } else {
                    if (window.confirm('Concepto registrado, ¿Salir?')) {
                        toast.success('Concepto de Presupuesto creado con éxito.');
                        this.props.goBackAndRefresh();
                    }
                }
            }
        })
        .catch(reason => alert(reason));
    }

    render() {
        return (
            <div className='form-container'>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Nombre del Concepto:</label>
                        <TextInput onChange={event => this.setState({ concept_name: event.target.value })} >{ this.state.concept_name }</TextInput>
                    </div>
                    <div className='form-input'>
                        <label>Importe Estimado Mensual:</label>
                        <MoneyTextInput current_money_value={this.state.estimated_amount} setMoneyValue={value => this.setState({ estimated_amount: value })} />
                    </div>
                </div>
                <div className='form-input-div'>
                    <div className='form-input'>
                        <label>Área Correspondiente:</label>
                        <select defaultValue={ this.props.initial_month_budget_concept ? this.props.initial_month_budget_concept.area : '0' } onChange={event => this.setState({ concept_area: event.target.value as '0' | '1' | '2' | '3' })}>
                            <option value='0'>Equipo</option>
                            <option value='1'>Servicio</option>
                            <option value='2'>Consumible</option>
                            <option value='3'>Refacciones</option>
                        </select>
                    </div>
                    <div className='form-input'>
                        <label>Tipo de Concepto:</label>
                        <select defaultValue={ this.props.initial_month_budget_concept ? this.props.initial_month_budget_concept.type : '0' } onChange={event => this.setState({ concept_type: event.target.value as '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' })}>
                            <option value='0'>Nómina</option>
                            <option value='1'>Servicio</option>
                            <option value='2'>Crédito Automovilístico</option>
                            <option value='3'>Crédito Bancario</option>
                            <option value='4'>Tarjeta de Crédito</option>
                            <option value='5'>Cuota Bancaria</option>
                            <option value='6'>Impuesto</option>
                            <option value='7'>Mensajerías</option>
                            <option value='8'>Transporte</option>
                            <option value='9'>Otros</option>
                        </select>
                    </div>
                </div>
                <div className='top-separator' />
                <div className='button-strip'>
                    <PrimaryButton onClick={ this.submitMonthBudgetConcept } >{ this.props.initial_month_budget_concept ? 'Guardar' : 'Registrar'}</PrimaryButton>
                    <SecondaryButton onClick={ this.props.goBackAndRefresh } >Cancelar</SecondaryButton>
                </div>
            </div>
        );
    }
}