import { PurchaseAccount, SaleAccount } from "../../../Interfaces";
import ItemSelector from "./ItemSelector";

interface AccountSelectorProps {
    selected: (account: any) => void;
    accounts: SaleAccount[] | PurchaseAccount[];
    selected_account: SaleAccount | PurchaseAccount | null;
}

export default (props: AccountSelectorProps) => {
    return (
        <ItemSelector selected_item={props.selected_account} items={ props.accounts } selected={ props.selected } />
    );
}