import React, { useState } from 'react';
import { Currency } from '../../Interfaces';

interface MoneyInputProps {
    setValue: (value: string) => void;
    setCurrency?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    currency?: number;
    children: string | number;
}

interface MoneyTextInputProps {
    setMoneyValue: (value: string) => void;
    current_money_value: string;
}

export function MoneyTextInput(props: MoneyTextInputProps) {

    const [characters_stack, setCharacterStack] = useState<string[]>([]);

    const handleInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        const new_characters_stack = characters_stack;
        const key_as_num = Number.parseInt(event.key);
        if (event.key === 'Backspace') {
            new_characters_stack.pop();
        }
        if (key_as_num > -1 && key_as_num < 10) {
            new_characters_stack.push(event.key);
        }
        setCharacterStack(new_characters_stack);
        if (new_characters_stack.length === 0) {
            props.setMoneyValue('0.00');   
        } else if (new_characters_stack.length === 1) {
            props.setMoneyValue('0.0' + new_characters_stack[0]);
        } else if (new_characters_stack.length === 2) {
            props.setMoneyValue('0.' + new_characters_stack[0] + new_characters_stack[1]);
        } else if (new_characters_stack.length > 2) {
            console.log(new_characters_stack);
            props.setMoneyValue(new_characters_stack.slice(0, -2).join('') + '.' + new_characters_stack.slice(-2, new_characters_stack.length).join(''));
        }
    }

    return (
        <input onKeyDown={ handleInput } className='form-text-input' style={{ textAlign: 'right' }} inputMode='decimal' value={props.current_money_value} />
    );
}

export default function MoneyInput(props: MoneyInputProps) {
    return (
        <div style={{ overflow: 'visible', display: 'flex', gap: '5px', width: '100%' }}>
            <div style={{ overflow: 'visible', flex: '1 0 0', position: 'relative' }}>
                <p style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '10px', margin: '0', color: 'rgba(0, 0 , 0, 0.5)', userSelect: 'none' }}>$</p>
                <MoneyTextInput current_money_value={ props.children as string} setMoneyValue={value => props.setValue(value) } />
                {/* <input onFocus={event => event.target.select()} className='form-text-input' style={{ textAlign: 'right' }} onChange={ props.setValue } inputMode={'decimal'} value={ props.children } /> */}
            </div>
            {
                props.currency !== undefined && props.setCurrency !== undefined ?
                <select defaultValue={ props.currency } onChange={ props.setCurrency } style={{ width: '4.5em' }}>
                    <option value={Currency.MXN}>MXN</option>
                    <option value={Currency.USD}>USD</option>
                </select>
                :
                null
            }
        </div>
    );
}