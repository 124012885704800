import latinize from "latinize";
import React from "react";
import { GenericItem } from "../../../Interfaces";

interface ItemSelectorProps {
    selected: <T extends GenericItem>(item: T | null) => void,
    items: any[],
    selected_item: any,
    filter_properties?: string[]
}

interface ItemSelectorState {
    focused: boolean,
    search_string: string,
    selecting_item: number,
    filtered_items: JSX.Element[]
}

class ItemSelector extends React.Component<ItemSelectorProps, ItemSelectorState> {
    option_list: React.RefObject<HTMLDivElement>;

    constructor(props: ItemSelectorProps) {
        super(props);

        this.state = {
            focused: false,
            search_string: '',
            selecting_item: 0,
            filtered_items: []
        }

        this.option_list = React.createRef();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);
    }

    componentDidMount() {
        this.generateItemOptions(this.state.selecting_item);
    }

    componentDidUpdate(previous_props: ItemSelectorProps, previous_state: ItemSelectorState) {
        if (previous_props.items !== this.props.items || previous_state.search_string !== this.state.search_string) {
            this.generateItemOptions(this.state.selecting_item);
        }
    }

    generateItemOptions(current_selected_item: number) {
        const item_options: JSX.Element[] = [];
        const searchable_search_string = this.state.search_string ? latinize(this.state.search_string).toLowerCase() : '';
        for (const item of this.props.items) {
            const searchable_display_name = latinize(item.display_name.toLowerCase());
            if (searchable_display_name.includes(searchable_search_string)) {
                item_options.push(<p onMouseDown={() => this.props.selected(item) } key={ item.id } className='item-selector-item' style={{ backgroundColor: item_options.length === current_selected_item ? 'rgba(0, 0, 0, 0.1)' : '' }} >{ item.display_name }</p>);
                continue;
            } else if (this.props.filter_properties?.length) {
                for (const property_string_to_filter of this.props.filter_properties) {
                    const item_helper: any = item;
                    if (item_helper[property_string_to_filter]) {
                        const searchable_property_string = latinize(item_helper[property_string_to_filter]).toLowerCase();
                        if ((searchable_property_string !== null || searchable_property_string !== undefined) && searchable_property_string.includes(searchable_search_string)) {
                            item_options.push(<p onMouseDown={() => this.props.selected(item) } key={ item.id } className='item-selector-item' style={{ backgroundColor: item_options.length === current_selected_item ? 'rgba(0, 0, 0, 0.1)' : '' }}>{ item.display_name }</p>);
                            break;
                        }
                    }
                }
            }
        }
        this.setState({ filtered_items: item_options, selecting_item: current_selected_item });
    }

    handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (this.props.selected_item) {
            this.props.selected(null);
            this.setState({ search_string: event.target.value });
            return;
        }
        this.setState({ search_string: event.target.value }, () => this.generateItemOptions(0));
    }

    handleNavigation(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (this.state.selecting_item < this.state.filtered_items.length - 1) {
                this.generateItemOptions(this.state.selecting_item + 1);
                return;
            }
        }
        if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (this.state.selecting_item > 0) {
                this.generateItemOptions(this.state.selecting_item - 1);
                return;
            }
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            for (const item of this.props.items) {
                if (this.state.filtered_items[this.state.selecting_item].key) {
                    if (item.id === Number.parseInt(this.state.filtered_items[this.state.selecting_item].key as string)) {
                        this.props.selected(item);
                        (document.activeElement as HTMLElement).blur();
                        return;
                    }
                }
            }
        }
    }

    render() {
        return (
            <div onKeyDown={ this.handleNavigation } onFocus={() => this.setState({ focused: true })} onBlur={() => this.setState({ focused: false })} style={{ width: '100%', overflow: 'visible', position: 'relative' }}>
                <input onChange={ this.handleInputChange } className='form-text-input' value={ this.props.selected_item ? this.props.selected_item.display_name : this.state.search_string}></input>
                <div ref={ this.option_list } className='no-scrollbar float-from-right' style={{
                    position: 'absolute',
                    marginTop: '5px',
                    height: 'fit-content',
                    maxHeight: '33vh',
                    width: 'inherit',
                    backgroundColor: 'white',
                    display: this.state.focused ? 'flex' : 'none',
                    flexDirection: 'column',
                    borderRadius: '10px',
                    outline: 'solid 1px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    gap: '5px',
                    overflow: 'auto',
                    zIndex: '10',
                    boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.1)'
                    }}>
                        { this.state.filtered_items }
                </div>
            </div>
        );
    }
}

export default ItemSelector;